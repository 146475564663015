// src/components/ReportISC02InfoRefDetail.js
import { Button, Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchReportISC02InfoRefDetail from '../../components/reportISC02/reportISC02InfoRefDetail/CardSearchReportISC02InfoRefDetail';
import TableReportISC02InfoRefDetail from '../../components/reportISC02/reportISC02InfoRefDetail/TableReportISC02InfoRefDetail';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';
import { navigateISC02 } from '../../components/reportISC02/fn';
import { useFetchReportISC02InfoRefDetail } from '../../features/hooks/reportISC02/useFetchReportISC02InfoRefDetail';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { fetchReportISCSubmissionGetDetailNote, fetchReportISCSubmissionSaveUpload } from '../../features/redux/apiSlice/apiReportISC02Slice';
import { downloadFile } from '../../helper/downloadFile';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import { resetDetailNoteDataInput } from '../../features/redux/slice/detailNoteSlice';

const ReportISC02InfoRefDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC02InfoRefDetail();
    const {
        tableReportISC02InfoRefDetail,
    } = useSelector(state => state.table);
    const {
        meta = {},
        data = {},
    } = tableReportISC02InfoRefDetail
    const {
        upload_id = null,
        can_save = false,
        cycle_name = '',
        cycle_id = null,
        due_date = '',
        submission_id = null,
        reference_code = '',
        report_items = [],
        pagination = {},
    } = data
    console.log(`data de`, data)
    let { infoId = '', submissionId = '', submissionDetailId = '' } = useParams();

    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC02 = cycle_name || infoId
    const refReportISC02 = reference_code || submissionId
    const uploadReportISC02 = upload_id || submissionDetailId

    console.log(`reference_code`, reference_code)

    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-02` },
        { title: `รายงานงบการเงิน ${stringDefault(nameReportISC02, '-')}`, link: reportISC02Host.reportISC02InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC02, '-')}`, link: reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-')) }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
        { title: `ส่งข้อมูล`, link: `` },
    ]
    const handleBackButton = () => {
        console.log('Back button clicked')
        let url = reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'))
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    }
    const handleDownload = (record, url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url
        if (URL) {
            downloadFile(URL, fileName)
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    const onClickFileExcel = (record) => {
        const { report_file_url = '', report_file_name = '' } = record
        handleDownload(record, report_file_url, report_file_name)
    }

    const onClickViewMore = (record) => {
        dispatch(setLoadingTimeout());

        let url = reportISC02Host.reportISC02SubmissionDetailReportByTypeBase(stringDefault(record.cycle_id || infoId, '-'), stringDefault(record.submission_id || submissionId, '-'),
            stringDefault(record.upload_id || submissionDetailId, '-'), navigateISC02(record.report_name, record.file_record_id)
        );
        dispatch(resetDetailNoteDataInput());
        // if (record.report_name == "รายงานผู้สอบบัญชี" && !!record.file_record_id) {
        // http://localhost:3000/report-isc-02/info/1/submission/22/submission-detail/42/submission-auditor?file-record-id=337
        // url += !!record.file_record_id ? `?file-record-id=${stringDefault(record.file_record_id, '-')}` : ''
        // }
        // console.log('url: ' ,url );
        // dispatch(fetchReportISCSubmissionGetDetailNote({
        //     "submission_id": record.submission_id || submissionId,
        //     "upload_id": record.upload_id || submissionDetailId,
        //     "file_record_id": record.file_record_id =='null'?null : record.file_record_id
        // }));
        setTimeout(() => {
            navigate(url, { state: { fileRecordId: record.file_record_id ?? null } });
        }, 1000);
        return {
        }
    }
    const handleSaveButton = () => {
        dispatch(fetchReportISCSubmissionSaveUpload({
            "submission_id": submissionId,
            "upload_id": submissionDetailId,
            "info_id": infoId
        }));
    }

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 20]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02InfoRefDetail
                            nameReportISC02={nameReportISC02}
                            refReportISC02={refReportISC02}
                            uploadReportISC02={uploadReportISC02}
                        />
                    </Col>
                    {/* <Col style={{ width: "100%", padding: 0, }}>
                        <Typography.Text strong style={{ color: 'var(--color-table-text)' }}>{`จำนวนรายงานทั้งหมด ${stringDefault(report_count, '-')} รายงาน`}</Typography.Text>
                    </Col> */}
                    {/* <Row gutter={[16, 16]} justify={'space-between'} align={'middle'} style={{ width: "100%", margin: 0, }}>
                        <Col style={{ padding: 0, }}>
                            <Typography.Text strong style={{ color: 'var(--color-error-text)' }}>{`พบ Error จำนวน ${stringDefault(error_count, '-')} รายการ โปรดตรวจสอบ`}</Typography.Text>
                        </Col>
                        <Col style={{ padding: 0, }}>
                            <Button
                                type="primary"
                                icon={
                                    <Image width={20} src={IconFileExcelWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                }
                                style={{ background: 'var(--color-error)', display: 'flex', }}
                                onClick={downloadXLSFileTemplateISC}
                            >
                                <Flex>
                                    Export Error
                                </Flex>
                            </Button>
                        </Col>
                    </Row> */}
                    {/* <Tabs
                        onChange={onChangeTab}
                        type="card"
                        className='w-full'
                        activeKey={isSelectTab}
                        size="small"
                        tabBarExtraContent={
                            <div className='grid grid-cols-3 gap-4 mr-5 items-center'>
                                <Button type="primary" shape="circle" size="small" style={{ background: report.findIndex(tab => tab === isSelectTab) <= 0 ? 'var(--color-gray-primary)' : '' }} icon={<LeftOutlined />} onClick={handlePrevTab} />
                                <Button type="primary" shape="circle" size="small" style={{ background: report.findIndex(tab => tab === isSelectTab) >= report.length - 1 ? 'var(--color-gray-primary)' : '' }} icon={<RightOutlined />} onClick={handleNextTab} />
                                <Tooltip overlayInnerStyle={{ width: '300px', maxWidth: '1000px' }} placement="leftTop" title={tooltipInformation} key='tooltip-information' style={{ verticalAlign: 'sub' }} color="#FFFFFF" autoAdjustOverflow={true}>
                                    <Image width={18} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                </Tooltip>
                            </div>
                        }
                        items={allsheets.data.report_code.map((data, i) => {
                            return {
                                label: `${data}`,
                                key: `${data}`,
                                children: (
                                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                                        <Row gutter={[8, 8]}>
                                            <Col style={{ width: "100%", }}>
                                                <TableReportISC02InfoRefDetail
                                                    dataList={error_items}
                                                    pagination={pagination}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                ),
                            };
                        })}
                    /> */}

                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>
                            <Col style={{ width: "100%", }}>
                                <TableReportISC02InfoRefDetail
                                    dataList={report_items}
                                    onClickViewMore={onClickViewMore}
                                    pagination={pagination}
                                    onClickFileExcel={onClickFileExcel}
                                />
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row items='middle' justify={'center'} className="py-6">
                    <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white" onClick={handleBackButton}>ย้อนกลับ</Button>
                    <Button
                        disabled={!can_save}
                        type="primary"
                        className='font-bold'
                        style={{ background: can_save ? 'var(--color-primary)' : 'var(--color-gray-primary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', color: 'white' }}
                        onClick={handleSaveButton}
                    >
                        ส่งข้อมูล
                    </Button>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISC02InfoRefDetail;