import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Image, Input, Row, Select, Tabs, Tooltip, Typography } from "antd";
import { uniqueId } from "lodash";
import LuckyExcel from "luckyexcel";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchReportCycleSubmissionImportExcel, fetchReportCycleSubmissionUploadExcel, fetchReportISCSubmissionCheckDataName, fetchReportISCSubmissionGetExcelJson, fetchReportISCSubmissionMapError, fetchReportISCSubmissionMapValue } from "../../../features/redux/apiSlice/apiReportISC02Slice";
import { setModalUploadReportISC02 } from "../../../features/redux/slice/modalSlice";
import { setSelectTabAndStep, setSelectTabField } from "../../../features/redux/slice/reportISCTabSlice";
import { initialUploadCheckDataNameISC02, initialUploadFileExcelISC02, initialUploadFileISC02 } from "../../../features/redux/slice/uploadFileSlice";
import { parseJsonString, stringDefault } from "../../../helper/base";
import { IconFileExcelWhite, IconInformation } from "../../../helper/icon";

const { Option } = Select;

const SubmissionImportReportExcel = () => {
    const luckysheet = window.luckysheet;
    const dispatch = useDispatch();
    // const { editUploadFileISC02 } = useSelector(state => state.user);
    const sheetRef = useRef();
    const fileInputRef = useRef();
    const [form] = Form.useForm();
    const [scriptsLoaded, setScriptsLoaded] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [sheetId] = useState(uniqueId("datasheet_"));

    const company_id = useSelector((state) => state?.user?.companyInfo?.company_id);
    const { isSelectTab = "", stepTab = 0, isSelectTabDraft = "" } = useSelector(state => state.reportISCTab);
    const { uploadFileISC02, editUploadFileISC02, fileExcelISC02, fileExcelCheckDataNameISC02, fileExcelMapErrorISC02, fileExcelMapValueISC02, uploadFileLuckySheet, } = useSelector(state => state.uploadFile);
    const { filePart, jsonPart } = fileExcelISC02 || initialUploadFileExcelISC02;
    const { fileSheet = null } = filePart || initialUploadFileExcelISC02;
    const { data: dataUploadFileISC, err: errUploadFileISC } = uploadFileISC02;
    const { file_id = '', sheet_names = [], isFileExcelISC02Finish = false, currency_units = [] } = dataUploadFileISC || { file_id: '', sheet_names: [], isFileExcelISC02Finish: false, currency_units: [] };
    const { msg = '', icon = '' } = errUploadFileISC || initialUploadFileISC02;
    const {
        // currency_units,
        can_set_name: can_set_name_FileExcelISC02 = false,
        can_set_value: can_set_value_FileExcelISC02 = false,
        can_save_report: can_save_report_FileExcelISC02 = false,
    } = jsonPart || {}; // stepTab: 0

    const {
        data: dataFileExcelCheckDataNameISC02,
        can_set_name: can_set_name_FileExcelCheckDataNameISC02 = false,
        can_set_value: can_set_value_FileExcelCheckDataNameISC02 = false,
    } = fileExcelCheckDataNameISC02; // stepTab: 1
    const { msd_names = null, map_errors = null } = dataFileExcelCheckDataNameISC02 || initialUploadCheckDataNameISC02;

    const {
        data: dataFileExcelMapErrorISC02,
        can_set_name: can_set_name_FileExcelMapErrorISC02 = false,
        can_set_value: can_set_value_FileExcelMapErrorISC02 = false,
    } = fileExcelMapErrorISC02; // stepTab: 2

    const {
        data: dataFileExcelMapValueISC02,
        can_set_value: can_set_value_FileExcelMapValueISC02 = false,
    } = fileExcelMapValueISC02; // stepTab: 3

    let { infoId = '', submissionId = '', submissionDetailId = '', fileRecordId = '' } = useParams();
    const isModeEdit = !!fileRecordId && fileRecordId >= 0 && fileRecordId != '-'


    useEffect(() => {
        if (fileRecordId != '-') {
            dispatch(fetchReportISCSubmissionGetExcelJson({
                submission_id: Number(submissionId),
                file_record_id: Number(fileRecordId),
            }));
        }

        const loadScripts = async () => {
            const jQueryScript = document.createElement('script');
            jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
            jQueryScript.type = 'text/javascript';
            jQueryScript.onload = async () => {
                const mousewheelScript = document.createElement('script');
                mousewheelScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery-mousewheel/3.1.13/jquery.mousewheel.min.js';
                mousewheelScript.type = 'text/javascript';
                mousewheelScript.onload = async () => {
                    const stylesheets = [
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/css/pluginsCss.css',
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/plugins.css',
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/css/luckysheet.css',
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/assets/iconfont/iconfont.css',
                    ];
                    stylesheets.forEach(href => {
                        const link = document.createElement('link');
                        link.rel = 'stylesheet';
                        link.href = href;
                        document.head.appendChild(link);
                    });

                    const luckysheetModule = await import('luckysheet');
                    const luckysheet = luckysheetModule.default || luckysheetModule;
                    window.luckysheet = luckysheet;

                    setScriptsLoaded(true);
                };
                document.head.appendChild(mousewheelScript);
            };
            document.head.appendChild(jQueryScript);
        };

        loadScripts();
    }, []);

    useEffect(() => {
        if (scriptsLoaded && sheetRef.current && fileData) {
            // const luckysheet = window.luckysheet;
            if (luckysheet) {
                sheetRef.current.setAttribute("id", sheetId);
                luckysheet.create({
                    container: sheetId,
                    data: fileData?.sheets,
                    allowUpdate: false,
                    updateUrl: '',
                    autoFormat: false,
                    allowCopy: false,
                    showtoolbar: false,
                    showtoolbarConfig: {
                        undoRedo: false,
                        paintFormat: false,
                        currencyFormat: false,
                        percentageFormat: false,
                        numberDecrease: false,
                        numberIncrease: false,
                        moreFormats: false,
                        font: false,
                        fontSize: false,
                        bold: false,
                        italic: false,
                        strikethrough: false,
                        underline: false,
                        textColor: false,
                        fillColor: false,
                        border: false,
                        mergeCell: false,
                        horizontalAlignMode: false,
                        verticalAlignMode: false,
                        textWrapMode: false,
                        textRotateMode: false,
                        image: false,
                        link: false,
                        chart: false,
                        postil: false,
                        pivotTable: false,
                        function: false,
                        frozenMode: false,
                        sortAndFilter: false,
                        conditionalFormat: false,
                        dataVerification: false,
                        splitColumn: false,
                        screenshot: false,
                        findAndReplace: false,
                        protection: false,
                        print: false,
                    },
                    showinfobar: false,
                    showsheetbar: false,
                    showsheetbarConfig: {
                        add: false,
                        menu: false,
                        sheet: false
                    },
                    showstatisticBar: false,
                    showstatisticBarConfig: {
                        count: false,
                        view: false,
                        zoom: false
                    },
                    enableAddRow: false,
                    enableAddBackTop: false,
                    userInfo: {
                        userInfo: false,
                    },
                    showConfigWindowResize: false,
                    forceCalculation: false,
                    cellRightClickConfig: {
                        copy: false,
                        copyAs: false,
                        paste: false,
                        insertRow: false,
                        insertColumn: false,
                        deleteRow: false,
                        deleteColumn: false,
                        deleteCell: false,
                        hideRow: false,
                        hideColumn: false,
                        rowHeight: false,
                        columnWidth: false,
                        clear: false,
                        matrix: false,
                        sort: false,
                        filter: false,
                        chart: false,
                        image: false,
                        link: false,
                        data: false,
                        cellFormat: false
                    },
                    sheetRightClickConfig: {
                        delete: false,
                        copy: false,
                        rename: false,
                        color: false,
                        hide: false,
                        move: false,
                    },
                    sheetFormulaBar: false,
                    defaultFontSize: 16,
                    limitSheetNameLength: false,
                    cellEdit: false,
                    allowEdit: false,
                    enableAddRow2: false,
                    showHelpBox: false,
                    hook: {
                        rangeSelect: (range) => {
                            if (isModeEdit) return null

                            form.getFieldValue()
                            const rangeAxis = luckysheet.getRangeAxis(range);
                            const rangeRowCol = luckysheet.getRange(range);

                            form.setFieldsValue({
                                nameRangeUseFrom: stepTab === 1 ? rangeAxis.toString() : form.getFieldValue('nameRangeUseFrom'),
                                valueCellRangeUseFrom: stepTab === 2 ? `Cell : ${stringDefault(rangeAxis.toString(), '-')}` : form.getFieldValue('valueCellRangeUseFrom'),
                                cellRangeUseFrom: stepTab === 2 ? rangeAxis.toString() : form.getFieldValue('cellRangeUseFrom'),
                                msdNameUseFrom: stepTab === 2 ? form.getFieldValue('msdNameUseFrom') : form.getFieldValue('msdNameUseFrom'),
                                newNameUseFrom: stepTab === 2 ? form.getFieldValue('newNameUseFrom') : form.getFieldValue('newNameUseFrom'),
                                valueRangeUseFrom: stepTab === 3 ? rangeAxis[0]?.toString() ? rangeAxis[0]?.toString() : form.getFieldValue('valueRangeUseFrom') : form.getFieldValue('valueRangeUseFrom'),
                                prevValueRangeUseFrom: stepTab === 3 ? rangeAxis[1]?.toString() ? rangeAxis[1]?.toString() : form.getFieldValue('prevValueRangeUseFrom') : form.getFieldValue('prevValueRangeUseFrom'),
                                currencyUnitUseFrom: stepTab === 3 ? form.getFieldValue('currencyUnitUseFrom') : form.getFieldValue('currencyUnitUseFrom'),
                            });
                            form.getFieldValue()
                        }
                    }
                });

            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [luckysheet, scriptsLoaded, fileData, sheetRef, form, stepTab]);

    useEffect(() => {
        // const luckysheet = window.luckysheet;
        if (luckysheet) {
            form.resetFields();
            if (!uploadFileLuckySheet && !uploadFileLuckySheet?.uid) {
                setFileData(null);
            } else if (!isModeEdit) {
                LuckyExcel.transformExcelToLucky(uploadFileLuckySheet, (exportJson, luckysheetfile) => {
                    if (!exportJson?.sheets || exportJson?.sheets.length === 0) {
                        alert("Failed to read the content of the excel file, currently does not support xls files!");
                        return;
                    }

                    setFileData(exportJson);

                    if (window.luckysheet && typeof window.luckysheet.destroy === 'function') {
                        window.luckysheet.destroy();
                    }

                    if (sheetRef.current) {
                        sheetRef.current.setAttribute("id", sheetId);
                        window.luckysheet.create({
                            container: sheetId,
                            data: exportJson.sheets,
                            title: 'Sheet1',
                        });

                        const sheetIndex = exportJson.sheets.findIndex(sheet => sheet.name === isSelectTab);
                        if (sheetIndex !== -1) {
                            window.luckysheet.setSheetActive(sheetIndex);
                        } else {
                            console.warn(`Sheet with name ${isSelectTab} not found`);
                        }

                        // Log borderInfoCompute
                        // const borderInfoCompute = window.luckysheet.getSheetData().config.borderInfoCompute;
                        // console.log('borderInfoCompute:', borderInfoCompute);
                    }
                });
            }
        }
        // return () => {
        //     if (window.luckysheet && typeof window.luckysheet.destroy === 'function') {
        //         window.luckysheet.destroy();
        //     }
        // };
    }, [luckysheet, uploadFileLuckySheet, isSelectTab]);

    useEffect(() => {
        // const luckysheet = window.luckysheet;
        if (luckysheet) {
            const parsedData = parseJsonString(editUploadFileISC02);
            if (parsedData) {
                if (!parsedData?.sheets || parsedData?.sheets.length === 0) {
                    alert("Failed to read the content of the excel file, currently does not support xls files!");
                    return;
                }

                setFileData(parsedData);

                if (window.luckysheet && typeof window.luckysheet.destroy === 'function') {
                    window.luckysheet.destroy();
                }

                if (sheetRef.current) {
                    sheetRef.current.setAttribute("id", sheetId);
                    window.luckysheet.create({
                        container: sheetId,
                        data: parsedData.sheets,
                        title: 'Sheet1',
                    });

                    const sheetIndex = parsedData.sheets.findIndex(sheet => sheet.name === isSelectTab);
                    if (sheetIndex !== -1) {
                        window.luckysheet.setSheetActive(sheetIndex);
                    } else {
                        console.warn(`Sheet with name ${isSelectTab} not found`);
                    }

                }
            }
        }

        // return () => {
        //     if (window.luckysheet && typeof window.luckysheet.destroy === 'function') {
        //         window.luckysheet.destroy();
        //     }
        // };
    }, [luckysheet, editUploadFileISC02, isSelectTab]);


    useEffect(() => {
        const updatedFileData = { ...fileData };
        const sheetIndex = updatedFileData?.sheets?.findIndex(sheet => sheet?.name === isSelectTab);
        if (fileData && map_errors?.length > 0) {
            if (sheetIndex !== -1) {

                if (updatedFileData.sheets[sheetIndex].config.borderInfo) {
                    const borderInfoFilter = updatedFileData.sheets[sheetIndex].config?.borderInfo?.filter(item => item?.id !== "rangeIsc02")
                    updatedFileData.sheets[sheetIndex].config.borderInfo = borderInfoFilter
                }

                map_errors.forEach(error => {
                    const range = cellToRange(error.name_cell);
                    if (!updatedFileData.sheets[sheetIndex].config.borderInfo) {
                        updatedFileData.sheets[sheetIndex].config.borderInfo = [];
                    }

                    updatedFileData.sheets[sheetIndex].config.borderInfo.push({
                        id: "rangeIsc02",
                        rangeType: "range",
                        borderType: "border-all",
                        style: "1",
                        color: "#ff0000",
                        range: [{ row: [range.row, range.row], column: [range.column, range.column] }]
                    });
                });

                setFileData(updatedFileData);

                if (window.luckysheet && typeof window.luckysheet.destroy === 'function') {
                    window.luckysheet.destroy();
                }

                sheetRef.current.setAttribute("id", sheetId);
                window.luckysheet.create({
                    container: sheetId,
                    data: updatedFileData.sheets,
                    title: 'Sheet1',
                });

                window.luckysheet.setSheetActive(sheetIndex);
            }
        } else if (fileData && map_errors?.length == 0) {
            if (sheetIndex !== -1) {
                const borderInfoFilter = updatedFileData.sheets[sheetIndex].config?.borderInfo?.filter(item => item?.id !== "rangeIsc02")
                updatedFileData.sheets[sheetIndex].config.borderInfo = borderInfoFilter
            }
            setFileData(updatedFileData);
        }
    }, [map_errors, map_errors?.length]);


    const handleResize = () => {
        const luckysheet = window.luckysheet;
        const element = document.getElementById('dataSheetClass');
        if (element && luckysheet && typeof luckysheet.resize === 'function') {
            luckysheet.resize();
        }
    };

    /* 
      กำหนดชื่อข้อมูล can_set_name
      กำหนดค่าข้อมูล can_set_value
    */
    const isShowBtnNeme = () => {
        return stepTab == 0 ? false :
            stepTab == 1 ? false :
                stepTab == 2 ? false :
                    stepTab == 3 ? true :
                        stepTab == 4 ? true :
                            false
    }

    const isShowBtnValue = () => {
        return stepTab == 0 ? can_set_value_FileExcelISC02 :
            stepTab == 1 ? can_set_value_FileExcelCheckDataNameISC02 :
                stepTab == 2 ? can_set_value_FileExcelMapErrorISC02 :
                    stepTab == 3 ? can_set_value_FileExcelMapValueISC02 :
                        stepTab == 4 ? can_set_value_FileExcelMapValueISC02 :
                            false
    }

    const isShowBtnSave = () => {
        return stepTab == 0 ? can_save_report_FileExcelISC02 :
            stepTab == 1 ? false :
                stepTab == 2 ? false :
                    stepTab == 3 ? false :
                        stepTab == 4 ? false :
                            false
    }

    const optionsMapError = (list) => list?.map((item) => ({ ...item, value: item?.id, label: item?.name }));
    const optionsCurrencyUnits = (list) => list?.map((item) => ({ value: item, label: item }));

    const cellToRange = (cell) => {
        const columnLetter = cell.match(/[A-Z]+/)[0];
        const rowNumber = parseInt(cell.match(/\d+/)[0], 10);

        const columnIndex = columnLetter.split('').reduce((acc, char) => {
            return acc * 26 + char.charCodeAt(0) - ('A'.charCodeAt(0) - 1);
        }, 0) - 1;

        const rowIndex = rowNumber - 1;

        return {
            row: rowIndex,
            column: columnIndex
        };
    }

    const deepCloneAndEnsureExtensible = (obj) => {
        if (obj && typeof obj === 'object') {
            const copy = Array.isArray(obj) ? [] : {};
            Object.keys(obj).forEach(key => {
                copy[key] = deepCloneAndEnsureExtensible(obj[key]);
            });
            return copy;
        }
        return obj;
    };

    const ensureExtensible = (obj) => {
        if (!Object.isExtensible(obj)) {
            return { ...obj }; // Create a new extensible object by spreading properties
        }
        return obj;
    };

    const onChangeTab = (key) => {
        if (isModeEdit) {
            dispatch(setSelectTabAndStep({
                isSelectTab: key,
                stepTab: 1
            }));
        } else {
            dispatch(fetchReportCycleSubmissionImportExcel({
                submission_id: Number(submissionId),
                file_id: file_id,
                sheet_name: key,
            }));
        }
    };

    const handleNextTab = () => {
        const currentIndex = sheet_names.findIndex(tab => tab === isSelectTab);
        if (currentIndex < sheet_names.length - 1) {
            onChangeTab(sheet_names[currentIndex + 1]);
        }
    };

    const handlePrevTab = () => {
        const currentIndex = sheet_names.findIndex(tab => tab === isSelectTab);
        if (currentIndex > 0) {
            onChangeTab(sheet_names[currentIndex - 1]);
        }
    };

    const handleChangeForm = (e, key = '') => {
        form.getFieldValue()
        let fKey = '';
        let fValue = '';
        if (!!key) {
            fKey = key;
            fValue = e;
        } else {
            fKey = e?.target?.name;
            fValue = e?.target?.value;
        }

        if (fKey === 'msdNameUseFrom') {
            form.setFieldsValue({
                newNameUseFrom: '',
            });
        }
        if (fKey === 'newNameUseFrom') {
            form.setFieldsValue({
                msdNameUseFrom: '',
            });
        }

        form.setFieldsValue({
            [fKey]: fValue
        });
        form.getFieldValue()
    }

    const handleOpenModalUploadReportISC02 = () => {
        setTimeout(() => {
            dispatch(setModalUploadReportISC02({ isOpen: true }));
        }, 1000);
    };

    const handleStep01 = () => {
        if (isModeEdit) return null

        const {
            nameRangeUseFrom,
            msdNameUseFrom,
            newNameUseFrom,
            valueRangeUseFrom,
            prevValueRangeUseFrom,
            currencyUnitUseFrom,
        } = form.getFieldsValue();

        dispatch(fetchReportISCSubmissionCheckDataName({
            submission_id: Number(submissionId),
            file_id: file_id,
            sheet_name: isSelectTab,
            name_range: nameRangeUseFrom,
        }));
    };

    const handleStep02 = () => {
        if (isModeEdit) return null

        const checkNameCell = (nameCell) => {
            const mapping = [...map_errors]?.find(mapping => mapping.name_cell === nameCell);
            return mapping ? mapping.mapping_id : null;
        };

        const mappingId = checkNameCell(form.getFieldValue('cellRangeUseFrom'))
        const msdNameUseFromData = form.getFieldValue('msdNameUseFrom')
        // if (!!mappingId || mappingId === 0) {
        dispatch(fetchReportISCSubmissionMapError({
            mapping_id: mappingId,
            msd_name: stringDefault(optionsMapError(msd_names)?.find(item => item.id == msdNameUseFromData)?.label, ''),
            msd_id: msdNameUseFromData ? stringDefault(msdNameUseFromData, '') : null,
            new_name: stringDefault(form.getFieldValue('newNameUseFrom'), ''),
            sheet_name: isSelectTab,
            file_id: file_id,
            company_id: company_id,
        }));
        // }
    };

    const handleStep03 = async () => {
        if (isModeEdit) return null

        try {
            const values = await form.validateFields(['valueRangeUseFrom', 'prevValueRangeUseFrom', 'currencyUnitUseFrom']);
            const {
                nameRangeUseFrom,
                msdNameUseFrom,
                newNameUseFrom,
                valueRangeUseFrom,
                prevValueRangeUseFrom,
                currencyUnitUseFrom,
            } = values;

            dispatch(fetchReportISCSubmissionMapValue({
                submission_id: Number(submissionId),
                file_id: file_id,
                sheet_name: isSelectTab,
                value_range: valueRangeUseFrom,
                prev_value_range: prevValueRangeUseFrom,
                currency_unit: currencyUnitUseFrom
            }));
        } catch (error) {
            console.log(`----  handleStep03  error:`, error);
        }
    };

    const handleFileChange = (event) => {
        const files = event.target.files;

        if (files.length > 0) {
            let file = files[0];
            delete file.uid2;
            const maxSize = 100 * 1024 * 1024;

            if (file.size > maxSize) {
                alert("File size exceeds 10MB. Please upload a smaller file.");
                event.target.value = ""; // Reset the input field
                return;
            }

            dispatch(fetchReportCycleSubmissionUploadExcel({
                submission_id: Number(submissionId),
                upload_id: Number(submissionDetailId),
                type: "ReportISC02",
                file: file,
            }))

            LuckyExcel.transformExcelToLucky(file, (exportJson, luckysheetfile) => {
                if (!exportJson?.sheets || exportJson?.sheets.length === 0) {
                    alert("Failed to read the content of the excel file, currently does not support xls files!");
                    return;
                }

                setFileData(exportJson);

                const luckysheet = window.luckysheet;
                if (luckysheet && typeof luckysheet.destroy === 'function') {
                    luckysheet.destroy();
                }
            });
        }

    };

    return (
        <Row style={{ gap: '6px', padding: "18px 32px" }}>
            <Col flex={1} style={{ gap: '6px', display: 'flex', flexDirection: 'column' }}>

                {/* Box Tab */}
                <Row justify="space-between" style={{ gap: '6px' }}>
                    <Col flex={1}>
                        <Tabs
                            activeKey={isSelectTab}
                            onChange={onChangeTab}
                            type="card"
                            className="tab-report-excel w-full"
                            size="small"
                            tabBarExtraContent={
                                <Row align="middle" style={{ display: 'flex', gap: "8px" }}>
                                    {sheet_names.length > 0 && (
                                        <>
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                style={{ border: '0', padding: '6px !important', minWidth: '24px', minHeight: '24px', maxWidth: '24px', maxHeight: '24px', background: sheet_names.findIndex(tab => tab === isSelectTab) <= 0 ? 'var(--color-gray-primary)' : '' }}
                                                icon={
                                                    <LeftOutlined
                                                        style={{ display: 'flex', fontSize: '10px', color: 'var(--color-white)' }}
                                                    />
                                                }
                                                disabled={sheet_names.findIndex(tab => tab === isSelectTab) <= 0}
                                                onClick={handlePrevTab}
                                            />
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                style={{ border: '0', padding: '6px !important', minWidth: '24px', minHeight: '24px', maxWidth: '24px', maxHeight: '24px', background: sheet_names.findIndex(tab => tab === isSelectTab) >= sheet_names.length - 1 ? 'var(--color-gray-primary)' : '' }}
                                                icon={
                                                    <RightOutlined
                                                        style={{ display: 'flex', fontSize: '10px', color: 'var(--color-white)' }}
                                                    />
                                                }
                                                disabled={sheet_names.findIndex(tab => tab === isSelectTab) >= sheet_names.length - 1}
                                                onClick={handleNextTab}
                                            />
                                        </>
                                    )}
                                    <Button
                                        hidden={!isShowBtnNeme()}
                                        type="primary"
                                        className="importAction"
                                        style={{ background: 'var(--color-quinary)', paddingLeft: '2rem', paddingRight: '2rem' }}
                                        onClick={() => {
                                            if (isModeEdit) return null
                                            dispatch(setSelectTabField({ field: 'stepTab', data: 1 }));
                                        }}
                                    >
                                        กำหนดชื่อข้อมูล
                                    </Button>
                                    <Button
                                        hidden={!isShowBtnValue()}
                                        type="primary"
                                        className="importAction"
                                        style={{ background: 'var(--color-quinary)', paddingLeft: '2rem', paddingRight: '2rem' }}
                                        onClick={() => {
                                            if (isModeEdit) return null
                                            dispatch(setSelectTabField({ field: 'stepTab', data: 3 }));
                                        }}
                                    >
                                        กำหนดค่าข้อมูล
                                    </Button>

                                    <Button
                                        type="primary"
                                        className='importAction'
                                        icon={
                                            <Image width={20} src={IconFileExcelWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle' }} />
                                        }
                                        style={{ background: fileData || isModeEdit ? 'var(--color-gray-primary)' : 'var(--color-excel)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem' }}
                                        disabled={fileData || isModeEdit}
                                        onClick={() => {
                                            if (fileData || isModeEdit) return null
                                            handleOpenModalUploadReportISC02()
                                        }}
                                    >
                                        Import
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    <Button
                                        hidden
                                        type="primary"
                                        onClick={() => window.location.reload()}
                                    >
                                        reload
                                    </Button>
                                </Row>
                            }
                            items={[...sheet_names].map((data) => ({
                                label: data,
                                key: data,
                                children: null,
                            }))}
                        />
                    </Col>
                </Row>
                {/* Box Tab */}

                <Row style={{ gap: '6px' }}>
                    {/* Box Right */}
                    <Col style={{ flex: 3, overflow: 'hidden' }}>
                        <div
                            style={{
                                height: '40vh',
                                maxHeight: '40vh',
                            }}
                        >
                            {
                                !fileData ?
                                    (
                                        <Empty
                                            style={{
                                                padding: '35px 25px',
                                                minHeight: '100%',
                                                maxHeight: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            imageStyle={{ display: 'none' }}
                                            description="-- กรุณา Import ไฟล์ --"
                                        />
                                    )
                                    :
                                    (
                                        <div
                                            id="dataSheetClass"
                                            className="dataSheetClass"
                                            ref={sheetRef}
                                            style={{
                                                margin: '0px',
                                                padding: '0px',
                                                minHeight: '40vh',
                                                maxHeight: '100%',
                                                left: '0px',
                                                top: '0px',
                                                zIndex: 999,
                                                position: 'relative',
                                            }}
                                        />
                                    )
                            }

                        </div>
                    </Col>
                    {/* Box Right */}

                    {/* Box Left */}
                    <Col style={{ width: 'var(--width-report-isc02)', display: stepTab === 0 ? 'none' : 'flex' }}>
                        <Card
                            title={stepTab === 1 ? `กำหนดชื่อข้อมูล` : stepTab === 2 ? `ตั้งค่าเซลล์` : stepTab === 3 ? `กำหนดค่าข้อมูล` : `ตั้งค่าเซลล์`}
                            rootClassName="h-full"
                            className="h-full"
                            style={{
                                flex: 1,
                            }}
                            classNames={{
                                header: '!px-[11px] !py-[0px] !min-h-[30px]',
                                body: '!p-[11px]',
                            }}
                            styles={{
                                header: {
                                    padding: '11px !important',
                                    height: '100% !important',
                                },
                                body: {
                                    padding: '11px !important',
                                    height: '100% !important',
                                }
                            }}
                        >
                            <Form form={form}>
                                {stepTab !== 0 && (
                                    <>
                                        <Col style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Typography.Paragraph>{`Sheet : ${stringDefault(isSelectTab, '-')}`}</Typography.Paragraph>
                                            </Col>
                                        </Col>
                                    </>
                                )}
                                {stepTab == 2 && (
                                    <>
                                        <Col style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Form.Item name="valueCellRangeUseFrom" style={{ minWidth: "100%", margin: 0 }}>
                                                    <Input
                                                        name="valueCellRangeUseFrom"
                                                        variant="borderless"
                                                        disabled
                                                        style={{
                                                            cursor: 'default !important',
                                                            color: 'inherit',
                                                            padding: 0,
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Col>
                                    </>
                                )}
                                {stepTab === 1 && (
                                    <>
                                        <Col span={5} style={{ minWidth: "100%", margin: 0, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Row gutter={[5, 5]}>
                                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                    <Typography.Text className="text-[14px]">คอลัมน์ชื่อข้อมูล</Typography.Text>
                                                    <Form.Item name="nameRangeUseFrom" style={{ minWidth: "100%", margin: 0 }}>
                                                        <Input
                                                            name="nameRangeUseFrom"
                                                            placeholder=""
                                                            value={form.getFieldValue('nameRangeUseFrom')}
                                                            disabled={isModeEdit}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[5, 5]} justify="center">
                                                <Button
                                                    type="primary"
                                                    onClick={() => dispatch(setSelectTabField({ field: 'stepTab', data: 0 }))}
                                                    className="font-bold"
                                                    block={false}
                                                    hidden
                                                >
                                                    ย้อนกลับ
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    onClick={handleStep01}
                                                    disabled={isModeEdit}
                                                    style={{ background: isModeEdit ? 'var(--color-gray-primary)' : 'var(--color-primary)', color: 'var(--color-white)', }}
                                                    className="font-bold"
                                                    block={false}
                                                >
                                                    ตรวจสอบ
                                                </Button>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                {stepTab === 2 && (
                                    <>
                                        <Col span={5} style={{ minWidth: "100%", margin: 0, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Row gutter={[5, 5]}>
                                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                    <Typography.Text className="text-[14px]">ชื่อข้อมูล</Typography.Text>
                                                    <Form.Item name="msdNameUseFrom" style={{ minWidth: "100%", margin: 0 }}>
                                                        <Select
                                                            listHeight={350}
                                                            optionFilterProp="label"
                                                            showSearch
                                                            name="msdNameUseFrom"
                                                            placeholder="-- เลือกชื่อข้อมูล --"
                                                            onChange={e => handleChangeForm(e, 'msdNameUseFrom')}
                                                        >
                                                            {
                                                                optionsMapError(msd_names)?.map((option) => {
                                                                    return (
                                                                        <Option
                                                                            key={`msdNameUseFrom-${option.value}`}
                                                                            value={option.value}
                                                                            label={option.label}
                                                                        >
                                                                            {option.label}
                                                                        </Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                        <Typography.Text className="text-[14px]">กรณีไม่มีชื่อข้อมูลในตัวเลือก</Typography.Text>
                                                        <Form.Item name="newNameUseFrom" style={{ minWidth: "100%", margin: 0 }}>
                                                            <Input
                                                                name="newNameUseFrom"
                                                                placeholder="กรุณากรอกข้อมูล"
                                                                onChange={handleChangeForm}
                                                                value={form.getFieldValue('newNameUseFrom')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row gutter={[5, 5]} justify="center">
                                                <Button
                                                    type="primary"
                                                    onClick={() => dispatch(setSelectTabField({ field: 'stepTab', data: 1 }))}
                                                    className="font-bold"
                                                    block={false}
                                                    hidden
                                                >
                                                    ย้อนกลับ
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    onClick={handleStep02}
                                                    className="font-bold"
                                                    block={false}
                                                >
                                                    กำหนดค่า
                                                </Button>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                {stepTab === 3 && (
                                    <>
                                        <Col span={5} style={{ minWidth: "100%", margin: 0, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Row gutter={[5, 5]}>
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                        <Typography.Text className="text-[14px]">คอลัมน์ค่าข้อมูลงวดปัจจุบัน <span className='text-[--color-error]'>*</span></Typography.Text>
                                                        <Form.Item
                                                            name="valueRangeUseFrom"
                                                            style={{ minWidth: "100%", margin: 0 }}
                                                            rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน "คอลัมน์ค่าข้อมูลงวดปัจจุบัน" /Please fill in all information.' }]}
                                                        >
                                                            <Input
                                                                name="valueRangeUseFrom"
                                                                placeholder=""
                                                                value={form.getFieldValue('valueRangeUseFrom')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Row gutter={[5, 5]}>
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                        <Typography.Text className="text-[14px]">คอลัมน์ค่าข้อมูลงวดก่อนหน้า <span className='text-[--color-error]'>{/* * */}</span></Typography.Text>
                                                        <Form.Item
                                                            name="prevValueRangeUseFrom"
                                                            style={{ minWidth: "100%", margin: 0 }}
                                                            rules={[{ required: false, message: 'กรุณากรอกข้อมูลให้ครบถ้วน "คอลัมน์ค่าข้อมูลงวดก่อนหน้า" /Please fill in all information.' }]}
                                                        >
                                                            <Input
                                                                name="prevValueRangeUseFrom"
                                                                placeholder=""
                                                                value={form.getFieldValue('prevValueRangeUseFrom')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Typography.Text className="text-[14px]">หน่วยเงิน <span className='text-[--color-error]'>*</span></Typography.Text>
                                            <Form.Item
                                                name="currencyUnitUseFrom"
                                                style={{ minWidth: "100%", margin: 0 }}
                                                rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน "หน่วยเงิน" /Please fill in all information.' }]}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    showSearch
                                                    name="currencyUnitUseFrom"
                                                    placeholder="-- เลือกสกุลเงิน --"
                                                    onChange={e => handleChangeForm(e, 'currencyUnitUseFrom')}
                                                >
                                                    {optionsCurrencyUnits(currency_units)?.map((option) => (
                                                        <Option
                                                            key={`currencyUnitUseFrom-${option.value}`}
                                                            value={option.value}
                                                            label={option.label}
                                                        >
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Row gutter={[5, 5]} justify="center">
                                                <Button
                                                    type="primary"
                                                    onClick={() => dispatch(setSelectTabField({ field: 'stepTab', data: 2 }))}
                                                    className="font-bold"
                                                    block={false}
                                                    hidden
                                                >
                                                    ย้อนกลับ
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    onClick={handleStep03}
                                                    className="font-bold"
                                                    block={false}
                                                >
                                                    กำหนดค่า
                                                </Button>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                {stepTab === 4 && (
                                    <>
                                        <Col flex={1} style={{ display: 'flex', flexDirection: 'column', padding: '8px 0' }}>
                                            <Typography.Text className="text-[14px] text-center text-[var(--color-text-gray-primary)]">กรุณากดปุ่ม กำหนดชื่อข้อมูล<br />หรือ กำหนดค่าข้อมูล<br />กรณีต้องการแก้ไข</Typography.Text>
                                            <Button
                                                type="primary"
                                                onClick={() => dispatch(setSelectTabField({ field: 'stepTab', data: 3 }))}
                                                className="font-bold"
                                                block={false}
                                                hidden
                                            >
                                                ย้อนกลับ
                                            </Button>
                                        </Col>
                                    </>
                                )}
                            </Form>
                        </Card>
                    </Col>
                    {/* Box Left */}
                </Row>
                {/* Toolip */}
                {
                    (stepTab !== 0 && stepTab !== 1) && (
                        <>
                            <Tooltip
                                key={`tooltip-information-isc02-report-excel`}
                                placement="topLeft"
                                color="#FFFFFF"
                                arrow={false}
                                title={
                                    <Col className="w-full">
                                        <Row className="w-full gap-2">
                                            <Col>
                                                <Image width={18} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                            </Col>
                                            <Col>
                                                <Typography.Text style={{ fontSize: "14px" }}>คำอธิบาย</Typography.Text>
                                            </Col>
                                        </Row>
                                        <Row className="w-ful px-2" gutter={[6]}>
                                            <Typography.Text style={{ fontSize: "14px" }}>หลังกำหนดค่าข้อมูลและกดปุ่มตรวจสอบแล้ว ระบบจะแสดงกรอบสีแดงสำหรับเซลล์ชื่อข้อมูลที่ไม่สามารถจับคู่อัตโนมัติ โดยผู้ใช้ต้องทำการตั้งค่าเซลล์ดังกล่าวเอง</Typography.Text>
                                        </Row>
                                    </Col>
                                }
                            >
                                <Image width={18} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            </Tooltip>
                        </>
                    )
                }
                {/* Box Left */}
            </Col >
        </Row >
    );
};

export default SubmissionImportReportExcel;
