import { createSlice } from '@reduxjs/toolkit';


const apiReportISCSlice = createSlice({
    name: 'apiReportISC',
    initialState: {},
    reducers: {
        fetchReportCycleGetList(state) { },
        fetchReportCycleGetDetail(state) { },
        fetchReportCycleSubmissionCancelRequest(state) { },
        fetchReportCycleSubmissionGenerate(state) { },
        fetchReportCycleSubmissionUploadExcel(state) { },
        fetchReportCycleSubmissionImportExcel(state) { },
        fetchReportCycleSubmissionGetDetail(state) { },
        fetchReportCycleSubmissionGetUploadErrorDetail(state) { },
        fetchReportCycleSubmissionDownloadReportFileById(state) { },
        fetchReportISCSubmissionUploadDetailGetAllSheet(state) { },
        fetchReportISCSubmissionUploadDetailGetAllSheetSelectTab(state) { },
        fetchReportISCSubmissionSaveByPass(state) { },
        fetchReportISCSubmissionResubmit(state) { },
        fetchReportISCSubmissionUpdateStatusInterval(state) { },
    },
});

export const {
    fetchReportCycleGetList,
    fetchReportCycleGetDetail,
    fetchReportCycleSubmissionCancelRequest,
    fetchReportCycleSubmissionGenerate,
    fetchReportCycleSubmissionUploadExcel,
    fetchReportCycleSubmissionImportExcel,
    fetchReportCycleSubmissionGetDetail,
    fetchReportCycleSubmissionGetUploadErrorDetail,
    fetchReportCycleSubmissionDownloadReportFileById,
    fetchReportISCSubmissionUploadDetailGetAllSheet,
    fetchReportISCSubmissionUploadDetailGetAllSheetSelectTab,
    fetchReportISCSubmissionSaveByPass,
    fetchReportISCSubmissionResubmit,
    fetchReportISCSubmissionUpdateStatusInterval,

} = apiReportISCSlice.actions;

export default apiReportISCSlice.reducer;
