import { createSlice } from '@reduxjs/toolkit';


const apiReportISC02Slice = createSlice({
    name: 'apiReportISC02',
    initialState: {},
    reducers: {
        fetchReportCycleGetList(state) { },
        fetchReportCycleGetDetail(state) { },
        fetchReportCycleSubmissionCancelRequest(state) { },
        fetchReportCycleSubmissionGenerate(state) { },
        fetchReportCycleSubmissionUploadExcel(state) { },
        fetchReportCycleSubmissionImportExcel(state) { },
        fetchReportCycleSubmissionGetDetail(state) { },
        fetchReportCycleSubmissionGetDetailReport(stare) { },
        fetchReportCycleSubmissionGetUploadErrorDetail(state) { },
        fetchReportCycleSubmissionDownloadReportFileById(state) { },
        fetchReportCycleSubmissionGetAuditDropdown(state) { },
        fetchReportCycleSubmissionCheckAccountant(state) { },
        fetchReportCycleSubmissionGetSubmissionHeader(state) { },
        fetchReportCycleSubmissionUploadReport(state) { },
        fetchReportCycleSubmissionSaveReportAuditor(state) { },
        fetchReportCycleSubmissionSaveReportNote(state) { },
        fetchReportCycleSubmissionCreateUpload(state) { },
        fetchReportISCSubmissionCheckDataName(state) { },
        fetchReportISCSubmissionMapError(state) { },
        fetchReportISCSubmissionMapValue(state) { },
        fetchReportISCSubmissionSaveReport(state) { },
        fetchReportISCSubmissionSaveUpload(state) { },
        fetchReportISCSubmissionEditReportAuditor(state) { },
        fetchReportISCSubmissionSaveDetailNote(state) { },
        fetchReportISCSubmissionGetDetailNote(state) { },
        fetchReportISCSubmissionGetNoteSection(state) { },
        fetchReportISCSubmissionGetExcelJson(state) { },
        fetchReportCycleSubmissionSaveReportNoteYear(state) { },
    },
});

export const {
    fetchReportCycleGetList,
    fetchReportCycleGetDetail,
    fetchReportCycleSubmissionCancelRequest,
    fetchReportCycleSubmissionGenerate,
    fetchReportCycleSubmissionUploadExcel,
    fetchReportCycleSubmissionImportExcel,
    fetchReportCycleSubmissionGetDetail,
    fetchReportCycleSubmissionGetDetailReport,
    fetchReportCycleSubmissionGetUploadErrorDetail,
    fetchReportCycleSubmissionDownloadReportFileById,
    fetchReportCycleSubmissionGetAuditDropdown,
    fetchReportCycleSubmissionCheckAccountant,
    fetchReportCycleSubmissionGetSubmissionHeader,
    fetchReportCycleSubmissionUploadReport,
    fetchReportCycleSubmissionSaveReportAuditor,
    fetchReportCycleSubmissionSaveReportNote,
    fetchReportCycleSubmissionCreateUpload,
    fetchReportISCSubmissionCheckDataName,
    fetchReportISCSubmissionMapError,
    fetchReportISCSubmissionMapValue,
    fetchReportISCSubmissionSaveReport,
    fetchReportISCSubmissionSaveUpload,
    fetchReportISCSubmissionEditReportAuditor,
    fetchReportISCSubmissionSaveDetailNote,
    fetchReportISCSubmissionGetDetailNote,
    fetchReportISCSubmissionGetNoteSection,
    fetchReportISCSubmissionGetExcelJson,
    fetchReportCycleSubmissionSaveReportNoteYear
} = apiReportISC02Slice.actions;

export default apiReportISC02Slice.reducer;
