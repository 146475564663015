// src/components/TableReportISC02InfoRefDetail.js
import { Button, Col, Empty, Image, Row, Table, Typography } from 'antd';
import React from 'react';
import { stringDefault } from '../../../helper/base';
import { useDispatch } from 'react-redux';
import { initialStatePagination, setPage } from '../../../features/redux/slice/paginationSlice';
import { IconFileDocFill, IconFileExcel, IconFilePDF, IconViewMore, IconViewMoreOrange } from '../../../helper/icon';
import { convertDateFormat } from '../../../helper/date';
const { Column } = Table;


const TableReportISC02InfoRefDetail = ({
    dataList = [],
    pagination = {},
    onClickViewMore = () => null,
    onClickFileExcel = () => null,
}) => {
    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
        total_pages = initialStatePagination.totalPages,
    } = pagination

    const data = dataList;
    console.log(`data`, dataList);

    return (
        <Table
            dataSource={data}
            scroll={{ x: true }}
            className={[
                "full-width-table",
            ].join(' ')}
            rowKey={"row_no"}
            pagination={{
                onChange: (page, pageSize) => {
                    dispatch(setPage(page));
                },
                showSizeChanger: false,
                current: page,
                pageSize: page_size,
                total: total,
                showQuickJumper: true,
                showTotal: (t, range) =>
                    `แสดงข้อมูล  ${range[0]} -  ${range[1]} จาก  ${total} รายการ`, // `แสดงข้อมูล 1 - 17 จาก 17 รายการ`
            }}
            locale={{
                emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- ไม่พบข้อมูล -' />,
            }}
        >
            <Column className="whitespace-nowrap" align="center" title="ลำดับ" dataIndex="order" key="order" render={(text, record, index) => <Typography.Paragraph>{index + 1}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="ชื่อแบบรายงาน" dataIndex="report_name" key="report_name" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="วันที่ส่ง" dataIndex="uploaded_at" key="uploaded_at" render={(text, record) => <Typography.Paragraph>{stringDefault(convertDateFormat(text, 'dateTime'), '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="ผู้ส่งล่าสุด" dataIndex="last_submitted_by" key="last_submitted_by" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="สถานะ" dataIndex="status" key="status" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column
                className="whitespace-nowrap"
                align='center'
                title="เครื่องมือ"
                key="submission_id"
                render={(text, record) => (
                    <Row gutter={[1, 1]} justify={'center'} wrap={false}>
                        {!!record.report_file_url && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickFileExcel(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                <Image
                                    width={25}
                                    src={
                                        record?.report_file_name?.toLowerCase().endsWith('.pdf')
                                            ? IconFilePDF
                                            : record?.report_file_name?.toLowerCase().endsWith('.xlsx')
                                            ? IconFileExcel
                                            : record?.report_file_name?.toLowerCase().endsWith('.docx')
                                            ? IconFileDocFill // Replace with the correct icon for .docx files
                                            : null // No default icon, so render nothing if no match
                                    }
                                    preview={false}
                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                />
                                </Button>
                            </Col>
                        )}
                        <Col>
                            <Button
                                type="text"
                                onClick={() => onClickViewMore(text, record)}
                                style={{
                                    alignSelf: 'center',
                                    padding: 0,
                                }}
                            >
                                <Image
                                    width={25}
                                    src={record.is_view ? IconViewMore : IconViewMoreOrange}
                                    preview={false}
                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                />
                            </Button>
                        </Col>
                    </Row>
                )}
            />

        </Table>
    );
};

export default TableReportISC02InfoRefDetail;
