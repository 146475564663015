// src/components/ReportISC02InfoRefDetail.js
import { Button, Card, Col, Layout, message, Row, Typography, } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchReportISC02InfoRefDetail from '../../components/reportISC02/reportISC02InfoRefDetail/CardSearchReportISC02InfoRefDetail';
import UploadReportISC02 from '../../components/reportISC02/UploadReportISC02';
import { useFetchReportISC02InfoRefDetailHeader } from '../../features/hooks/reportISC02/useFetchReportISC02InfoRefDetailHeader';
import { fetchReportCycleSubmissionSaveReportNote, fetchReportCycleSubmissionSaveReportNoteYear, fetchReportISCSubmissionGetDetailNote, fetchReportISCSubmissionGetNoteSection } from '../../features/redux/apiSlice/apiReportISC02Slice';
import { resetFormData } from '../../features/redux/slice/formSlice';
import { resetUploadFileISC02FileName, resetUploadReport } from '../../features/redux/slice/uploadFileSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import ModalUploadReportISC02ViewDetailNote from '../../components/reportISC02/modal/ModalUploadReportISC02ViewDetailNote';
import { setModalReportISC02BackDetailNote, setModalReportISC02SaveDetailNote, setModalUploadReportISC02ViewDetailNote } from '../../features/redux/slice/modalSlice';
import { CloseCircleFilled, UploadOutlined } from '@ant-design/icons';
import Quarter from '../../components/reportISC02/reportISC02DetailNote/Quarter';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import Year2 from '../../components/reportISC02/reportISC02DetailNote/Year2';
import { resetDetailNoteDataInput, setDetailNoteDataInput } from '../../features/redux/slice/detailNoteSlice';
import ModalReportISC02BackDetailNote from '../../components/reportISC02/modal/ModalReportISC02BackDetailNote';
import ModalReportISC02SaveDetailNote from '../../components/reportISC02/modal/ModalReportISC02SaveDetailNote';

const ReportISC02InfoRefDetailNote = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC02InfoRefDetailHeader();

    const detailNote = useSelector(state => state.detailNote)

    const [sectionOne, setSectionOne] = useState([]);
    const [sectionTwo, setSectionTwo] = useState([]);
    const [sectionThree, setSectionThree] = useState([]);
    const [sectionFour, setSectionFour] = useState([]);
    const [sectionFive, setSectionFive] = useState([]);
    const [sectionSix, setSectionSix] = useState([]);
    const [sectionSeven, setSectionSeven] = useState([]);
    const [sectionEigth, setSectionEigth] = useState([]);
    const [sectionNine, setSectionNine] = useState([]);
    const [sectionTen, setSectionTen] = useState([]);
    const [sectionEleven, setSectionEleven] = useState([]);
    const [sectionTwelve, setSectionTwelve] = useState([]);
    const [dataError, setDataError] = useState([]);
    const [currencyUnitError, setCurrencyUnitError] = useState([]);
    const [unit, setUnit] = useState('');
    const [reportFileName, setReportFileName] = useState('');
    const [dataType, setDataType] = useState(null);
    const [inputData, setInputData] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [checkInputValue, setCheckInputValue] = useState(false);
    const [checkTextInput, setCheckTextInput] = useState(false);


    const {
        isc02Header,
    } = useSelector(state => state.isc);
    const {
        meta = {},
        data = {},
    } = isc02Header
    const {
        row_no = null,
        total_rows = null,
        submission_id = null,
        cycle_id = null,
        cycle_name = '',
        due_date = '',
        reference_code = '',
        upload_num = null,
        first_submission_date = null,
        last_submission_date = null,
        last_submitted_by = '',
        submission_status = '',
        approval_status = '',
        approved_date = null,
        company_id = null,
        notes = '',
        is_edit_request = false,
        can_cancel_edit = false,
        is_view = false
    } = data

    let { infoId = '', submissionId = '', submissionDetailId = '', fileRecordId = '' } = useParams();


    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC02 = cycle_name || infoId
    const refReportISC02 = reference_code || submissionId
    const uploadReportISC02 = submissionDetailId
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-02` },
        { title: `รายงานงบการเงิน ${stringDefault(nameReportISC02, '-')}`, link: reportISC02Host.reportISC02InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC02, '-')}`, link: reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-')) }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
        { title: `ส่งข้อมูล`, link: reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-')) },
        { title: `หมายเหตุประกอบงบการเงิน` },
    ]
    const {
        formData,
        uploadReportSubmissionDetail,
        uploadReportSubmissionDetailFileName,
    } = useSelector(state => ({
        formData: state.form.formData,
        uploadReportSubmissionDetail: state.uploadFile.uploadReportSubmissionDetail,
        uploadReportSubmissionDetailFileName: state.uploadFile.uploadReportSubmissionDetailFileName,
    }));
    const filename = uploadReportSubmissionDetailFileName

    const handleSaveButton = () => {
        setTimeout(() => {
            dispatch(setModalReportISC02BackDetailNote({ isOpen: false, }));
        }, 100);
        if (submit == false) {
            setCheckTextInput(true);
            setTimeout(() => {
                dispatch(setModalReportISC02SaveDetailNote({ isOpen: true, }));
            }, 100);
        } else {
            setCheckTextInput(false);
            if (data?.cycle_type == 'Quarter') {
                const savedata = {
                    "submission_id": submissionId,
                    "upload_id": submissionDetailId,
                    "file_record_id": fileRecordId,
                    "currency_unit": unit == '' ? 'บาท' : unit
                }
                dispatch(fetchReportCycleSubmissionSaveReportNote(savedata));
            } else {
                const parseValue = value => {
                    if (value === null || value === undefined) {
                        return 0;
                    }
                    return parseFloat(value.replace(/,/g, ''));
                };

                const dataInputSave = inputData.map(item => ({
                    ...item,
                    value: parseValue(item.value)
                }));
                const savedata = {
                    "submission_id": submissionId,
                    "upload_id": submissionDetailId,
                    "file_record_id": fileRecordId,
                    "currency_unit": unit == '' ? 'บาท' : unit,
                    "compensation_data_type": dataType,
                    "input_data": dataInputSave,
                }
                dispatch(fetchReportCycleSubmissionSaveReportNoteYear(savedata));
            }
        }
    };

    const onClickViewMore = (message, sectionId) => {
        fileRecordId = uploadReportSubmissionDetail?.data?.file_record_id ?? fileRecordId;
        // dispatch(setDetailNoteDataInput(inputData))
        let url = `/report-isc-02/info/${infoId}/submission/${submissionId}/submission-detail/${submissionDetailId}/submission-note/${fileRecordId == '-' ? null : fileRecordId}/view-report`;
        dispatch(fetchReportISCSubmissionGetNoteSection({
            "submission_id": submissionId,
            "upload_id": submissionDetailId,
            "file_record_id": fileRecordId == 'null' ? null : fileRecordId,
            "section_id": sectionId
        }));
        dispatch(setLoadingTimeout());
        setTimeout(() => {
            navigate(url, { state: { message: message, sectionId: sectionId } });
        }, 1000);
        return {
        }
    }
    const NavigateBackPage = () => {
        dispatch(resetFormData());
        dispatch(resetUploadReport());
        dispatch(resetDetailNoteDataInput());
        let url = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-'))
        setTimeout(() => {
            dispatch(setModalReportISC02BackDetailNote({ isOpen: false }));
            navigate(url);
        }, 1000);
    }

    const handleBackButton = () => {
        setTimeout(() => {
            dispatch(setModalReportISC02BackDetailNote({ isOpen: true }));
        }, 100);
    }

    const handleOpenModalUploadReportISC02ViewDetailNote = () => {
        setTimeout(() => {
            dispatch(setModalUploadReportISC02ViewDetailNote({ isOpen: true, }));
        }, 1000);
    };

    const [fileList, setFileList] = useState([]);
    const handleRemove = () => {
        if (reportFileName !== '') {
            setReportFileName('');
        } else {
            setFileList([]); // Clear the fileList when modal is closed
            dispatch(resetUploadReport());
            dispatch(resetUploadFileISC02FileName());
        }
    };

    const checkValueLife = () => {
        if (data?.cycle_type == 'Quarter') {
            const dataErrorDetailNote = detailNote?.detailNote?.data?.list_check_error || []
            const dataDetailNote = detailNote?.detailNote?.data?.data_items || null
            if (data?.cycle_name == 'ไตรมาส 1 2567') {
                const dataSection = dataDetailNote?.filter(item => item?.msd_detail_id >= 1 && item?.msd_detail_id <= 13 && item?.msd_detail >= 27 && item?.msd_detail <= 30);
                const dataList = dataSection?.filter(item => item?.value == null);

                if (filename != '' || reportFileName != '') {
                    if (dataList.length == 0 && dataErrorDetailNote.length == 0) {
                        setSubmit(true);
                    } else {
                        setSubmit(false);
                    }
                } else {
                    setSubmit(false);
                }
            } else {
                const dataSection = dataDetailNote?.filter(item => item?.msd_detail_id >= 1 && item?.msd_detail_id <= 30);
                const dataList = dataSection?.filter(item => item?.value == null);

                if (filename != '' || reportFileName != '') {
                    if (dataList.length == 0 && dataErrorDetailNote.length == 0) {
                        setSubmit(true);
                    } else {
                        setSubmit(false);
                    }
                } else {
                    setSubmit(false);
                }
            }

        } else {
            const dataErrorDetailNote = detailNote?.detailNote?.data?.list_check_error || []
            const dataDetailNote = detailNote?.detailNote?.data?.data_items || null
            const dataSection = dataDetailNote?.filter(item => item?.msd_detail_id >= 1 && item?.msd_detail_id <= 72 && item?.msd_detail_id != 62);
            const dataList = dataSection?.filter(item => item?.value == null);
            const datainput = inputData?.filter(e => e.value == null)
            // console.log('ceck data==>',submit)

            if (filename != '' || reportFileName != '') {
                if (dataList.length == 0 && datainput.length == 0 && dataType != null && dataErrorDetailNote.length == 0) {
                    setSubmit(true);
                } else {
                    setSubmit(false);
                }
            } else {
                setSubmit(false);
            }
        }
    }

    const getDataLifeCycle = () => {
        const dataDetailNote = detailNote?.detailNote?.data?.data_items || null
        const fileNameReport = detailNote?.detailNote?.data?.file_name || ''
        const unitDetailNote = detailNote?.detailNote?.data?.currency_unit || ''
        const dataTypeDetailNote = detailNote?.detailNote?.data?.compensation_data_type
        const dataErrorDetailNote = detailNote?.detailNote?.data?.list_check_error || []
        const secOne = dataDetailNote?.filter(item => item?.msd_detail_id >= 1 && item?.msd_detail_id <= 5);
        const secTwo = dataDetailNote?.filter(item => item?.msd_detail_id >= 14 && item?.msd_detail_id <= 18);
        const secThree = dataDetailNote?.filter(item => item?.msd_detail_id >= 27 && item?.msd_detail_id <= 30);
        const secFour = dataDetailNote?.filter(item => item?.msd_detail_id >= 31 && item?.msd_detail_id <= 36);
        const secFive = dataDetailNote?.filter(item => item?.msd_detail_id >= 37 && item?.msd_detail_id <= 39);
        const secSix = dataDetailNote?.filter(item => item?.msd_detail_id >= 40 && item?.msd_detail_id <= 41);
        const secSeven = dataDetailNote?.filter(item => item?.msd_detail_id == 42);
        const secEigth = dataDetailNote?.filter(item => item?.msd_detail_id == 48);
        const secNine = dataDetailNote?.filter(item => item?.msd_detail_id >= 54 && item?.msd_detail_id <= 57);
        const secTen = dataDetailNote?.filter(item => item?.msd_detail_id >= 58 && item?.msd_detail_id <= 60);
        const secEleven = dataDetailNote?.filter(item => item?.msd_detail_id == 61);
        const secTwelve = dataDetailNote?.filter(item => item?.msd_detail_id == 69);
        const dataInput = dataDetailNote?.filter(item => item?.msd_detail_id >= 74 && item?.msd_detail_id <= 81);
        if (detailNote?.detailNoteDataInput?.data_input?.length > 0) {
            const data = detailNote?.detailNoteDataInput?.data_input.map(item => ({
                ...item,
                value: item.value !== null ? `${item.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0.00'
            }));
            setInputData(data);
        } else {
            const data = dataInput?.map(item => ({
                ...item,
                value: item.value !== null ? `${item.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0.00'
            }));
            setInputData(data);
        }
        if (detailNote?.detailNoteDataInput?.unit != undefined) {
            const currency_unit = detailNote?.detailNoteDataInput?.unit ?? ''
            setUnit(currency_unit);
        } else {
            setUnit(unitDetailNote);
        }

        if (detailNote?.detailNoteDataInput?.data_type != undefined) {
            const data_type = detailNote?.detailNoteDataInput?.data_type
            setDataType(data_type);
        } else {
            setDataType(dataTypeDetailNote);
        }

        setSectionOne(secOne);
        setSectionTwo(secTwo);
        setSectionThree(secThree);
        setSectionFour(secFour);
        setSectionFive(secFive);
        setSectionSix(secSix);
        setSectionSeven(secSeven);
        setSectionEigth(secEigth);
        setSectionNine(secNine);
        setSectionTen(secTen);
        setSectionEleven(secEleven);
        setSectionTwelve(secTwelve);
        setDataError(dataErrorDetailNote);
        dispatch(setLoadingTimeout());
        setReportFileName(fileNameReport);
        checkValueLife()
    }

    useEffect(() => {
        dispatch(fetchReportISCSubmissionGetDetailNote({
            "submission_id": submissionId,
            "upload_id": submissionDetailId,
            "file_record_id": fileRecordId == 'null' ? null : fileRecordId,
            "currency_unit": unit == '' ? 'บาท' : unit
        }));
    }, [unit]);

    useEffect(() => {
        checkValueLife()
    }, [!checkInputValue, filename, reportFileName]);

    useEffect(() => {
        getDataLifeCycle()
    }, [detailNote.detailNote]);

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <ModalReportISC02BackDetailNote handleSave={() => handleSaveButton()} NavigateBackPage={() => NavigateBackPage()} />
            <ModalReportISC02SaveDetailNote />
            <ModalUploadReportISC02ViewDetailNote />
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 20]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02InfoRefDetail
                            nameReportISC02={nameReportISC02}
                            refReportISC02={refReportISC02}
                            uploadReportISC02={uploadReportISC02}
                        />
                    </Col>

                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: 0, }}>
                        <Card
                            title={`หมายเหตุประกอบงบการเงิน`}
                            styles={{
                                body: {
                                    padding: 0,
                                },
                                title: {
                                    fontSize: 20,
                                    color: '#000000',
                                }
                            }}
                        >
                            <Col style={{ paddingLeft: '32px', paddingRight: '32px', paddingTop: '32px' }}>
                                <span className='text-[16px] text-[#464646]'>1.	ไฟล์แนบเอกสารหมายเหตุประกอบงบการเงิน
                                    <span className='text-[16px] text-red-600 font-bold pl-2'>*</span>
                                </span><br />
                                <span className='text-[16px] text-[#464646] ms-[18px]'>{`ไฟล์ต้นฉบับเอกสารหมายเหตุประกอบงบการเงิน รองรับไฟล์สกุล .pdf .xlsx และ .docx เท่านั้น`}</span><br />
                            </Col>
                            <Col style={{ paddingLeft: '32px', paddingRight: '32px', paddingBottom: '32px', paddingTop: '12px' }}>
                                <div className='ms-[18px]'>
                                    {reportFileName == '' ? !uploadReportSubmissionDetail?.data?.file_id ? (
                                        <Button
                                            onClick={handleOpenModalUploadReportISC02ViewDetailNote}
                                            keyName={'ReportNote'}
                                            className="upload"
                                            type="primary"
                                            style={{}}
                                        >
                                            อัปโหลดไฟล์
                                        </Button>

                                    ) : (
                                        <Row wrap={false} style={{ marginLeft: '0 !important', marginRight: '0 !important', gap: '1rem', }}>
                                            <Typography.Text style={{ color: 'var(--color-table-text)', fontSize: '16px' }}>{filename}</Typography.Text>
                                            <Button type="text"
                                                onClick={handleRemove}
                                                icon={<CloseCircleFilled style={{ fontSize: 20 }} />}
                                                style={{ color: '#D5D5D5', padding: 0, justifyContent: 'flex-start', alignItems: 'flex-start' }}
                                            />
                                        </Row>
                                    ) : (
                                        <Row wrap={false} style={{ marginLeft: '0 !important', marginRight: '0 !important', gap: '1rem', }}>
                                            <Typography.Text style={{ color: 'var(--color-table-text)', fontSize: '16px' }}>{reportFileName}</Typography.Text>
                                            <Button type="text"
                                                onClick={handleRemove}
                                                icon={<CloseCircleFilled style={{ fontSize: 20, }} />}
                                                style={{ color: '#D5D5D5', padding: 0, justifyContent: 'flex-start', alignItems: 'flex-start' }}
                                            />
                                        </Row>
                                    )}
                                </div>
                            </Col>
                            <Col style={{ paddingLeft: '32px', paddingRight: '32px', paddingBottom: '16px', paddingTop: '0px' }}>
                                <hr></hr>
                            </Col>
                            {data?.cycle_type == 'Quarter' ?
                                <Quarter
                                    onClickViewMore={onClickViewMore}
                                    setUnit={setUnit} unit={unit}
                                    sectionOne={sectionOne}
                                    sectionTwo={sectionTwo}
                                    sectionThree={sectionThree}
                                    dataError={dataError}
                                />
                                :
                                <Year2
                                    onClickViewMore={onClickViewMore}
                                    setUnit={setUnit} unit={unit}
                                    setDataType={setDataType} dataType={dataType}
                                    setInputData={setInputData} dataInput={inputData ?? []}
                                    sectionOne={sectionOne}
                                    sectionTwo={sectionTwo}
                                    sectionThree={sectionThree}
                                    sectionFour={sectionFour}
                                    sectionFive={sectionFive}
                                    sectionSix={sectionSix}
                                    sectionSeven={sectionSeven}
                                    sectionEigth={sectionEigth}
                                    sectionNine={sectionNine}
                                    sectionTen={sectionTen}
                                    sectionEleven={sectionEleven}
                                    sectionTwelve={sectionTwelve}
                                    dataError={dataError}
                                    setCheckInputValue={setCheckInputValue}
                                    setSubmit={setSubmit}
                                    checkTextInput={checkTextInput}
                                />
                            }
                        </Card>
                    </Col>

                </Row>
                <Row items='middle' justify={'center'} className="py-6">
                    <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white" onClick={handleBackButton}>ย้อนกลับ</Button>
                    <Button type="primary" className="font-bold" disabled={!submit} onClick={handleSaveButton}>บันทึก</Button>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISC02InfoRefDetailNote;