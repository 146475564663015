import { Card, Col, Image, Row, Typography } from 'antd';
import React from 'react';
import { IconFilePDF } from '../../helper/icon';
import {  useSelector } from 'react-redux';

const CardSearchManual = () => {
    const {
        companyInfo,
    } = useSelector(state => state.user);
    return (
        <Card title="คู่มือการใช้งาน" classNames={{ header: 'ant-card-head-search-report-isc', }}
            styles={{
                title: {
                    fontSize: 20,
                    color: '#000000',
                }
            }}>

            <a
                //this will save the file as "ICeRT เอกสารคู่มือการใช้งานระบบ สำหรับทดสอบเดือนสิงหาคม V.1.0.pdf"
                download="230767คู่มือการใช้งานระบบเพื่อการยื่นรายงาน.pdf"
                href="230767คู่มือการใช้งานระบบเพื่อการยื่นรายงาน.pdf"
                className="btn btn-success" target="_blank"
            >
                <Row gutter={4}>
                    <Col>
                        <Typography.Text className='text-[16px] font-bold'>1.คู่มือการใช้งานระบบ ICeRT</Typography.Text>
                    </Col>
                    <Col>
                        <Image
                            width={20}
                            src={IconFilePDF}
                            preview={false}
                            wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                        />
                    </Col>
                </Row>
            </a>
            {companyInfo.insurance_type === "GI" && (
                                 <a
                                 //this will save the file as "2.5 คู่มือรายงาน XML_วินาศภัย_สำหรับหมวดที่ 2_v4_ใหม่.pdf"
                                 download="2.5 คู่มือรายงาน XML_วินาศภัย_สำหรับหมวดที่ 2_v4_ใหม่.pdf"
                                 href="2.5 คู่มือรายงาน XML_วินาศภัย_สำหรับหมวดที่ 2_v4_ใหม่.pdf"
                                 className="btn btn-success" target="_blank"
                             >
                                 <Row gutter={4}>
                                     <Col>
                                         <Typography.Text className='text-[16px] font-bold'>2.คู่มือการบันทึกข้อมูลในแบบรายงาน ว</Typography.Text>
                                     </Col>
                                     <Col>
                                         <Image
                                             width={20}
                                             src={IconFilePDF}
                                             preview={false}
                                             wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                             style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                         />
                                     </Col>
                                 </Row>
                             </a>
                            )}
            {companyInfo.insurance_type === "LI" && (
                            <a
                                //this will save the file as "1.5 คู่มือรายงาน XML_ชีวิต_สำหรับหมวดที่ 2_v4_ใหม่.pdf"
                                download="1.5 คู่มือรายงาน XML_ชีวิต_สำหรับหมวดที่ 2_v4_ใหม่.pdf"
                                href="1.5 คู่มือรายงาน XML_ชีวิต_สำหรับหมวดที่ 2_v4_ใหม่.pdf"
                                className="btn btn-success" target="_blank"
                            >
                                <Row gutter={4}>
                                    <Col>
                                        <Typography.Text className='text-[16px] font-bold'>2.คู่มือการบันทึกข้อมูลในแบบรายงาน ช</Typography.Text>
                                    </Col>
                                    <Col>
                                        <Image
                                            width={20}
                                            src={IconFilePDF}
                                            preview={false}
                                            wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        />
                                    </Col>
                                </Row>
                            </a>
                            )}
        </Card >
    );
};

export default CardSearchManual;

