import moment from "moment-timezone";

const convertDateFormat = (date = null, format = 'default', locale = 'thai') => {
    const formatFiled = {
        default: 'DD/MM/YYYY', // 05/01/2567
        year: 'YYYY', // 2567
        dateTime: 'DD/MM/YYYY HH:mm:ss น.', // 05/01/2567 11:00:00 น. 
    };

    /*NOTE - 
        '2024-05-31T16:59:59.000Z' ==> 31/05/2567 23:59:59 น.
        '2024-05-31T17:00:00.000Z' ==> 01/06/2567 00:00:00 น.
    */

    // const originalDate = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    // const originalDate = moment.tz(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Asia/Bangkok');

    // Check if the date provided is valid
    if (!date) {
        return '';
    }

    // Parse the date using moment and convert it to Bangkok time
    const originalDate = moment.tz(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'UTC').tz('Asia/Bangkok');
    if (!originalDate.isValid()) {
        return '';
    }

    // Convert Gregorian year to Buddhist year
    // const thaiYear = parseInt(originalDate.format('YYYY')) + ((locale == 'thai' ? 543 : 0) - (locale == 'en' ? 543 * 2 : 0));
    const thaiYear = parseInt(originalDate.format('YYYY')) + (locale == 'thai' ? 543 : 0);
    let formattedDate = originalDate.format(formatFiled[format] || formatFiled['default']);
    if (locale === 'thai') {
        formattedDate = formattedDate.replace(/\d{4}/, thaiYear);
    }

    return formattedDate;
};

export {
    convertDateFormat,
};
