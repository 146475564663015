import controller from "../../api/controller";
import { reportISCHost } from "../host/reportISCHost";

const apiReportCycleGetList = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/ReportCycle/ISC01/get_list
            // {
            //     "companyId": 3,        
            //     "filter": {
            //         "cycleYearList": 2023,
            //         "submissionStatus": [],
            //         "approvalStatus": [],
            //     },
            //     "paging": {
            //         "page": 1,
            //         "pageSize": 20,
            //         "total": 0        
            //     }
            // }
            {
                "companyId": 1,
                "filter": {
                    "cycleYearList": 2024,
                    "submissionStatus": [],
                    "approvalStatus": []
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 20,
                    "total": 0
                }
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCGetList, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleGetDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/ReportCycle/ISC01/get_detail
            // {
            //     "companyId": 3,  
            //     "cycleId": 12,
            //     "filter": {
            //         "keyword": "",
            //         "submissionStatus": [],
            //         "approvalStatus": [],
            //         "fromDate": "",
            //         "toDate": "",
            //     },
            //     "paging": {
            //         "page": 1,
            //         "pageSize": 20,
            //         "total": 0        
            //     }
            // }
            {
                "companyId": 1,
                "cycleId": 1,
                "filter": {
                    "keyword": "",
                    "toDate": null,
                    "fromDate": null,
                    "submissionStatus": [],
                    "approvalStatus": []
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 20,
                    "total": 0
                }
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCGetDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionCancelRequest = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/cancel_request
            {
                "data": {
                    "companyId": 3,
                    "cycleId": 12,
                    "submissionId": 1,
                    "cancelReason": "",
                }
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionCancelEditRequest, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGenerate = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/create
            // {
            //     "companyId": 3,
            //     "cycleId": 12,
            //     "isRequestEdit": false
            //     "editReason": ""
            // }
            {
                "companyId": 1,
                "cycleId": 1,
                "isEditRequest": false
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionGenerateRef, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionUploadExcel = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission/upload
            {
                "submissionId": 1,
                "file": file
            }
        */
        const res = await controller.requestPost(
            reportISCHost.reportISCSubmissionUploadExcel,
            data,
            {
                // baseURL: urlDownload,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionImportExcel = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/report-submission/confirm-import
            {
                "companyId": 3,
                "cycleId": 12,
                "submissionId": 1,
                "fileName": "report_test.xlsx",
                "fileData": binaryData, 
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionImportExcel, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGetDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/get_detail
            {
                "submissionId": 1,
                "companyId": 3,
                "cycleId": 12,
                "filter": {
                    "keyword": "",
                    "verify_status": [],
                    "fromDate": "",
                    "toDate": "",
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 20,
                    "total": 0
                }
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionGetDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGetUploadErrorDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/upload_error_detail
            {
                "companyId": 3,
                "cycleId": 12,
                "submissionId": 1,
                "submissionNum": 2,
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionCancelUploadErrorDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionUploadDetailGetAllSheet = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  https://icert-api.devmas.net/api/report-submission/upload-detail/get-all-sheet
            {
                "submission_id": 3,
                "upload_id": 4,
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionUploadDetailGetAllSheet, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionUploadDetailGetSheetDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  https://icert-api.devmas.net/api/report-submission/upload-detail/get-sheet-detail
            {
                "upload_id": 1,
                "report_code": "ช0200",
                "pagination": {
                    "page": 1,
                    "page_size": 20
                }
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionUploadDetailGetSheetDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionDownloadReportFileById = async (urlDownload = '') => {
    try {

        // let idOnly = queryId.includes('report-submission') ? queryId.split('=').slice(1).join('=') : queryId
        // console.log(`----  apiReportCycleSubmissionDownloadReportFileById  idOnly:`, idOnly);
        // const res = await controller.requestGet(reportISCHost.reportISCSubmissionGetFile(queryId), null, { 'Content-Type': 'application/octet-stream' });
        const res = await controller.requestGet(null,
            {
                baseURL: urlDownload,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            }
        );
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionSaveByPass = async (data = {}) => {
    try {
        /* NOTE - 
            POST:   https://icert-api.devmas.net/api/report-submission/upload-detail/save-bypass
            {
                "upload_id": 4,
                "report_code": "ช0200",
                "bypass_items": [
                        {
                            "col_code": "XฺB",
                            "row_code": "1.1.0.0.0"
                        },       
                ]
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionSaveByPass, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionResubmit = async (data = {}) => {
    try {
        /* NOTE - 
            POST:   https://icert-api.devmas.net/api/report-submission/upload-detail/resubmit
            {
                "submission_id": 3,
                "upload_id": 4,
            }
        */
        const res = await controller.requestPost(reportISCHost.reportISCSubmissionResubmit, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionUpdateStatus = async (data = {}) => {
    try {
        /* NOTE - 
            GET:   https://icert-api.devmas.net/api/report-submission/upload-detail/get-status?submission_id=41
            {
                "submission_id": 3,
            }
        */
        const res = await controller.requestGet(reportISCHost.reportISCSubmissionUpdateStatusInterval(data?.submission_id));
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const ReportISCService = {
    apiReportCycleGetList,
    apiReportCycleGetDetail,
    apiReportCycleSubmissionCancelRequest,
    apiReportCycleSubmissionGenerate,
    apiReportCycleSubmissionUploadExcel,
    apiReportCycleSubmissionImportExcel,
    apiReportCycleSubmissionGetDetail,
    apiReportCycleSubmissionGetUploadErrorDetail,
    apiReportCycleSubmissionDownloadReportFileById,
    apiReportISCSubmissionUploadDetailGetAllSheet,
    apiReportISCSubmissionUploadDetailGetSheetDetail,
    apiReportISCSubmissionSaveByPass,
    apiReportISCSubmissionResubmit,
    apiReportISCSubmissionUpdateStatus,
};

export default ReportISCService;
