import dayjs from "dayjs";
import 'dayjs/locale/th';
import moment from "moment";

// Set dayjs locale to Thai
dayjs.locale('th');

moment.updateLocale('th', {
    relativeTime: {
        future: 'ใน %s',
        past: '%s ที่ผ่านมา',
        s: 'ไม่กี่วินาที',
        ss: '%d วินาที',
        m: '1 นาที',
        mm: '%d นาที',
        h: '1 ชั่วโมง',
        hh: '%d ชั่วโมง',
        d: '1 วัน',
        dd: '%d วัน',
        M: '1 เดือน',
        MM: '%d เดือน',
        y: '1 ปี',
        yy: '%d ปี',
    },
    calendar: {
        sameDay: '[วันนี้ เวลา] LT',
        nextDay: '[พรุ่งนี้ เวลา] LT',
        nextWeek: 'dddd [เวลา] LT',
        lastDay: '[เมื่อวานนี้ เวลา] LT',
        lastWeek: '[วัน]dddd[ที่แล้ว เวลา] LT',
        sameElse: 'L',
    },
    longDateFormat: {
        LTS: 'HH:mm:ss',
        LT: 'HH:mm',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY เวลา HH:mm',
        LLLL: 'วันddddที่ D MMMM YYYY เวลา HH:mm',
    },
    meridiemParse: /เช้า|บ่าย/,
    isPM: function (input) {
        return input === 'บ่าย';
    },
    meridiem: function (hour, minute, isLower) {
        if (hour < 12) {
            return 'เช้า';
        } else {
            return 'บ่าย';
        }
    },
});


const stringDefault = (text = undefined, defaultText = '') => {
    return String(text || defaultText)

}

const buddhistYear = (value) => {
    if (!value) return value;
    return moment(value).add(543, 'year');
};

// const convertToBuddhistYear = (date = null, addDate = 543) => {
//     return date ? moment(date).add(addDate, 'years') : date;
// };

// const customFormat = (value, addDate = 543) => {
//     return value ? convertToBuddhistYear(value, addDate).format('DD/MM/YYYY') : '';
// };


const convertToBuddhistYear = (date, addDate = 543) => {
    return date ? dayjs(date.year(date.year() + addDate)) : date;
};

const customFormat = (value, addDate = 543) => {
    return value ? convertToBuddhistYear(value,addDate).format('DD/MM/YYYY') : '';
};


const isObject = (x) => x && Object.keys(x).length > 0 && x.constructor === Object;

const fileToJson = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const fileData = reader.result;
            // Extract base64 string from data URL
            const base64Data = fileData.split(',')[1];
            const json = JSON.stringify({
                name: file.name,
                lastModified: file.lastModified,
                size: file.size,
                type: file.type,
                data: base64Data // Store only the base64 string
            });
            resolve(json);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};

const jsonToFile = async (jsonStr) => {
    try {
        // Parse the JSON string back into an object
        const obj = JSON.parse(jsonStr);

        // Decode base64 string
        const byteCharacters = atob(obj.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the byte array and then a File object
        const blob = new Blob([byteArray], { type: obj.type });
        const file = new File([blob], obj.name, {
            type: obj.type,
            lastModified: obj.lastModified
        });

        return file;
    } catch (error) {
        console.error('Error converting JSON to file:', error);
        return null;
    }
};


const parseJsonString = (jsonStr) => {
    try {
        if (typeof jsonStr === 'string') {
            let parsedData = JSON.parse(jsonStr);
            if (typeof parsedData === 'string') {
                parsedData = JSON.parse(parsedData);
            }
            return parsedData;
        } else {
            console.error('jsonStr is not a string:', jsonStr);
            return null;
        }
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
    }
};



export {
    buddhistYear,
    convertToBuddhistYear,
    customFormat,
    stringDefault,
    isObject,
    fileToJson,
    jsonToFile,
    parseJsonString,
};

