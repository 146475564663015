// src/components/Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <div className='max-w-[1980px] m-[auto]'>
            <Header />
                <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
