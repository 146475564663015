import controller from "../../api/controller";
import { indexHost } from "../host/index";

const apiConfigsAllBase = async () => {
    try {
        /* NOTE - 
            GET:   https://icert-api.devmas.net/api/configs/get-all
        */
        const res = await controller.requestGet(indexHost.configsAllBase);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const BaseService = {
    apiConfigsAllBase,
};

export default BaseService;
