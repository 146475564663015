import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportCycleGetDetail } from '../../redux/apiSlice/apiReportISC03Slice';
import { useParams } from 'react-router-dom';

export const useFetchReportISC03Info = () => {
    const dispatch = useDispatch();
    const { companyInfo } = useSelector(state => state.user);
    const { searchReportISC03 } = useSelector(state => state.search);
    const { pagination } = useSelector(state => state.pagination);

    let { infoId = '' } = useParams();

    const {
        keyword = '',
        toDate = null,
        fromDate = null,
        submissionStatus = [],
        approvalStatus = [],
    } = searchReportISC03;

    const {
        company_id = null,
        thai_name = '',
        short_name = '',
        full_name_eng = '',
        status = '',
        domain_name = '',
    } = companyInfo;
    const {
        tableReportISC03Info,
    } = useSelector(state => state.table);

    const {
        meta = {},
        data = {},
    } = tableReportISC03Info
    const {
        cycle_id = null,
        cycle_name = '',
        current_date = '',
        start_date = '',
        due_date = '',
        can_req_edit = false,
        submission_status_list = [],
        approval_status_list = [],
        submission_items = [],
    } = data
    const {
        page = null,
        pageSize = null,
        total = null,
    } = pagination;

    const fetchDataTable = () => {
        dispatch(fetchReportCycleGetDetail(
            {
                "company_id": company_id,
                "cycle_id": infoId,
                "filter": {
                    "keyword": keyword,
                    "to_date": toDate,
                    "from_date": fromDate,
                    "submission_status": submissionStatus,
                    "approval_status": approvalStatus
                },
                "pagination": {
                    "page": page,
                    "page_size": pageSize,
                    "total": total,
                }
            }
        ));
    }

    useEffect(() => {
        fetchDataTable()
    }, [
        keyword,
        toDate,
        fromDate, ,
        submissionStatus,
        approvalStatus,
        page,
        // pageSize,
        // total,
    ]);

    return {
    };
};


