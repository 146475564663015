import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportCycleSubmissionGetSubmissionHeader } from '../../redux/apiSlice/apiReportISC02Slice';
import { useParams } from 'react-router-dom';

export const useFetchReportISC02InfoRefDetailHeader = () => {
    const dispatch = useDispatch();
    let { infoId = '', submissionId = '', submissionDetailId = '' } = useParams();

    const fetchDataTable = () => {
        dispatch(fetchReportCycleSubmissionGetSubmissionHeader(
            {
                "submission_id": submissionId,
            }
        ));
    }

    useEffect(() => {
        fetchDataTable()
    }, [
        // keyword,
        // toDate,
        // fromDate, ,
        // status,
        // page,
        // pageSize,
        // total,
    ]);

    return {
    };
};