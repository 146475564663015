import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Input, Form, } from 'antd';
import { setModalReportISC03 } from '../../../features/redux/slice/modalSlice';
import { FormOutlined } from '@ant-design/icons';
import { fetchReportCycleSubmissionCancelRequest } from '../../../features/redux/apiSlice/apiReportISC02Slice';
import { useNavigate, useParams } from 'react-router-dom';
import { resetIsc03Save } from '../../../features/redux/slice/iscSlice';
import { stringDefault } from '../../../helper/base';
import { reportISC03Host } from '../../../server/service/host/reportISC03Host';
const { TextArea } = Input;

const ModalReportISC03 = ({
    handleSaveButton = () => null,
    handleBackButton = () => null,
}) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { modalReportISC03 } = useSelector(state => state.modal);

    const {
        isOpen = false,
        value = {}
    } = modalReportISC03

    const dispatch = useDispatch();
    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const handleOpen = () => {
        dispatch(setModalReportISC03({ isOpen: true, }));
    };
    const handleCancel = () => {
        dispatch(setModalReportISC03({ isOpen: false, }));
    };

    const isc03SaveItems = useSelector(state => state.isc.isc03Save.items);
    const { isFormValidISC03 } = useSelector(state => state.form);
    console.log('detail_id', isc03SaveItems)

    return (
        <>
            <Modal
                maskClosable={false}
                title="แจ้งเตือนการออกจากหน้ารายงาน"
                centered
                open={isOpen}
                onOk={handleSaveButton}
                onCancel={handleCancel}
                footer={[
                    <div className='flex justify-center mt-10 mb-3'>
                        <Button type="primary" onClick={handleBackButton} style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white">
                            ไม่บันทึก
                        </Button>
                        {/* <Button
                            className='px-10 font-bold'
                            type="primary"
                            onClick={() => handleSaveButton()}
                        >
                            บันทึก
                        </Button> */}
                        <Button
                            type="primary"
                            className='font-bold'
                            style={{ display: 'flex', paddingLeft: '2rem', paddingRight: '2rem' }}
                            onClick={() => handleSaveButton()}
                        >
                            บันทึก
                        </Button>
                    </div>
                ]}
                width={1000}
            >
                <>
                    <p className='my-4'>ท่านกำลังออกจากหน้ารายงานโดยไม่บันทึกข้อมูล ต้องการบันทึกข้อมูลก่อนหรือไม่ ?</p>
                </>
            </Modal >
        </>
    );
};

export default ModalReportISC03;
