import React from 'react';
import ICert from '../image/icert.png';

const Header = () => {
    return (
        <>
            <div className="block lg:hidden">
                <nav className="drop-shadow-md bg-white max-w-screen">
                    <div className="max-w-screen flex mx-auto place-content-center relative z-30">
                        <a href="/" className="flex md:justify-center space-x-3 rtl:space-x-reverse place-content-center z-50">
                            <img className='p-2 w-[272px] mx-auto' alt="" src={ICert}></img>
                        </a>
                    </div>
                </nav>
            </div>
            <div className="hidden lg:block">
                <nav className="bg-[#184E84] max-w-screen">
                    <div className="max-w-screen flex items-center mx-auto h-[80px] px-[72px]">
                        <a href="/" className="flex md:justify-center items-center space-x-3 rtl:space-x-reverse z-30">
                            <div className='shadow-header bg-white rounded-b-[40px] w-[300px] pt-[30px] relative z-50 hidden lg:block'>
                                <img className='.p-2 pb-[15px] w-[257px] mx-auto' alt="" src={ICert}></img>
                            </div>
                        </a>
                        <div className="items-center justify-between w-full md:flex md:w-auto md:order-1 hidden lg:block">
                            <ul className="lg:block flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
                                <li>
                                    <a href="/" className="font-kanit text-[18px] py-2 px-[40px] text-white hidden lg:block hyphens-none" aria-current="page">ระบบเพื่อการยื่นรายงานตามมาตรฐานการรายงานทางการเงินของธุรกิจประกันภัย <br />
                                        <span className='hidden xl:block'>(Insurance Companies' e-Reporting System and Thai Financial Reporting Standards : ICeRT)</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

        </>
    );
};

export default Header;
