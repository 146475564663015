// src/components/ReportISC02InfoRefDetail.js
import { Button, Card, Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import ModalUploadReportISC02 from '../../components/reportISC02/modal/ModalUploadReportISC02';
import CardSearchReportISC02InfoRefDetail from '../../components/reportISC02/reportISC02InfoRefDetail/CardSearchReportISC02InfoRefDetail';
import SubmissionImportReportExcel from '../../components/reportISC02/submissionImportReportExcel';
import { useFetchReportISC02InfoRefDetailHeader } from '../../features/hooks/reportISC02/useFetchReportISC02InfoRefDetailHeader';
import { fetchReportCycleSubmissionGetUploadErrorDetail, fetchReportISCSubmissionSaveReport } from '../../features/redux/apiSlice/apiReportISC02Slice';
import { setSelectTab } from '../../features/redux/slice/reportISCTabSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';
import SubmissionDetailReportUploadFileISC02 from '../../components/reportISC02/reportISC02SubmissionDetailReport/SubmissionDetailReportUploadFileISC02';
import Test001 from '../../components/reportISC02/reportISC02SubmissionDetailReport/test001';

const ReportISC02InfoRefDetailReportExcel = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC02InfoRefDetailHeader();
    const { uploadFileISC02, fileExcelISC02, fileExcelMapValueISC02 } = useSelector(state => state.uploadFile);
    const { isSelectTab = "", stepTab = 0, isSelectTabDraft = "", errorData = {}, } = useSelector(state => state.reportISCTab);
    const { data: dataUploadFileISC, err: errUploadFileISC } = uploadFileISC02;
    const { file_id = '', sheet_names = [], isFileExcelISC02Finish = false } = dataUploadFileISC || { file_id: '', sheet_names: [], isFileExcelISC02Finish: false };
    const { filePart, jsonPart } = fileExcelISC02 || {};
    const { sheet_name, currency_units, can_save_report = null, can_set_name = null, can_set_value = null, data_name_setted = null, data_value_setted = null, map_errors } = jsonPart || {};

    const {
        isc02Header,
    } = useSelector(state => state.isc);
    const {
        meta = {},
        data = {},
    } = isc02Header
    const {
        row_no = null,
        total_rows = null,
        submission_id = null,
        cycle_id = null,
        cycle_name = '',
        due_date = '',
        reference_code = '',
        upload_num = null,
        first_submission_date = null,
        last_submission_date = null,
        last_submitted_by = '',
        submission_status = '',
        approval_status = '',
        approved_date = null,
        company_id = null,
        notes = '',
        is_edit_request = false,
        can_cancel_edit = false,
        is_view = false
    } = data


    let { infoId = '', submissionId = '', submissionDetailId = '', fileRecordId = '' } = useParams();

    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC02 = cycle_name || infoId
    const refReportISC02 = reference_code || submissionId
    const uploadReportISC02 = submissionDetailId
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-02` },
        { title: `รายงานงบการเงิน ${stringDefault(nameReportISC02, '-')}`, link: reportISC02Host.reportISC02InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC02, '-')}`, link: reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-')) }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
        { title: `ส่งข้อมูล`, link: reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-')) },
        { title: `รายงานงบการเงิน` },
    ]


    const report = ["ช0200", "ช0100", "ช1210", "ช1220", "ช0220", "ช0130", "ช1240", "ช1260", "ช0270", "ช0180", "ช1290", "ช140", "ช1262", "ช0275", "ช0181", "ช1296", "ช141", "ช1253", "ช0570", "ช01210", "ช1490", "ช1405"]

    const allsheets = {
        "meta": {
            "timestamp": "2024-05-24 03:41:16",
            "status_code": 200,
            "message": "Success"
        },
        "data": {
            "upload_id": 4,
            "report_code": ["ช0200", "ช0100", "ช1210", "ช1220", "ช0220", "ช0130", "ช1240", "ช1260", "ช0270", "ช0180", "ช1290", "ช140", "ช1262", "ช0275", "ช0181", "ช1296", "ช141", "ช1253", "ช0570", "ช01210", "ช1490", "ช1405"],
        },
    }

    const {
        can_save_report: can_save_report_FileExcelMapValueISC02 = false,
    } = fileExcelMapValueISC02?.data || {} // stepTab: 3

    const isShowBtnSave = () => {
        return stepTab == 0 ? false :
            stepTab == 1 ? false :
                stepTab == 2 ? false :
                    stepTab == 3 ? false :
                        stepTab == 4 ? can_save_report_FileExcelMapValueISC02 :
                            false
    }


    const onChangeTab = (key) => {
        dispatch(setSelectTab({ isSelectTab: key }))

        dispatch(fetchReportCycleSubmissionGetUploadErrorDetail({
            "upload_id": errorData.data.upload_id,
            "report_code": String(key),
            "paging": {
                "page": 1,
                "page_size": 20,
            }
        }));
    };

    const handleNextTab = () => {
        const currentIndex = report.findIndex(tab => tab === isSelectTab);
        if (currentIndex < report.length - 1) {
            onChangeTab(report[currentIndex + 1]);
        }
    };

    const handlePrevTab = () => {
        const currentIndex = report.findIndex(tab => tab === isSelectTab);
        if (currentIndex > 0) {
            onChangeTab(report[currentIndex - 1]);
        }
    }

    const handleBackButton = () => {
        console.log('Back button clicked')
        let url = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-'))
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    }

    const handleSaveButton = () => {

        console.log('Save button clicked')
        // console.log(`----  handleSaveButton  can_save_report:`, can_save_report);
        // console.log(`----  handleSaveButton  file_id:`, file_id);
        // console.log(`----  handleSaveButton  isFileExcelISC02Finish:`, isFileExcelISC02Finish);
        if (isShowBtnSave() && file_id) {
            dispatch(fetchReportISCSubmissionSaveReport({
                "submission_id": submissionId,
                "upload_id": submissionDetailId,
                "report_file_id": file_id,
                "info_id": infoId,
            }))
        }
    }

    const handleImportFile = () => {
        console.log(`----  handleImportFile  handleImportFile:`, handleImportFile);
    }

    useEffect(() => {
        return () => {
            navigate(0)
        }
    }, []);


    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <ModalUploadReportISC02 />
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 20]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02InfoRefDetail
                            nameReportISC02={nameReportISC02}
                            refReportISC02={refReportISC02}
                            uploadReportISC02={uploadReportISC02}
                        />
                    </Col>

                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: 0, }}>
                        <Card
                            title={`รายงานงบการเงิน`}
                            styles={{
                                body: {
                                    padding: 0,
                                }
                            }}
                        >
                            <SubmissionImportReportExcel />
                            {/* <SubmissionDetailReportUploadFileISC02 /> */}
                            {/* <Test001 /> */}
                        </Card>
                    </Col>

                </Row>
                <Row items='middle' justify={'center'} className="py-6">
                    <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white" onClick={handleBackButton}>ย้อนกลับ</Button>
                    <Button
                        type="primary"
                        className="font-bold"
                        onClick={handleSaveButton}
                        style={{ background: isShowBtnSave() ? 'var(--color-primary)' : 'var(--color-gray-primary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', color: 'white' }}
                        disabled={!isShowBtnSave()}
                    >บันทึก</Button>
                </Row>
            </Content>
        </Layout>
    );
};

export default ReportISC02InfoRefDetailReportExcel;