import React from 'react';
import { Col, Layout, Row, Typography, Card, Table } from 'antd';
import Breadcrumb from '../../components/Breadcrumb';
import './AnnounPageStyle.css'; // Import the custom CSS file

const { Content } = Layout;
const { Title, Text } = Typography;
const { Column } = Table;

const AnnounPage = () => {
    const breadcrumbList = [
        { title: 'ประกาศ', link: ''},
    ];

    const data1 = [
        {
            key: '1',
            page: 'ช1210, ช3305',
            description: `รายการ 5.2.1 หุ้นทุนที่จดทะเบียนในตลาดหลักทรัพย์ ดึงข้อมูลมาจากหน้า ช3305 โดยรวม “[4.N.0.0.0] ตราสารทุนที่บริษัทลงทุนในบริษัทที่ได้รับใบอนุญาตประกอบธุรกิจประกันชีวิตตามกฎหมายว่าด้วยการประกันชีวิตอื่น หรือบริษัทที่ได้รับใบอนุญาตให้ประกอบธุรกิจประกันวินาศภัยตามกฎหมายว่าด้วยการประกันวินาศภัย โดยไม่มีวัตถุประสงค์ในการแก้ไขฐานะทางการเงิน หรือปรับโครงสร้างหนี้ ยกเว้น (3.)” อยู่ด้วย แต่ในความเป็นจริงแล้ว ตราสารทุนของบริษัทประกันดังกล่าวอาจไม่ได้จดทะเบียนในตลาดหลักทรัพย์ -> แนวทางแก้ไขคือ เพิ่มตัวเลือกช่อง “ประเภทหุ้นทุน” ของหน้า ช3305 โดยแยกเป็น 
-	[4.1.N.0.0.0] ตราสารทุนของบริษัทประกันที่จดทะเบียนในตลาดหลักทรัพย์
-	[4.2.N.0.0.0] ตราสารทุนของบริษัทประกันที่ไม่ได้จดทะเบียนในตลาดหลักทรัพย์
และแก้ไขสูตรหน้า ช1210 โดย รายการ 5.2.1 หุ้นทุนที่จดทะเบียนในตลาดหลักทรัพย์ จะรวมจำนวนของ [4.1.N.0.0.0] และรายการ 5.2.3 หุ้นทุนอื่น ๆ จะรวมจำนวนของ [4.2.N.0.0.0]
`,
        },
        {
            key: '2',
            page: 'ช2600',
            description: `รายการเบี้ยประกันภัยค้างรับจากการรับประกันภัยโดยตรง ขาดบรรทัดรายการสำหรับสัญญาเพิ่มเติม -> แนวทางแก้ไขคือ เพิ่มบรรทัดรายการ สัญญาเพิ่มเติมอุบัติเหตุ สัญญาเพิ่มเติมสุขภาพ สัญญาเพิ่มเติมอื่น ๆ (อ้างอิงตามช่องของหน้า ช2400)`,
        },
        {
            key: '3',
            page: 'ช3300',
            description: `ช่อง “ร้อยละของเงินลงทุนในหลักทรัพย์ต่อสินทรัพย์รวม” สูตร error #REF! -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
        {
            key: '4',
            page: 'ช1220',
            description: `รายการ “9.1 ค่าใช้จ่ายค้างจ่าย” ดึงค่ายอดรวมค่าใช้จ่ายค้างจ่ายมาจากหน้า ช2520 หักรายการจำนวนของผลประโยชน์อื่น-ระยะยาว เพียงอย่างเดียว ทำให้ยอดหนี้สินเกิน ที่ถูกต้องต้องหักรายการผลประโยชน์หลังออกจากงาน และรายการผลประโยชน์เมื่อเลิกจ้างด้วย เนื่องจากจำนวนดังกล่าวถูกแสดงอยู่ในบรรทัด “8. ภาระผูกพันผลประโยชน์พนักงาน” แล้ว -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
    ];

    const data2 = [
        {
            key: '1',
            page: 'ว2400',
            description: `ช่อง “รวมทั้งสิ้น” รายการที่ 1.2 ถึง 4.2 สูตรไม่ได้รวมข้อมูลของ การประกันความเสี่ยงภัยทรัพย์สิน (IAR) -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
        {
            key: '2',
            page: 'ว1210, ว3305',
            description: `รายการ 5.2.1 หุ้นทุนที่จดทะเบียนในตลาดหลักทรัพย์ ดึงข้อมูลมาจากหน้า ว3305 โดยรวม “[4.N.0.0.0] ตราสารทุนที่บริษัทลงทุนในบริษัทที่ได้รับใบอนุญาตประกอบธุรกิจประกันชีวิตตามกฎหมายว่าด้วยการประกันชีวิตอื่น หรือบริษัทที่ได้รับใบอนุญาตให้ประกอบธุรกิจประกันวินาศภัยตามกฎหมายว่าด้วยการประกันวินาศภัย โดยไม่มีวัตถุประสงค์ในการแก้ไขฐานะทางการเงิน หรือปรับโครงสร้างหนี้ ยกเว้น (3.)” อยู่ด้วย แต่ในความเป็นจริงแล้ว ตราสารทุนของบริษัทประกันดังกล่าวอาจไม่ได้จดทะเบียนในตลาดหลักทรัพย์ -> แนวทางแก้ไขคือ เพิ่มตัวเลือกช่อง “ประเภทหุ้นทุน” ของหน้า ว3305 โดยแยกเป็น 
-	[4.1.N.0.0.0] ตราสารทุนของบริษัทประกันที่จดทะเบียนในตลาดหลักทรัพย์
-	[4.2.N.0.0.0] ตราสารทุนของบริษัทประกันที่ไม่ได้จดทะเบียนในตลาดหลักทรัพย์
และแก้ไขสูตรหน้า ช1210 โดย รายการ 5.2.1 หุ้นทุนที่จดทะเบียนในตลาดหลักทรัพย์ จะรวมจำนวนของ [4.1.N.0.0.0] และรายการ 5.2.3 หุ้นทุนอื่น ๆ จะรวมจำนวนของ [4.2.N.0.0.0]
`,
        },
        {
            key: '3',
            page: 'ว3300',
            description: `ช่อง “ร้อยละของเงินลงทุนในหลักทรัพย์ต่อสินทรัพย์รวม” สูตร error #REF! -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
        {
            key: '4',
            page: 'ว1210',
            description: `รายการ “1. เงินสดและเงินฝากกับสถาบันการเงิน” ไม่ได้ดึงจำนวน “รวมเงินสด” หน้า ว3710 มารวมด้วย -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
        {
            key: '5',
            page: 'ว3301',
            description: `รายการ “เงินฝากสถาบันการเงินประเภทไม่กำหนดระยะเวลาจ่ายคืน” สูตรดึงมาเฉพาะยอดเงินฝากสถาบันการเงินประเภทไม่กำหนดระยะเวลาการจ่ายคืนของบัญชีออมทรัพย์ในประเทศเท่านั้น ทำให้ยอดไม่ครบตามที่กรอกในหน้า ว3710 -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
        {
            key: '6',
            page: 'ว2600',
            description: `รายการ “2.3 การประกันภัยความรับผิดทางขนส่ง” ช่อง “เบี้ยประกันภัยรับโดยตรงรวมตลอดทั้งปี” กับ “ค่าจ้างและค่าบำเหน็จจากการรับประกันภัยโดยตรงรวมตลอดทั้งปี” ดึงค่ามาจาก ว2400 ผิดช่อง -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
        {
            key: '7',
            page: 'ว1220',
            description: `รายการ “8.1 ค่าใช้จ่ายค้างจ่าย” ดึงค่ายอดรวมค่าใช้จ่ายค้างจ่ายมาจากหน้า ว2520 หักรายการจำนวนของผลประโยชน์อื่น-ระยะยาว เพียงอย่างเดียว ทำให้ยอดหนี้สินเกิน ที่ถูกต้องต้องหักรายการผลประโยชน์หลังออกจากงาน และรายการผลประโยชน์เมื่อเลิกจ้างด้วย เนื่องจากจำนวนดังกล่าวถูกแสดงอยู่ในบรรทัด “7. ภาระผูกพันผลประโยชน์พนักงาน” แล้ว -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
        {
            key: '8',
            page: 'ว2801',
            description: `ช่อง “ความคุ้มครองที่เหลืออยู่ องค์ประกอบที่เป็นส่วนขาดทุนได้รับคืน” ของทั้ง งวดปัจจุบัน และ งวดก่อน สูตรคำนวณหาย -> แนวทางแก้ไขคือ แก้ไขสูตร`,
        },
    ];

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Card title="ประกาศ" classNames={{ header: 'ant-card-head-search-report-isc', }}
                        styles={{
                            title: {
                                fontSize: 20,
                                color: '#000000',
                            }
                        }}>
                        <Col style={{ width: "100%", paddingBottom: '15px' }}>
                            <Text>
                                สำนักงาน คปภ. พบความผิดพลาดของสูตรในรายงานเกี่ยวกับฐานะการเงินและกิจการ เวอร์ชั่น 4.1 ซึ่งสำนักงาน คปภ. จะดำเนินการปรับปรุงแก้ไขแบบฟอร์มภายหลังรอบการนำส่งรายงานเกี่ยวกับฐานะการเงินและกิจการคู่ขนาน (Parallel Run) เดือนสิงหาคม 2567 เพื่อให้แบบฟอร์มมีความถูกต้องครบถ้วนก่อนเริ่มใช้งานจริงในปี 2568 เป็นต้นไป 
                                <span style={{ paddingLeft: '2px', paddingRight: '2px', color: '#1E00FF' }}>
                                     ทั้งนี้ การนำส่งรายงานเกี่ยวกับฐานะการเงินและกิจการคู่ขนาน บริษัทสามารถนำส่งข้อมูลที่แสดงผล
                                    ตามสูตรที่เป็นอยู่ในปัจจุบันได้
                                </span>
                                สำนักงาน คปภ. จะยกเว้นการเปรียบเทียบยอดรายการกับงบการเงินคู่ขนาน โดยจุดที่พบความผิดพลาดมีดังนี้
                            </Text>
                        </Col>
                        <Col style={{ width: "100%" }}>
                            <Text style={{fontWeight: 'bold'}}>
                                รายงานเกี่ยวกับฐานะการเงินและกิจการของประกันชีวิต (รายงาน ช)
                            </Text>
                            <Table dataSource={data1} pagination={false} bordered className="custom-table">
                                <Column title="หน้า" dataIndex="page" key="page" width="7%"
                                    style={{
                                        background: '#C0E3A8', // Light grey background
                                        color: '#000', // Black text color
                                        fontWeight: 'bold', // Bold text
                                    }}
                                />
                                <Column title="ประเด็น" dataIndex="description" key="description"
                                    style={{
                                        background: '#C0E3A8 !important' , // Light grey background
                                        color: '#000', // Black text color
                                        fontWeight: 'bold', // Bold text
                                    }}
                                />
                            </Table>
                            <div style={{ paddingTop: '20px' }}>
                                <Text style={{ fontWeight: 'bold', }}>
                                    รายงานเกี่ยวกับฐานะการเงินและกิจการของประกันวินาศภัย (รายงาน ว)
                                </Text>
                            </div>
                            <Table style={{ paddingBottom: '15px' }} dataSource={data2} pagination={false} bordered className="custom-table">
                                <Column title="หน้า" dataIndex="page" key="page" width="7%" />
                                <Column title="ประเด็น" dataIndex="description" key="description" />
                            </Table>
                        </Col>
                    </Card>
                </Row>
            </Content>
        </Layout>
    );
};




export default AnnounPage;
