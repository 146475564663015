import { Button, Col, Image, Row, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import Countdown from './CountDown';
import ShowDate from './ShowDate';
import { IconBell, IconMenu, LogoICert } from '../helper/icon';
import CardUser from './CardUser';
import Notification from './Notification';
import { setCollapsedSidebar } from '../features/redux/slice/sidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reportISCHost } from '../server/service/host/reportISCHost';
import { reportISC02Host } from '../server/service/host/reportISC02Host';
import BoxDueDate from './BoxDueDate';

const { Title } = Typography;

const HeaderBase = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const companyInfo = useSelector(state => state.user.companyInfo);

    // const urlCheckPass = (reportISCHost.reportISCBase).replace('/', '')
    // const urlCheckPassISC02 = (reportISC02Host.reportISC02Base).replace('/', '')

    // const isShowCountdown = (params['*'].includes(urlCheckPass) && params['*'] != urlCheckPass) ||
    //     (params['*'].includes(urlCheckPassISC02) && params['*'] != urlCheckPassISC02)

    return (
        <Header style={{ position: 'relative', zIndex: 99, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.5rem', minHeight: 'var(--header-height)', boxShadow: '0 0 6px 3px rgba(0, 0, 0, 0.3)' }}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', gap: '6px', }} wrap={false}>
                <Row align={'middle'} wrap={false} style={{ gap: '6px', flex: 1, }}>
                    <Image
                        style={{ minWidth: '57px' }}
                        width={57}
                        src={LogoICert}
                        preview={false}
                    />
                    <Row className='show-desktop'>
                        <Col style={{ alignSelf: 'center', }}>
                            <Title ellipsis style={{ rows: 1, color: 'var(--color-primary)', fontSize: '20px', lineHeight: '24px' }}>ICeRT</Title>
                            <Title ellipsis style={{ rows: 1, color: 'var(--color-table-text)', fontSize: '16px', lineHeight: '20px' }}>สำหรับบริษัทประกันภัย</Title>
                        </Col>
                    </Row>
                    <Button
                        type="text"
                        onClick={() => {
                            dispatch(setCollapsedSidebar());
                            setTimeout(() => {
                                const luckysheet = window.luckysheet;
                                if (luckysheet && typeof luckysheet.resize === 'function') {
                                    luckysheet.resize();
                                }
                            }, 300); // Delay to allow CSS transition to complete
                        }}
                        style={{
                            position: 'relative',
                            zIndex: 99,
                            alignSelf: 'center',
                            marginLeft: '20px',
                            // padding: '0px 0px 0px 50px',
                        }}
                        icon={
                            <Image
                                width={16}
                                src={IconMenu}
                                preview={false}
                                wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                            />
                        }
                    />
                    {companyInfo && (
                        <Typography.Title level={5} style={{ margin: 0, minWidth: 'var(--width-company-name)' }} ellipsis={true}>
                            {companyInfo.thai_name}
                        </Typography.Title>
                    )}
                </Row>
                <Row align={'middle'} wrap={false} style={{ gap: '4px' }}>
                    {/* <BoxDueDate isShow={true} /> */}
                    {/* {isShowCountdown && (
                        <Row
                            className='show-desktop'
                            style={{
                                gap: '6px',
                            }}
                        >
                            <Row
                                style={{
                                    columnGap: '6px',
                                }}
                                align={'middle'}
                                justify={'end'}
                                wrap
                            >
                                <Col
                                    style={{
                                        height: 'min-content',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    <ShowDate />
                                </Col>
                                <Col>
                                    <Countdown />
                                </Col>
                            </Row>
                        </Row>
                    )} */}
                    <Notification />
                    <CardUser />
                </Row>
            </Row>
        </Header>
    );
};

export default HeaderBase;
