// src/components/ReportISC02InfoRefDetail.js
import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import ModalUploadReportISC02ViewDetailAuditor from '../../components/reportISC02/modal/ModalUploadReportISC02ViewDetailAuditor';
import CardSearchReportISC02InfoRefDetailAuditor from '../../components/reportISC02/reportISC02InfoRefDetailAuditor/CardSearchReportISC02InfoRefDetailAuditor';
import FormReportISC02InfoRefDetailAuditor from '../../components/reportISC02/reportISC02InfoRefDetailAuditor/FormReportISC02InfoRefDetailAuditor';
import { useFetchReportISC02InfoRefDetailHeader } from '../../features/hooks/reportISC02/useFetchReportISC02InfoRefDetailHeader';
import { fetchReportCycleSubmissionGetAuditDropdown, fetchReportCycleSubmissionSaveReportAuditor, fetchReportISCSubmissionEditReportAuditor } from '../../features/redux/apiSlice/apiReportISC02Slice';
import { resetCheckInformation, resetFormDetailAuditorISC02, selectForm } from '../../features/redux/slice/formSlice';
import { resetUploadReport } from '../../features/redux/slice/uploadFileSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';

const ReportISC02InfoRefDetailAuditor = ({ onFinish = () => null, }) => {
    let { infoId = '', submissionId = '', submissionDetailId = '', fileRecordId = '' } = useParams();
    const { auditDropdown } = useSelector(selectForm);
    // const [searchParams, setSearchParams] = useSearchParams();
    // const fileRecordId = searchParams.get('file-record-id')
    // console.log(`----  ReportISC02InfoRefDetailAuditor  fileRecordId:`, fileRecordId);
    const typeEdit = (!!fileRecordId || fileRecordId == 0) && fileRecordId != '-' && fileRecordId != 'null'

    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchReportCycleSubmissionGetAuditDropdown())
        return () => {
        }
    }, []);
    const { } = useFetchReportISC02InfoRefDetailHeader();

    const {
        isc02Header,
    } = useSelector(state => state.isc);
    const {
        meta = {},
        data = {},
    } = isc02Header
    const {
        row_no = null,
        total_rows = null,
        submission_id = null,
        cycle_id = null,
        cycle_name = '',
        due_date = '',
        reference_code = '',
        upload_num = null,
        first_submission_date = null,
        last_submission_date = null,
        last_submitted_by = '',
        submission_status = '',
        approval_status = '',
        approved_date = null,
        company_id = null,
        notes = '',
        is_edit_request = false,
        can_cancel_edit = false,
        is_view = false
    } = data


    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC02 = cycle_name || infoId
    const refReportISC02 = reference_code || submissionId
    const uploadReportISC02 = submissionDetailId
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-02` },
        { title: `รายงานงบการเงิน ${stringDefault(nameReportISC02, '-')}`, link: reportISC02Host.reportISC02InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC02, '-')}`, link: reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-')) }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
        { title: `ส่งข้อมูล`, link: reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-')) },
        { title: `รายงานผู้สอบบัญชี` },
    ]

    const {
        formDetailAuditorISC02,
        uploadReportSubmissionDetail,
        checkInformation,
    } = useSelector(state => ({
        formDetailAuditorISC02: state.form.formDetailAuditorISC02,
        uploadReportSubmissionDetail: state.uploadFile.uploadReportSubmissionDetail,
        checkInformation: state.form.checkInformation,
    }));

    const handleSaveButton = (payloadData) => {

        console.log(`----  handleSaveButton  auditDropdown?.data?.comments:`, auditDropdown?.data?.comments);
        const commentCode = auditDropdown?.data?.comments
            ?.filter(comment => comment?.comment_thai == formDetailAuditorISC02?.auditor_comment)
            ?.map(comment => comment?.code)[0];
        console.log(`----  handleSaveButton  formDetailAuditorISC02:`, formDetailAuditorISC02);
        console.log(`----  handleSaveButton  commentCode:`, commentCode);
        const payload = {
            "submission_id": submissionId,
            "upload_id": submissionDetailId,
            "comment_date": formDetailAuditorISC02?.comment_date,
            "comment_code": commentCode,
            "auditor_comment": formDetailAuditorISC02?.auditor_comment,
            "notes": formDetailAuditorISC02?.notes,
            "report_file_id": uploadReportSubmissionDetail?.data?.file_id,
            "file_record_id": uploadReportSubmissionDetail?.data?.file_record_id || formDetailAuditorISC02?.file_record_id,
            "info_id": infoId,
            ...payloadData,
            "auditing_company_code": checkInformation?.data?.audit_company_code,
            "accountant_license_number": checkInformation?.data?.license_number,
            "license_number": checkInformation?.data?.license_number,
        }
        console.log(`----  handleSaveButton  payload:`, payload);
        dispatch(fetchReportCycleSubmissionSaveReportAuditor(payload));
    };

    /* 
    {
  "submission_id": "69",
  "upload_id": "88",
  "auditing_company_code": "4409",
  "accountant_license_number": "4409",
  "notes": "",
  "report_file_id": "ed689a5f-ad5c-4656-9605-eb57769b4768",
  "file_record_id": 989
}
    */

    const handleBackButton = () => {
        console.log('Back button clicked')
        dispatch(resetFormDetailAuditorISC02());
        dispatch(resetCheckInformation());
        dispatch(resetUploadReport());
        let url = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-'))
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    }

    useEffect(() => {
        if (typeEdit) {
            dispatch(fetchReportISCSubmissionEditReportAuditor(
                {
                    "submission_id": submissionId,
                    "upload_id": submissionDetailId,
                    "file_record_id": fileRecordId
                }
            ))
        }
        return () => {
        }
    }, []);
    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <ModalUploadReportISC02ViewDetailAuditor />
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 20]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02InfoRefDetailAuditor
                            nameReportISC02={nameReportISC02}
                            refReportISC02={refReportISC02}
                            uploadReportISC02={uploadReportISC02}
                        />
                    </Col>

                    {/* <Col style={{ width: "100%", background: 'var(--color-white)', padding: 0, }}> */}
                    <FormReportISC02InfoRefDetailAuditor
                        handleBackButton={handleBackButton}
                        handleSaveButton={handleSaveButton}
                    />
                    {/* </Col> */}

                </Row>
                {/* <Row items='middle' justify={'center'} className="py-6">
                    <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white" onClick={handleBackButton}>ย้อนกลับ</Button>
                    <Button htmlType="submit" type="primary" className="font-bold" onClick={handleSaveButton}>บันทึก</Button>
                </Row> */}
            </Content>
        </Layout >
    );
};

export default ReportISC02InfoRefDetailAuditor;