import { createSlice } from '@reduxjs/toolkit';

export const initialIsc02Header = {}
export const initialIsc03Save = {
    items: []  // Initialize as an empty array
}

export const initialState = {
    isc02Header: initialIsc02Header,
    isc03Save: initialIsc03Save,
};

const iscSlice = createSlice({
    name: 'isc',
    initialState,
    reducers: {
        setIsc02Header: (state, action) => {
            const { payload = initialIsc02Header } = action;
            state.isc02Header = payload;
        },

        setIsc03Save: (state, action) => {
            const { detail_id, reason } = action.payload;
            const itemIndex = state.isc03Save.items.findIndex(record => record.detail_id === detail_id);
        
            if (itemIndex !== -1) {
                // Update existing item
                state.isc03Save.items[itemIndex].reason = reason;
            } else {
                // Add new item if it doesn't exist
                state.isc03Save.items.push({ detail_id, reason });
            }
        },
        resetIsc03Save: (state, action) => {
            state.isc03Save = initialIsc03Save;
        },
    },
});

export const {
    setIsc02Header,
    setIsc03Save,
    resetIsc03Save,
} = iscSlice.actions;

export const selectIsc = (state) => state.isc;

export default iscSlice.reducer;