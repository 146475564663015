import { createSlice } from '@reduxjs/toolkit';


const apiReportISC03Slice = createSlice({
    name: 'apiReportISC03',
    initialState: {},
    reducers: {
        fetchReportCycleGetList(state) { },
        fetchReportCycleGetDetail(state) { },
        fetchReportCycleSubmissionGenerate(state) { },
        fetchReportISCSubmissionCheckData(state) { },
        fetchReportISCSubmissionSaveData(state) { },
        fetchReportISCSubmissionGetOutputLastSubmission(state) { },
        fetchReportISCSubmissionGetOutputSubmission(state) { },
    },
});

export const {
    fetchReportCycleGetList,
    fetchReportCycleGetDetail,
    fetchReportCycleSubmissionGenerate,
    fetchReportISCSubmissionCheckData,
    fetchReportISCSubmissionSaveData,
    fetchReportISCSubmissionGetOutputLastSubmission,
    fetchReportISCSubmissionGetOutputSubmission,
} = apiReportISC03Slice.actions;

export default apiReportISC03Slice.reducer;
