import { Button, Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalReportISC03Save } from '../../../features/redux/slice/modalSlice';

const ModalReportISC03Save= () => {
    const { modalReportISC03Save } = useSelector(state => state.modal);

    const {
        isOpen = false,
        value = {}
    } = modalReportISC03Save

    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(setModalReportISC03Save({ isOpen: false, }));
    };

    return (
        <>
            <Modal
                maskClosable={false}
                title={<p className='font-bold text-xl'>บันทึกข้อมูลไม่สำเร็จ</p>}
                centered
                open={isOpen}
                onCancel={handleCancel}
                footer={[
                    <div className='flex justify-center mt-10 mb-3'>
                        <Button className='px-8 me-3 font-bold' type="primary" key="back" onClick={handleCancel} style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)', }}>
                            ย้อนกลับ
                        </Button>
                    </div>
                ]}
                width={1000}
            >
                <>
                    <p className='my-5 font-normal text-sm'>กรุณากรอกข้อมูลให้ครบถ้วน /Please fill in all information.</p>
                </>
            </Modal >
        </>
    );
};

export default ModalReportISC03Save;
