import { ClearOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
// import locale from 'antd/es/date-picker/locale/th_TH';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchReportCycleGetDetail } from '../../../features/redux/apiSlice/apiReportISCSlice';
import { initialStatePagination, resetPagination } from '../../../features/redux/slice/paginationSlice';
import { initialStateSearchReportISCInfo, resetSearchStateInfo, setSearchReportISCInfo } from '../../../features/redux/slice/searchSlice';
import { customFormat, stringDefault } from '../../../helper/base';
import BoxDueDate from '../../BoxDueDate';
const { Option } = Select;

const CardSearchReportISCInfo = ({ nameReportISC = '' }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { pagination } = useSelector(state => state.pagination);
    let { infoId = '', submissionId = '', uploadId = '' } = useParams();
    const {
        searchReportISCInfo,
        tableReportISCInfo,
        company_id,
    } = useSelector(state => ({
        companyInfo: state.user.companyInfo,
        searchReportISCInfo: state.search.searchReportISCInfo,
        tableReportISCInfo: state.table.tableReportISCInfo,
    }));

    // Ensure defaults are set to avoid undefined errors

    const {
        keyword = '',
        cycleId = '',
        submissionStatus = [],
        approvalStatus = [],
        fromDate = null,
        toDate = null,
    } = searchReportISCInfo;

    const {
        meta = {},
        data = {},
    } = tableReportISCInfo

    const {
        // cycle_id = null,
        cycle_name = '',
        current_date = '',
        start_date = '',
        due_date = '',
        can_req_edit = false,
        // company_id = null,
        submission_status_list = [],
        approval_status_list = [],
    } = data

    const {
        page = null,
        pageSize = [],
        total = [],
    } = pagination

    const onFinish = (values) => {
        const payload = {
            keyword: values.keyword || '',
            cycleId: values.cycleId || null,
            submissionStatus: values.submissionStatus ? [...values.submissionStatus] : [],
            approvalStatus: values.approvalStatus ? [...values.approvalStatus] : [],
            fromDate: values.fromDate || '',
            toDate: values.toDate || '',
        };

        dispatch(resetPagination())
        dispatch(setSearchReportISCInfo(payload));
        dispatch(fetchReportCycleGetDetail(
            {
                "company_id": company_id,
                "cycle_id": infoId,
                "filter": {
                    "keyword": values.keyword,
                    "to_date": values.toDate,
                    "from_date": values.fromDate,
                    "submission_status": values.submissionStatus,
                    "approval_status": values.approvalStatus
                },
                "pagination": {
                    "page": initialStatePagination.page,
                    "page_size": initialStatePagination.pageSize,
                }
            }
        ));
    };

    const onReset = () => {
        dispatch(resetPagination())
        dispatch(resetSearchStateInfo());
        form.resetFields();
        dispatch(fetchReportCycleGetDetail(
            {
                "company_id": company_id,
                "cycle_id": infoId,
                "filter": {
                    "keyword": initialStateSearchReportISCInfo.keyword,
                    "to_date": initialStateSearchReportISCInfo.toDate,
                    "from_date": initialStateSearchReportISCInfo.fromDate,
                    "submission_status": initialStateSearchReportISCInfo.submissionStatus,
                    "approval_status": initialStateSearchReportISCInfo.approvalStatus
                },
                "pagination": {
                    "page": initialStatePagination.page,
                    "page_size": initialStatePagination.pageSize,
                }
            }
        ));
    };

    return (
        <Card
            extra={<BoxDueDate isShow={true} />}
            title={`แบบรายงานเกี่ยวกับฐานะการเงินและกิจการ ${stringDefault(nameReportISC, '-')}`}
            classNames={{ header: 'ant-card-head-search-report-isc', title: 'min-w-[130px]', }}
            styles={{
                title: {
                    fontSize: 20,
                    color: '#000000',
                }
            }}
        >
            <Form form={form} onFinish={onFinish}>
                <Row gutter={[16, 16]} style={{ width: "100%", margin: 0 }}>
                    <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                        <Col flex="1 1 250px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>ค้นหา เลขที่อ้างอิงข้อมูล, ชื่อผู้ส่งล่าสุด</Typography.Text>
                                    <Form.Item name="keyword" style={{ width: "100%", margin: 0 }}>
                                        <Input
                                            placeholder="-- ระบุคำที่ต้องการค้นหา --"
                                            defaultValue={keyword}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>สถานะการส่ง</Typography.Text>
                                    <Form.Item name="submissionStatus" style={{ width: "100%", margin: 0 }}>
                                        <Select
                                            mode="multiple"
                                            placeholder="ทั้งหมด"
                                            defaultValue={submissionStatus}
                                            style={{ width: '100%' }}
                                            className='select-scroll'
                                        >
                                            {submission_status_list.map(option => (
                                                <Option key={`submission-status-list-${option}`} value={option}>{option}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>สถานะการอนุมัติ</Typography.Text>
                                    <Form.Item name="approvalStatus" style={{ width: "100%", margin: 0 }}>
                                        <Select
                                            mode="multiple"
                                            placeholder="ทั้งหมด"
                                            defaultValue={approvalStatus}
                                            style={{ width: '100%' }}
                                            className='select-scroll'
                                        >
                                            {approval_status_list.map(option => (
                                                <Option key={`approval-status-list-${option}`} value={option}>{option}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>วันที่ส่งล่าสุด</Typography.Text>
                                    <Form.Item name="fromDate" style={{ width: "100%", margin: 0 }}>
                                        <DatePicker
                                            format={customFormat}
                                            placeholder="DD/MM/YYYY"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>ถึงวันที่</Typography.Text>
                                    <Form.Item name="toDate" style={{ width: "100%", margin: 0 }}>
                                        <DatePicker
                                            format={customFormat}
                                            placeholder="DD/MM/YYYY"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                        <Col>
                            <Row gutter={[16, 16]} justify="start" align="middle">
                                <Col>
                                    <Button type="primary" htmlType="submit" style={{ paddingLeft: '30px', paddingRight: '30px', minWidth: '180px' }} icon={<FileSearchOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ค้นหา</Button>
                                </Col>
                                <Col>
                                    <Button type="primary" onClick={onReset} style={{ background: 'var(--color-gray-primary)', minWidth: '180px' }} icon={<ClearOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ล้างการค้นหา</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Form>
        </Card>
    );
};

export default CardSearchReportISCInfo;
