import { indexHost } from ".";

export const reportISC03Host = {
    base: indexHost.base,

    /* -------------------------------- Sprint3 ------------------------------- */
    /* Web Report-03 ISC */
    reportISC03Base: `/report-isc-03`,
    reportISC03InfoBase: `/report-isc-03/info/:infoId`,
    reportISC03SubmissionBase: `/report-isc-03/info/:infoId/submission/:submissionId`,
    
    reportISC03InfoByIdBase: (infoId) => `/report-isc-03/info/${infoId}`,
    reportISC03SubmissionById: (submissionId = '-') => `/submission/${submissionId}`,
    reportISC03SubmissionByIdBase: (infoId, submissionId) => `/report-isc-03/info/${infoId}/submission/${submissionId}`,

    /* Web Report-03 ISC */

    /* Api Report-03 ISC */
    reportISCGetList: `/report-cycle/isc03/get-list`,
    reportISCGetDetail: `/report-cycle/isc03/get-detail`,
    reportISCSubmissionGenerateRef: `/report-submission-isc03/create`,
    reportISCSubmissionCheckData: `/report-submission-isc03/check-data`,
    reportISCSubmissionSaveData: `/report-submission-isc03/save-data`,
    reportISCSubmissionGetOutputLastSubmission: (queryId) => `/report-submission-isc03/get-output-lastsubmission?${queryId}`,
    reportISCSubmissionGetOutputSubmission: (queryId) => `/report-submission-isc03/get-output-submission?${queryId}`,


    /* Api Report-03 ISC */
    /* ------------------------------------------------------------------------- */
}