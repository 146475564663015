import axios from 'axios';
import { reportISCHost } from '../service/host/reportISCHost';

/*NOTE - 
api baseURL: https://icert-api.devmas.net/api
*/

//export const authorizationToken = `Bearer ${localStorage.getItem("token")}`
export const headers = {
    "Accept": "application/json, application/problem+json; charset=utf-8",
    "Content-Type": "application/json",
    //"Authorization": authorizationToken,
};

const createCustomAxiosInstance = (url = null, responseType = null) => {
    const baseURL = url || reportISCHost.base;
    headers.Authorization = localStorage.getItem("token");
    const axiosClient = axios.create({
        baseURL: baseURL,
        responseType: responseType,
        headers: headers,
        timeout: 100000,
    });
    // Set up response interceptors
    axiosClient.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            // Log the error status if available
            if (error.response?.status === 401 && originalRequest && originalRequest?.url !== "/Auth/refresh-token") {
                console.log("originalRequest:", originalRequest)
                console.log("Getting new access token")
                try {
                    const res = await axios.post(baseURL + "/Auth/refresh-token", null, { headers: mergeHeaders(headers) });
                    console.log("token:", res)

                    if (res.status == "200") {
                        localStorage.setItem("token", res.data.token)
                        originalRequest.headers.Authorization = localStorage.getItem("token");
                        return axios(originalRequest);
                    } else {
                        console.error("token is null");
                        localStorage.clear();
                        window.location.reload();
                        return Promise.reject("token is null");
                    }
                } catch (err) {
                    console.error("Error getting new refresh token:", err);
                    localStorage.clear();
                    window.location.reload();
                    return Promise.reject(error);
                }
            }
            return Promise.reject(error);
        }
    );

    // Helper function to merge custom headers with default headers
    const mergeHeaders = (customHeaders) => {
        return {
            ...axiosClient.defaults.headers.common,
            ...customHeaders,
        };
    };

    // Define HTTP request methods with custom headers support
    const get = (path, headers = {}) => {
        return axiosClient.get(path, { headers: mergeHeaders(headers) });
    };

    const put = (path, data, headers = {}) => {
        return axiosClient.put(path, data, { headers: mergeHeaders(headers) });
    };

    const post = (path, data, headers = {}) => {
        return axiosClient.post(path, data, { headers: mergeHeaders(headers) });
    };

    const patch = (path, data, headers = {}) => {
        return axiosClient.patch(path, data, { headers: mergeHeaders(headers) });
    };

    const head = (path, headers = {}) => {
        return axiosClient.head(path, { headers: mergeHeaders(headers) });
    };

    const deleted = (path, headers = {}) => {
        // Use "deleted" to avoid conflict with reserved keyword "delete"
        return axiosClient.delete(path, { headers: mergeHeaders(headers) });
    };

    // Export the Request object
    const Request = { get, put, post, patch, head, deleted };
    return Request;
};

export default createCustomAxiosInstance;
