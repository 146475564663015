// src/components/BaseAll.js
import useFetchConfigAll from "../features/hooks/base/useFetchConfigAll";

const BaseAll = () => {

  const { } = useFetchConfigAll()

  return null
};

export default BaseAll;
