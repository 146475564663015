import { Button, Empty, Image, Row, Table, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { initialStatePagination, setPage } from '../../features/redux/slice/paginationSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { IconExport, IconExportDisable, IconFileExcel, IconFilePDF, IconViewMore, IconViewMoreOrange } from '../../helper/icon';
import { convertStatus } from './fn';
const { Column } = Table;

const TableReportISC03 = ({
    dataList = [],
    pagination = {},
    onClickFileExcel = () => null,
    onClickFilePDF = () => null,
    onClickExport = () => null,
    onClickViewMore = () => null,
}) => {
    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
        total_pages = initialStatePagination.totalPages,
    } = pagination;

    const data = dataList;

    return (
        <Table
            dataSource={data}
            scroll={{ x: true }}
            className="full-width-table"
            rowKey={"key"}
            rowClassName={(record, index) =>
                Boolean(record?.in_period) ? 'table-in-period' : ''
            }
            pagination={{
                onChange: (page, pageSize) => {
                    dispatch(setPage(page));
                },
                showSizeChanger: false,
                current: page,
                pageSize: page_size,
                total: total,
                showQuickJumper: true,
                showTotal: (t, range) =>
                    `แสดงข้อมูล  ${range[0]} -  ${range[1]} จาก  ${total} รายการ`,
            }}
            locale={{
                emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- ไม่พบข้อมูล -' />,
            }}
        >
            <Column className="whitespace-nowrap" title="งวดรายงาน" dataIndex="cycle_name" key="cycle_name" />
            <Column className="whitespace-nowrap" title="วันที่เริ่มส่ง" dataIndex="start_date" key="start_date" render={text => <Typography.Paragraph>{stringDefault(convertDateFormat(text), '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" title="กำหนดส่ง" dataIndex="due_date" key="due_date" render={text => <Typography.Paragraph>{stringDefault(convertDateFormat(text), '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" title="สถานะการส่ง" dataIndex="submission_status" key="submission_status" render={text => <Typography.Paragraph style={{ color: convertStatus(text).color }}>{stringDefault(convertStatus(text).text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" title="สถานะการอนุมัติ" dataIndex="approval_status" key="approval_status" render={text => <Typography.Paragraph style={{ color: convertStatus(text).color }}>{stringDefault(convertStatus(text).text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" title="วันที่ส่งครั้งแรก" dataIndex="first_submission_date" key="first_submission_date" render={text => <Typography.Paragraph>{stringDefault(convertDateFormat(text), '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" title="วันที่ส่งล่าสุด" dataIndex="last_submission_date" key="last_submission_date" render={text => <Typography.Paragraph>{stringDefault(convertDateFormat(text), '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" title="ผู้ส่งล่าสุด" dataIndex="last_submitted_by" key="last_submitted_by" render={text => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align='center' title="Template รายงาน ช/ว" dataIndex="report_template_version" key="report_template_version" render={text => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align='center' title="Output Report" dataIndex="output_report_url" key="output_report_url" render={(text, record) => (
                <>
                    <Button
                        type="text"
                        disabled={!text} 
                        onClick={() => onClickExport(text, record)}
                        style={{
                            alignSelf: 'center',
                            padding: 0,
                        }}
                    >
                        <Image
                            width={20}
                            src={text ? IconExport : IconExportDisable}
                            preview={false}
                            wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                        />
                    </Button>

                </>
            )}
            />
            < Column className="whitespace-nowrap" align='center' title="เครื่องมือ" key="id" render={(text, record) => (
                <Button
                    type="text"
                    disabled={record?.edit_disable}
                    onClick={() => onClickViewMore(text, record)}
                    style={{
                        alignSelf: 'center',
                        padding: '0px 15px',
                    }}
                >
                    <Image
                        width={25}
                        src={record.edit_disable ? IconViewMore : IconViewMoreOrange}
                        preview={false}
                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                    />
                </Button>
            )}
            />
        </Table >
    );
};

export default TableReportISC03;
