import { createSlice } from '@reduxjs/toolkit';

export const initialStateUser = {}

export const initialState = {
    userInfo: initialStateUser,
    companyInfo: initialStateUser,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserField: (state, action) => {
            // dispatch(setUserField({ field: 'userInfo', data: null }));
            const { field = "", data = null } = action.payload;
            state[field] = data;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload.user;
        },
        setCompanyInfo: (state, action) => {
            state.companyInfo = action.payload.company;
        },
    },
});

export const {
    setUserField,
    setUserInfo,
    setCompanyInfo,
} = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
