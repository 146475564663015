import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isObject } from '../../../helper/base';
import { fetchReportISCSubmissionUploadDetailGetAllSheet, fetchReportISCSubmissionUploadDetailGetAllSheetSelectTab } from '../../redux/apiSlice/apiReportISCSlice';

export const useFetchReportISCError = () => {
    const dispatch = useDispatch();
    const { pagination } = useSelector(state => state.pagination);
    const { isSelectTab, errorData } = useSelector(state => state.reportISCTab);
    let { submissionId = '', uploadId = '' } = useParams();
    const {
        page = null,
        pageSize = [],
        total = [],
    } = pagination;

    const fetchDataTable = () => {
        dispatch(fetchReportISCSubmissionUploadDetailGetAllSheet(
            {
                "submission_id": submissionId,
                "upload_id": uploadId,

                "pagination": {
                    "page": page,
                    "page_size": pageSize,
                }
            }
        ));
    }

    const fetchDataSelectTab = () => {
        console.log('test:',page);
        dispatch(fetchReportISCSubmissionUploadDetailGetAllSheetSelectTab(
            {
                "report_code": isSelectTab,
                "upload_id": uploadId,

                "pagination": {
                    "page": page,
                    "page_size": pageSize,
                }
            }
        ));
    }

    useEffect(() => {
        fetchDataTable()
    }, []);

    useEffect(() => {
        // if (isObject(errorData) && isSelectTab) {
        if (isSelectTab) {
            fetchDataSelectTab()
        }
    }, [
        isSelectTab,
        page,
    ]);

    return {
    };
};
