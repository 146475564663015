// src/components/ReportISC03Info.js
import { Button, Col, Image, Layout, Row, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import { useFetchReportISC03Info } from '../../features/hooks/reportISC03/useFetchReportISC03Info';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { initialStatePagination } from '../../features/redux/slice/paginationSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { IconInformation } from '../../helper/icon';
import CardSearchReportISC03Info from '../../components/reportISC03/reportISC03Info/CardSearchReportISC03Info';
import TableReportISC03Info from '../../components/reportISC03/reportISC03Info/TableReportISC03Info';
import { reportISC03Host } from '../../server/service/host/reportISC03Host';
import { useFetchReportCycleSubmissionGenerate } from '../../features/hooks/reportISC03/useFetchReport03CycleSubmissionGenerate';
import { setModalReportISC03 } from '../../features/redux/slice/modalSlice';
import { downloadFile } from '../../helper/downloadFile';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import { resetTableReportISC03InfoRef } from '../../features/redux/slice/tableSlice';

const ReportISC03Info = () => {
    const { fetchDataReportCycleSubmissionGenerate } = useFetchReportCycleSubmissionGenerate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC03Info();


    const {
        companyInfo,
    } = useSelector(state => state.user);
    const {
        tableReportISC03Info,
    } = useSelector(state => state.table);
    const {
        company_id = null,
    } = companyInfo
    const {
        meta = {},
        data = {},
    } = tableReportISC03Info
    const {
        cycle_id = null,
        // company_id = null,
        cycle_name = '',
        current_date = '',
        start_date = '',
        due_date = '',
        can_submit = false,
        submit_disable = false,
        can_req_edit = false,
        submission_status_list = [],
        approval_status_list = [],
        submission_items = [],
        pagination = initialStatePagination,
    } = data

    let { infoId = '', submissionId = '', submissionDetailId = '' } = useParams();

    const info_id = infoId
    const btnInfo = 'ส่งข้อมูล';
    const btnInfoIcon = <PlusOutlined />;
    const tooltipInformation = (
        <span>
            {'* เพิ่ม ประเด็น เรื่อง รายาน xml กับ งบการเงิน ที่จับมาเทียบกัน'}
        </span>
    );
    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC03 = cycle_name

    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: reportISC03Host.reportISC03Base },
        { title: `รายงานกระทบยอด ${stringDefault(nameReportISC03, '-')}`, link: `` },
    ]
    const handleDownload = (record, url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url
        if (URL) {
            downloadFile(URL, fileName) //work
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    const onClickExport = (text, record) => {
        const { output_report_url = '', output_report_name = '' } = record
        handleDownload(record, output_report_url, output_report_name)
    }
    const onClickButtonOnTable = (record) => {
        if (!can_req_edit && !can_submit) return
        console.log(`----  onClickButtonOnTable  record:`, record);
        dispatch(setModalReportISC03({ isOpen: false, }));
        const fetchApiGen = async () => {
            fetchDataReportCycleSubmissionGenerate({
                company_id: Number(company_id),
                cycle_id: Number(record.data.cycle_id),
                is_edit_request: record.data.can_req_edit,
            })
        }
        fetchApiGen()
        return {
        }
    };

    const onClickSearch = (record) => {
        console.log(`----  onClickSearch  record:`, record);
        onNavigate(record)
        return {
        }
    };
    const onNavigate = (record) => {
        console.log(`----  onClickViewMore  record:`, record);
        dispatch(setLoadingTimeout());
        dispatch(setModalReportISC03({ isOpen: false, }));
        let url = reportISC03Host.reportISC03SubmissionByIdBase(stringDefault(record?.infoId || info_id, '/-'), stringDefault(record?.submission_id, '/-'))
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    };

    const onClickViewMore = (record) => {
        console.log(`----  onClickViewMore  record:`, record);
        onNavigate(record)
        return {
        }
    };

    useEffect(() => {
        dispatch(resetTableReportISC03InfoRef());
        return () => {
        }
    }, []);

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC03Info nameReportISC03={nameReportISC03} />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>

                            <Col style={{ width: "100%", margin: 0 }}>
                                <Row justify="space-between" align="bottom">
                                    <Typography.Text strong>{`กำหนดส่ง ${dateline}`}</Typography.Text>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle" style={{ width: "100%", margin: 0 }}>
                                            <Col style={{ paddingLeft: 0 }}>
                                                <Tooltip title={tooltipInformation} key='tooltip-information-isc03-info' style={{ verticalAlign: 'sub', }} >
                                                    <Image width={18} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                                </Tooltip>
                                            </Col>
                                            <Button
                                                type="primary"
                                                disabled={!can_req_edit && !can_submit}
                                                className="actionButton"
                                                icon={btnInfoIcon}
                                                style={{ background: can_req_edit ? 'var(--color-quinary)' : !can_submit ? 'var(--color-gray-primary)' : 'var(--color-quaternary)' }}
                                                onClick={() => onClickButtonOnTable(tableReportISC03Info)}
                                            >
                                                {btnInfo}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col style={{ width: "100%", }}>
                                <TableReportISC03Info
                                    dataList={submission_items}
                                    pagination={pagination}
                                    onClickSearch={onClickSearch}
                                    onClickViewMore={onClickViewMore}
                                    onClickExport={onClickExport}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISC03Info;
