import createCustomAxiosInstance from './axios';

// Function for GET requests
const requestGet = async (endpoint, axiosCustom = {}) => {
    const {
        baseURL = null,
        responseType = null,
        headers = {},
    } = axiosCustom
    console.log(`[GET] ${endpoint}`);
    const Request = createCustomAxiosInstance(baseURL, responseType);
    return Request.get(endpoint, headers);
};

// Function for PUT requests
const requestPut = async (endpoint, payload = {}, axiosCustom = {}) => {
    const {
        baseURL = null,
        responseType = null,
        headers = {},
    } = axiosCustom
    console.log(`[PUT] ${endpoint} :`, payload, headers);
    const Request = createCustomAxiosInstance(baseURL, responseType);
    return Request.put(endpoint, payload, headers);
};

// Function for POST requests
const requestPost = async (endpoint, payload = {}, axiosCustom = {}) => {
    const {
        baseURL = null,
        responseType = null,
        headers = {},
    } = axiosCustom
    console.log(`[POST] ${endpoint} :`, payload, headers);
    const Request = createCustomAxiosInstance(baseURL, responseType);
    return Request.post(endpoint, payload, headers);
};

// Function for PATCH requests
const requestPatch = async (endpoint, payload = {}, axiosCustom = {}) => {
    const {
        baseURL = null,
        responseType = null,
        headers = {},
    } = axiosCustom
    console.log(`[PATCH] ${endpoint} :`, payload, headers);
    const Request = createCustomAxiosInstance(baseURL, responseType);
    return Request.patch(endpoint, payload, headers);
};

// Function for HEAD requests
const requestHead = async (endpoint, axiosCustom = {}) => {
    const {
        baseURL = null,
        responseType = null,
        headers = {},
    } = axiosCustom
    console.log(`[HEAD] ${endpoint}`);
    const Request = createCustomAxiosInstance(baseURL, responseType);
    return Request.head(endpoint, headers);
};

// Function for DELETE requests
const requestDelete = async (endpoint, axiosCustom = {}) => {
    const {
        baseURL = null,
        responseType = null,
        headers = {},
    } = axiosCustom
    console.log(`[DELETE] ${endpoint}`);
    const Request = createCustomAxiosInstance(baseURL, responseType);
    return Request.deleted(endpoint, headers); // Fixed method name to 'delete'
};

// Export the controller object with all request functions
const controller = { requestGet, requestPut, requestPost, requestPatch, requestHead, requestDelete };
export default controller;
