// src/components/ReportISC02.js
import { Col, Image, Layout, Row, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchReportISC02 from '../../components/reportISC02/CardSearchReportISC02';
import TableReportISC02 from '../../components/reportISC02/TableReportISC02';
import { useFetchReportISC02 } from '../../features/hooks/reportISC02/useFetchReportISC02';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { initialStatePagination } from '../../features/redux/slice/paginationSlice';
import { stringDefault } from '../../helper/base';
import { downloadFile } from '../../helper/downloadFile';
import { IconInformation } from '../../helper/icon';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';
import CardSearchReportISC03 from '../../components/reportISC03/CardSearchReportISC03';
import TableReportISC03 from '../../components/reportISC03/TableReportISC03';
import { reportISC03Host } from '../../server/service/host/reportISC03Host';
import { useFetchReportISC03 } from '../../features/hooks/reportISC03/useFetchReportISC03';
import { fetchReportISCSubmissionGetOutputLastSubmission } from '../../features/redux/apiSlice/apiReportISC03Slice';

const ReportISC03 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC03();

    const {
        tableReportISC03,
    } = useSelector(state => state.table);
    const {
        meta = {},
        data = {},
    } = tableReportISC03
    const {
        company_id = null,
        submission_status_list = [],
        approval_status_list = [],
        report_items = [],
        // pagination = {},
        pagination = initialStatePagination,
    } = data
    console.log(`----  ReportISC03  tableReportISC03:`, tableReportISC03);

    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const tooltipInformation = 'รายการที่มีพื้นหลังสีเขียว คือ งวดปัจจุบัน'

    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `` },
    ]

    const onClickViewMore = (text, record) => {
        let url = reportISC03Host.reportISC03InfoByIdBase(stringDefault(record?.cycle_id, '/-'))
        console.log(`----  ReportISC03  url:`, url);
        dispatch(setLoadingTimeout());
        setTimeout(() => {
            navigate(url);
        }, 1000);
    }
    const handleDownload = (record, url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url
        if (URL) {
            downloadFile(URL, fileName) //work
            console.log(`fileName`, fileName);
            console.log(`URL`, URL);
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    const onClickExport = (text, record) => {
        const { output_report_url = '', output_report_name = '' } = record
        handleDownload(record, output_report_url, output_report_name)
    }
    useEffect(() => {
        return () => {
        }
    }, []);

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            {/*TODO - Delete when finish */}
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC03 />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>

                            {/* <Col style={{ width: "100%", margin: 0 }}>
                                <Row justify="end" align="bottom">
                                    <Col>
                                        <Tooltip title={tooltipInformation} key='tooltip-information-isc02-report' style={{ verticalAlign: 'sub', }} >
                                            <Image width={18} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Col> */}

                            <Col style={{ width: "100%", }}>
                                <TableReportISC03
                                    dataList={report_items}
                                    pagination={pagination}
                                    onClickViewMore={onClickViewMore}
                                    onClickExport={onClickExport}
                                />
                            </Col>

                        </Row>
                    </Col >
                </Row >
            </Content >
        </Layout >
    );
};

export default ReportISC03;

