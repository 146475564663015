import { createSlice } from '@reduxjs/toolkit';


/*NOTE
    { type: 'info', msg: '', timeout:1000 } 
    { type: 'success', msg: '', timeout:1000 } 
    { type: 'error', msg: '', timeout:1000 } 
    { type: 'warning', msg: '', timeout:1000 } 
    { type: 'loading', msg: '', timeout:1000 } 
 */

export const initialAlertMsg = { type: '', msg: '', timeout: 1000 }

export const initialState = {
    alert: initialAlertMsg,
};

const alertMsgSlice = createSlice({
    name: 'alertMsg',
    initialState,
    reducers: {
        setAlert(state, action) {
            /* 
                dispatch(setAlert({ type: 'error', msg: '' }))
            */
            const { payload = initialAlertMsg } = action;
            state.alert = payload;
        },
        resetAlert(state, action) {
            state.alert = initialAlertMsg;
        },
    },
});

export const {
    setAlert,
    resetAlert,
} = alertMsgSlice.actions;

export const selectAlertMsg = (state) => state.alertMsg;

export default alertMsgSlice.reducer;
