// src/components/ReportISCInfo.js
import { Button, Col, Image, Layout, Row, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import CardSearchReportISCInfo from '../../components/reportISC/reportISCInfo/CardSearchReportISCInfo';

import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import TableReportISCInfo from '../../components/reportISC/reportISCInfo/TableReportISCInfo';
import { setTableReportISCInfo } from '../../features/redux/slice/tableSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { IconEditWhite, IconInformation } from '../../helper/icon';
import { mockTableReportISC01Info } from '../../mock/mockTableReportISC01Info';
import { useFetchReportISCInfo } from '../../features/hooks/reportISC/useFetchReportISCInfo';
import { useFetchReportCycleSubmissionGenerate } from '../../features/hooks/reportISC/useFetchReportCycleSubmissionGenerate';
import { initialStatePagination } from '../../features/redux/slice/paginationSlice';
import { reportISCHost } from '../../server/service/host/reportISCHost';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import ModalReportISC from '../../components/reportISC/modal/ModalReportISC';
import { fetchReportCycleSubmissionCancelRequest } from '../../features/redux/apiSlice/apiReportISCSlice';
import { setModalReportISC, setModalReportISCViewDetail } from '../../features/redux/slice/modalSlice';
import ModalReportISCViewDetail from '../../components/reportISC/modal/ModalReportISCViewDetail';

const ReportISCInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISCInfo();
    const { fetchDataReportCycleSubmissionGenerate } = useFetchReportCycleSubmissionGenerate();

    const {
        companyInfo,
    } = useSelector(state => state.user);
    const {
        tableReportISCInfo,
    } = useSelector(state => state.table);
    const {
        company_id = null,
    } = companyInfo
    const {
        meta = {},
        data = {},
    } = tableReportISCInfo
    const {
        timestamp = '',
    } = meta
    const {
        cycle_id = null,
        // company_id = null,
        cycle_name = '',
        current_date = '',
        start_date = '',
        due_date = '',
        can_submit = false,
        submit_disable = false,
        can_req_edit = false,
        submission_status_list = [],
        approval_status_list = [],
        submission_items = [],
        pagination = initialStatePagination,
    } = data

    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    let btnInfo;
    let btnInfoIcon;

    switch (true) {
        case can_req_edit:
            btnInfo = 'ขอแก้ไข';
            btnInfoIcon = (
                <Image
                    width={18}
                    src={IconEditWhite}
                    preview={false}
                    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                />
            );
            break;
        case !submit_disable:
            btnInfo = 'ส่งข้อมูล';
            btnInfoIcon = <PlusOutlined />;
            break;
        default:
            btnInfo = 'ขอแก้ไข';
            btnInfoIcon = (
                <Image
                    width={18}
                    src={IconEditWhite}
                    preview={false}
                    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                />
            );
            break;
    }

    // const btnInfo = can_req_edit ? 'ขอแก้ไข' : !submit_disable ? "ส่งข้อมูล" : "ขอเก้ไข"
    // const btnInfoIcon = can_req_edit ? <Image width={18} src={IconEditWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} /> : !submit_disable ? <PlusOutlined /> : <Image width={18} src={IconEditWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
    const tooltipInformation = (
        <span>
            {'* เมื่อกดปุ่ม ส่งข้อมูล ระบบจะทำการสร้างเลขที่อ้างอิงข้อมูล ผู้ใช้งานต้องดำเนินการส่งแบบรายงานโดยใช้เลขที่อ้างอิงข้อมูลดังกล่าวจนกว่าจะส่งผ่าน จึงจะสามารถสร้างเลขที่อ้างอิงข้อมูลใหม่ได้'}
            <br />
            {'* หากมีการนำส่งครั้งแรกเรียบร้อยแล้ว เมื่อเลยกำหนดส่ง ปุ่ม ส่งข้อมูล จะเปลี่ยนเป็นปุ่ม ขอแก้ไข'}
        </span>
    );
    const nameReportISC = cycle_name
    const dateline = convertDateFormat(due_date)

    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: reportISCHost.reportISCBase },
        { title: `แบบรายงานเกี่ยวกับฐานะการเงินและกิจการ ${stringDefault(nameReportISC, '-')}`, link: `` },
    ]

    const onClickClose = (record) => {
        console.log(`----  onClickClose  record:`, record);

        dispatch(setModalReportISC({ isOpen: true, value: record }));

        return {
        }
    };

    const onClickInfo = (record) => {
        console.log(`----  onClickInfo  record:`, record);
        dispatch(setModalReportISCViewDetail({ isOpen: true, value: record }));
        return {
        }
    };

    const onClickFileExcel = (record) => {
        console.log(`----  onClickFileExcel  record:`, record);
        return {
        }
    };

    const onNavigate = (record) => {
        console.log(`----  onClickViewMore  record:`, record);
        dispatch(setLoadingTimeout());
        let url = `/report-isc-01/info/${stringDefault(record.cycle_id, '-')}/submission/${stringDefault(record.submission_id, '-')}`
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    };

    const onClickSearch = (record) => {
        console.log(`----  onClickSearch  record:`, record);
        onNavigate(record)
        return {
        }
    };

    const onClickViewMore = (record) => {
        console.log(`----  onClickViewMore  record:`, record);
        onNavigate(record)
        return {
        }
    };

    const onClickButtonOnTable = (record) => {
        if (!can_req_edit && !can_submit) return

        let url = reportISCHost.reportISCInfoByIdBase(stringDefault(record?.infoId, '/-'))
        const fetchApiGen = async () => {
            /*TODO - call api
            POST:  http://icert:3000/api/report-submission/create
            {
                "companyId": 3,
                "cycleId": 12, //get cycleId
                "isEditRequest": false
            }
            */

            fetchDataReportCycleSubmissionGenerate({
                company_id: Number(company_id),
                cycle_id: Number(record.data.cycle_id),
                is_edit_request: record.data.can_req_edit,
            })
        }
        fetchApiGen()

        return {
        }
    };


    useEffect(() => {
        // dispatch(setTableReportISCInfo(mockTableReportISC01Info));
        return () => {
        }
    }, []);

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <ModalReportISC />
            <ModalReportISCViewDetail />
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISCInfo nameReportISC={nameReportISC} />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>

                            <Col style={{ width: "100%", margin: 0 }}>
                                <Row justify="space-between" align="bottom">
                                    <Typography.Text strong>{`กำหนดส่ง ${dateline}`}</Typography.Text>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle" style={{ width: "100%", margin: 0 }}>
                                            <Col style={{ paddingLeft: 0 }}>
                                                <Tooltip title={tooltipInformation} key='tooltip-information-isc01-report-info' style={{ verticalAlign: 'sub', }} >
                                                    <Image width={18} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                                </Tooltip>
                                            </Col>
                                            <Button
                                                type="primary"
                                                disabled={!can_req_edit && !can_submit}
                                                className="actionButton"
                                                icon={btnInfoIcon}
                                                style={{ background: can_req_edit ? 'var(--color-quinary)' : !can_submit ? 'var(--color-gray-primary)' : 'var(--color-quaternary)' }}
                                                onClick={() => onClickButtonOnTable(tableReportISCInfo)}
                                            >
                                                {btnInfo}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col style={{ width: "100%", }}>
                                <TableReportISCInfo
                                    dataList={submission_items}
                                    pagination={pagination}
                                    onClickClose={onClickClose}
                                    onClickInfo={onClickInfo}
                                    onClickFileExcel={onClickFileExcel}
                                    onClickSearch={onClickSearch}
                                    onClickViewMore={onClickViewMore}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISCInfo;