import { all } from 'redux-saga/effects';
import apiBaseSagas from './saga/apiBaseSagas';
import apiReportISCSagas from './saga/apiReportISCSagas';
import apiReportISC02Sagas from './saga/apiReportISC02Sagas.js';
import apiReportISC03Sagas from './saga/apiReportISC03Sagas.js';

export default function* rootSaga() {
    yield all([
        apiBaseSagas(),
        apiReportISCSagas(),
        apiReportISC02Sagas(),
        apiReportISC03Sagas(),
    ]);
}
