// src/pages/ChangeLog.js
import { Col, Typography } from 'antd';
import React from 'react';

const ChangeLog = () => {
    return (
        <Col>
            <Typography.Title style={{ padding: '1rem' }}>
                version 1.0.5.0
            </Typography.Title>
        </Col>
    );
};

export default ChangeLog;
