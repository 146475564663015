import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchReportISCSubmissionCheckData } from '../../redux/apiSlice/apiReportISC03Slice';

export const useFetchReportISC03InfoRef = () => {
    const dispatch = useDispatch();

    let { infoId = '', submissionId = '' } = useParams();
    const fetchDataTable = () => {
        dispatch(fetchReportISCSubmissionCheckData(
            {
                "submission_id": submissionId
            }
        ));      
    }

    useEffect(() => {
        fetchDataTable()
    }, [
        submissionId,
    ]);

    return {
    };
};