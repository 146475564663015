import { createSlice } from '@reduxjs/toolkit';


const apiBaseSlice = createSlice({
    name: 'apiBase',
    initialState: {},
    reducers: {
        fetchConfigAll(state) { },
    },
});

export const {
    fetchConfigAll,
} = apiBaseSlice.actions;

export default apiBaseSlice.reducer;
