import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Typography, Button, Switch, Divider, Input, Spin, Flex } from 'antd';
import userService from '../../server/service/user';
import logService from '../../server/service/log';

const UserEditPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location?.state?.userId;
    const isView = location?.state?.isView;
    const [isLoading, setIsLoading] = useState(true);

    const [user, setUser] = useState({});

    useEffect(() => {
        (async () => {
            if (userId) {
                const res = await userService.getData(userId);

                console.log({ res });

                setUser(res);
            }

            setIsLoading(false);
        })();
    }, [location]);

    const onCancelHandle = () => {
        navigate('/user');
    }

    const onSaveHandle = async () => {
        try {
            const oldData = await userService.getData(user.user_id)

            await userService.updateStatus(user);

            let logMsg = `แก้ไขผู้ใช้งาน "${user.email}"`;
            if (oldData.first_name != user.first_name)
                logMsg += ` เปลี่ยนชื่อเป็น "${user.first_name ?? ""}" จาก "${oldData.first_name ?? ""}",`;
            if (oldData.last_name != user.last_name)
                logMsg += ` เปลี่ยนนามสกุลเป็น "${user.last_name ?? ""}" จาก "${oldData.last_name ?? ""}",`;
            if (oldData.contact_phone != user.contact_phone)
                logMsg += ` เปลี่ยนเบอร์ติดต่อเป็น "${user.contact_phone ?? ""}" จาก "${oldData.contact_phone ?? ""}",`;
            if (oldData.is_active != user.is_active)
                logMsg += ` เปลี่ยนสถานะเป็น "${user.is_active ? "ใช้งาน" : "ปิดใช้งาน"}" จาก "${oldData.is_active ? "ใช้งาน" : "ปิดใช้งาน"}",`;

            if (logMsg.substring(logMsg.length - 1) == ',')
                logMsg = logMsg.substring(0, logMsg.length - 1);

            await logService.log(logService.LogType.User, logMsg);

            navigate('/user');
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div style={{ padding: '24px', backgroundColor: '#fff' }}>
            <Typography.Title level={4}>รายละเอียดผู้ใช้งาน</Typography.Title>
            <Divider />
            {!isLoading ? (<Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
                <Col span={12}>
                    <Typography.Text strong>สิทธิ์การใช้งาน</Typography.Text>
                    <div>{user.role_name}</div>
                </Col>
                <Col span={12}>
                    <Typography.Text strong>ชื่อผู้ใช้งาน</Typography.Text>
                    <div>{user.email}</div>
                </Col>
                <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Typography.Text strong>ชื่อ</Typography.Text>
                    {!isView ?
                        <Input onChange={(e) => setUser({ ...user, ...{ first_name: e.target.value?.trim() } })} value={user.first_name} />
                        : <div>{user.first_name}</div>
                    }
                </Col>
                <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Typography.Text strong>นามสกุล</Typography.Text>
                    {!isView ?
                        <Input onChange={(e) => setUser({ ...user, ...{ last_name: e.target.value?.trim() } })} value={user.last_name} />
                        : <div>{user.last_name}</div>
                    }
                </Col>
                <Col span={12}>
                    <Typography.Text strong>อีเมล</Typography.Text>
                    <div>{user.email}</div>
                </Col>
                <Col span={12}>
                    <Typography.Text strong>เบอร์ติดต่อ</Typography.Text>
                    {!isView ?
                        <Input onChange={(e) => setUser({ ...user, ...{ contact_phone: e.target.value?.trim() } })} value={user.contact_phone} />
                        : <div>{user.contact_phone}</div>
                    }
                </Col>
                <Col span={12}>
                    <Typography.Text strong>วันที่เริ่มต้น</Typography.Text>
                    <div>{null}</div>
                </Col>
                <Col span={12}>
                    <Typography.Text strong>วันที่สิ้นสุด</Typography.Text>
                    <p>{null}</p>
                </Col>
                <Col span={12}>
                    <Typography.Text strong>สถานะ</Typography.Text>
                    <div>
                        <Switch checked={user.is_active} onChange={(val) => setUser({ ...user, ...{ is_active: val } })} disabled={isView} />
                    </div>
                </Col>
            </Row>
            ) : <Flex justify="center" align="center" gap="middle"><Spin size="large"></Spin></Flex>}
            <Divider />
            <Row justify="center" gutter={[16, 16]}>
                <Col>
                    <Button loading={isLoading} onClick={onCancelHandle} style={{ backgroundColor: '#d9d9d9', color: '#000' }}>{isView ? 'กลับ' : 'ยกเลิก'}</Button>
                </Col>
                {!isView && (
                    <Col>
                        <Button type="primary" loading={isLoading} onClick={onSaveHandle}>บันทึก</Button>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default UserEditPage;
