// src/components/ReportISC02InfoRef.js
import { Button, Col, Image, Layout, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchReportISC02InfoRef from '../../components/reportISC02/reportISC02InfoRef/CardSearchReportISC02InfoRef';
import TableReportISC02InfoRef from '../../components/reportISC02/reportISC02InfoRef/TableReportISC02InfoRef';
import { useFetchReportISC02InfoRef } from '../../features/hooks/reportISC02/useFetchReportISC02InfoRef';
import { fetchReportCycleSubmissionCreateUpload } from '../../features/redux/apiSlice/apiReportISC02Slice';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { setModalUploadReportISC02ViewDetail } from '../../features/redux/slice/modalSlice';
import { initialStatePagination } from '../../features/redux/slice/paginationSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { downloadFile } from '../../helper/downloadFile';
import { IconFileExcelWhite } from '../../helper/icon';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';

const ReportISC02InfoRef = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC02InfoRef();

    const {
        companyInfo,
    } = useSelector(state => state.user);

    const {
        tableReportISC02InfoRef,
    } = useSelector(state => state.table);
    const {
        company_id = null,
    } = companyInfo
    const {
        meta = {},
        data = {},
    } = tableReportISC02InfoRef
    const {
        submission_id = '',
        reference_code = null,
        // company_id = null,
        cycle_name = '',
        cycle_id = null,
        can_import = false,
        start_date = '',
        current_date = '',
        due_date = '',
        template_url = '',
        template_name = '',
        upload_items = [],
        status_list = [],
        pagination = initialStatePagination,
    } = data

    let { infoId = '', submissionId = '', submissionDetailId = '' } = useParams();

    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC02 = cycle_name || infoId
    const refReportISC02 = reference_code || submissionId
    const uploadReportISC02 = submissionDetailId

    console.log(`reference_code`, reference_code)
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-02` },
        { title: `รายงานงบการเงิน ${stringDefault(nameReportISC02, '-')}`, link: reportISC02Host.reportISC02InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC02, '-')}`, link: `` },
    ]

    const handleDownload = (record, url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url
        if (URL) {
            downloadFile(URL, fileName) //work
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    const onClickInfo = (record) => {
        console.log(`----  onClickInfo  record:`, record);
        dispatch(setModalUploadReportISC02ViewDetail({ isOpen: true, value: record }));
    }
    const onClickFileExcel = (record) => {
        console.log(`----  onClickFileExcel  record:`, record);
        const { report_url = '', report_name = '' } = record
        handleDownload(record, report_url, report_name)
    }
    const onClickFileExcelErr = (record) => {
        console.log(`----  onClickFileExcelErr  record:`, record);
        const { error_excel_url = '', error_excel_name = '' } = record
        handleDownload(record, error_excel_url, error_excel_name)
    }
    const onClickSearch = (record) => {
        console.log(`----  onClickSearch  record:`, record);
        dispatch(setLoadingTimeout());
        let url = `/report-isc-02/info/${stringDefault(record.cycle_id || infoId, '-')}/submission/${stringDefault(record.submission_id, '-')}/submission-detail/${stringDefault(record.upload_id, '-')}`
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    }
    const handleImportReportISC02InFoRef = () => {
        dispatch(fetchReportCycleSubmissionCreateUpload({
            "submission_id": submissionId,
            "info_id": infoId,
        }));
    };

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02InfoRef nameReportISC02={nameReportISC02} refReportISC02={refReportISC02} />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>

                            <Col style={{ width: "100%", margin: 0 }}>
                                <Row justify="space-between" align="bottom">
                                    <Typography.Text strong>{`กำหนดส่ง ${dateline}`}</Typography.Text>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle" style={{ width: "100%", margin: 0 }}>
                                            <Button
                                                disabled={!can_import}
                                                type="primary"
                                                className='importAction'
                                                icon={
                                                    <Image width={20} src={IconFileExcelWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                                }
                                                style={{ background: can_import ? 'var(--color-excel)' : 'var(--color-gray-primary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem' }}
                                                onClick={handleImportReportISC02InFoRef}
                                            >
                                                Import
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col style={{ width: "100%", }}>
                                <TableReportISC02InfoRef
                                    dataList={upload_items}
                                    pagination={pagination}
                                    onClickInfo={onClickInfo}
                                    onClickFileExcel={onClickFileExcel}
                                    onClickFileExcelErr={onClickFileExcelErr}
                                    onClickSearch={onClickSearch}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISC02InfoRef;