import { Button, Form, Input, Modal, } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setModalReportISCViewDetail } from '../../../features/redux/slice/modalSlice';
import { stringDefault } from '../../../helper/base';
const { TextArea } = Input;

const ModalReportISCViewDetail = () => {
    const [form] = Form.useForm();
    const { modalReportISCViewDetail } = useSelector(state => state.modal);
    const {
        companyInfo,
    } = useSelector(state => state.user);

    const {
        company_id = null,
    } = companyInfo
    const {
        isOpen = false,
        value = {}
    } = modalReportISCViewDetail

    const {
        notes,
    } = value


    const dispatch = useDispatch();
    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const handleOpen = () => {
        dispatch(setModalReportISCViewDetail({ isOpen: true, }));
    };
    const handleCancel = () => {
        dispatch(setModalReportISCViewDetail({ isOpen: false, }));
    };

    // const handleSubmit = (values) => {
    //     /* 
    //    {
    //        "company_id": 3,
    //        "cycle_id": 12,
    //        "submission_id": 1,
    //        "cancel_reason": "",
    //    }
    //     */
    //     dispatch(fetchReportCycleSubmissionCancelRequest({
    //         "company_id": Number(company_id),
    //         "cycle_id": Number(infoId),
    //         "submission_id": submission_id,
    //         "cancel_reason": values.editRequestReason // เหตุผลแก้ไข กรณีขอแก้ไข (NOTES)
    //     }));
    // };


    return (
        <>
            {/* <Button icon={<FormOutlined />} className='bg-[#7A8688] text-white btedithover' onClick={handleOpen}>
                ขอแก้ไข
            </Button> */}
            <Modal
                maskClosable={false}
                title="ยกเลิกคำขอแก้ไข"
                centered
                open={isOpen}
                // onOk={handleSubmit}
                onCancel={handleCancel}
                footer={[
                    <div className='flex justify-center mt-10 mb-3'>
                        <Button className='px-8 me-3 font-bold' key="back" onClick={handleCancel} style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)', }}>
                            ย้อนกลับ
                        </Button>
                        {/* <Button
                            className='px-10 '
                            type="primary"
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button> */}
                    </div>
                ]}
                width={1000}
            >
                <>
                    <p className='my-4 font-bold'>เหตุผลในการขอยกเลิกคำขอแก้ไข</p>
                    {/* <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            name="editRequestReason"
                            rules={[{ required: true, message: 'กรุณากรอกเหตุผลในการขอยกเลิกคำขอแก้ไข' }]}
                        > */}
                    <TextArea
                        disabled
                        rows={4}
                        value={stringDefault(notes, '-')}
                        style={{
                            cursor: 'default',
                            color: '#464646',
                            backgroundColor: '#DEE2E6',
                        }}
                    />
                    {/* </Form.Item>
                    </Form> */}
                </>
            </Modal >
        </>
    );
};

export default ModalReportISCViewDetail;
