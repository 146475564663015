import { CloseCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Modal, Row, Typography, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportCycleSubmissionImportExcel, fetchReportCycleSubmissionUploadExcel } from '../../../features/redux/apiSlice/apiReportISCSlice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setAlert } from '../../../features/redux/slice/alertMsgSlice';
import { setLoading } from '../../../features/redux/slice/loadingSlice';
import { setModalUploadReportISC } from '../../../features/redux/slice/modalSlice';
import { resetUploadFileISC } from '../../../features/redux/slice/uploadFileSlice';
import { iconRender } from '../fn';

const ModalUploadReportISC = () => {
    const [form] = Form.useForm();
    const { isLoading } = useSelector(state => state.loading);
    const { modalUploadReportISC } = useSelector(state => state.modal);
    const { tableReportISCInfoRef } = useSelector(state => state.table);
    const { data: dataUploadFileISC, err: errUploadFileISC } = useSelector(state => state.uploadFile.uploadFileISC);
    const { file_id = '' } = dataUploadFileISC || { file_id: '' };
    const { msg = '', icon = '' } = errUploadFileISC || { msg: '', icon: '' };
    const {
        meta = {},
        data = {},
    } = tableReportISCInfoRef
    const {
        is_edit_request = false
    } = data
    const { submissionId = '' } = useParams();
    const dispatch = useDispatch();
    const {
        isOpen = false,
    } = modalUploadReportISC
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => {
        dispatch(resetUploadFileISC());
        dispatch(setModalUploadReportISC({ isOpen: false }));
        setFileList([]); // Clear the fileList when modal is closed
    };

    const handleReset = () => {
        setFileList([]); // Clear the fileList when modal is closed
        dispatch(resetUploadFileISC());
    };


    const handleSubmit = async (values) => {
        try {
            const values = await form.validateFields(['editRequestReason']);
    
            // Extract the file name without the extension
            const fileName = fileList[0]?.name.split('.').slice(0, -1).join('.');
    
            dispatch(fetchReportCycleSubmissionImportExcel({
                "submission_id": Number(submissionId),
                "report_file_id": file_id,
                "file_name": fileName, // ส่งชื่อไฟล์ (ไม่เอานามสกุลไปด้วย)
                "reason": values.editRequestReason // เหตุผลแก้ไข กรณีขอแก้ไข (NOTES)
            }));
        } catch (error) {
            console.log(`----  handleSubmit  error:`, error);
        }
    };
    

    const customUpload = {
        name: 'file',
        fileList,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        beforeUpload: (file) => {
            if (fileList.length >= 1) {
                dispatch(setAlert({ type: 'error', msg: 'คุณสามารถอัปโหลดได้เพียงไฟล์เดียว' }));
                return Upload.LIST_IGNORE;
            }
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isExcel) {
                handleReset();
                dispatch(setAlert({ type: 'error', msg: 'รองรับไฟล์ .xlsx เท่านั้น' }));
                return Upload.LIST_IGNORE;
            }
            const isSizeAcceptable = file.size / 1024 / 1024 < 100;
            if (!isSizeAcceptable) {
                handleReset();
                dispatch(setAlert({ type: 'error', msg: 'ขนาดไฟล์เกิน 100MB!' }));
                return Upload.LIST_IGNORE;
            }
            return true;
        },
        onChange: (info) => {
            dispatch(resetUploadFileISC());
            setFileList(info.fileList.slice(-1)); // Keep only the latest file
            if (info.file.status === 'done') {
                // dispatch(setAlert({ type: 'success', msg: `${info.file.name} อัปโหลดไฟล์สำเร็จ` }));
            } else if (info.file.status === 'error') {
                dispatch(setAlert({ type: 'error', msg: `${info.file.name} อัปโหลดไฟล์ล้มเหลว` }));
                handleReset();
            }
        },
        itemRender: (originNode, file, fileList, actions) => {
            console.log(file, file.name)
            return (
                <Row justify={'space-between'} wrap={false} style={{ marginLeft: '0 !important', marginRight: '0 !important', gap: '1rem', }}>
                    <Typography.Text style={{ color: 'var(--color-table-text)', fontSize: '16px' }}>{file.name}</Typography.Text>
                    <Button type="text"
                        onClick={() => {
                            actions.remove(file);
                            handleReset();
                        }}
                        icon={<CloseCircleFilled style={{ fontSize: 20, }} />}
                        style={{ color: 'var(--color-gray-primary)', padding: 0, justifyContent: 'flex-start', alignItems: 'flex-start' }}
                    />
                </Row>
            );
        },
        customRequest: ({ file, onSuccess, onError, onProgress }) => {
            try {
                const simulateUploadProgress = () => {
                    dispatch(setLoading())
                    let progress = 0;
                    const interval = setInterval(() => {
                        progress += 10;
                        if (progress <= 100) {
                            onProgress({ percent: progress });
                        } else {
                            clearInterval(interval);
                            onSuccess(
                                dispatch(fetchReportCycleSubmissionUploadExcel({
                                    submission_id: Number(submissionId),
                                    file: file,
                                }))
                            );
                        }
                    }, 100);
                };
                setTimeout(() => {
                    simulateUploadProgress();
                }, 1000);
            } catch (error) {
                onError(
                    dispatch(setAlert({ type: 'error', msg: 'คุณสามารถอัปโหลดได้เพียงไฟล์เดียว' }))
                )
            }
        },
        progress: {
            strokeColor: {
                '0%': '#4899e3',
                '80%': '#4899e3',
                '100%': '#11458d',
            },
            strokeWidth: 5,
            format: (percent) => {
                return percent && `${parseFloat(percent.toFixed(2))}%`
            }
        },
        showUploadList: {
            showRemoveIcon: true,
            showPreviewIcon: true,
            showDownloadIcon: true,
            removeIcon: true,
            removeIcon: <CloseCircleFilled onClick={(e) => console.log(e, 'custom removeIcon event')} />,
        },
        itemRender: (originNode, file, fileList, actions) => {
            console.log(file, file.name)
            return (
                <>
                    <Col style={{ width: "100%", margin: 0 }}>
                        <Row  align="bottom">
                            <Typography.Text className='text-[16px]' >{file.name}  <Button type="text" onClick={() => {
                                        dispatch(resetUploadFileISC());
                                        actions.remove(file)
                                    }}
                                        icon={<CloseCircleFilled //className='text-[#464646]' 
                        style={{ color: 'var(--color-gray-primary)' }}
                                        />}></Button></Typography.Text>
                           
                           
                        </Row>
                    </Col>
                </>
            );
        },
    };


    useEffect(() => {
        if (msg) {
            setFileList([]); // Clear the fileList when modal is closed
        }
    }, [msg]);

    return (
        <Modal
            maskClosable={false}
            title="นำเข้าข้อมูล"
            centered
            open={isOpen}
            onOk={handleSubmit}
            onCancel={handleCancel}
            footer={[
                <div className="flex justify-center mt-10 mb-3">
                    <Button className="px-8 me-3 font-bold" key="back" onClick={handleCancel} style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)' }}>
                        ย้อนกลับ
                    </Button>
                    {!!file_id && (
                        <Button className="px-10 font-bold" type="primary" onClick={() => form.submit()}>
                            บันทึก
                        </Button>
                    )}
                </div>
            ]}
            width={1000}
        >
            <p>(รองรับไฟล์ .xlsx ขนาดไม่เกิน 100MB เท่านั้น)</p>
            <div>
                <Upload {...customUpload}>
                    <Col gap={6}>
                        {/* MSG ERR */}
                        {!!msg && (
                            <Row style={{ marginTop: '1rem', gap: '1rem', }}>
                                <Image
                                    width={20}
                                    src={iconRender(icon)}
                                    preview={false}
                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                />
                                <Typography.Text type="danger">{msg}</Typography.Text>
                            </Row>
                        )}
                        {/* MSG ERR */}

                        {!file_id && !isLoading && (
                            <Button
                                className="upload my-4"
                                type="primary"
                                style={{ paddingLeft: '30px', paddingRight: '30px', minWidth: '180px' }}
                                icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />
                                }>
                                อัปโหลดไฟล์
                            </Button>
                        )}
                    </Col>
                </Upload>
            </div>

            <div hidden={!is_edit_request}>
                <p className='my-4'>เหตุผลในการขอแก้ไข <span className='text-[--color-error]'>*</span></p>
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="editRequestReason"
                        rules={[{ required: is_edit_request, message: 'กรุณากรอกเหตุผลในการขอแก้ไข' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </div>

        </Modal>
    );
};

export default ModalUploadReportISC;
