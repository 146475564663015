import controller from "../../api/controller";
import { reportISC03Host } from "../host/reportISC03Host";

const apiReportCycleGetList = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  https://icert-api.devmas.net/api/report-cycle/isc03/get-list
            {
                "company_id": 3,        
                "filter": {
                    "cycle_year": [],
                    "upload_status": [],
                    "approval_status": [],
                },
                "paging": {
                    "page": 1,
                    "page_size": 20,
                }
            }
        */
        const res = await controller.requestPost(reportISC03Host.reportISCGetList, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleGetDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/ReportCycle/ISC01/get_detail
            // {
            //     "companyId": 3,  
            //     "cycleId": 12,
            //     "filter": {
            //         "keyword": "",
            //         "submissionStatus": [],
            //         "approvalStatus": [],
            //         "fromDate": "",
            //         "toDate": "",
            //     },
            //     "paging": {
            //         "page": 1,
            //         "pageSize": 20,
            //         "total": 0        
            //     }
            // }
            {
                "companyId": 1,
                "cycleId": 1,
                "filter": {
                    "keyword": "",
                    "toDate": null,
                    "fromDate": null,
                    "submissionStatus": [],
                    "approvalStatus": []
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 20,
                    "total": 0
                }
            }
        */
        const res = await controller.requestPost(reportISC03Host.reportISCGetDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGenerate = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/create
            // {
            //     "companyId": 3,
            //     "cycleId": 12,
            //     "isRequestEdit": false
            //     "editReason": ""
            // }
            {
                "companyId": 1,
                "cycleId": 1,
                "isEditRequest": false
            }
        */
        const res = await controller.requestPost(reportISC03Host.reportISCSubmissionGenerateRef, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionCheckData = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc03/check-data`
            {
            "company_id": 1,
            "cycle_id": 1,
            "is_edit_request": true
            }

        */
        const res = await controller.requestPost(reportISC03Host.reportISCSubmissionCheckData, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionSaveData = async (data = {}) => {
    try {
        // POST:  https://icert-api.devmas.net/api/report-submission-isc03/Save-data
        // {
        //     "submission_id": 37,
        //     "data": [
        //         {
        //             "detail_id": 64,
        //             "reason": "test"
        //         },
        //         {
        //             "detail_id": 65,
        //             "reason": "test"
        //         }
        //     ]
        // }
        const res = await controller.requestPost(reportISC03Host.reportISCSubmissionSaveData, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionGetOutputLastSubmission = async (urlDownload = '') => {
    try {
        const res = await controller.requestGet(null,
            {
                baseURL: urlDownload,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            }
        );
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionGetOutputSubmission = async (urlDownload = '') => {
    try {

        // let idOnly = queryId.includes('report-submission') ? queryId.split('=').slice(1).join('=') : queryId
        // console.log(`----  apiReportCycleSubmissionDownloadReportFileById  idOnly:`, idOnly);
        // const res = await controller.requestGet(reportISC02Host.reportISCSubmissionGetFile(queryId), null, { 'Content-Type': 'application/octet-stream' });
        const res = await controller.requestGet(null,
            {
                baseURL: urlDownload,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            }
        );
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const reportISC03Service = {
    apiReportCycleGetList,
    apiReportCycleGetDetail,
    apiReportCycleSubmissionGenerate,
    apiReportISCSubmissionGetOutputLastSubmission,
    apiReportISCSubmissionCheckData,
    apiReportISCSubmissionSaveData,
    apiReportISCSubmissionGetOutputSubmission,
};

export default reportISC03Service;
