import { CloseCircleFilled, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Modal, Row, Typography, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchReportCycleSubmissionImportExcel, fetchReportCycleSubmissionUploadExcel, fetchReportCycleSubmissionUploadReport } from '../../../features/redux/apiSlice/apiReportISC02Slice';
import { setAlert } from '../../../features/redux/slice/alertMsgSlice';
import { setLoading } from '../../../features/redux/slice/loadingSlice';
import { setModalUploadReportISC02ViewDetailAuditor } from '../../../features/redux/slice/modalSlice';
import { resetUploadFileISC02, resetUploadReport, setUploadFileISC02FileName } from '../../../features/redux/slice/uploadFileSlice';
import { iconRender } from '../fn';

const ModalUploadReportISC02ViewDetailAuditor = () => {
    const [form] = Form.useForm();
    const { isLoading } = useSelector(state => state.loading);
    const { modalUploadReportISC02ViewDetailAuditor } = useSelector(state => state.modal);
    const { tableReportISC02InfoRef } = useSelector(state => state.table);
    const { data: dataUploadFileISC, err: errUploadFileISC } = useSelector(state => state.uploadFile.uploadFileISC02Draft);
    const { isSelectTab = '', isSelectTabDraft = "" } = useSelector(state => state.reportISCTab);
    const { uploadReportSubmissionDetail, } = useSelector(state => ({ uploadReportSubmissionDetail: state.uploadFile.uploadReportSubmissionDetail, }));
    const { file_id = '', sheet_names = [] } = dataUploadFileISC || { file_id: '', sheet_names: [] };
    const { msg = '', icon = '' } = errUploadFileISC || { msg: '', icon: '' };
    const {
        meta = {},
        data = {},
    } = tableReportISC02InfoRef;
    const {
        is_edit_request = false
    } = data;
    const { submissionId = '', submissionDetailId = '' } = useParams();
    const dispatch = useDispatch();
    const {
        isOpen = false,
    } = modalUploadReportISC02ViewDetailAuditor;
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => {
        dispatch(resetUploadReport());
        dispatch(setModalUploadReportISC02ViewDetailAuditor({ isOpen: false }));
        setFileList([]); // Clear the fileList when modal is closed
    };

    const handleSubmit = () => {
        dispatch(setModalUploadReportISC02ViewDetailAuditor({ isOpen: false }));
        if (!!uploadReportSubmissionDetail?.data?.file_record_id || uploadReportSubmissionDetail?.data?.file_record_id >= 0) {
            const url = window.location.href
            window.history.pushState(null, '', url.replace('/-', `/${uploadReportSubmissionDetail?.data?.file_record_id}`))
        }
        setFileList([]);
    };

    const handleReset = () => {
        setFileList([]); // Clear the fileList when modal is closed
        dispatch(resetUploadReport());
    };

    const customUpload = {
        name: 'file',
        fileList,
        // accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ,application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document ',
        accept: 'application/pdf',
        beforeUpload: (file) => {
            if (fileList.length >= 1) {
                dispatch(setAlert({ type: 'error', msg: 'คุณสามารถอัปโหลดได้เพียงไฟล์เดียว' }));
                return Upload.LIST_IGNORE;
            }
            const fileType = file.type;
            const validTypes = [
                // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/pdf',
                // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ];
            if (!validTypes.includes(fileType)) {
                handleReset();
                // dispatch(setAlert({ type: 'error', msg: 'ไฟล์ต้องเป็นประเภท .pdf .xlsx และ .docx เท่านั้น' }));
                dispatch(setAlert({ type: 'error', msg: 'ไฟล์ต้องเป็นประเภท .pdf เท่านั้น' }));
                return Upload.LIST_IGNORE;
            }
            const isSizeAcceptable = file.size / 1024 / 1024 < 100;
            if (!isSizeAcceptable) {
                handleReset();
                dispatch(setAlert({ type: 'error', msg: 'ขนาดไฟล์เกิน 100MB!' }));
                return Upload.LIST_IGNORE;
            }
            return true;
        },
        onChange: (info) => {
            setFileList(info.fileList.slice(-1)); // Keep only the latest file

            if (info.file.status === 'done') {
                dispatch(setAlert({ type: 'success', msg: `${info.file.name} อัปโหลดไฟล์สำเร็จ` }));
                // Set the uploaded file name in Redux state
                dispatch(setUploadFileISC02FileName(info.file.name));
            } else if (info.file.status === 'error') {
                dispatch(setAlert({ type: 'error', msg: `${info.file.name} อัปโหลดไฟล์ล้มเหลว` }));
                handleReset();
            }
        },
        itemRender: (originNode, file, fileList, actions) => {
            return (
                <Row justify={'space-between'} wrap={false} style={{ marginLeft: '0 !important', marginRight: '0 !important', gap: '1rem', }}>
                    <Typography.Text style={{ color: 'var(--color-table-text)', fontSize: '16px' }}>{file.name}</Typography.Text>
                    <Button type="text"
                        onClick={() => {
                            actions.remove(file);
                            handleReset();
                        }}
                        icon={<CloseCircleFilled style={{ fontSize: 20, }} />}
                        style={{ color: '#D5D5D5', padding: 0, justifyContent: 'flex-start', alignItems: 'flex-start' }}
                    />
                </Row>
            );
        },
        customRequest: ({ file, onSuccess, onError, onProgress }) => {
            console.log(`----  modalUploadReportISC02ViewDetailAuditor  customRequest file:`, file);
            try {
                const simulateUploadProgress = () => {
                    dispatch(setLoading())
                    let progress = 0;
                    const interval = setInterval(() => {
                        progress += 10;
                        if (progress <= 100) {
                            onProgress({ percent: progress });
                        } else {
                            clearInterval(interval);
                            // dispatch(setUploadFileISC02FileName())
                            onSuccess(
                                dispatch(fetchReportCycleSubmissionUploadReport({
                                    submission_id: Number(submissionId),
                                    upload_id: Number(submissionDetailId),
                                    type: "AuditorReport",
                                    file: file,
                                }))
                            );
                        }
                    }, 100);
                };
                setTimeout(() => {
                    simulateUploadProgress();
                }, 1000);
            } catch (error) {
                onError(
                    dispatch(setAlert({ type: 'error', msg: 'คุณสามารถอัปโหลดได้เพียงไฟล์เดียว' }))
                )
            }
        },
        progress: {
            strokeColor: {
                '0%': '#4899e3',
                '80%': '#4899e3',
                '100%': '#11458d',
            },
            strokeWidth: 5,
            format: (percent) => {
                return percent && `${parseFloat(percent.toFixed(2))}%`
            }
        },
        showUploadList: {
            showRemoveIcon: true,
            removeIcon: <DeleteOutlined onClick={(e) => console.log(e, 'custom removeIcon event')} />,
        },
    };
    // useEffect((file) => {
    //     if (file) {
    //         setUploadFileISC02FileName(); 
    //     }
    // }, [file]);

    useEffect(() => {
        if (msg) {
            setFileList([]);
        }
    }, [msg]);

    useEffect(() => {
    }, [isOpen]);

    return (
        <Modal
            maskClosable={false}
            title="ไฟล์แนบเอกสารรายงานผู้สอบบัญชี"
            centered
            open={isOpen}
            onOk={handleSubmit}
            onCancel={handleCancel}
            footer={[
                <div className="flex justify-center mt-10 mb-3" key="footer-buttons">
                    <Button className="px-8 me-3 font-bold" key="btn-back" onClick={handleCancel} style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)' }}>
                        ย้อนกลับ
                    </Button>
                    {(!!uploadReportSubmissionDetail?.data?.file_id) && (
                        <Button className="px-10 font-bold" key="btn-save" type="primary" onClick={handleSubmit}>
                            บันทึก
                        </Button>
                    )}
                </div>
            ]}
            width={1000}
            zIndex={2000}
        >
            <p>(รองรับไฟล์สกุล .pdf ขนาดไม่เกิน 100MB เท่านั้น)</p>
            {/* <p>(รองรับไฟล์สกุล .pdf .xlsx และ .docx ขนาดไม่เกิน 100MB เท่านั้น)</p> */}
            <div>
                <Upload {...customUpload}>
                    <Col gap={6}>
                        {/* MSG ERR */}
                        {!!msg && (
                            <Row style={{ marginTop: '1rem', gap: '1rem', }}>
                                <Image
                                    width={20}
                                    src={iconRender(icon)}
                                    preview={false}
                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                />
                                <Typography.Text type="danger">{msg}</Typography.Text>
                            </Row>
                        )}
                        {/* MSG ERR */}

                        {!uploadReportSubmissionDetail?.data?.file_id && !isLoading && (
                            <Button
                                className="upload my-4"
                                type="primary"
                                style={{ paddingLeft: '30px', paddingRight: '30px', minWidth: '180px' }}
                                icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />
                                }>
                                อัปโหลดไฟล์
                            </Button>
                        )}
                    </Col>
                </Upload>
            </div>
        </Modal>
    );
};

export default ModalUploadReportISC02ViewDetailAuditor;
