import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportCycleGetList } from '../../redux/apiSlice/apiReportISCSlice';
import { useFetchReportISCDefault } from './useFetchReportISCDefault';

export const useFetchReportISC = () => {
    const { fetchDataTable } = useFetchReportISCDefault()
    const dispatch = useDispatch();
    const { companyInfo } = useSelector(state => state.user);
    const { searchReportISC } = useSelector(state => state.search);
    const { pagination } = useSelector(state => state.pagination);

    const {
        cycleYear = [],
        submissionStatus = [],
        approvalStatus = [],
    } = searchReportISC;
    const {
        company_id = null,
        thai_name = '',
        short_name = '',
        full_name_eng = '',
        status = '',
        domain_name = '',
    } = companyInfo;

    const {
        page = null,
        pageSize = [],
        total = [],
    } = pagination;

    // const fetchDataTable = () => {
    //     dispatch(fetchReportCycleGetList(
    //         {
    //             "company_id": company_id,
    //             "filter": {
    //                 "cycle_year": !!cycleYear ? cycleYear : [],
    //                 "submission_status": submissionStatus,
    //                 "approval_status": approvalStatus,
    //             },
    //             "pagination": {
    //                 "page": page,
    //                 "page_size": pageSize,
    //             }
    //         }
    //     ));
    // }

    useEffect(() => {
        // console.log(`----  useEffect  fetchDataTable:`,);
        fetchDataTable()
    }, [
        cycleYear.length,
        submissionStatus.length,
        approvalStatus.length,
        page,
        // pageSize,
        // total,
    ]);

    return {
    };
};
