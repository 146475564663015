import { all, call, put, takeLatest } from 'redux-saga/effects';
import BaseService from '../../../server/service/baseService';
import { fetchConfigAll } from '../apiSlice/apiBaseSlice';
import { setConfig } from '../slice/configSlice';
import { setLoadingTimeout } from '../slice/loadingSlice';

function* fetchConfigAllSaga(action) {
    try {
        const data = yield call(BaseService.apiConfigsAllBase);
        console.log(`----  function*fetchConfigAllSaga  data:`, data);
        yield put(setConfig(data?.data))
    } catch (error) {
        console.log(`----  function*fetchConfigAllSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout({ time: 500 }));
    }
}

function* watchFetchBase() {
    yield takeLatest(fetchConfigAll.type, fetchConfigAllSaga);
}

export default function* apiBaseSagas() {
    yield all([
        watchFetchBase(),
    ]);
}
