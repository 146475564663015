// ModalReportISC02BackDetailAuditor

import { Button, Form, Input, Modal, } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalReportISC02BackDetailAuditor } from '../../../features/redux/slice/modalSlice';

const ModalReportISC02BackDetailAuditor = ({ handleSave, navigateBackPage }) => {
    const { modalReportISC02BackDetailAuditor } = useSelector(state => state.modal);

    const {
        isOpen = false,
    } = modalReportISC02BackDetailAuditor

    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(setModalReportISC02BackDetailAuditor({ isOpen: false, }));
    }
    const handleBtnSave = () => {
        handleCloseModal()
        handleSave()
    };
    const handleBtnCancel = () => {
        handleCloseModal()
        navigateBackPage()
    };

    return (
        <>
            <Modal
                maskClosable={false}
                title={<p className='text-xl'>แจ้งเตือนการออกจากหน้ารายงาน</p>}
                centered
                open={isOpen}
                onCancel={handleBtnCancel}
                footer={[
                    <div className='flex justify-center mt-10 mb-1'>
                        <Button
                            className='px-6 me-3 font-bold'
                            key="back"
                            onClick={handleBtnCancel}
                            style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)', }}
                        >
                            ย้อนกลับ
                        </Button>
                        <Button
                            className='px-6'
                            type="primary"
                            onClick={handleBtnSave}
                        >
                            บันทึก
                        </Button>
                    </div>
                ]}
                width={600}
            >
                <p className='mt-5 font-normal text-sm'>ท่านกำลังออกจากหน้ารายงานโดยไม่บันทึกข้อมูล ต้องการบันทึกข้อมูลก่อนหรือไม่ ?</p>
            </Modal >
        </>
    );
};

export default ModalReportISC02BackDetailAuditor;
