import { Button, Col, Empty, Image, Row, Table, Tooltip, Typography, Input, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { IconCircleClose, IconFileExcel, IconFileExcelFill, IconFileInfoFill, IconSearch, IconViewMore } from '../../../helper/icon';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateFormat } from '../../../helper/date';
import { convertStatusInfoRef } from '../fn';
import { stringDefault } from '../../../helper/base';
import { initialStatePagination, setPage } from '../../../features/redux/slice/paginationSlice';
import { setIsc03Save } from '../../../features/redux/slice/iscSlice';
import { setFormValidity, setIsFormValidISC03 } from '../../../features/redux/slice/formSlice';
const { Column, ColumnGroup } = Table;

const TableReportISC03InfoRef = ({
    dataList = [],
    pagination = {},
    onClickInfo = () => null,
    onClickFileExcel = () => null,
    onClickFileExcelErr = () => null,
    onClickSearch = () => null,
    checkDataInput,
}) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
        total_pages = initialStatePagination.totalPages,
    } = pagination || initialStatePagination

    const data = [...dataList];
    const [dataInput, setDataInput] = useState([]);

    useEffect(() => {
        validateFormData(dataInput);
        console.log(checkDataInput)
    }, [dataInput, checkDataInput]);

    useEffect(() => {
        const fromCheck = data.filter(item => item.can_input_reason == true)
        setDataInput(fromCheck);
    }, [dataList])

    const onValuesChange = (changedValues, allValues) => {
        const detailId = Object.keys(changedValues)[0];
        const updatedReason = changedValues[detailId]?.reason;

        const updatedData = dataInput.map(item => {
            if (item.detail_id === Number(detailId)) {
                return {
                    ...item,
                    reason: updatedReason
                };
            }
            return item;
        });
        setDataInput(updatedData);
        dispatch(setIsc03Save({
            detail_id: detailId,
            reason: updatedReason
        }));
    };

    const validateFormData = (data) => {
        for (const item of data) {
            if (item.reason.trim() === '' || item.reason === undefined || item.reason === null) {
                return dispatch(setIsFormValidISC03({ isFormValid: false }));
            }
        }
        return dispatch(setIsFormValidISC03({ isFormValid: true }));
    };

    const checkValueIndex = (valuesId) => {
        const value = dataInput.find(item => item.detail_id === valuesId);
        if(value?.reason == ''){
            return true;
        } else{
            return false;
        }
    };

    const formatNumber = (value) => {
        if (value === null || value === undefined) {
            return null;
        } else {
            return `${value?.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
    }

    return (
        <Form onValuesChange={onValuesChange} initialValues={{ items: dataList.reduce((acc, item) => ({ ...acc, [item.detail_id]: { reason: item.reason } }), {}) }}>
            <Table
                dataSource={data}
                scroll={{ x: true }}
                className="full-width-table custom-table-isc03 custom-table-isc03-line"
                rowKey={"row_no"}
                rowClassName={(record, index) =>
                    Boolean(record?.is_header) ? 'table-in-asset' : ''
                }
                pagination={false}
                locale={{
                    emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- ไม่พบข้อมูล -' />,
                }}
            >
                <Column

                    className=" text-[16px] w-64"
                    strong align="start"
                    title="แบบรายงานเกี่ยวกับฐานะการเงินและกิจการ"
                    dataIndex="xml_dataname"
                    key="xml_dataname"
                    render={(text, record, index) =>
                        <Typography.Paragraph strong>
                            {record.is_header === 0 ? `${record.order_no} ${record.xml_dataname}` : `${stringDefault(record.order_no, '')}  ${stringDefault(record.xml_dataname, '')}`}
                        </Typography.Paragraph>}
                />
                <Column
                    className=" text-[16px] w-36"
                    strong align="start"
                    title="Balance"
                    dataIndex="xml_balance"
                    key="xml_balance"
                    render={(text, record, index) =>
                        <Typography.Paragraph>
                            {record.is_header ? formatNumber(text) : stringDefault(formatNumber(text), '-')}
                        </Typography.Paragraph>}
                />
                <Column className=" text-[16px] w-64" strong align="start" title="รายงานงบการเงิน" dataIndex="finance_dataname" key="finance_dataname" render={(text, record, index) => <Typography.Paragraph>{record.is_header ? text : stringDefault(text, '-')}</Typography.Paragraph>} />
                <Column className=" text-[16px] w-36" strong align="start" title="Balance" dataIndex="finance_balance" key="finance_balance" render={(text, record, index) => <Typography.Paragraph>{record.is_header ? formatNumber(text) : stringDefault(formatNumber(text), '-')}</Typography.Paragraph>} />
                <Column className=" text-[16px] w-36" strong align="start" title="ความแตกต่าง (จำนวน)" dataIndex="diff_balance" key="diff_balance" render={(text, record, index) => <Typography.Paragraph>{record.is_header ? formatNumber(text) : stringDefault(formatNumber(text), '-')}</Typography.Paragraph>} />
                <Column className=" text-[16px] w-36" strong align="center" title="ความแตกต่าง (%)" dataIndex="diff_percent" key="diff_percent" render={(text, record, index) => <Typography.Paragraph>{record.is_header ? (text > 1 ? `${formatNumber(text)} %` : formatNumber(text)) : (text > 1 ? `${stringDefault(formatNumber(text), '-')} %` : stringDefault(formatNumber(text), '-'))}</Typography.Paragraph>} />
                <Column className=" text-[16px] w-36" strong align="center" title="เหตุผล" render={(text, record) => (
                    <>
                        {record.input_reason ? (
                            <Form.Item
                                className="hide-form-error"
                                style={{ width: "100%", margin: 0 }}
                                name={[record.detail_id, 'reason']}
                                initialValue={record.reason}
                                rules={[{ required: true, message: '' }]}
                                validateStatus={checkDataInput == true ? checkValueIndex(record.detail_id)  ? 'error' : '' : ''}
                            >
                                <Input
                                    disabled={!record.can_input_reason}
                                    style={{ width: "100%", height: '36px', borderColor: record.can_input_reason ? '' : '#E5EBEB', backgroundColor: record.can_input_reason ? '' : '#E5EBEB', color: record.can_input_reason ? '' : '#464646' }}
                                />
                            </Form.Item>
                        ) : (
                            <></>
                        )}
                    </>
                )}
                />
            </Table >
        </Form>
    );
};

export default TableReportISC03InfoRef;

