import { createSlice } from '@reduxjs/toolkit';


export const initialUploadFileErr = { msg: '', icon: '' }
export const initialUploadFileISC = {}
export const initialUploadFileISC02 = {}
export const initialUploadReport = {}
export const initialUploadReportFileName = ''
export const initialUploadReportErr = { msg: '', icon: '' }
export const initialUploadFileExcelISC02 = {}
export const initialUploadCheckDataNameISC02 = {}
export const initialMapErrorISC02 = {}
export const initialMapValueISC02 = {}
export const initialUploadFileLuckySheet = {}
export const initialUploadFileData = {}

export const initialState = {
    uploadFileISC: initialUploadFileISC,
    uploadFileISC02: initialUploadFileISC02,
    uploadFileISC02Draft: initialUploadFileISC02,
    uploadReportSubmissionDetail: initialUploadReport,
    fileExcelISC02: initialUploadFileExcelISC02,
    fileExcelISC02Draft: initialUploadFileExcelISC02,
    fileExcelCheckDataNameISC02: initialUploadCheckDataNameISC02,  //step2
    fileExcelMapErrorISC02: initialMapErrorISC02, //step3
    fileExcelMapValueISC02: initialMapValueISC02, //step3
    uploadReportSubmissionDetailFileName: initialUploadReportFileName,
    uploadFileLuckySheet: initialUploadFileLuckySheet,
    uploadFileLuckySheetDraft: initialUploadFileLuckySheet,
    uploadFileData: initialUploadFileData,
    editUploadFileISC02: null,
};

const uploadFileSlice = createSlice({
    name: 'uploadFile',
    initialState,
    reducers: {
        setUploadFileField: (state, action) => { // dispatch(setUploadFileField({ field: 'fileExcelISC02', data: null }));
            const { field = "", data = null } = action.payload;
            state[field] = data;
        },
        setUploadFileISC(state, action) {
            const { payload = initialUploadFileISC } = action;
            const { data = initialUploadFileISC, err = initialUploadFileErr } = payload;
            state.uploadFileISC = {
                ...data,
                err: { ...err },
            };
        },
        resetUploadFileISC(state) {
            state.uploadFileISC = initialUploadFileISC;
        },


        // ISC02
        setUploadFileISC02(state, action) {
            const { payload = initialUploadFileISC02 } = action;
            const { data = initialUploadFileISC02, err = initialUploadFileErr } = payload;
            state.uploadFileISC02 = {
                ...data,
                err: { ...err },
            };
        },
        setUploadFileISC02FileName: (state, action) => {
            state.uploadReportSubmissionDetailFileName = action.payload;
        },
        resetUploadFileISC02FileName: (state, action) => {
            state.uploadReportSubmissionDetailFileName = initialUploadReportFileName;
        },
        setUploadFileISC02FileData: (state, action) => {
            state.uploadFileData = action.payload;
        },
        resetUploadFileISC02FileData: (state, action) => {
            state.uploadFileData = initialUploadFileData;
        },
        resetUploadFileISC02(state) {
            state.fileExcelISC02 = initialUploadFileExcelISC02;
            state.fileExcelISC02Draft = initialUploadFileExcelISC02;
            state.uploadFileISC02 = initialUploadFileISC;
            state.uploadFileISC02Draft = initialUploadFileISC;
            state.uploadFileLuckySheet = initialUploadFileLuckySheet;
            state.uploadFileLuckySheetDraft = initialUploadFileLuckySheet;
        },
        setUploadReport(state, action) {
            const { payload = initialUploadReport } = action;
            const { data = initialUploadReport, err = initialUploadReportErr } = payload;
            state.uploadReportSubmissionDetail = {
                ...data,
                err: { ...err },
            };
        },
        resetUploadReport(state, action) {
            state.uploadReportSubmissionDetail = {
                ...initialUploadReport,
                err: { ...initialUploadReportErr }
            };
        },
        resetUploadReport(state) {
            state.uploadReportSubmissionDetail = initialUploadReport;
        },
    },
});

export const {
    setUploadFileField,
    setUploadFileISC,
    resetUploadFileISC,
    setUploadFileISC02,
    resetUploadFileISC02,
    setUploadReport,
    resetUploadReport,
    setUploadFileISC02FileName,
    resetUploadFileISC02FileName,
    setUploadFileISC02FileData,
    resetUploadFileISC02FileData,
} = uploadFileSlice.actions;

export const selectUploadFile = (state) => state.uploadFile;

export default uploadFileSlice.reducer;
