import { Col, Input, Layout, Row, Form, Typography, Card, Button, Table, Empty, Image } from 'antd';
import { ClearOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logService from '../../server/service/log';
import Breadcrumb from '../../components/Breadcrumb';
import { IconViewMore } from '../../helper/icon';
const { Column } = Table;

const MsdListPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            await logService.log(logService.LogType.MasterData, "ดูรายการ");   
        })();
    }, []);

    const breadcrumbList = [
        { title: `ส่งและติดตามผลข้อมูล`, link: `` },
        { title: `ดัชนีตารางอ้างอิงมาตรฐาน`, link: `` },
    ]

    const data = [
        { orderNo: 1, name: 'รหัสบริษัทประกันภัย', path: '/msd/companies' },
        { orderNo: 2, name: 'คำนำหน้าชื่อบุคคล' },
        { orderNo: 3, name: 'ประเภทผู้ถือหุ้น' },
        { orderNo: 4, name: 'สัญชาติ' },
        { orderNo: 5, name: 'หน่วยงานตามโครงสร้างส่วนราชการใหม่' },
        { orderNo: 6, name: 'รหัสรัฐวิสาหกิจอื่นๆ ตามความหมายของรายงานการดำรงเงินกองทุนตามความเสี่ยง' },
        { orderNo: 7, name: 'ธนาคารเพื่อการพัฒนาซึ่งร่วมก่อตั้งโดยหลายประเทศ' },
        { orderNo: 8, name: 'ธนาคารในประเทศ' },
        { orderNo: 9, name: 'รหัสสาขา ของธนาคารและสาขาในประเทศ' },
        { orderNo: 10, name: 'สถาบันการเงินต่างประเทศ (Swift Code)' },
        { orderNo: 11, name: 'รหัสประเทศ' },
        { orderNo: 12, name: 'รหัสหน่วยงานในต่างประเทศ' },
        { orderNo: 13, name: 'กลุ่มผู้ทำธุรกรรมที่เกี่ยวข้อง' },
    ];

    const onDetail = (record) => {
        // console.log({ record })
        if (record && record.path) {
            navigate(record.path);
        }
    }

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <Card title="รายการข้อมูลหลัก" classNames={{ header: 'ant-card-head-search-report-isc', }}
                            styles={{
                                title: {
                                    fontSize: 20,
                                    color: '#000000',
                                }
                            }}>
                            <Form>
                                <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                                    <Col flex="1 1 150px">
                                        <Row gutter={[5, 5]}>
                                            <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>ค้นหา</Typography.Text>
                                                <Input placeholder="โปรดระบุ" />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle">
                                            <Col>
                                                <Button type="primary" style={{ paddingLeft: '30px', paddingRight: '30px', }} icon={<FileSearchOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ค้นหา</Button>
                                            </Col>
                                            <Col>
                                                <Button type="primary" style={{ background: 'var(--color-gray-primary)', }} icon={<ClearOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ล้างการค้นหา</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col flex="1 1 250px" />
                                </Row>
                            </Form >
                        </Card >
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>
                            <Col style={{ width: "100%", padding: 0, }}>

                                <Table
                                    dataSource={data}
                                    scroll={{ x: true }}
                                    className="full-width-table"
                                    pagination={false}
                                    style={{ background: 'var(--color-white)' }}
                                    locale={{
                                        emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- ไม่พบข้อมูล -' />,
                                    }}
                                >
                                    <Column title="ลำดับ" dataIndex="orderNo" key="orderNo" width="10%" align='center' />
                                    <Column title="ชื่อข้อมูลหลัก" dataIndex="name" key="name" />
                                    <Column
                                        title="เครื่องมือ"
                                        key="action"
                                        width="10%"
                                        align='center'
                                        render={(text, record) => (
                                            <Button
                                                type="text"
                                                style={{
                                                    alignSelf: 'center',
                                                    padding: '0px 15px',
                                                }}
                                                onClick={() => onDetail(record)}
                                            >
                                                <Image
                                                    width={30}
                                                    src={IconViewMore}
                                                    preview={false}
                                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                                />
                                            </Button>
                                        )}
                                    />
                                </Table>
                            </Col>
                        </Row>
                    </Col >
                </Row >
            </Content >
        </Layout >
    );
};

export default MsdListPage;

