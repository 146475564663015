// src/pages/UserManual.js
import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchManual from '../../components/userManual/CardSearchManual';

const UserManual = () => {

    const breadcrumbList = [
        { title: `คู่มือการใช้งาน`, link: `` },
    ]


    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchManual />
                    </Col>
                </Row >
            </Content >
        </Layout >
    );
};

export default UserManual;

