import { saveAs } from 'file-saver';
//import * as xlsx from 'xlsx';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { stringDefault } from '../../../helper/base';
import { reportISC02Host } from '../../../server/service/host/reportISC02Host';
import ReportISC02Service from '../../../server/service/reportISC02Service';
import { fetchReportCycleGetDetail, fetchReportCycleGetList, fetchReportCycleSubmissionCancelRequest, fetchReportCycleSubmissionCheckAccountant, fetchReportCycleSubmissionCreateUpload, fetchReportCycleSubmissionDownloadReportFileById, fetchReportCycleSubmissionGenerate, fetchReportCycleSubmissionGetAuditDropdown, fetchReportCycleSubmissionGetDetail, fetchReportCycleSubmissionGetDetailReport, fetchReportCycleSubmissionGetSubmissionHeader, fetchReportCycleSubmissionGetUploadErrorDetail, fetchReportCycleSubmissionImportExcel, fetchReportCycleSubmissionImportExcelStart, fetchReportCycleSubmissionImportExcelYTest, fetchReportCycleSubmissionSaveReportAuditor, fetchReportCycleSubmissionSaveReportNote, fetchReportCycleSubmissionSaveReportNoteYear, fetchReportCycleSubmissionUploadExcel, fetchReportCycleSubmissionUploadReport, fetchReportISCSubmissionCheckDataName, fetchReportISCSubmissionEditReportAuditor, fetchReportISCSubmissionGetDetailNote, fetchReportISCSubmissionGetExcelJson, fetchReportISCSubmissionGetNoteSection, fetchReportISCSubmissionMapError, fetchReportISCSubmissionMapValue, fetchReportISCSubmissionSaveDetailNote, fetchReportISCSubmissionSaveReport, fetchReportISCSubmissionSaveUpload } from '../apiSlice/apiReportISC02Slice';
import { setAlert } from '../slice/alertMsgSlice';
import { setCountdown } from '../slice/countdownSlice';
import { setAuditDropdown, setCheckAccountant, setCheckInformation, setEditReportAuditor, setFormDetailAuditorISC02 } from '../slice/formSlice';
import { setIsc02Header } from '../slice/iscSlice';
import { setLoading, setLoadingTimeout } from '../slice/loadingSlice';
import { setModalReportISC02SaveDetailNote, setModalUploadReportISC02 } from '../slice/modalSlice';
import { setPagination } from '../slice/paginationSlice';
import { selectReportISCTab, setSelectTabAndStep, setSelectTabField } from '../slice/reportISCTabSlice';
import { setTableReportISC02, setTableReportISC02Info, setTableReportISC02InfoRef, setTableReportISC02InfoRefDetail } from '../slice/tableSlice';
import { resetUploadFileISC02, selectUploadFile, setUploadFileField, setUploadFileISC02, setUploadReport } from '../slice/uploadFileSlice';
import { resetDetailNoteDataInput, setDetailNote, setDetailNoteSection, setSaveDetailNoteSection } from '../slice/detailNoteSlice';

function* fetchReportCycleGetListSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleGetList, action.payload);
        console.log(`----  function*fetchReportCycleGetListSaga  data:`, data);
        yield put(setPagination(data.data.pagination))
        yield put(setTableReportISC02(data));
    } catch (error) {
        console.log(`----  function*fetchReportCycleGetListSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout({ time: 500 }));
    }
}

function* fetchReportCycleGetDetailSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleGetDetail, action.payload);
        console.log(`----  function*fetchReportCycleGetDetailSaga  data:`, data);
        yield put(setTableReportISC02Info(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleGetDetailSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionCancelRequestSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionCancelRequest, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionCancelRequestSaga  data:`, data);
        yield put(setTableReportISC02Info(data));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionCancelRequestSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        window.location.reload();
    }
}


function* fetchReportCycleSubmissionGenerateSaga(action) {
    let dataFetch = null
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionGenerate, action.payload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  data:`, data);

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  dataFetch:`, dataFetch);
        const referenceCode = dataFetch?.data?.submission_id;
        if (referenceCode != null) {
            const submissionUrl = reportISC02Host.reportISC02SubmissionById(stringDefault(referenceCode, '-'))
            const navigateUrl = window.location.href + submissionUrl;
            window.location.href = navigateUrl
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /* Navigate */
    }
}

function* fetchReportCycleSubmissionUploadExcelSaga(action) {
    try {
        yield put(setLoading());
        console.log(`----  function*fetchReportCycleSubmissionUploadExcelSaga  action:`, action);
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionUploadReport, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionUploadExcelSaga  data:`, data);
        // const data = {
        //     "meta": {
        //         "timestamp": "2024-07-02T14:49:26.681Z",
        //         "status_code": 200,
        //         "message": "Upload success"
        //     },
        //     "data": {
        //         "file_record_id": 271,
        //         "file_id": "ad1af695-fb9d-4e8c-9016-b7df41285cf6",
        //         "file_path": "/appdata/uploads/isc02/1/1/23/ad1af695-fb9d-4e8c-9016-b7df41285cf6.xlsx",
        //         "is_first_submit": true,
        //         "sheet_names": [
        //             "Sheet1",
        //             "Sheet2"
        //         ]
        //     }
        // }

        // yield put(setUploadFileField({ field: 'fileExcelISC02Draft', data: { ...action?.payload?.file, isFileExcelISC02Finish: false } }));
        yield put(setUploadFileField({ field: 'uploadFileLuckySheetDraft', data: action?.payload?.file }));
        yield put(setUploadFileField({ field: 'uploadFileISC02Draft', data: data }));
        // yield put(setUploadFileISC02({ data: data }));
        // yield put(setSelectTab({ isSelectTab: data?.data?.sheet_names[0] }));
        yield put(setSelectTabField({ field: 'isSelectTabDraft', data: data?.data?.sheet_names[0] }));
        yield put(setAlert({ type: 'success', msg: 'เก็บข้อมูลไฟล์บนระบบสำเร็จ' }));

        // yield call(fetchReportCycleSubmissionImportExcelSaga, {
        //     payload: {
        //         submission_id: Number(action?.payload?.submission_id),
        //         file_id: data?.data?.file_id,
        //         sheet_name: data?.data?.sheet_names[0],
        //     }
        // });

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionUploadExcelSaga  error:`, error);
        yield delay(2000)
        yield put(setAlert({ type: 'error', msg: 'เก็บข้อมูลไฟล์บนระบบล้มเหลว กรุณาอัปโหลดไฟล์อีกครั้ง' }));
        // ไม่สามารถอัปโหลดไฟล์ได้ เนื่องจากขนาดไฟล์ไม่ถูกต้อง กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        // ไม่สามารถอัปโหลดไฟล์ได้ เนื่องจากสกุลไฟล์ไม่ถูกต้อง กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        // ไม่สามารถอัปโหลดไฟล์ได้ กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        yield put(setUploadFileISC02({ err: { msg: error?.err?.response?.data?.meta?.message || 'ไม่สามารถอัปโหลดไฟล์ได้ กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง', icon: 'warning' } }));
        yield put(resetUploadFileISC02({}));
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionImportExcelSaga(action) {

    try {
        console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  action:`, action);
        const { uploadFileISC02Draft, uploadFileLuckySheetDraft } = yield select(selectUploadFile);
        const { isSelectTabDraft } = yield select(selectReportISCTab);
        const response = yield call(ReportISC02Service.apiReportCycleSubmissionGetSheetData, action.payload);
        // const responseFile = yield call(ReportISC02Service.apiReportCycleSubmissionGetSheetFile, action.payload);

        let jsonPart = response?.data?.data || response?.data;
        let filePart = null;


        yield put(setUploadFileField({
            field: 'fileExcelISC02',
            data: {
                jsonPart: jsonPart,
                filePart: filePart,
                isFileExcelISC02Finish: true,
            }
        }));

        // Output the extracted parts
        // console.log('JSON Part:', jsonPart);
        // console.log('File Part:', filePart);

        // yield put(setUploadFileField({ field: 'fileExcelISC02', data: filePart }));

        yield put(setUploadFileField({ field: 'uploadFileLuckySheet', data: uploadFileLuckySheetDraft }));
        yield put(setUploadFileISC02({ data: uploadFileISC02Draft }));
        yield put(setSelectTabAndStep({
            isSelectTab: action?.payload?.sheet_name || isSelectTabDraft,
            stepTab: 1
        }));
        yield put(setModalUploadReportISC02({ isOpen: false }));
        const url = window.location.href
        window.history.pushState(null, '', url.replace('/-', `/${uploadFileISC02Draft?.data?.file_record_id}`))

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  error:`, error);
        yield put(resetUploadFileISC02({}));
    } finally {
        yield put(setLoadingTimeout());
        // console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  fileExcelISC02Draft:`, fileExcelISC02Draft);
        // yield put(setUploadFileField({ field: 'fileExcelISC02', data: { ...fileExcelISC02Draft, isFileExcelISC02Finish: true } }));
        // yield put(setUploadFileISC02({ data: uploadFileISC02Draft }));
        // yield put(setSelectTab({ isSelectTab: action?.payload?.sheet_name || isSelectTabDraft }));
        // window.location.reload();
        // yield put(setModalUploadReportISC02({ isOpen: false }));
    }
}

function* fetchReportCycleSubmissionImportExcelYTestSaga(action) {
    try {
        const response = yield call(ReportISC02Service.apiReportCycleSubmissionGetSheetData, action.payload);

        // Extract boundary
        const contentType = response.data.split('\r\n')[0];
        const boundary = contentType.split('--')[1].trim();

        // Split the response data using the boundary
        const parts = response.data.split(`--${boundary}`);

        // Initialize variables for JSON and file parts
        let jsonPart = null;
        let filePart = null;

        // Iterate through parts to find JSON and file parts
        parts.forEach(part => {
            if (part.includes('Content-Type: application/json')) {
                // Extract JSON part
                const jsonContent = part.split('\r\n\r\n')[1].trim();
                jsonPart = JSON.parse(jsonContent);
            } else if (part.includes('Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                // Extract file part as ArrayBuffer
                const fileContentArrayBuffer = response.data.slice(
                    part.indexOf('\r\n\r\n') + 4, // Skip header
                    part.indexOf('--' + boundary) // End at the boundary
                );
                // Convert ArrayBuffer to Blob
                filePart = new Blob([fileContentArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            } else if (part.includes('Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                const fileContent = part.split('\r\n\r\n')[1].trim();
                console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  fileContent:`, fileContent);
                // Extract file part
                // const fileContent = part.split('\r\n\r\n')[1].trim();
                // Convert file content to Blob
                const byteCharacters = atob(fileContent);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  byteArray:`, byteArray);
                filePart = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


            } else if (part.includes('Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                // Extract file part
                const fileContent = part.split('\r\n\r\n')[1].trim();
                // filePart = atob(fileContent); // Convert from base64 to binary
                filePart = fileContent;
                console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  filePart:`, filePart);

                // Create a Blob from binary content
                const byteArray = new Uint8Array(response.data);
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Convert Blob to a URL for further processing
                filePart = URL.createObjectURL(blob);
            }
        });

        // Output the extracted parts
        // console.log('JSON Part:', jsonPart);
        // console.log('File Part:', filePart);

        yield put(setUploadFileField({ field: 'fileExcelISC02', data: filePart }));
        yield put(setModalUploadReportISC02({ isOpen: false }));

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield put(setModalUploadReportISC02({ isOpen: false }));
        // window.location.reload();
    }
}


function* fetchReportCycleSubmissionGetDetailSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionGetDetail, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionGetDetailSaga  data:`, data);
        yield put(setTableReportISC02InfoRef(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGetDetailSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* fetchReportCycleSubmissionGetDetailReportSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionGetDetailReport, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionGetDetailReportSaga  data:`, data);
        yield put(setTableReportISC02InfoRefDetail(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGetDetailReportSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionGetUploadErrorDetailSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionGetUploadErrorDetail, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionGetUploadErrorDetailSaga  data:`, data);
        yield put(setTableReportISC02InfoRefDetail(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGetUploadErrorDetailSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionDownloadReportFileByIdSaga(action) {
    console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  action:`, action);
    try {

        const response = yield call(ReportISC02Service.apiReportCycleSubmissionDownloadReportFileById, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  response:`, response);
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  blob:`, blob);
        const fileName = `file.xlsx`
        yield call(saveAs, blob, fileName);

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* fetchReportCycleSubmissionCheckAccountantSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionCheckAccountant, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionCheckAccountant  data:`, data);
        yield put(setCheckInformation(data));
        yield put(setFormDetailAuditorISC02({
            license_number: data?.data?.license_number,
            full_name: data?.data?.full_name,
            // licensed_by_gaap: data?.data?.licensed_by_gaap,
            error_license_number: false,
        }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionCheckAccountant  error:`, error);

        // ไม่พบเลขที่ใบอนุญาตผู้สอบบัญชี
        yield put(setFormDetailAuditorISC02({
            error_license_number: true
        }));
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionGetAuditDropdownSaga() {
    try {
        const response = yield call(ReportISC02Service.apiReportCycleSubmissionGetAuditDropdown);
        console.log(`----  function*fetchReportCycleSubmissionGetAuditDropdownSaga  data:`, response);
        yield put(setAuditDropdown(response));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGetAuditDropdownSaga  error:`, error);
    }
}
function* fetchReportCycleSubmissionGetSubmissionHeaderSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionGetSubmissionHeader, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionGetSubmissionHeader  data:`, data);
        yield put(setIsc02Header(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGetSubmissionHeader  error:`, error);
    }
}

function* fetchReportCycleSubmissionUploadReportSaga(action) {
    try {
        yield put(setLoading());
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionUploadReport, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionUploadReport  data:`, data);
        yield put(setUploadReport({ data: data }));
        // if (action?.payload?.file_record_id == null) {
        //     const url = window.location.href
        //     window.history.pushState(null,'',url.replace('/null', `/${data?.data?.file_record_id}`))
        // }

        yield put(setAlert({ type: 'success', msg: 'เก็บข้อมูลไฟล์บนระบบสำเร็จ' }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionUploadReport  error:`, error);
        yield delay(2000)
        yield put(setAlert({ type: 'error', msg: 'เก็บข้อมูลไฟล์บนระบบล้มเหลว กรุณาอัปโหลดไฟล์อีกครั้ง' }));
        // ไม่สามารถอัปโหลดไฟล์ได้ เนื่องจากขนาดไฟล์ไม่ถูกต้อง กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        // ไม่สามารถอัปโหลดไฟล์ได้ เนื่องจากสกุลไฟล์ไม่ถูกต้อง กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        // ไม่สามารถอัปโหลดไฟล์ได้ กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        yield put(setUploadReport({ err: { msg: error?.err?.response?.data?.meta?.message || 'ไม่สามารถอัปโหลดไฟล์ได้ กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง', icon: 'warning' } }));
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionSaveReportAuditorSaga(action) {
    let dataFetch = null
    try {
        const { info_id, ...rest } = action.payload;
        const newPayload = { ...rest };

        const data = yield call(ReportISC02Service.apiReportCycleSubmissionSaveReportAuditor, newPayload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionSaveReportAuditorSaga  data:`, data);
        yield put(setCheckInformation(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  dataFetch:`, dataFetch);
        const referenceCode = action.payload.upload_id;
        if (referenceCode != null) {
            const submissionUrl = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(action.payload.info_id, '-'), stringDefault(action.payload.submission_id, '-'), stringDefault(referenceCode, '-'))
            const navigateUrl = window.location.origin + submissionUrl;
            window.location.href = navigateUrl
            // console.log(`submissionUrl`, submissionUrl)
            // console.log(`navigateUrl`, navigateUrl)
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /*End Navigate */
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionSaveReportAuditorSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
    }
}
function* fetchReportCycleSubmissionSaveReportNoteSaga(action) {
    let dataFetch = null
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionSaveReportNote, action.payload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionSaveReportNoteSaga  data:`, data);
        yield put(setCheckInformation(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  dataFetch:`, dataFetch);
        yield put(resetDetailNoteDataInput());
        yield put(setModalReportISC02SaveDetailNote({ isOpen: true, }));
        const referenceCode = action.payload.upload_id;
        const truncateUrl = ((url) => {
            const parts = url.split('/');
            const truncatedParts = parts.slice(0, -2);
            return truncatedParts.join('/');
        });

        if (referenceCode != null) {
            // const submissionUrl = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(action.payload.info_id, '/-'), stringDefault(action.payload.submission_id, '/-'), stringDefault(referenceCode, '/-'))
            // const navigateUrl = window.location.origin + submissionUrl;
            window.location.href = truncateUrl(window.location.href)
            console.log(truncateUrl(window.location.href))
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /*End Navigate */
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionSaveReportNoteSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
    }
}
function* fetchReportCycleSubmissionSaveReportNoteYearSaga(action) {
    let dataFetch = null
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionSaveReportNoteYear, action.payload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionSaveReportNoteSaga  data:`, data);
        yield put(setCheckInformation(data));
        yield put(resetDetailNoteDataInput());
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  dataFetch:`, dataFetch);
        yield put(setModalReportISC02SaveDetailNote({ isOpen: true, }));
        const referenceCode = action.payload.upload_id;
        const truncateUrl = ((url) => {
            const parts = url.split('/');
            const truncatedParts = parts.slice(0, -2);
            return truncatedParts.join('/');
        });

        if (referenceCode != null) {
            // const submissionUrl = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(action.payload.info_id, '/-'), stringDefault(action.payload.submission_id, '/-'), stringDefault(referenceCode, '/-'))
            // const navigateUrl = window.location.origin + submissionUrl;
            window.location.href = truncateUrl(window.location.href)
            console.log(truncateUrl(window.location.href))
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /*End Navigate */
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionSaveReportNoteSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
    }
}
function* fetchReportCycleSubmissionCreateUploadSaga(action) {
    let dataFetch = null
    try {
        const data = yield call(ReportISC02Service.apiReportCycleSubmissionCreateUpload, action.payload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSagat  data:`, data);
        // yield put(setIsc02UploadId(data));
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  dataFetch:`, dataFetch);
        const referenceCode = dataFetch?.data?.upload_id;
        if (referenceCode != null) {
            const submissionUrl = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(action.payload.info_id, '/-'), stringDefault(action.payload.submission_id, '/-'), stringDefault(referenceCode, '/-'))
            const navigateUrl = window.location.origin + submissionUrl;
            window.location.href = navigateUrl
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /*End Navigate */
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
    }
}
function* fetchReportISCSubmissionSaveUploadSaga(action) {
    let dataFetch = null
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionSaveUpload, action.payload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  data:`, data);
        // yield put(setIsc02UploadId(data));
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  dataFetch:`, dataFetch);
        const referenceCode = dataFetch?.data?.upload_id;
        if (referenceCode != null) {
            const submissionUrl = reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(action.payload.info_id, '/-'), stringDefault(action.payload.submission_id, '/-'))
            const navigateUrl = window.location.origin + submissionUrl;
            window.location.href = navigateUrl
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /*End Navigate */
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionCreateUploadSaga  error:`, error);
    }
    finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
    }
}
function* fetchReportISCSubmissionCheckDataNameSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionCheckDataName, action.payload);
        console.log(`----  function*fetchReportISCSubmissionCheckDataNameSaga  data:`, data);
        yield put(setSelectTabField({
            field: 'stepTab',
            data: data?.data?.have_error ? 2 : 3
        }));
        yield put(setUploadFileField({
            field: 'fileExcelCheckDataNameISC02',
            data: data
        }));
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionCheckDataNameSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportISCSubmissionMapErrorSaga(action) {
    try {
        const { fileExcelCheckDataNameISC02 } = yield select(selectUploadFile);
        const data = yield call(ReportISC02Service.apiReportISCSubmissionMapError, action.payload);
        console.log(`----  function*fetchReportISCSubmissionMapError  data:`, data);
        if (data?.data?.map_errors <= 0) {
            yield put(setSelectTabField({
                field: 'stepTab',
                data: 3
            }));
        }
        // yield put(setUploadFileField({
        //     field: 'fileExcelCheckDataNameISC02',
        //     data: {
        //         ...fileExcelCheckDataNameISC02,
        //         data: {
        //             ...fileExcelCheckDataNameISC02?.data,
        //             map_errors: [...data?.data?.map_errors || []],
        //             have_error: Boolean(data?.data?.have_error),
        //         }
        //     }
        // }));
        // } else {
        yield put(setUploadFileField({
            field: 'fileExcelCheckDataNameISC02',
            data: {
                ...fileExcelCheckDataNameISC02,
                data: {
                    ...fileExcelCheckDataNameISC02?.data,
                    map_errors: [...data?.data?.map_errors || []],
                    have_error: Boolean(data?.data?.have_error),
                }
            }
        }));
        // }
        yield put(setUploadFileField({
            field: 'fileExcelMapErrorISC02',
            data: data
        }));
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionMapError  error:`, error);
        yield put(setSelectTabField({
            field: 'stepTab',
            data: 3
        }));
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportISCSubmissionMapValueSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionMapValue, action.payload);
        console.log(`----  function*apiReportISCSubmissionMapValue  data:`, data);
        yield put(setUploadFileField({
            field: 'fileExcelMapValueISC02',
            data: data
        }));
        yield put(setSelectTabField({
            field: 'stepTab',
            data: 4
        }));
    } catch (error) {
        console.log(`----  function*apiReportISCSubmissionMapValue  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportISCSubmissionSaveReportSaga(action) {
    try {
        console.log(`----  function*fetchReportISCSubmissionSaveReportSaga  action:`, action);
        const { info_id, ...rest } = action.payload;
        const newPayload = { ...rest };
        const data = yield call(ReportISC02Service.apiReportISCSubmissionSaveReport, newPayload);
        console.log(`----  function*fetchReportISCSubmissionSaveReportSaga  data:`, data);
        const referenceCode = action?.payload?.upload_id;
        if (referenceCode != null) {
            const submissionUrl = reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(action?.payload?.info_id, '-'), stringDefault(action?.payload?.submission_id, '-'), stringDefault(referenceCode, '-'))
            const navigateUrl = window.location.origin + submissionUrl;
            window.location.href = navigateUrl
            // console.log(`submissionUrl`, submissionUrl)
            // console.log(`navigateUrl`, navigateUrl)
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        // return null
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionSaveReportSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* fetchReportISCSubmissionEditReportAuditorSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionEditReportAuditor, action.payload);
        yield put(setEditReportAuditor(data));
        console.log(`----  function*fetchReportISCSubmissionEditReportAuditor  data:`, data);


        const fetchedData = {
            name_thai: data?.data?.report_detail?.audit_company_name,
            //approval_end_date: data?.data?.accountant?.approval_end_date,
            //approval_start_date: data?.data?.accountant?.approval_start_date,
            full_name: data?.data?.report_detail?.auditor_name,
            license_number: data?.data?.report_detail?.accountant_license_number,
            licensed_by_gaap: data?.data?.report_detail?.licensed_by_gaap,
            //status: data?.data?.accountant?.status,
            auditor_comment: data?.data?.report_detail?.auditor_comment,
            comment_date: data?.data?.report_detail?.comment_date,
            notes: data?.data?.report_detail?.notes,
            file_record_id: data?.data?.report_detail?.file_record_id,
        };

        // dispatch(setFormDetailAuditorISC02(fetchedData));
        yield put(setFormDetailAuditorISC02(fetchedData));
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionEditReportAuditor  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* fetchReportISCSubmissionSaveDetailNoteSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionSaveDetailNote, action.payload);
        yield put(setSaveDetailNoteSection(data));
        console.log(`----  function*fetchReportISCSubmissionSaveDetailNote  data:`, data);
        const referenceCode = data?.data?.file_record_id;
        if (referenceCode != null) {
            const submissionUrl = window.location.href;
            const parts = submissionUrl.split('/');
            const newUrl = parts.slice(0, parts.length - 2).join('/');
            const navigateUrl = `${newUrl}/${referenceCode}`
            window.location.href = navigateUrl;
            // console.log(`submissionUrl`, submissionUrl)
            // console.log(`navigateUrl`, navigateUrl)
        }
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionSaveDetailNote  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* fetchReportISCSubmissionGetDetailNoteSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionGetNoteView, action.payload);
        yield put(setDetailNote(data));
        console.log(`----  function*GetNoteView  data:`, data);
    } catch (error) {
        console.log(`----  function*GetNoteView  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* fetchReportISCSubmissionGetNoteSectionSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionGetNoteSection, action.payload);
        yield put(setDetailNoteSection(data));
        console.log(`----  function*GetNoteView  data:`, data);
    } catch (error) {
        console.log(`----  function*GetNoteView  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportISCSubmissionGetExcelJsonSaga(action) {
    try {
        const data = yield call(ReportISC02Service.apiReportISCSubmissionGetExcelJson, action.payload);
        console.log(`----  function*fetchReportISCSubmissionGetExcelJsonSaga  data:`, data);
        yield put(setUploadFileField({ field: 'editUploadFileISC02', data: JSON.stringify(data?.data?.json_stringify) }));
        yield put(setUploadFileISC02({ data: data }));
        yield put(setSelectTabAndStep({
            isSelectTab: data?.data?.sheet_names[0],
            stepTab: 1
        }));
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionGetExcelJsonSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* watchFetchReportCycleGetList() {
    yield takeLatest(fetchReportCycleGetList.type, fetchReportCycleGetListSaga);
    yield takeLatest(fetchReportCycleGetDetail.type, fetchReportCycleGetDetailSaga);
    yield takeLatest(fetchReportCycleSubmissionCancelRequest.type, fetchReportCycleSubmissionCancelRequestSaga);
    yield takeLatest(fetchReportCycleSubmissionGenerate.type, fetchReportCycleSubmissionGenerateSaga);
    yield takeLatest(fetchReportCycleSubmissionUploadExcel.type, fetchReportCycleSubmissionUploadExcelSaga);
    yield takeLatest(fetchReportCycleSubmissionImportExcel.type, fetchReportCycleSubmissionImportExcelSaga);
    yield takeLatest(fetchReportCycleSubmissionGetDetail.type, fetchReportCycleSubmissionGetDetailSaga);
    yield takeLatest(fetchReportCycleSubmissionGetDetailReport.type, fetchReportCycleSubmissionGetDetailReportSaga);
    yield takeLatest(fetchReportCycleSubmissionGetUploadErrorDetail.type, fetchReportCycleSubmissionGetUploadErrorDetailSaga);
    yield takeLatest(fetchReportCycleSubmissionDownloadReportFileById.type, fetchReportCycleSubmissionDownloadReportFileByIdSaga);
    yield takeLatest(fetchReportCycleSubmissionGetAuditDropdown.type, fetchReportCycleSubmissionGetAuditDropdownSaga);
    yield takeLatest(fetchReportCycleSubmissionCheckAccountant.type, fetchReportCycleSubmissionCheckAccountantSaga);
    yield takeLatest(fetchReportCycleSubmissionGetSubmissionHeader.type, fetchReportCycleSubmissionGetSubmissionHeaderSaga);
    yield takeLatest(fetchReportCycleSubmissionUploadReport.type, fetchReportCycleSubmissionUploadReportSaga);
    yield takeLatest(fetchReportCycleSubmissionSaveReportAuditor.type, fetchReportCycleSubmissionSaveReportAuditorSaga);
    yield takeLatest(fetchReportCycleSubmissionSaveReportNote.type, fetchReportCycleSubmissionSaveReportNoteSaga);
    yield takeLatest(fetchReportCycleSubmissionCreateUpload.type, fetchReportCycleSubmissionCreateUploadSaga);
    yield takeLatest(fetchReportISCSubmissionCheckDataName.type, fetchReportISCSubmissionCheckDataNameSaga);
    yield takeLatest(fetchReportISCSubmissionMapError.type, fetchReportISCSubmissionMapErrorSaga);
    yield takeLatest(fetchReportISCSubmissionMapValue.type, fetchReportISCSubmissionMapValueSaga);
    yield takeLatest(fetchReportISCSubmissionSaveReport.type, fetchReportISCSubmissionSaveReportSaga);
    yield takeLatest(fetchReportISCSubmissionSaveUpload.type, fetchReportISCSubmissionSaveUploadSaga);
    yield takeLatest(fetchReportISCSubmissionEditReportAuditor.type, fetchReportISCSubmissionEditReportAuditorSaga);
    yield takeLatest(fetchReportISCSubmissionSaveDetailNote.type, fetchReportISCSubmissionSaveDetailNoteSaga);
    yield takeLatest(fetchReportISCSubmissionGetDetailNote.type, fetchReportISCSubmissionGetDetailNoteSaga);
    yield takeLatest(fetchReportISCSubmissionGetNoteSection.type, fetchReportISCSubmissionGetNoteSectionSaga);
    yield takeLatest(fetchReportISCSubmissionGetExcelJson.type, fetchReportISCSubmissionGetExcelJsonSaga);
    yield takeLatest(fetchReportCycleSubmissionSaveReportNoteYear.type, fetchReportCycleSubmissionSaveReportNoteYearSaga);

}

export default function* apiReportISC02Sagas() {
    yield all([
        watchFetchReportCycleGetList(),
    ]);
}
