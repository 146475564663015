import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { stringDefault } from '../../../helper/base';

const useCountDown = () => {

    const { countdown, countdownTimestamp } = useSelector(state => state.countdown);
    const [currentTimestamp, setCurrentTimestamp] = useState(new Date(countdownTimestamp));
    const [dateCounter, setDateCounter] = useState({
        days: { count: '00', label: 'วัน' },
        hours: { count: '00', label: 'ชั่วโมง' },
        minutes: { count: '00', label: 'นาที' },
        seconds: { count: '00', label: 'วินาที' },
        late: false,
    });

    useEffect(() => {
        setCurrentTimestamp(new Date(countdownTimestamp))
    }, [countdownTimestamp]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTimestamp(new Date(currentTimestamp.getTime() + 1000))
            const dueDate = new Date(countdown);

            let difference = dueDate - currentTimestamp;
            let late = difference <= 0;

            if (late) {
                difference = Math.abs(difference);
            }

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setDateCounter({
                days: { count: stringDefault(days).padStart(2, '0'), label: 'วัน' },
                hours: { count: stringDefault(hours).padStart(2, '0'), label: 'ชั่วโมง' },
                minutes: { count: stringDefault(minutes).padStart(2, '0'), label: 'นาที' },
                seconds: { count: stringDefault(seconds).padStart(2, '0'), label: 'วินาที' },
                late: late
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [countdown, currentTimestamp]);

    return {
        days: dateCounter?.days,
        hours: dateCounter?.hours,
        minutes: dateCounter?.minutes,
        seconds: dateCounter?.seconds,
        late: dateCounter?.late,
        dateCounter: dateCounter,
        currentTimestamp: currentTimestamp,
        countdown: countdown,
        countdownTimestamp: countdownTimestamp,
    }
}

export default useCountDown;
