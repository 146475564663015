import { createSlice } from '@reduxjs/toolkit';


export const initialDetailNote = {}
export const initialDetailNoteDataInput = []
export const initialDetailNoteSection = {}
export const initialSaveDetailNoteSection = {}

export const initialState = {
    detailNote: initialDetailNote,
    detailNoteDataInput: initialDetailNoteDataInput,
    sectionNote: initialDetailNoteSection,
    saveDetailNoteSection: initialSaveDetailNoteSection
};

const detailNoteSlice = createSlice({
    name: 'detailNote',
    initialState,
    reducers: {
        setDetailNote: (state, action) => {
            state.detailNote = action.payload;
        },

        resetDetailNote: (state, action) => {
            state.detailNote = initialDetailNote;
        },

        setDetailNoteSection: (state, action) => {
            state.sectionNote = action.payload;
        },

        resetDetailNoteSection: (state, action) => {
            state.sectionNote = initialDetailNoteSection;
        },
        
        setSaveDetailNoteSection: (state, action) => {
            state.saveDetailNoteSection = action.payload;
        },

        resetDetailNoteDataInput: (state, action) => {
            state.detailNoteDataInput = initialDetailNoteDataInput;
        },
        
        setDetailNoteDataInput: (state, action) => {
            state.detailNoteDataInput = action.payload;
        }

    },
});

export const {
    setDetailNote,
    resetDetailNote,
    setDetailNoteSection,
    resetDetailNoteSection,
    setSaveDetailNoteSection,
    setDetailNoteDataInput,
    resetDetailNoteDataInput
} = detailNoteSlice.actions;

export const selectDetailNote = (state) => state.detailNote;

export default detailNoteSlice.reducer;