//6. รายได้จากการลงทุนและค่าใช้จ่ายทางการเงินจากการประกันภัย (จำนวนเงินที่รับรู้ในงบกำไรขาดทุนเบ็ดเสร็จอื่น)(วินาศ)
import React, { useEffect, useState, useRef } from 'react'
import { InputNumber, Input, Button, Col, Row, Flex, Form } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

function RealizedMoneyTotalGi({ setDataRows, setSectionId, dataSection, setSubmittable, checkTextInput }) {
    const { TextArea } = Input;
    const [form] = Form.useForm()
    const { setFieldsValue, getFieldValue } = form
    const [result, setResult] = useState(0);
    const inputRef = useRef(null);

    const formatNumber = (value) => {
        return `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const onBlurName = (index, field, value) => {
        let formData = form.getFieldValue();
        setDataRows(form.getFieldsValue('items'));
        validateFields(formData);
    };

    const handleChange = (e) => {
        let { value } = e.target;
        value = value.replace(/[^0-9\.,-]/g, '');
        const negativeSign = value.startsWith('-') ? '-' : '';
        const [integerPart, decimalPart] = value.replace('-', '').split('.');
        const notManusintegerPart = integerPart.replace(/[^0-9\.,]/g, '');
        const resetComma = notManusintegerPart.replace(/,/g, '');
        const formattedInteger = resetComma.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        e.target.value = decimalPart !== undefined ? `${negativeSign}${formattedInteger}.${decimalPart.replace(/-/g, '')}` : `${negativeSign}${formattedInteger}`;
    };

    const validateFields = (fields) => {
        for (const item of fields.items) {
            if (item.item_name?.trim() === '') {
                return setSubmittable(false);
            }
            for (const detail of item.details) {
                if (detail.value === '' || detail.value === null) {
                    return setSubmittable(false);
                }
            }
        }
        return setSubmittable(true);
    }

    const removeResultFromItems = () => {
        let formData = form.getFieldValue();
        const removeResult = formData.items.map(item => {
            const { result, ...rest } = item;
            return rest;
        });
        setDataRows(removeResult);
    };

    const calculateRow = (details) => {
        return details.reduce((sum, detail) => sum + Number(`${detail.value}`.replace(/,/g, '')), 0);
    };

    const onBlurValue = (index, field, value) => {
        let formData = form.getFieldValue();

        const formatNumberString = (num) => {
            return num?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        };

        const convertString = formData?.items.map(item => ({
            ...item,
            details: item.details.map(detail => ({
                ...detail,
                value: formatNumberString(detail.value)
            })),
            result: calculateRow(item.details)
        }));
        form.setFieldsValue({
            items: convertString
        });

        setResult(convertString.reduce((sum, item) => sum + Number(`${item.result}`.replace(/,/g, '')), 0));

        removeResultFromItems();
        setDataRows(form.getFieldsValue('items'));
        validateFields(formData);
    };

    const desiredOrderSec = [73, 72];

    const sortDetails = (details, order) => {
        return order.map(id => {
            return details.find(item => item.msd_detail_id === id);
        });
    };

    useEffect(() => {
        setSectionId(12);
        if (dataSection?.length == 0) {
            setFieldsValue({
                items: [
                    {
                        item_name: '',
                        details: [
                            { msd_detail_id: 73, value: '0.00' },
                            { msd_detail_id: 72, value: '0.00' },
                        ],
                        result: ''
                    },
                ]
            })
        } else {
            const filteredData = dataSection?.map(item => {
                return {
                    ...item,
                    details: item.details.filter(detail => [73, 72].includes(detail.msd_detail_id))
                };
            });
            const sortedSetdataSec = filteredData.map(item => {
                return {
                    ...item,
                    details: sortDetails(item.details, desiredOrderSec)
                };
            })
            const updatedItems = sortedSetdataSec?.map(item => ({
                ...item,
                result: '',
            }));
            setFieldsValue({
                items: updatedItems,
            });
        }
        onBlurValue();
    }, [dataSection]);


    const addField = () => {
        const formData = form.getFieldValue();
        const newData = {
            item_name: '',
            details: [
                { msd_detail_id: 73, value: '0.00' },
                { msd_detail_id: 72, value: '0.00' },
            ],
            result: ''
        };
        form.setFieldsValue({
            items: [...(formData.items || []), newData]
        });
        setDataRows(form.getFieldsValue('items'));
        validateFields(form.getFieldValue());
    }

    const removeField = (index) => {
        const formData = form.getFieldValue();
        const updatedItems = (formData.items || []).filter((_, i) => i !== index);
        form.setFieldsValue({
            items: updatedItems
        });
        onBlurValue();
        setDataRows(form.getFieldsValue('items'));
    }

    useEffect(() => {
        function incrementInputId(inputId) {
            const result = inputId.match(/(\d+)$/);
            if (result) {
                const num = parseInt(result[0], 10) + 1;
                return inputId.replace(/(\d+)$/, num.toString());
            }
            return inputId;
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const inputs = Array.from(document.querySelectorAll('.input-field'));
                const newInputId = incrementInputId(document.activeElement.id);

                const nextInput = document.getElementById(newInputId);

                if (nextInput) {
                    nextInput.focus();
                } else {

                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Col style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
                <Col style={{ width: '96%' }} span={30}>
                    <div className='bg-[#184E84] pt-1 rounded-t-2xl'>
                        <Row justify="space-between" align="top" style={
                            {
                                backgroundColor: 'white',
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                                borderWidth: '2px',
                                borderColor: '#E5EBEB',
                                paddingTop: '10px',
                                paddingBottom: '15px',
                            }
                        }>
                            <Col span={6} >
                                <Row align="middle" justify='center' style={{ textAlign: 'center', fontWeight: 700 }}>
                                    ประเภท
                                </Row>
                            </Col>
                            <Col span={6} >
                                <Row justify={'center'} style={{ textAlign: 'center', fontWeight: 700 }}>
                                    รายได้จากการลงทุนสุทธิ
                                </Row>
                            </Col>
                            <Col span={6} >
                                <Flex justify='center' align='center' style={{ textAlign: 'center', fontWeight: 700 }}>
                                    ค่าใช้จ่ายทางการเงินจากการประกันภัยสุทธิ
                                </Flex>
                            </Col>
                            <Col span={6} >
                                <Flex justify='center' align='center' style={{ textAlign: 'center', fontWeight: 700 }}>
                                    รวม
                                </Flex>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col style={{ width: '35px', paddingLeft: 10 }}>
                </Col>
            </Row>
            <Row style={{ width: '100%', marginTop: 10 }}>
                <Col style={{ width: '96%' }} span={30}>
                    <Row justify="space-between" align="middle">
                        <Col span={6} >
                        </Col>
                        <Col span={6} >
                        </Col>
                        <Col span={6} >
                        </Col>
                        <Col span={6} >
                            <Row style={{
                                paddingLeft: '5px',
                                paddingRight: '0px',
                                gap: '15px',
                            }}>
                                <Flex style={{
                                    width: '100%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {formatNumber(result)}
                                </Flex>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col style={{ width: '35px', paddingLeft: 10 }}>
                </Col>
            </Row>
            <Form form={form} style={{ marginTop: 20 }}>
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <Row style={{ width: '100%' }} key={field.key}>
                                    <Col style={{ width: '96%' }} span={30}>
                                        <Row justify="space-between" align="start">
                                            <Col span={6} >
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'item_name']}
                                                    fieldKey={[field.fieldKey, 'item_name']}
                                                    validateStatus={checkTextInput == true ? form.getFieldValue('items')[field.name].item_name == '' ? 'error' : '' : ''}
                                                    style={{ width: '100%' }}
                                                >
                                                    <TextArea
                                                        className="input-field"
                                                        id={`input-${field.key}-0`}
                                                        autoSize={{ minRows: 1 }}
                                                        style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                                                        placeholder="-- กรุณากรอกข้อมูล --"
                                                        onBlur={onBlurName}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Form.List name={[field.name, 'details']}>
                                                {(detailFields) => (
                                                    <>
                                                        {detailFields.map((detailField) => (
                                                            <>
                                                                <Col span={6} id='input-insurance-services'>
                                                                    <Row style={{
                                                                        paddingLeft: '5px',
                                                                        paddingRight: '0px',
                                                                        gap: '15px',
                                                                    }}>
                                                                        <Form.Item
                                                                            {...detailField.detailRestField}
                                                                            name={[detailField.name, 'value']}
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            <Input
                                                                                className="input-field"
                                                                                id={`input-${field.key}-${detailField.key + 1}`}
                                                                                controls={false}
                                                                                ref={inputRef}
                                                                                formatter={value => {
                                                                                    return `${parseFloat(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                                                }}
                                                                                onBlur={e => {
                                                                                    const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                                                                    newDetails[detailField.name].value = formatNumber(Number(e.target.value.replace(/,/g, '')));
                                                                                    console.log('checkvalue ==>', Number(e.target.value.replace(/,/g, '')) === NaN)
                                                                                    form.setFieldsValue({
                                                                                        items: [
                                                                                            ...form.getFieldValue('items').slice(0, field.name),
                                                                                            {
                                                                                                ...form.getFieldValue(['items', field.name]),
                                                                                                details: newDetails,
                                                                                            },
                                                                                            ...form.getFieldValue('items').slice(field.name + 1),
                                                                                        ],
                                                                                    });
                                                                                    onBlurValue();
                                                                                }}
                                                                                onFocusCapture={e => {
                                                                                    setTimeout(() => {
                                                                                        inputRef.current.select();
                                                                                    }, 0);
                                                                                    const value = form.getFieldValue('items')[field.name].details[detailField.name].value;
                                                                                    const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                                                                    if (value === '0.00' || value === 0 || value === '') {
                                                                                        newDetails[detailField?.name].value = '';
                                                                                    } else {
                                                                                        newDetails[detailField?.name].value = value;
                                                                                    }
                                                                                    form.setFieldsValue({
                                                                                        items: [
                                                                                            ...form.getFieldValue('items').slice(0, field.name),
                                                                                            {
                                                                                                ...form.getFieldValue(['items', field.name]),
                                                                                                details: newDetails,
                                                                                            },
                                                                                            ...form.getFieldValue('items').slice(field.name + 1),
                                                                                        ],
                                                                                    });
                                                                                }}
                                                                                onChangeCapture={handleChange}
                                                                                style={{
                                                                                    width: '100%', whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                    </Row>
                                                                </Col>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            </Form.List>
                                            <Col span={6} id='input-insurance-services'>
                                                <Row style={{
                                                    paddingLeft: '5px',
                                                    paddingRight: '0px',
                                                    gap: '15px',
                                                }}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'result']}
                                                        fieldKey={[field.fieldKey, 'result']}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <InputNumber
                                                            id='input-insurance-services'
                                                            controls={false}
                                                            style={{
                                                                width: '100%', background: '#E5EBEB', color: 'black', whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                            disabled={true}
                                                            formatter={value => {
                                                                return `${parseFloat(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                            }}
                                                            parser={value => value.replace(/,/g, '')}
                                                        />
                                                    </Form.Item>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col style={{ width: '35px', paddingLeft: 10 }}>
                                        {fields.length > 1 ?
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        removeField(field.name)
                                                    }}
                                                    icon={<MinusOutlined />}
                                                    style={{ background: '#FF6658', color: 'white' }}
                                                />
                                            </Form.Item> : null
                                        }
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button form={form}
                                    onClick={addField}
                                    style={{
                                        marginTop: '20px',
                                        borderStyle: 'dashed', // Set border style to dashed
                                        borderColor: 'black', // Set border color
                                        borderWidth: '1px',
                                        width: '96%',// Set border width
                                    }}>
                                    เพิ่มรายการ
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form >
        </Col >
    )
}

export default RealizedMoneyTotalGi