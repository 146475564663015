// src/components/TableReportISC02InfoRefDetail.js
import { CloseCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, ConfigProvider, DatePicker, Form, Image, Input, Row, Select, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import { buddhistLocale, globalBuddhistLocale } from '../../../App';
import { fetchReportCycleSubmissionCheckAccountant, fetchReportCycleSubmissionSaveReportAuditor } from '../../../features/redux/apiSlice/apiReportISC02Slice';
import { resetFormDetailAuditorISC02, selectForm, setFormDetailAuditorISC02 } from '../../../features/redux/slice/formSlice';
import { setModalReportISC02BackDetailAuditor, setModalUploadReportISC02ViewDetailAuditor } from '../../../features/redux/slice/modalSlice';
import { resetUploadFileISC02FileName, resetUploadReport } from '../../../features/redux/slice/uploadFileSlice';
import { buddhistYear, convertToBuddhistYear, customFormat, stringDefault } from '../../../helper/base';
import { IconInformation } from '../../../helper/icon';
import dayjs from 'dayjs';
import ModalReportISC02BackDetailAuditor from '../modal/ModalReportISC02BackDetailAuditor';
import { reportISC02Host } from '../../../server/service/host/reportISC02Host';

const { Option } = Select;
const { TextArea } = Input;

const FormReportISC02InfoRefDetailAuditor = ({
    handleBackButton = () => null,
    handleSaveButton = () => null,
}) => {
    let { infoId = '', submissionId = '', submissionDetailId = '', fileRecordId = '' } = useParams();
    const [isShowModalConfirmBack, setIsShowModalConfirmBack] = useState(false);
    const [form] = Form.useForm();
    const tooltipInformation = 'กรณีความเห็นของผู้สอบบัญชี คือ ไม่มีเงื่อนไข จะไม่สามารถกรอกข้อมูลสรุปสาระสำคัญประเด็นของผู้สอบบัญชีได้'
    const dispatch = useDispatch();
    const {
        formDetailAuditorISC02,
        uploadReportSubmissionDetailFileName,
        uploadReportSubmissionDetail,
        isc02Header,
        companyInfo,
        auditDropdown,
    } = useSelector(state => ({
        formDetailAuditorISC02: state.form.formDetailAuditorISC02,
        isc02Header: state.isc.isc02Header,
        uploadReportSubmissionDetail: state.uploadFile.uploadReport,
        uploadReportSubmissionDetailFileName: state.uploadFile.uploadReportSubmissionDetailFileName,
        companyInfo: state.user.companyInfo,
        auditDropdown: state.form.auditDropdown,
    }));

    const {
        company_id
    } = companyInfo

    const {
        auditing_companies = null,
        accountants = null,
        comments = null,
    } = auditDropdown?.data || {}
    // console.log(`----  auditing_companies:`, auditing_companies);
    // console.log(`----  accountants:`, accountants);
    // console.log(`----  auditDropdown:`, auditDropdown);

    const {
        name_thai = '',
        error_license_number = false,
        license_number = '',
        full_name = '',
        licensed_by_gaap = '',
        auditor_comment = '',
        notes = '',
        comment_date = '',
    } = formDetailAuditorISC02;
    console.log(`----  FormReportISC02InfoRefDetailAuditor  formDetailAuditorISC02:`, formDetailAuditorISC02);

    const filename = uploadReportSubmissionDetailFileName
    const typeEdit = (!!fileRecordId || fileRecordId == 0) && fileRecordId != '-' && fileRecordId != 'null'

    const disabledDate = (current) => {
        // return current && current < dayjs().startOf('day');
        return current && current < dayjs(isc02Header?.data?.start_date).startOf('day');
    };

    const onCheckData = async () => {
        try {
            await form.validateFields().then(values => {
                const { license_number } = values;
                dispatch(fetchReportCycleSubmissionCheckAccountant({ license_number }));
            }).catch(errorInfo => {
                console.log(`----  validateFields  errorInfo:`, errorInfo);
            });
        } catch (error) {
            console.log(`----  onCheckData  error:`, error);
        }
    };

    const onCheckSubmitData = async () => {
        try {

            const payload = form.getFieldValue()
            // handleSaveButton(payload)
            await form.validateFields().then(values => {
                if (error_license_number) return null
                handleSaveButton(payload)
            }).catch(errorInfo => {
                console.log(`----  validateFields  errorInfo:`, errorInfo);
            });
        } catch (error) {
            console.log(`----  onCheckData  error:`, error);
        }
    };

    const onCheckAccountantData = async () => {
        try {
            await form.validateFields(['license_number', 'name_thai']).then(values => {
                console.log(`----  validateFields  values:`, values);
                const { license_number } = values
                if (!!license_number && license_number >= 0) {
                    dispatch(fetchReportCycleSubmissionCheckAccountant({ license_number: license_number }));
                }
            }).catch(errorInfo => {
                console.log(`----  validateFields  errorInfo:`, errorInfo);
            });
        } catch (error) {
            console.log(`----  onCheckData  error:`, error);
        }
    };

    // const onValuesChange = async (values, allValues) => {
    //     console.log(`----  onValuesChange  values:`, values);
    //     console.log(`----  onValuesChange  Object.keys(values):`, Object.keys(values));
    //     console.log(`----  onValuesChange  allValues:`, allValues);
    //     try {
    //         // Reset errors for all fields
    //         const fieldsToReset = Object.keys(allValues).map(fieldName => ({
    //             name: fieldName,
    //             errors: [],
    //         }));
    //         form.setFields(fieldsToReset);

    //         dispatch(setFormDetailAuditorISC02(values));
    //     } catch (error) {
    //         console.log(`----  onValuesChange  error:`, error);
    //     }
    // };
    const onValuesChange = (changedValues, allValues) => {

        try {
            setIsShowModalConfirmBack(true)
            // Reset errors for all fields
            const fieldsToReset = Object.keys(allValues).map(fieldName => ({
                name: fieldName,
                errors: [],
            }));
            form.setFields(fieldsToReset);
            let value = {}
            // If the name_thai field is changed, reset the related fields
            if (changedValues?.name_thai) {
                value = {
                    license_number: undefined,
                    full_name: undefined,
                    licensed_by_gaap: undefined,
                }
                form.setFieldsValue(value);
            }

            // Dispatch the updated values to the store
            dispatch(setFormDetailAuditorISC02({
                ...allValues,
                ...value,
            }));
        } catch (error) {
            console.log(`----  onValuesChange  error:`, error);
        }
    };

    const handleOpenModalUploadReportISC02ViewDetailAuditor = () => {
        setIsShowModalConfirmBack(true)
        setTimeout(() => {
            dispatch(setModalUploadReportISC02ViewDetailAuditor({ isOpen: true, }));
        }, 1000);
    };

    const handleRemove = () => {
        dispatch(resetUploadReport());
        dispatch(resetUploadFileISC02FileName());
    };


    useEffect(() => {
        const date = comment_date ? dayjs(comment_date) : null
        form.setFieldsValue({
            ...formDetailAuditorISC02,
            comment_date: date,
        });
    }, [formDetailAuditorISC02]);



    useEffect(() => {
        if (!typeEdit) {
            form.resetFields()
        }
        return () => {
            dispatch(resetFormDetailAuditorISC02());
            form.resetFields();
        }
    }, []);


    return (
        <Col style={{ padding: 0 }}>
            {isShowModalConfirmBack && (
                <ModalReportISC02BackDetailAuditor
                    handleSave={onCheckSubmitData}
                    navigateBackPage={handleBackButton}
                />
            )}
            <Form
                form={form}
                onValuesChange={onValuesChange}
            >
                <Card
                    title={`รายงานผู้สอบบัญชี`}
                >
                    <Row gutter={[16, 16]} style={{ width: "100%", margin: 0 }}>
                        <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                            <Col span={24}>
                                <Row gutter={[5, 5]}>
                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                        <Typography.Text className='text-[16px]'>บริษัทสอบบัญชี <span className='text-[#FF0101]'>*</span></Typography.Text>
                                        <Form.Item
                                            name="name_thai"
                                            style={{ width: "100%", margin: 0 }}
                                            rules={[{ required: true, message: 'กรุณาเลือกบริษัทสอบบัญชี' }]}
                                        >
                                            <Select
                                                showSearch
                                                disabled={typeEdit}
                                                rules={[{ required: true, message: 'กรุณาเลือกบริษัทสอบบัญชี' }]}
                                                placeholder="-- เลือกบริษัทตรวจสอบบัญชี --"
                                                style={{ width: '100%' }}
                                                className='select-scroll'
                                            // value={name_thai}
                                            >
                                                {auditing_companies?.filter(item => accountants?.some(company => company.code === item.audit_company_code)).map(item => {
                                                    return (
                                                        <Option key={`audit-name-${item?.name_thai}`} value={item?.name_thai}>{item?.name_thai}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    <Col md={12} xs={24} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '5px', minHeight: '92.5px' }}>
                                        <Typography.Text className='text-[16px]'>เลขที่ใบอนุญาตผู้สอบบัญชี <span className='text-[#FF0101]'>*</span></Typography.Text>
                                        {
                                            error_license_number ?
                                                <Form.Item
                                                    name="license_number"
                                                    style={{ width: "100%", margin: 0 }}
                                                    validateStatus={error_license_number ? 'error' : ''}
                                                    help={error_license_number ? 'ไม่พบเลขที่ใบอนุญาตผู้สอบบัญชี' : ''}
                                                    rules={[{ required: true, message: 'กรุณาเลือกเลขที่ใบอนุญาตผู้สอบบัญชี' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        disabled={typeEdit}
                                                        rules={[{ required: true, message: 'กรุณาเลือกบริษัทสอบบัญชี' }]}
                                                        placeholder="-- เลือกเลขที่ใบอนุญาตผู้สอบบัญชี --"
                                                        style={{ width: '100%' }}
                                                        className='select-scroll'
                                                    // value={name_thai}
                                                    >
                                                        {accountants?.filter(company => company?.accounting_firm == form.getFieldValue('name_thai')).map(item => {
                                                            return (
                                                                <Option key={`audit-name-${item?.license_number}`} value={item?.license_number}>{item?.license_number}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                :
                                                <Form.Item
                                                    name="license_number"
                                                    style={{ width: "100%", margin: 0 }}
                                                    rules={[{ required: true, message: 'กรุณาเลือกเลขที่ใบอนุญาตผู้สอบบัญชี' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        disabled={typeEdit}
                                                        rules={[{ required: true, message: 'กรุณาเลือกบริษัทสอบบัญชี' }]}
                                                        placeholder="-- เลือกเลขที่ใบอนุญาตผู้สอบบัญชี --"
                                                        style={{ width: '100%' }}
                                                        className='select-scroll'
                                                    // value={name_thai}
                                                    >
                                                        {accountants?.filter(company => company?.accounting_firm == form.getFieldValue('name_thai')).map(item => {
                                                            return (
                                                                <Option key={`audit-name-${item?.license_number}`} value={item?.license_number}>{item?.license_number}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                        }
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'flex-end', }}>
                                        <Button
                                            disabled={typeEdit}
                                            onClick={() => typeEdit ? null : onCheckAccountantData()}
                                            className="mb-[22px] px-6 font-bold text-white"
                                            type="primary"
                                            style={{ background: typeEdit ? 'var(--color-gray-primary)' : 'var(--color-primary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', color: 'white' }}
                                        >
                                            ตรวจสอบข้อมูล
                                        </Button>
                                    </Col >
                                </Row >
                            </Col >
                        </Row >
                        <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    <Col md={12} xs={24} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1, justifyContent: 'flex-start', }}>
                                        <Typography.Text className='text-[16px]'>ชื่อ-นามสกุลของผู้สอบบัญชี</Typography.Text>
                                        <Form.Item name="full_name" style={{ width: "100%", margin: 0 }}>
                                            <Input
                                                disabled
                                                // value={full_name}
                                                style={{ backgroundColor: '#E5EBEB', color: '#464646', borderColor: '#E5EBEB' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={6} xs={24} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1, maxWidth: '50%', justifyContent: 'flex-start', }}>
                                        <Typography.Text className='text-[16px] w-full'>ผู้สอบบัญชีที่ได้รับความเห็นชอบจาก สำนักงาน ก.ล.ต. <span className='text-[#FF0101]'>*</span></Typography.Text>
                                        {/* <Form.Item name="licensed_by_gaap" style={{ width: "100%", margin: 0 }}>
                                            <Input
                                                disabled
                                                // value={licensed_by_gaap}
                                                style={{ backgroundColor: '#E5EBEB', color: '#464646', borderColor: '#E5EBEB' }}
                                            />
                                        </Form.Item> */}

                                        <Form.Item
                                            name="licensed_by_gaap"
                                            style={{ width: "100%", margin: 0 }}
                                            rules={[{ required: true, message: 'กรุณาเลือกผู้สอบบัญชีที่ได้รับความเห็นชอบ' }]}
                                        >
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                className='select-scroll'
                                                placeholder="-- เลือกผู้สอบบัญชีที่ได้รับความเห็นชอบ --"
                                                disabled={typeEdit}
                                            >
                                                {['ใช่', 'ไม่'].map(option => (
                                                    <Option key={`licensed-by-gaap-list-${option}`} value={option}>{option}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'flex-start', }}>
                                        <Typography.Text className='text-[16px]'>วันที่ลงนามในหน้ารายงานผู้สอบบัญชี <span className='text-[#FF0101]'>*</span></Typography.Text>
                                        <Form.Item
                                            name="comment_date"
                                            style={{ width: "100%", margin: 0 }}
                                            rules={[{ required: true, message: 'กรุณาเลือกวันที่ลงนามในหน้ารายงานผู้สอบบัญชี' }]}
                                        >
                                            <DatePicker
                                                // value={comment_date ? convertToBuddhistYear(moment(comment_date)) : null}
                                                // onChange={(date) => onValuesChange({ comment_date: date ? date.toISOString() : null })}
                                                disabled={typeEdit}
                                                disabledDate={disabledDate}
                                                format={customFormat}
                                                placeholder="DD/MM/YYYY"
                                                style={{ width: '100%' }}
                                            />
                                            {/* <ConfigProvider locale={globalBuddhistLocale}>
                                            <DatePicker
                                                disabled={typeEdit}
                                                name="comment_date"
                                                value={comment_date ? convertToBuddhistYear(moment(comment_date), 543) : null}
                                                onChange={(date) => onValuesChange({ comment_date: date ? date.toISOString() : null })}
                                                // format={(value) => customFormat(value, 0)}
                                                // locale={buddhistLocale}
                                                placeholder="DD/MM/YYYY"
                                                style={{ width: '100%' }}
                                            />
                                            </ConfigProvider> */}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'flex-start', }}>
                                        <Typography.Text className='text-[16px]'>ความเห็นของผู้สอบบัญชี <span className='text-[#FF0101]'>*</span></Typography.Text>
                                        <Form.Item
                                            name="auditor_comment"
                                            style={{ width: "100%", margin: 0 }}
                                            rules={[{ required: true, message: 'กรุณาเลือกความเห็นของผู้สอบบัญชี' }]}
                                        >
                                            <Select
                                                showSearch
                                                disabled={typeEdit}
                                                placeholder="-- เลือกความเห็นของผู้สอบบัญชี --"
                                                // value={auditor_comment}
                                                style={{ width: '100%' }}
                                                className='select-scroll'
                                            >
                                                {auditing_companies && comments?.map(item => (
                                                    <Option
                                                        key={`audit-comment-${item?.comment_thai}`}
                                                        value={item?.comment_thai}
                                                    >
                                                        {item?.comment_thai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row >
                            </Col >
                        </Row >
                        <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    <Col span={24} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                        <Typography.Text className='text-[16px]'>สรุปสาระสำคัญประเด็นของผู้สอบบัญชี

                                            {/* {auditor_comment !== '10109001' && auditor_comment !== '101109002' ? (
                                            <span className='text-[#FF0101]'> *</span>
                                            ) : ""} */}
                                            {auditor_comment !== "ไม่มีเงื่อนไข" && (
                                                <span className='text-[#FF0101]'> *</span>
                                            )}
                                            <Tooltip title={tooltipInformation} key='tooltip-information-isc02-detail-auditor' style={{ verticalAlign: 'sub', }} >
                                                <Image className='ms-[5px]' width={15} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />

                                            </Tooltip>
                                        </Typography.Text>
                                        <Form.Item
                                            name="notes"
                                            style={{ width: "100%", margin: 0 }}
                                            rules={[{ required: auditor_comment !== "ไม่มีเงื่อนไข", message: 'กรุณากรอกสรุปสาระสำคัญประเด็นของผู้สอบบัญชี' }]}
                                        >
                                            <TextArea
                                                disabled={typeEdit || auditor_comment == "ไม่มีเงื่อนไข"}
                                                // value={notes}
                                                placeholder="-- กรอกสรุปสาระสำคัญประเด็นของผู้สอบบัญชี --"
                                                autoSize={{
                                                    minRows: 5,
                                                    maxRows: 10,
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    <Col span={24} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                        {/* <Typography.Text className='text-[16px] text-[#464646]'>ไฟล์แนบเอกสารรายงานผู้สอบบัญชี</Typography.Text> */}
                                        <Typography.Text className='text-[16px] text-[#464646]'>{`ไฟล์แนบเอกสารรายงานผู้สอบบัญชี รองรับไฟล์สกุล .pdf ขนาดไม่เกิน 100MB เท่านั้น`}</Typography.Text>
                                        {/* <Typography.Text className='text-[16px] text-[#464646]'>{`ไฟล์ต้นฉบับเอกสารหมายเหตุประกอบงบการเงิน รองรับไฟล์สกุล .pdf .xlsx และ .docx ขนาดไม่เกิน 100MB เท่านั้น`}</Typography.Text> */}
                                    </Col>
                                    <Col style={{ width: "100%", margin: 0 }}>
                                        {!uploadReportSubmissionDetail?.data?.file_id && !uploadReportSubmissionDetailFileName ?
                                            (
                                                <Button
                                                    disabled={typeEdit}
                                                    type="primary"
                                                    keyName={'AuditorReport'}
                                                    className='font-bold'
                                                    style={{ background: typeEdit ? 'var(--color-gray-primary)' : 'var(--color-quaternary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', color: 'white' }}
                                                    // style={{ background: 'var(--color-quaternary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', color: 'white' }}
                                                    onClick={() => typeEdit ? null : handleOpenModalUploadReportISC02ViewDetailAuditor()}
                                                    icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}
                                                >
                                                    {/* {typeEdit ? 'อัปโหลดไฟล์ใหม่' : 'อัปโหลดไฟล์'} */}
                                                    อัปโหลดไฟล์
                                                </Button>
                                            )
                                            :
                                            (
                                                <Row justify={'flex-start'} wrap={false} style={{ marginLeft: '0 !important', marginRight: '0 !important', gap: '1rem', }}>
                                                    <Typography.Text style={{ color: 'var(--color-table-text)', fontSize: '16px' }}>{uploadReportSubmissionDetailFileName}</Typography.Text>
                                                    <Button type="text"
                                                        onClick={handleRemove}
                                                        icon={<CloseCircleFilled style={{ fontSize: 20, }} />}
                                                        style={{ color: '#D5D5D5', padding: 0, justifyContent: 'flex-start', alignItems: 'flex-start' }}
                                                    />
                                                </Row>
                                            )
                                        }
                                    </Col >
                                </Row >
                            </Col >
                        </Row >
                    </Row >

                </Card >
            </Form>
            <Row items='middle' justify={'center'} className="py-6">
                <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white"
                    onClick={() => {
                        isShowModalConfirmBack ?
                            dispatch(setModalReportISC02BackDetailAuditor({ isOpen: true, })) :
                            handleBackButton()
                    }}
                >ย้อนกลับ</Button>
                <Button
                    htmlType="submit"
                    type="primary"
                    className="font-bold"
                    disabled={typeEdit}
                    onClick={() => typeEdit ? null : onCheckSubmitData()}
                    style={{ background: typeEdit ? 'var(--color-gray-primary)' : 'var(--color-primary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', color: 'white' }}
                >
                    บันทึก
                </Button>
            </Row>
        </Col>
    );
};

export default FormReportISC02InfoRefDetailAuditor;
