import controller from '../../api/controller';
import { userHost } from "../host/userHost";

const getList = async (filter = { keyword: null, role: null, companyId: null, status: null }, itemPerPage = 20, pageNo = 1) => {
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/user
         * {
         *      "keyword": string?,
         *      "role": string?,
         *      "companyId": int?,
         *      "status": bool?,
         * }
         */

        const params = new URLSearchParams({ itemPerPage, pageNo });
        if (filter && filter.keyword) 
            params.set('keyword', filter.keyword)
        if (filter && filter.role) 
            params.set('role', filter.role)
        if (filter && filter.companyId) 
            params.set('companyId', filter.companyId)
        if (filter && filter.status != null) 
            params.set('status', filter.status)

        const res = await controller.requestGet(`${userHost.userBase}?${params.toString()}`);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const getData = async (id) => {
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/user/get-data/{id}
         */

        const res = await controller.requestGet(userHost.getData(id));
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const updateStatus = async (user) => {
    try {
        /*
         * NOTE - 
         * POST: {<!-- -->{host}}/api/user/get-data/{id}
         * {
         *      "user_id": int,
         *      "status": "Y" || "N",
         *      "first_name": string
         *      "last_name": string
         *      "contact_phone": string
         * }
         */

        const res = await controller.requestPost(userHost.updateStatus, 
            { 
                user_id: user.user_id, 
                status: user.is_active ? 'Y' : 'N', 
                first_name: user.first_name,
                last_name: user.last_name,
                contact_phone: user.contact_phone,
            });
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const deleteData = async (id) => {
    try {
        /*
         * NOTE - 
         * DELETE: {<!-- -->{host}}/api/user/{id}
         */

        const res = await controller.requestDelete(userHost.deleteData(id));
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const getDepartments = async () => {
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/user/departmets
         */

        const res = await controller.requestGet(userHost.getDepartments);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const userService = { getList, getData, updateStatus, deleteData, getDepartments };

export default userService;