import { Card, Col, Form, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPagination } from '../../features/redux/slice/paginationSlice';
import { setSearchReportISC03 } from '../../features/redux/slice/searchSlice';
import { convertDateFormat } from '../../helper/date';

const { Option } = Select;

const CardSearchReportISC03 = () => {
    const dispatch = useDispatch();
    const {
        searchReportISC03,
    } = useSelector(state => state.search);
    const {
        cycleYear = [],
        submissionStatus = [],
        approvalStatus = [],
    } = searchReportISC03

    const {
        tableReportISC03,
    } = useSelector(state => state.table);

    const {
        meta = {},
        data = {},
    } = tableReportISC03
    const {
        timestamp = '',
    } = meta

    const {
        company_id = null,
        current_cycle_year = null,
        cycle_year_list = [],
        submission_status_list = [],
        approval_status_list = [],
        // reportItems = [],
        // pagination = {},
        // pagination = initialStatePagination,
    } = data

    const [form] = Form.useForm();
    const [isSetDefaultCircleYear, setIsSetDefaultCircleYear] = useState(false);

    useEffect(() => {
        if (current_cycle_year && !isSetDefaultCircleYear) {
            setIsSetDefaultCircleYear(true)
            dispatch(setSearchReportISC03({ cycleYear: !!current_cycle_year ? [current_cycle_year] : [], }));
            setTimeout(() => {
                const fetchedData = {
                    cycleYear: [current_cycle_year],
                    submissionStatus: submissionStatus,
                    approvalStatus: approvalStatus,
                };

                form.setFieldsValue(fetchedData);
            }, 0);
        }

    }, [current_cycle_year])


    const onFinish = (values) => {
        dispatch(setSearchReportISC03({
            cycleYear: !!values.cycleYear ? [...values.cycleYear] : [],
            submissionStatus: !!values.submissionStatus ? [...values.submissionStatus] : [],
            approvalStatus: !!values.approvalStatus ? [...values.approvalStatus] : [],
        }))
        console.log('Received values:', values);
    };

    const onValuesChange = (changedValues, allValues) => {
        dispatch(resetPagination())
        onFinish(allValues);
    };

    return (
        <Card title="รายงานกระทบยอด" classNames={{ header: 'ant-card-head-search-report-isc', }}
            styles={{
                title: {
                    fontSize: 20,
                    color: '#000000',
                }
            }}
        >
            {/* <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange} initialValues={initialValues} > */}
            <Form
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                    <Col flex="1 1 250px">
                        <Row gutter={[5, 5]}>
                            <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <Typography.Text className='text-[16px]' strong>ปีรายงาน</Typography.Text>
                                <Form.Item name="cycleYear" style={{ width: "100%", margin: 0 }}>
                                    <Select className='select-scroll' value={cycleYear} mode="multiple" placeholder="เลือกหัวข้อ" style={{ width: "100%" }}>
                                        {cycle_year_list.map(option => {
                                            const optionTh = convertDateFormat(option, 'year')
                                            return (
                                                <Option key={`cycle-year-list-${option}`} value={option}>{optionTh}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col flex="1 1 250px">
                        <Row gutter={[5, 5]}>
                            <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <Typography.Text className='text-[16px]' strong>สถานะการส่ง</Typography.Text>
                                <Form.Item name="submissionStatus" style={{ width: "100%", margin: 0 }}>
                                    <Select className='select-scroll' value={submissionStatus} mode="multiple" placeholder="ทั้งหมด" style={{ width: "100%" }}>
                                        {submission_status_list.map(option => (
                                            <Option key={`submission-status-list-${option}`} value={option}>{option}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col flex="1 1 250px">
                        <Row gutter={[5, 5]}>
                            <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <Typography.Text className='text-[16px]' strong>สถานะการอนุมัติ</Typography.Text>
                                <Form.Item name="approvalStatus" style={{ width: "100%", margin: 0 }}>
                                    <Select className='select-scroll' value={approvalStatus} mode="multiple" placeholder="ทั้งหมด" style={{ width: "100%" }}>
                                        {approval_status_list.map(option => (
                                            <Option key={`approval-status-list-${option}`} value={option}>{option}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col flex="1 1 250px" />
                </Row>
            </Form >
        </Card >
    );
};

export default CardSearchReportISC03;

