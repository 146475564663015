import { createSlice } from '@reduxjs/toolkit';

export const initialStatePagination = {
    page: 1, // current page
    pageSize: 20, // limit
    total: 0, // list count
    totalPages: 0, // page count
}

export const initialState = {
    pagination: initialStatePagination,
};

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        resetPagination: (state, action) => {
            /* 
            dispatch(resetPagination());
             */

            state.pagination = initialStatePagination;
        },
        setPaginationDefault: (state, action) => {
            /* 
            dispatch(setPaginationDefault());
             */
            const {
                page = initialStatePagination.page,
                page_size = initialStatePagination.pageSize,
                total = initialStatePagination.total,
                total_pages = initialStatePagination.totalPages,
            } = action.payload;
            // state.pagination = payload;
            state.pagination.page = page;
            state.pagination.pageSize = page_size;
            state.pagination.total = total;
            state.pagination.totalPages = total_pages;
        },
        setPagination: (state, action) => {
            /* 
            const paginationData = {
                page: 2,
                pageSize: 25,
                total: 150,
                totalPages: 6,
            };  
            dispatch(setPagination(paginationData));
            */
            const {
                page = initialStatePagination.page,
                pageSize = initialStatePagination.pageSize,
                total = initialStatePagination.total,
                totalPages = initialStatePagination.totalPages,
            } = action.payload;
            state.pagination.page = page;
            state.pagination.pageSize = pageSize;
            state.pagination.total = total;
            state.pagination.totalPages = totalPages;
        },
        setPage: (state, action) => { // dispatch(setPage(100));
            const payload = action.payload || initialStatePagination.page;
            state.pagination.page = payload;
        },
        setPageSize: (state, action) => { // dispatch(setPageSize(100));
            const payload = action.payload || initialStatePagination.pageSize;
            state.pagination.pageSize = payload;
        },
        setTotal: (state, action) => { // dispatch(setTotal(100));
            const payload = action.payload || initialStatePagination.total;
            state.pagination.total = payload;
        },
        setTotalPages: (state, action) => { // dispatch(setTotalPages(10));
            const payload = action.payload || initialStatePagination.totalPages;
            state.pagination.totalPages = payload;
        },
    },
});

export const {
    resetPagination,
    setPaginationDefault,
    setPagination,
    setPage,
    setPageSize,
    setTotal,
    setTotalPages,
} = paginationSlice.actions;

export const selectPagination = (state) => state.pagination;

export default paginationSlice.reducer;
