import React, { useState, useEffect,Carousel } from 'react';
import axios from 'axios';
import { Link,useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import Banner from '../image/banner.png';
import LoginIcert from '../image/login-icert.png'
import Top from '../image/top.png'
import { LoginOutlined } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import { Hidden } from "@mui/material";
import Cookies from 'js-cookie';
import { wait } from "@testing-library/user-event/dist/utils";
import ShowCarousel from '../components/ShowCarousel';

const Home = () => {
    // const [images, setImages] = useState([]);
    const images = [
      { id: 1, thumbnailUrl: 'https://www.jorporonline.com/wp-content/uploads/2021/08/คปอ-คืออะไร.png', title: 'Image 1' },
      { id: 2, thumbnailUrl: 'https://via.placeholder.com/800x300', title: 'Image 2' },
      { id: 3, thumbnailUrl: 'https://via.placeholder.com/800x300', title: 'Image 3' },
    ];
  // useEffect(() => {
  //   // Simulate fetching image data from an API
  //   axios.get('https://jsonplaceholder.typicode.com/photos')
  //     .then(response => {
  //       console.error('response data:', response.data);
  //       setImages(response.data.slice(0, 10)); // Get the first 10 images
  //     })
  //     .catch(error => {
  //       console.error('Error fetching image data:', error);
  //     });
  // }, []);
    return (
        <Layout showLogin={true}>
          <ShowCarousel images={images} />
        </Layout>
    );
};

export default Home;

