import { Button, Form, Input, Modal, } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalReportISC02BackDetailNote } from '../../../features/redux/slice/modalSlice';

const ModalReportISC02BackDetailNote = ({ handleSave ,NavigateBackPage}) => {
    const { modalReportISC02BackDetailNote } = useSelector(state => state.modal);
    const {
        companyInfo,
    } = useSelector(state => state.user);

    const {
        company_id = null,
    } = companyInfo

    const {
        isOpen = false,
        value = {}
    } = modalReportISC02BackDetailNote

    const {
        notes,
    } = value

    const dispatch = useDispatch();

    const handleCancel = () => {
            dispatch(setModalReportISC02BackDetailNote({ isOpen: false, }));
    };

    return (
        <>
            <Modal
                maskClosable={false}
                title={<p className='text-xl'>แจ้งเตือนการออกจากหน้ารายงาน</p>}
                centered
                open={isOpen}
                onCancel={handleCancel}
                footer={[
                    <div className='flex justify-center mt-10 mb-1'>
                        <Button className='px-6 me-3 font-bold' key="back" onClick={()=>NavigateBackPage()} style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)', }}>
                            ย้อนกลับ
                        </Button>
                        <Button
                            className='px-6'
                            type="primary"
                            onClick={() => handleSave()}
                        >
                            บันทึก
                        </Button>
                    </div>
                ]}
                width={600}
            >
                <p className='mt-5 font-normal text-sm'>ท่านกำลังออกจากหน้ารายงานโดยไม่บันทึกข้อมูล ต้องการบันทึกข้อมูลก่อนหรือไม่ ?</p>
            </Modal >
        </>
    );
};

export default ModalReportISC02BackDetailNote;
