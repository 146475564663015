import { Typography } from 'antd';
import React from 'react';
import { stringDefault } from '../helper/base';
import { convertDateFormat } from '../helper/date';

const ShowDate = ({
    dateCounter,
    countdown,
    days,
    hours,
    minutes,
    seconds,
    late,
}) => {

    return (
        <Typography.Text ellipsis style={{ verticalAlign: 'middle', textAlign: 'right' }}>
            {`กำหนดส่ง: ${stringDefault(convertDateFormat(countdown, 'dateTime'), '-')}`}
            <br />
            {late ? `เลยกำหนดเวลาแล้ว...` : `จะครบกำหนดในอีก...`}
        </Typography.Text>
    );
}

export default ShowDate;
