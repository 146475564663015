import { createSlice } from '@reduxjs/toolkit';

export const initialIsSelectTab = ''
export const initialErrorData = {}
export const initialErrorDetail = {}
export const initialErrorSelectByPass = []
export const initialStepTab = 0

export const initialState = {
    isSelectTab: initialIsSelectTab,
    isSelectTabDraft: initialIsSelectTab,
    errorData: initialErrorData,
    errorDetail: initialErrorDetail,
    errorSelectByPass: initialErrorSelectByPass,
    stepTab: initialStepTab,
};

const reportISCTabSlice = createSlice({
    name: 'reportISCTab',
    initialState,
    reducers: {
        setSelectTabField: (state, action) => { // dispatch(setUploadFileField({ field: 'fileExcelISC02', data: null }));
            const { field = "", data = null } = action.payload;
            state[field] = data;
        },
        setSelectTab(state, action) {
            const { isSelectTab = initialIsSelectTab } = action.payload
            state.isSelectTab = isSelectTab;
        },
        setErrorData(state, action) {
            const { errorData = initialErrorData } = action.payload
            state.errorData = errorData;
        },
        setErrorDetail(state, action) {
            const { errorDetail = initialErrorDetail } = action.payload
            state.errorDetail = errorDetail;
        },
        setErrorSelectByPass(state, action) {
            const { errorSelectByPass = initialErrorSelectByPass } = action.payload
            state.errorSelectByPass = errorSelectByPass;
        },
        setSelectTabAndStep(state, action) {
            const { isSelectTab = initialIsSelectTab, stepTab = initialStepTab } = action.payload
            state.isSelectTab = isSelectTab;
            state.stepTab = stepTab;
        },
    },
});

export const {
    setSelectTabField,
    setSelectTab,
    setErrorData,
    setErrorDetail,
    setErrorSelectByPass,
    setSelectTabAndStep,
} = reportISCTabSlice.actions;

export const selectReportISCTab = (state) => state.reportISCTab;

export default reportISCTabSlice.reducer;
