import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Input, Form, } from 'antd';
import { setModalReportISC } from '../../../features/redux/slice/modalSlice';
import { FormOutlined } from '@ant-design/icons';
import { fetchReportCycleSubmissionCancelRequest } from '../../../features/redux/apiSlice/apiReportISCSlice';
import { useParams } from 'react-router-dom';
const { TextArea } = Input;

const ModalReportISC = () => {
    const [form] = Form.useForm();
    const { modalReportISC } = useSelector(state => state.modal);
    const {
        companyInfo,
    } = useSelector(state => state.user);

    const {
        company_id = null,
    } = companyInfo
    const {
        isOpen = false,
        value = {}
    } = modalReportISC

    const {
        submission_id,
    } = value


    const dispatch = useDispatch();
    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const handleOpen = () => {
        dispatch(setModalReportISC({ isOpen: true, }));
    };
    const handleCancel = () => {
        dispatch(setModalReportISC({ isOpen: false, }));
    };

    const handleSubmit = async (values) => {
        try {
            const values = await form.validateFields(['editRequestReason']);
            /* 
                {
                    "company_id": 3,
                    "cycle_id": 12,
                    "submission_id": 1,
                    "cancel_reason": "",
                }
            */

            dispatch(fetchReportCycleSubmissionCancelRequest({
                "company_id": Number(company_id),
                "cycle_id": Number(infoId),
                "submission_id": submission_id,
                "cancel_reason": values.editRequestReason // เหตุผลแก้ไข กรณีขอแก้ไข (NOTES)
            }));
        } catch (error) {
            console.log(`----  handleSubmit  error:`, error);
        }

    };


    return (
        <>
            {/* <Button icon={<FormOutlined />} className='bg-[#7A8688] text-white btedithover' onClick={handleOpen}>
                ขอแก้ไข
            </Button> */}
            <Modal
                maskClosable={false}
                title="ยกเลิกคำขอแก้ไข"
                centered
                open={isOpen}
                onOk={handleSubmit}
                onCancel={handleCancel}
                footer={[
                    <div className='flex justify-center mt-10 mb-3'>
                        <Button className='px-8 me-3 font-bold' key="back" onClick={handleCancel} style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)' }}>
                            ย้อนกลับ
                        </Button>
                        <Button
                            className='px-10 font-bold'
                            type="primary"
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button>
                    </div>
                ]}
                width={1000}
            >
                <>
                    <p className='my-4'>เหตุผลในการขอยกเลิกคำขอแก้ไข <span className='text-[--color-error]'>*</span></p>
                    <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            name="editRequestReason"
                            rules={[{ required: true, message: 'กรุณากรอกเหตุผลในการขอยกเลิกคำขอแก้ไข' }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                </>
            </Modal >
        </>
    );
};

export default ModalReportISC;
