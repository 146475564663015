import React from 'react';
import { Button, Table, Image, Empty } from 'antd';
import { IconViewMore } from '../../helper/icon';
const { Column } = Table;


const TableDashboard = ({
    dataList = [],
    onClickViewMore = () => null,
}) => {

    const data = true ? [
        {
            key: '1',
            topic: 'หัวข้อ 1',
            version: 'เวอร์ชั่น 1',
            company: 'บริษัทประกัน 1',
            name: 'John Doe',
            datetime: '2024-04-22 10:00',
            status: 'สถานะ 1',
        },
        {
            key: '2',
            topic: 'หัวข้อ 2',
            version: 'เวอร์ชั่น 2',
            company: 'บริษัทประกัน 2',
            name: 'Jane Smith',
            datetime: '2024-04-23 11:00',
            status: 'สถานะ 2',
        },
        // Add more data as needed
    ] : dataList;

    return (
        <Table
            dataSource={data}
            scroll={{ x: true }}
            className="full-width-table"
            locale={{
                emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- กรุณากดปุ่ม ส่งข้อมูล -' />,
            }}
        >
            <Column title="ชื่อหัวข้อ" dataIndex="topic" key="topic" width="25%" />
            <Column title="เวอร์ชั่น" dataIndex="version" key="version" width="12%" />
            <Column title="บริษัทประกัน" dataIndex="company" key="company" width="12%" />
            <Column title="ชื่อ-สกุล" dataIndex="name" key="name" width="12%" />
            <Column title="วันที่และเวลา" dataIndex="datetime" key="datetime" width="20%" />
            <Column title="สถานะ" dataIndex="status" key="status" width="12%" />
            <Column
                title="เครื่องมือ"
                key="action"
                width="10%"
                align='center'
                render={(text, record) => (
                    <>
                        <Button
                            type="text"
                            onClick={onClickViewMore}
                            style={{
                                alignSelf: 'center',
                                padding: '0px 15px',
                            }}
                        >
                            <Image
                                width={30}
                                src={IconViewMore}
                                preview={false}
                                wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                            />
                        </Button>
                    </>
                )}
            />
        </Table>
    );
};

export default TableDashboard;
