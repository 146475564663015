import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportCycleGetList } from '../../redux/apiSlice/apiReportISC02Slice';

export const useFetchReportISC02Default = () => {
    const dispatch = useDispatch();
    const { companyInfo } = useSelector(state => state.user);
    const { searchReportISC02 } = useSelector(state => state.search);
    const { pagination } = useSelector(state => state.pagination);

    const {
        cycleYear = [],
        submissionStatus = [],
        approvalStatus = [],
    } = searchReportISC02;
    const {
        company_id = null,
        thai_name = '',
        short_name = '',
        full_name_eng = '',
        status = '',
        domain_name = '',
    } = companyInfo;

    const {
        page = null,
        pageSize = null,
        total = null,
    } = pagination;

    const fetchDataTable = () => {
        dispatch(fetchReportCycleGetList(
            {
                "company_id": company_id,
                "filter": {
                    "cycle_year": !!cycleYear ? cycleYear : [],
                    "submission_status": submissionStatus,
                    "approval_status": approvalStatus,
                },
                "pagination": {
                    "page": page,
                    "page_size": pageSize,
                }
            }
        ));
    }

    // useEffect(() => {
    //     console.log(`----  useEffect  fetchDataTable:`,);
    //     fetchDataTable()
    // }, [
    //     cycleYear.length,
    //     submissionStatus,
    //     approvalStatus,
    //     page,
    //     pageSize,
    //     total,
    // ]);

    return {
        fetchDataTable,
    };
};
