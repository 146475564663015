import { Col, Input, Layout, Row, Form, Typography, Card, Button, Table, Empty, Image, Select } from 'antd';
import { ClearOutlined, FileSearchOutlined, PrinterOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import masterDataService from '../../server/service/masterData';
import logService from '../../server/service/log';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
const { Column } = Table;
const { Option } = Select;

const MsdCompanyListPage = () => {
    const defaultPageSize = 10;
    const [keyword, setKeyword] = useState('');
    const [companyTypeId, setCompanyTypeId] = useState(null);
    const [filter, setFilter] = useState({ keyword: null, companyTypeId: null });
    const [paging, setPaging] = useState({ item_per_page: defaultPageSize, page_no: 1 });
    const [totalItem, setTotalItem] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        (async () => {
            await logService.log(logService.LogType.MasterDataCompany, "ดูรายการ");   
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                var res = await masterDataService.getCompanies(filter, paging.item_per_page, paging.page_no);

                // console.log({ res });

                setCompanies(res.data);
                setTotalItem(res.paging.total_item);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [filter, paging])

    const onSearchHandle = () => {
        setFilter({ keyword: keyword, companyTypeId: companyTypeId });
    }

    const onClearHandle = () => {
        setKeyword('');
        setCompanyTypeId(null);
        setFilter({ keyword: null, companyTypeId: null });
    }

    const onShowSizeChange = (current, size) => {
        setPaging({ page_no: 1, item_per_page: size });
    }

    const onChangePage = (page, pageSize) => {
        setPaging({ page_no: page, item_per_page: pageSize });
    }

    const onExportFileHandle = async () => {
        try {
            setIsExporting(true);
            var res = await masterDataService.exportCompanies(filter);

            // console.log({ res });

            const blob = new Blob([res], { type: 'application/octet-stream' });

            const fileName = `รหัสบริษัทประกันภัย_${new dayjs().format("YYMMDDHHmmss")}.xlsx`

            saveAs(blob, fileName);

        } catch (error) {
            console.error(error);
        } finally {
            setIsExporting(false);
        }
    }

    const msdName = "รหัสบริษัทประกันภัย"

    const breadcrumbList = [
        { title: `ส่งและติดตามผลข้อมูล`, link: `` },
        { title: `ดัชนีตารางอ้างอิงมาตรฐาน`, link: `/msd/list` },
        { title: msdName, link: `` },
    ]

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <Card title={msdName} classNames={{ header: 'ant-card-head-search-report-isc', }}
                            styles={{
                                title: {
                                    fontSize: 20,
                                    color: '#000000',
                                }
                            }}>
                            <Form>
                                <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                                    <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                        <Typography.Text strong>ประเภทบริษัทประกันภัย</Typography.Text>
                                        <Select onChange={(val) => setCompanyTypeId(val)} value={companyTypeId} className='select-scroll' placeholder="-- เลือกประเภทบริษัทประกันภัย --" style={{ width: "100%" }} listItemHeight={10} listHeight={250}>
                                            <Option value={null}>ทั้งหมด</Option>
                                            <Option value={1}>บริษัทประกันชีวิต</Option>
                                            <Option value={2}>บริษัทประกันวินาศภัย</Option>
                                        </Select>
                                    </Col>
                                    <Col flex="1 1 150px">
                                        <Row gutter={[5, 5]}>
                                            <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>ค้นหา รหัส, ชื่อ</Typography.Text>
                                                <Input placeholder="โปรดระบุ" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle">
                                            <Col>
                                                <Button type="primary" onClick={onSearchHandle} loading={isLoading} style={{ paddingLeft: '30px', paddingRight: '30px', }} icon={<FileSearchOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ค้นหา</Button>
                                            </Col>
                                            <Col>
                                                <Button type="primary" onClick={onClearHandle} loading={isLoading} style={{ background: 'var(--color-gray-primary)', }} icon={<ClearOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ล้างการค้นหา</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col flex="1 1 250px" />
                                </Row>
                            </Form >
                        </Card >
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]} className='mb-5' justify="space-between" align="middle">
                            <Col flex="1 1" style={{ width: "100%", padding: 0, }}>
                                <div className='flex flex-row items-center'>
                                    <strong>ประเภทข้อมูลหลัก</strong>
                                    <span className="flex w-3 h-3 ms-5 me-2 bg-orange-200 rounded-full" />
                                    <span>ใช้สำหรับกรอกรหัส</span>
                                </div>
                            </Col>
                            <Col flex="1 1" style={{ width: "100%", padding: 0, }}>
                                <Row justify="end">
                                    <Col>
                                        <Button type="primary" onClick={onExportFileHandle} loading={isExporting} style={{ background: '#2E7D32' }} icon={<PrinterOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>Export</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col style={{ width: "100%", padding: 0, }}>
                                <Table
                                    dataSource={companies}
                                    loading={isLoading}
                                    scroll={{ x: true }}
                                    rowKey={(record) => record.msd_company_id}
                                    className="full-width-table"
                                    style={{ background: 'var(--color-white)' }}
                                    pagination={{
                                        defaultPageSize: defaultPageSize,
                                        pageSize: paging.item_per_page,
                                        total: totalItem,
                                        disabled: isLoading,
                                        showTotal: (total, range) => `แสดงข้อมูล ${range[0]} - ${range[1]} จาก ${total} รายการ `,
                                        onShowSizeChange: onShowSizeChange,
                                        onChange: onChangePage
                                    }}
                                    locale={{
                                        emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- ไม่พบข้อมูล -' />,
                                    }}
                                >
                                    <Column title="รหัส" dataIndex="code" key="code" width="15%" />
                                    <Column title="ชื่อภาษาไทย" dataIndex="name" key="name" />
                                    <Column title="ชื่อภาษาอังกฤษ" dataIndex="name_en" key="name_en" />
                                    <Column title="ประเภทบริษัทประกันภัย" dataIndex="company_type_name" key="company_type_name" width="15%" />
                                </Table>
                            </Col>
                        </Row>
                    </Col >
                </Row >
            </Content >
        </Layout >
    );
};

export default MsdCompanyListPage;

