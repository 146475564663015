/* eslint-disable no-unused-vars */
// src/components/MainLayout.js
import { Layout } from 'antd';
import React from 'react';
import HeaderBase from './HeaderBase';
import SidebarBase from './SidebarBase';

const { Content } = Layout;



const MainLayout = ({ children }) => {

    const onClickBell = () => {
        console.log(`----  MainLayout  onClickBell`);
    };

    return (
        <Layout
            style={{ minHeight: '100vh', }}
        >
            <HeaderBase
                onClickBell={onClickBell}
            />
            <Layout>

                <SidebarBase />

                <Layout
                    style={{ overflowY: 'auto', height: 'calc(100vh - var(--header-height))', }}
                >
                    <Content style={{ height: 'inherit', }}>
                        {children}
                    </Content>
                </Layout>

            </Layout>
        </Layout>
    );
};

export default MainLayout;
