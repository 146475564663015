export const indexHost = {
    /* Web Base */
    base: process.env.REACT_APP_BASE_API_URL,
    defaultBase: `/`,
    loginBase: `/login`,
    registerBase: `/register`,
    forgotPasswordBase: `/forgot-password/:param1`,
    homeBase: `/home`,
    changeLogBase: `/change-log`,
    userManualBase: `/user-manual`,
    /* Web Base */

    /* Api Base */
    configsAllBase: `/configs/get-all`,
    /* Api Base */
}