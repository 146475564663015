// src/components/ReportISC02InfoRefDetail.js
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Form, Image, Input, Layout, Row, Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Test001_1 from './test001_1';
import { reportISC02Host } from '../../../server/service/host/reportISC02Host';
import { IconFileExcelWhite } from '../../../helper/icon';
import { convertDateFormat } from '../../../helper/date';
import { stringDefault } from '../../../helper/base';
import { setErrorData, setSelectTab } from '../../../features/redux/slice/reportISCTabSlice';
import { fetchReportCycleSubmissionGetUploadErrorDetail } from '../../../features/redux/apiSlice/apiReportISC02Slice';
import CardSearchReportISC02InfoRefDetail from '../reportISC02InfoRefDetail/CardSearchReportISC02InfoRefDetail';
import Breadcrumb from '../../Breadcrumb';

const Test001 = () => {

    const sheet1Ref = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        tableReportISC02InfoRefDetail,
    } = useSelector(state => state.table);
    const {
        meta = {},
        data = {},
    } = tableReportISC02InfoRefDetail
    const {
        submission_num = null,
        reference_code = '',
        cycle_id = null,
        company_id = null,
        report_count = null,
        error_count = null,
        current_date = '',
        due_date = '',
        error_items = [],
        pagination = {},
    } = data

    let { infoId = '', submissionId = '', uploadId = '' } = useParams();
    console.log(` infoId, submissionId, uploadId`, infoId, submissionId, uploadId);
    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC02 = cycle_id || infoId
    const refReportISC02 = reference_code || submissionId
    const uploadReportISC02 = submission_num || uploadId
    // let url = reportISC02Host.reportISC02InfoByIdBase(stringDefault(record?.infoId, '/-'))
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-02` },
        { title: `รายงานงบการเงิน ไตรมาสที่ 4  2566 `, link: reportISC02Host.reportISC02InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC02, '-')}`, link: reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-')) }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
        { title: `ส่งข้อมูล`, link: reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(uploadId, '/-')) },
        { title: `รายงานงบการเงิน` },
    ]

    useEffect(() => {
        dispatch(setSelectTab({ isSelectTab: report[0] }))
        dispatch(setErrorData({ errorData: allsheets }))
        return () => {
        }
    }, []);

    const report = ["ช0200", "ช0100", "ช1210", "ช1220", "ช0220", "ช0130", "ช1240", "ช1260", "ช0270", "ช0180", "ช1290", "ช140", "ช1262", "ช0275", "ช0181", "ช1296", "ช141", "ช1253", "ช0570", "ช01210", "ช1490", "ช1405"]

    const allsheets = {
        "meta": {
            "timestamp": "2024-05-24 03:41:16",
            "status_code": 200,
            "message": "Success"
        },
        "data": {
            "upload_id": 4,
            "report_code": ["ช0200", "ช0100", "ช1210", "ช1220", "ช0220", "ช0130", "ช1240", "ช1260", "ช0270", "ช0180", "ช1290", "ช140", "ช1262", "ช0275", "ช0181", "ช1296", "ช141", "ช1253", "ช0570", "ช01210", "ช1490", "ช1405"],
        },
    }

    const {
        isSelectTab,
        errorData = {},
    } = useSelector(state => state.reportISCTab);

    const onChangeTab = (key) => {
        dispatch(setSelectTab({ isSelectTab: key }))

        dispatch(fetchReportCycleSubmissionGetUploadErrorDetail({
            "upload_id": errorData.data.upload_id,
            "report_code": String(key),
            "paging": {
                "page": 1,
                "page_size": 20,
            }
        }));
    };

    const handleNextTab = () => {
        const currentIndex = report.findIndex(tab => tab === isSelectTab);
        if (currentIndex < report.length - 1) {
            onChangeTab(report[currentIndex + 1]);
        }
    };

    const handlePrevTab = () => {
        const currentIndex = report.findIndex(tab => tab === isSelectTab);
        if (currentIndex > 0) {
            onChangeTab(report[currentIndex - 1]);
        }
    }

    const handleBackButton = () => {
        console.log('Back button clicked')
    }

    const handleSaveButton = () => {
        console.log('Save button clicked')
    }

    const handleImportFile = () => {
        console.log(`----  handleImportFile  handleImportFile:`, handleImportFile);
    }


    return (
        <Layout style={{ height: 'inherit', width: "100%", borderTop: '1px solid #f0f0f0' }}>
            {/* <Content style={{ padding: '24px', height: '100%' }}> */}
            <Content>
                {/* <Row gutter={[16, 20]}> */}
                {/* <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02InfoRefDetail
                            nameReportISC02={nameReportISC02}
                            refReportISC02={refReportISC02}
                            uploadReportISC02={uploadReportISC02}
                        />
                    </Col> */}

                <Col style={{ width: "100%", background: 'var(--color-white)', padding: 0, }}>
                    {/* <Card
                            title={`รายงานงบการเงิน`}
                            styles={{
                                body: {
                                    padding: 0,
                                }
                            }}
                        >

                        */}
                    <Row style={{ gap: '16px', padding: "32px" }}>
                        <Col style={{ flex: 3, }}>
                            <Test001_1 />
                        </Col>

                        <Col hidden style={{ flex: 1, }}>
                            <Card
                                title={`กำหนดชื่อข้อมูล`}
                                rootClassName="h-full"
                                className="h-full"
                                style={{
                                    body: {
                                        padding: '1rem',
                                        height: '100% !important',
                                    }
                                }}
                            >
                                <Col style={{ display: 'flex', flexDirection: 'column', gap: '8px', }}>
                                    <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                        <Typography.Paragraph>{`Sheet : ${stringDefault(null, '-')}`}</Typography.Paragraph>
                                    </Col>
                                </Col>
                                <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                    <Row gutter={[5, 5]}>
                                        <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <Typography.Text className='text-[16px]'>คอลัมน์ชื่อข้อมูล</Typography.Text>
                                            <Form.Item name="fromDate" style={{ minWidth: "100%", margin: 0 }}>
                                                <Input
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                    {/* 

                        </Card > */}
                </Col >

                {/* </Row > */}
                {/* <Row items='middle' justify={'center'} className="py-6">
                    <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white" onClick={handleBackButton}>ย้อนกลับ</Button>
                    <Button type="primary" className="font-bold" onClick={handleSaveButton}>บันทึก</Button>
                </Row> */}
            </Content >
        </Layout >
    );
};

export default Test001;