import { Col, Row } from 'antd';
import React from 'react';
import Countdown from './CountDown';
import ShowDate from './ShowDate';
import useCountDown from '../features/hooks/base/useCountDown';
import { reportISCHost } from '../server/service/host/reportISCHost';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { reportISC02Host } from '../server/service/host/reportISC02Host';

const BoxDueDate = ({
    isShow = true,
}) => {

    const {
        dateCounter,
        countdown,
        countdownTimestamp,
        days,
        hours,
        minutes,
        seconds,
        late,
    } = useCountDown()

    const params = useParams();


    const urlCheckPass = (reportISCHost.reportISCBase).replace('/', '')
    const urlCheckPassISC02 = (reportISC02Host.reportISC02Base).replace('/', '')

    const isShowCountdown =
        (params['*'].includes(urlCheckPass) && params['*'] != urlCheckPass)
        || (params['*'].includes(urlCheckPassISC02) && params['*'] != urlCheckPassISC02)


    if ((!isShow || (!countdownTimestamp && !countdown))) {
        return null;
    }


    return (
        <Row>
            <Row
                // className='show-desktop'
                style={{
                    gap: '6px',
                }}
            >
                <Row
                    style={{
                        columnGap: '6px',
                    }}
                    align={'middle'}
                    justify={'end'}
                    wrap
                >
                    <Col
                        style={{
                            height: 'min-content',
                            lineHeight: 'normal',
                        }}
                    >
                        <ShowDate
                            dateCounter={dateCounter}
                            countdown={countdown}
                            days={days}
                            hours={hours}
                            minutes={minutes}
                            seconds={seconds}
                            late={late}
                        />
                    </Col>

                    <Col>
                        <Countdown
                            dateCounter={dateCounter}
                            countdown={countdown}
                            days={days}
                            hours={hours}
                            minutes={minutes}
                            seconds={seconds}
                            late={late}
                        />
                    </Col>
                </Row>
            </Row>

        </Row>
    );
};

export default BoxDueDate;
