import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { stringDefault } from '../helper/base';

const Countdown = ({
    dateCounter,
    countdown,
    days,
    hours,
    minutes,
    seconds,
    late,
}) => {

    return (
        <div>
            <Row style={{ gap: '4px' }} justify={'center'} align={'middle'} wrap={false}>
                {
                    [
                        { ...days },
                        { ...hours },
                        { ...minutes },
                        // { ...seconds },
                    ].map((item, index) => {
                        return (
                            <Col
                                key={`countdown-${item?.label}`}
                                style={{
                                    position: 'relative',
                                    minWidth: 'var(--width-count)',
                                    background: !late ? 'var(--color-count-normal-bg)' : 'var(--color-count-late-bg)',
                                    borderRadius: '10px',
                                    padding: '2px'
                                }}
                            >
                                <Row
                                    align={'middle'}
                                    justify={'center'}
                                    style={{
                                        position: 'relative',
                                        background: !late ? 'var(--color-count-normal)' : 'var(--color-count-late)',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Typography.Text
                                        style={{
                                            fontSize: 'var(--font-count-top)',
                                            lineHeight: 'var(--line-height-top)',
                                            color: 'var(--color-white)',
                                        }}
                                    >
                                        {item.count}
                                    </Typography.Text>
                                </Row>
                                <Row align={'middle'} justify={'center'} className="rounded-b-lg p-[2px]">
                                    <Typography.Text
                                        style={{
                                            fontSize: 'var(--font-count-bottom)',
                                            color: 'var(--color-white)',
                                        }}
                                    >
                                        {item.label}
                                    </Typography.Text>
                                </Row>
                            </Col>
                        )
                    })
                }
            </Row>
        </div >
    );
}

export default Countdown;
