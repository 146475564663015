// src/components/TableReportISC03Info.js
import { Button, Col, Empty, Image, Row, Table, Tooltip, Typography } from 'antd';
import React from 'react';
import { IconCircleClose, IconExport, IconExportDisable, IconFileExcel, IconFileExcelFill, IconFileInfoFill, IconSearch, IconViewMore } from '../../../helper/icon';
import { convertDateFormat } from '../../../helper/date';
import { convertStatusInfo } from '../fn';
import { stringDefault } from '../../../helper/base';
import { initialStatePagination, setPage } from '../../../features/redux/slice/paginationSlice';
import { useDispatch } from 'react-redux';
const { Column } = Table;


const TableReportISC03Info = ({
    dataList = [],
    pagination = {},
    onClickSearch = () => null,
    onClickExport = () => null,
}) => {
    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
        total_pages = initialStatePagination.totalPages,
    } = pagination

    // const data = [
    //     {
    //         row_no: 1,
    //         total_rows: 1,
    //         submission_id: 23,
    //         cycle_id: 1,
    //         reference_code: "ICeRT-LI-67-M-100101F0001",
    //         upload_num: null,
    //         first_submission_date: null,
    //         last_submission_date: null,
    //         last_submitted_by: "",
    //         submission_status: "ฉบับร่าง",
    //         approval_status: "",
    //         approval_date: null,
    //         company_id: null,
    //         notes: "",
    //         is_edit_request: false,
    //         can_cancel_edit: false,
    //         report_url: "output_report_link",
    //         is_view: false,
    //         report_li_number: "ICeRT-LI-66-Q-999812F0034",
    //         report_fb_number: "ICeRT-FB-66-Q-999812F0034",
    //     },
    //     // {
    //     //     reference_code: "ICeRT-XX-66-Q-999812F00345",
    //     //     first_submission_date: "2024-01-05T14:00:00.000Z",
    //     //     last_submitted_by: "Admin strator",
    //     //     send_status: "ส่งแล้ว",
    //     //     approval_status: "อนุมัติ",
    //     //     approval_date: "2024-01-05T14:00:00.000Z",
    //     //     report_li_number: "ICeRT-LI-66-Q-999812F0034",
    //     //     report_fb_number: "ICeRT-FB-66-Q-999812F0034",
    //     //     data_status: "มีการเปลี่ยนแปลง",
    //     //     report_url: "output_report_link",
    //     //     can_cancel_edit: true,
    //     //     notes: "Some notes here",
    //     // },
    // ];

    const data = dataList;

    return (
        <Table
            dataSource={data}
            scroll={{ x: true }}
            className="full-width-table"
            rowKey={"row_no"}
            pagination={{
                onChange: (page, pageSize) => {
                    dispatch(setPage(page));
                },
                showSizeChanger: false,
                current: page,
                pageSize: page_size,
                total: total,
                showQuickJumper: true,
                showTotal: (t, range) =>
                    `แสดงข้อมูล  ${range[0]} -  ${range[1]} จาก  ${total} รายการ`, // `แสดงข้อมูล 1 - 17 จาก 17 รายการ`
            }}
            locale={{
                emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- กรุณากดปุ่ม ส่งข้อมูล -' />,
            }}
        >
            <Column
                className="whitespace-nowrap"
                align="start"
                title="เลขที่อ้างอิงข้อมูล"
                dataIndex="reference_code"
                key="reference_code"
                render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>}
            />
            <Column
                className="whitespace-nowrap"
                align="start"
                title="วันที่ส่ง"
                dataIndex="submission_date"
                key="submission_date"
                render={(text, record) => (
                    <Typography.Paragraph>
                        {stringDefault(convertDateFormat(record.submission_date, 'dateTime'), '-')}
                    </Typography.Paragraph>
                )}
            />
            <Column
                className="whitespace-nowrap"
                align="start"
                title="ผู้ส่งล่าสุด"
                dataIndex="last_submitted_by"
                key="last_submitted_by"
                render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>}
            />
            <Column
                className="whitespace-nowrap"
                align="center"
                title="สถานะการส่ง"
                dataIndex="submission_status"
                key="submission_status"
                render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>}
            />
            <Column
                className="whitespace-nowrap"
                align="center"
                title="สถานะการอนุมัติ"
                dataIndex="approval_status"
                key="approval_status"
                render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>}
            />
            <Column
                className="whitespace-nowrap"
                align="start"
                title="วันที่อนุมัติ"
                dataIndex="approved_date"
                key="approved_date"
                render={(text, record) => (
                    <Typography.Paragraph>
                        {stringDefault(convertDateFormat(record.approved_date, 'dateTime'), '-')}
                    </Typography.Paragraph>
                )}
            />
            <Column
                className="whitespace-nowrap"
                align="start"
                title="เลขที่รายงาน ช/ว"
                dataIndex="fef_code_isc01"
                key="fef_code_isc01"
                render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>}
            />
            <Column
                className="whitespace-nowrap"
                align="start"
                title="เลขที่รายงานงบการเงิน"
                dataIndex="fef_code_isc02"
                key="fef_code_isc02"
                render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>}
            />
            <Column
                className="whitespace-nowrap"
                align="center"
                title="สถานะข้อมูล"
                dataIndex="data_status"
                key="data_status"
                render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>}
            />

            <Column className="whitespace-nowrap" align='center' title="Output Report" dataIndex="output_report_url" key="output_report_url" render={(text, record) => (
                <>
                    <Button
                        type="text"
                        disabled={!text}
                        onClick={() => onClickExport(text, record)}
                        style={{
                            alignSelf: 'center',
                            padding: 0,
                        }}
                    >
                        <Image
                            width={20}
                            src={text ? IconExport : IconExportDisable}
                            preview={false}
                            wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                        />
                    </Button>

                </>
            )}
            />
            <Column
                className="whitespace-nowrap"
                align='center'
                title="เครื่องมือ"
                key="action"
                render={(text, record) => (
                    <Row gutter={[2, 2]} justify={'center'}>
                        <Col>
                            <Button
                                type="text"
                                onClick={() => onClickSearch(record)}
                                style={{
                                    alignSelf: 'center',
                                    padding: 0,
                                }}
                            >
                                <Image
                                    width={25}
                                    src={IconSearch}
                                    preview={false}
                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                />
                            </Button>
                        </Col>
                    </Row>
                )}
            />
        </Table>
    );
};

export default TableReportISC03Info;
