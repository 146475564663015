//import * as xlsx from 'xlsx';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { setCountdown } from '../slice/countdownSlice';
import { setLoadingTimeout } from '../slice/loadingSlice';
import { setPagination } from '../slice/paginationSlice';
import ReportISC03Service from '../../../server/service/reportISC03Service';
import { fetchReportCycleGetDetail, fetchReportCycleGetList, fetchReportCycleSubmissionGenerate, fetchReportISCSubmissionCheckData, fetchReportISCSubmissionGetOutputLastSubmission, fetchReportISCSubmissionGetOutputSubmission, fetchReportISCSubmissionSaveData } from '../apiSlice/apiReportISC03Slice';
import { setTableReportISC03, setTableReportISC03Info, setTableReportISC03InfoRef } from '../slice/tableSlice';
import { reportISC03Host } from '../../../server/service/host/reportISC03Host';
import { setAlert } from '../slice/alertMsgSlice';
import { stringDefault } from '../../../helper/base';
import { saveAs } from 'file-saver';

function* fetchReportCycleGetListSaga(action) {
    try {
        const data = yield call(ReportISC03Service.apiReportCycleGetList, action.payload);
        console.log(`----  function*fetchReportCycleGetListSaga  data:`, data);
        yield put(setPagination(data.data.pagination))
        yield put(setTableReportISC03(data));
    } catch (error) {
        console.log(`----  function*fetchReportCycleGetListSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout({ time: 500 }));
    }
}

function* fetchReportCycleGetDetailSaga(action) {
    try {
        const data = yield call(ReportISC03Service.apiReportCycleGetDetail, action.payload);
        console.log(`----  function*fetchReportCycleGetDetailSaga  data:`, data);
        yield put(setTableReportISC03Info(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleGetDetailSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}


function* fetchReportCycleSubmissionGenerateSaga(action) {
    let dataFetch = null
    try {
        const data = yield call(ReportISC03Service.apiReportCycleSubmissionGenerate, action.payload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  data:`, data);

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  dataFetch:`, dataFetch);
        const referenceCode = dataFetch?.data?.submission_id;
        if (referenceCode != null) {
            console.log(dataFetch)
            const submissionUrl = reportISC03Host.reportISC03SubmissionByIdBase(stringDefault(dataFetch?.data?.cycle_id, '/-'), stringDefault(referenceCode, '/-'))
            console.log(submissionUrl)
            const navigateUrl = window.location.href.split("/report-isc-03/info/")[0] + submissionUrl;
            console.log('submissionUrl',window.location.href.split("/report-isc-03/info/")[0] + submissionUrl)
            window.location.href = navigateUrl
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /* Navigate */
    }
}

function* fetchReportISCSubmissionCheckDataSaga(action) {
    try {
        const response = yield call(ReportISC03Service.apiReportISCSubmissionCheckData, action.payload);
        console.log(`----  function*fetchReportISCSubmissionCheckDataSaga  response:`, response);
        yield put(setTableReportISC03InfoRef(response));
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionCheckDataSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        // yield delay(3000);
    }
}

function* fetchReportISCSubmissionSaveDataSaga(action) {
    try {
        console.log(`----  function*fetchReportISCSubmissionSaveDataSaga  action:`, action);
        const { info_id, ...rest } = action.payload;
        const newPayload = { ...rest };
        const data = yield call(ReportISC03Service.apiReportISCSubmissionSaveData, newPayload);
        console.log(`----  function*fetchReportISCSubmissionSaveDataSaga  data:`, data);
        const referenceCode = action?.payload?.upload_id;
        if (referenceCode != null) {
            const submissionUrl = reportISC03Host.reportISC03InfoByIdBase(stringDefault(action?.payload?.info_id, '-'))
            const navigateUrl = window.location.origin + submissionUrl;
            window.location.href = navigateUrl
            // console.log(`submissionUrl`, submissionUrl)
            // console.log(`navigateUrl`, window.location.origin + submissionUrl)
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        // return null
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionSaveDataSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportISCSubmissionGetOutputLastSubmissionSaga(action) {
    console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  action:`, action);
    try {

        const response = yield call(ReportISC03Service.apiReportISCSubmissionGetOutputLastSubmission, action.payload);
        console.log(`----  function*fetchReportISCSubmissionGetOutputLastSubmissionSaga  response:`, response);
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        console.log(`----  function*fetchReportISCSubmissionGetOutputLastSubmissionSaga  blob:`, blob);
        const fileName = `file.xlsx`
        yield call(saveAs, blob, fileName);

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}
function* fetchReportISCSubmissionGetOutputSubmissionSaga(action) {
    console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  action:`, action);
    try {

        const response = yield call(ReportISC03Service.apiReportISCSubmissionGetOutputSubmission, action.payload);
        console.log(`----  function*fetchReportISCSubmissionGetOutputSubmissionSaga  response:`, response);
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        console.log(`----  function*fetchReportISCSubmissionGetOutputSubmissionSaga  blob:`, blob);
        const fileName = `file.xlsx`
        yield call(saveAs, blob, fileName);

    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionGetOutputSubmissionSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* watchFetchReportCycleGetList() {
    yield takeLatest(fetchReportCycleGetList.type, fetchReportCycleGetListSaga);
    yield takeLatest(fetchReportCycleGetDetail.type, fetchReportCycleGetDetailSaga);
    yield takeLatest(fetchReportCycleSubmissionGenerate.type, fetchReportCycleSubmissionGenerateSaga);
    yield takeLatest(fetchReportISCSubmissionCheckData.type, fetchReportISCSubmissionCheckDataSaga);
    yield takeLatest(fetchReportISCSubmissionSaveData.type, fetchReportISCSubmissionSaveDataSaga);
    yield takeLatest(fetchReportISCSubmissionGetOutputLastSubmission.type, fetchReportISCSubmissionGetOutputLastSubmissionSaga);
    yield takeLatest(fetchReportISCSubmissionGetOutputSubmission.type, fetchReportISCSubmissionGetOutputSubmissionSaga);

}

export default function* apiReportISC03Sagas() {
    yield all([
        watchFetchReportCycleGetList(),
    ]);
}
