import { IconWarning } from "../../../helper/icon";

const convertStatus = (status = '') => {
    const statusMap = {
        // 'waiting': { text: 'รอนําส่ง', color: '#464646' },
        // 'waiting late': { text: 'รอนําส่ง (ล่าช้า)', color: '#fa9804' },
        // 'approved': { text: 'อนุมัติ', color: '#464646' },
        // 'approved late': { text: 'อนุมัติ (ล่าช้า)', color: '#fa9804' },
        // 'edit approved': { text: 'อนุมัติแก้ไข', color: '#464646' },
        // 'waiting edit approve': { text: 'รออนุมัติแก้ไข', color: '#464646' },

        /* submissionStatus */
        'รอนำส่ง': { text: 'รอนำส่ง', color: '#747474' },
        'ส่งแล้ว': { text: 'ส่งแล้ว', color: '#40B290' },
        'ยังไม่ส่ง': { text: 'ยังไม่ส่ง', color: '#FF0101' },
        'ส่งล่าช้า': { text: 'ส่งล่าช้า', color: '#FA9804' },
        /* approvalStatus */
        'รออนุมัติแก้ไข': { text: 'รออนุมัติแก้ไข', color: '#747474' },
        'อนุมัติ': { text: 'อนุมัติ', color: '#40B290' },
        'อนุมัติแก้ไข': { text: 'อนุมัติแก้ไข', color: '#4899E3' },
    };

    return statusMap[status?.toLowerCase()] || { text: status, color: '#3C4142' };
};

const convertStatusInfo = (status = '') => {
    const statusMap = {
        // 'draft': { text: 'ฉบับร่าง', color: '#464646' },
        // 'checking': { text: 'กำลังตรวจสอบ', color: '#464646' },
        // 'approved': { text: 'อนุมัติ', color: '#464646' },
        // 'approved late': { text: 'อนุมัติ (ล่าช้า)', color: '#fa9804' },
        // 'not pass': { text: 'ไม่ผ่าน', color: '#ff6658' },
        // 'edit approved': { text: 'อนุมัติแก้ไข', color: '#464646' },
        // 'waiting edit approved': { text: 'รออนุมัติแก้ไข', color: '#464646' },
        // 'cancel edit request': { text: 'ยกเลิกคำขอแก้ไข', color: '#464646' },

        /* submissionStatus */
        'รอนำส่ง': { text: 'รอนำส่ง', color: '#747474' },
        'ส่งแล้ว': { text: 'ส่งแล้ว', color: '#40B290' },
        'ยังไม่ส่ง': { text: 'ยังไม่ส่ง', color: '#FF0101' },
        'ส่งล่าช้า': { text: 'ส่งล่าช้า', color: '#FA9804' },
        /* approvalStatus */
        'รออนุมัติแก้ไข': { text: 'รออนุมัติแก้ไข', color: '#747474' },
        'อนุมัติ': { text: 'อนุมัติ', color: '#40B290' },
        'อนุมัติแก้ไข': { text: 'อนุมัติแก้ไข', color: '#4899E3' },

        'ยกเลิกคำขอแก้ไข': { text: 'ยกเลิกคำขอแก้ไข', color: '#3C4142' },
    };

    return statusMap[status?.toLowerCase()] || { text: status, color: '#3C4142' };
};

const convertStatusInfoRef = (status = '') => {
    const statusMap = {
        // 'checking': { text: 'กำลังตรวจสอบ', color: '#464646' },
        // 'not pass': { text: 'ไม่ผ่าน', color: '#ff6658' },
        // 'approved': { text: 'อนุมัติ', color: '#464646' },
        // 'edit approved': { text: 'อนุมัติแก้ไข', color: '#464646' },
        // 'waiting edit approved': { text: 'รออนุมัติแก้ไข', color: '#464646' },

        'ยกเลิกคำขอแก้ไข': { text: 'ยกเลิกคำขอแก้ไข', color: '#3C4142' },

        'กำลังตรวจสอบ (1/2)': { text: 'กำลังตรวจสอบ (1/2)', color: '#3C4142' },
        'กำลังตรวจสอบ (2/2)': { text: 'กำลังตรวจสอบ (2/2)', color: '#3C4142' },
        'ไม่ผ่าน': { text: 'ไม่ผ่าน', color: '#FF0101' },
        'อนุมัติ': { text: 'อนุมัติ', color: '#3C4142' },
        'รอนำส่ง': { text: 'รอนำส่ง', color: '#747474' },
    };

    return statusMap[status?.toLowerCase()] || { text: status, color: '#3C4142' };
};

const convertStatusInfoRefErr = (status = '') => {
    const statusMap = {
        'อนุมัติ bypass': { text: 'อนุมัติ Bypass', color: '#40B290' },
        'ไม่อนุมัติ bypass': { text: 'ไม่อนุมัติ Bypass', color: '#FF0101' },
        'ไม่ผ่าน': { text: 'ไม่ผ่าน', color: '#464646' },
        'รออนุมัติ bypass': { text: 'รออนุมัติ Bypass', color: '#464646' },
    };

    console.log(`----  convertStatusInfoRefErr  statusMap[status?.toLowerCase()]:`, statusMap[status?.toLowerCase()]);
    return statusMap[status?.trim().toLowerCase()] || { text: status, color: '#3C4142' };
};

const iconRender = (icon = '') => {
    const iconMap = {
        'warning': IconWarning,
    };

    return iconMap[icon?.toLowerCase()] || null;
};

export {
    convertStatus,
    convertStatusInfo,
    convertStatusInfoRef,
    convertStatusInfoRefErr,
    iconRender,
};
