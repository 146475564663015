import { Button, Form, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setModalUploadReportISCViewDetail } from '../../../features/redux/slice/modalSlice';
import { resetUploadFileISC } from '../../../features/redux/slice/uploadFileSlice';
import { stringDefault } from '../../../helper/base';

const ModalUploadReportISCViewDetail = () => {
    const [form] = Form.useForm();
    const { modalUploadReportISCViewDetail } = useSelector(state => state.modal);
    const { tableReportISCInfoRef } = useSelector(state => state.table);
    const { data: dataUploadFileISC, err: errUploadFileISC } = useSelector(state => state.uploadFile.uploadFileISC);
    const { file_id = '' } = dataUploadFileISC || { file_id: '' };
    const { msg = '', icon = '' } = errUploadFileISC || { msg: '', icon: '' };
    const {
        meta = {},
        data = {},
    } = tableReportISCInfoRef
    const {
        is_edit_request = false
    } = data
    const { submissionId = '' } = useParams();
    const dispatch = useDispatch();
    const {
        isOpen = false,
        value = {}
    } = modalUploadReportISCViewDetail
    const [fileList, setFileList] = useState([]);

    const {
        notes,
        report_name,
        oic_comment,
    } = value


    const handleCancel = () => {
        dispatch(resetUploadFileISC());
        dispatch(setModalUploadReportISCViewDetail({ isOpen: false }));
        setFileList([]); // Clear the fileList when modal is closed
    };

    return (
        <Modal
            maskClosable={false}
            title="ดูรายละเอียด"
            centered
            open={isOpen}
            // onOk={handleSubmit}
            onCancel={handleCancel}
            footer={[
                <div className="flex justify-center mt-10 mb-3">
                    <Button className="px-8 me-3 font-bold" key="back" onClick={handleCancel} style={{ background: 'var(--color-gray-primary)', color: 'var(--color-white)' }}>
                        ย้อนกลับ
                    </Button>
                    {/* {!!file_id && (
                        <Button className="px-10 font-bold" type="primary" onClick={() => form.submit()}>
                            บันทึก
                        </Button>
                    )} */}
                </div>
            ]}
            width={1000}
        >
            <p className='my-4'>{stringDefault(report_name, '-')}</p>
            <p className='my-4 font-bold'>เหตุผลในการขอแก้ไข</p>
            <TextArea
                disabled
                rows={4}
                value={stringDefault(notes, '-')}
                style={{
                    cursor: 'default',
                    color: '#464646',
                    backgroundColor: '#DEE2E6',
                }}
            />

            <p className='my-4 font-bold'>ความเห็นจาก คปภ.</p>
            <p className='my-4'>{stringDefault(oic_comment, '-')}</p>
        </Modal>
    );
};

export default ModalUploadReportISCViewDetail;
