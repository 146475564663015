// src/components/ReportISCInfoRefError.js
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Image, Layout, Row, Tabs, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchReportISCInfoRefError from '../../components/reportISC/reportISCInfoRefError/CardSearchReportISCInfoRefError';
import TableReportISCInfoRefError from '../../components/reportISC/reportISCInfoRefError/TableReportISCInfoRefError';
import { useFetchReportISCError } from '../../features/hooks/reportISC/useFetchReportISCError';
import { fetchReportISCSubmissionResubmit, fetchReportISCSubmissionSaveByPass } from '../../features/redux/apiSlice/apiReportISCSlice';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { setErrorSelectByPass, setSelectTab } from '../../features/redux/slice/reportISCTabSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { downloadFile } from '../../helper/downloadFile';
import { IconFileExcelWhite, IconInformation } from '../../helper/icon';
import { reportISCHost } from '../../server/service/host/reportISCHost';

const { Text } = Typography;

const ReportISCInfoRefError = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISCError();
    const {
        isSelectTab,
        errorData,
        errorSelectByPass,
    } = useSelector(state => state.reportISCTab);
    const {
        tableReportISCInfoRefError,
    } = useSelector(state => state.table);
    const {
        meta = {},
        data = {},
    } = tableReportISCInfoRefError;
    const {
        submission_num = null,
        reference_code = '',
        cycle_name = null,
        company_id = null,
        upload_count = null,
        report_count = null,
        error_count = null,
        current_date = '',
        due_date = '',
        error_items = [],
        pagination = {},
        can_resubmit = false,
        error_file_url = '',
        error_file_name = '',
    } = data;

    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const dateline = convertDateFormat(due_date) || '-';
    const nameReportISC = cycle_name || infoId;
    const refReportISC = reference_code || submissionId;
    const uploadReportISC = upload_count || uploadId;
    // let url = reportISCHost.reportISCInfoByIdBase(stringDefault(record?.infoId, '/-'))
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-01` },
        { title: `แบบรายงานเกี่ยวกับฐานะการเงินและกิจการ ${stringDefault(nameReportISC, '-')}`, link: reportISCHost.reportISCInfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC, '-')}`, link: reportISCHost.reportISCSubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-')) }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
        { title: `Upload ครั้งที่ ${stringDefault(uploadReportISC, '-')}`, link: `` },
    ];

    const report = errorData?.data?.report_codes || [];

    const onChangeTab = (key) => {
        console.log('onChangeTab',key);
        dispatch(setErrorSelectByPass({}));
        dispatch(setSelectTab({ isSelectTab: key }));
    };

    const handleNextTab = () => {
        const currentIndex = report.findIndex(tab => tab.sheet_name === isSelectTab);
        if (currentIndex < report.length - 1) {
            onChangeTab(report[currentIndex + 1]?.sheet_name);
        }
    };

    const handlePrevTab = () => {
        const currentIndex = report.findIndex(tab => tab.sheet_name === isSelectTab);
        if (currentIndex > 0) {
            onChangeTab(report[currentIndex - 1]?.sheet_name);
        }
    };

    const handleBackButton = () => {
        console.log('Back button clicked')
        navigate(breadcrumbList[breadcrumbList?.length - 2]?.link);
    }

    const handleReSubmitButton = () => {
        dispatch(fetchReportISCSubmissionResubmit({
            "submission_id": submissionId,
            "upload_id": uploadId,
        }));
    };

    const handleSaveButton = () => {
        dispatch(fetchReportISCSubmissionSaveByPass({
            "upload_id": uploadId,
            "report_code": isSelectTab,
            "bypass_items": [...errorSelectByPass],
        }));
    };

    const handleDownload = (url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url;
        if (URL) {
            downloadFile(URL, fileName); //work
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 20]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISCInfoRefError
                            nameReportISC={nameReportISC}
                            refReportISC={refReportISC}
                            uploadReportISC={uploadReportISC}
                        />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <Text strong style={{ color: 'var(--color-table-text)' }}>{`จำนวนรายงานทั้งหมด ${stringDefault(report_count, '-')} รายงาน`}</Text>
                    </Col>
                    <Row gutter={[16, 16]} justify={'space-between'} align={'middle'} style={{ width: "100%", margin: 0, }}>
                        <Col style={{ padding: 0, }}>
                            <Text strong style={{ color: 'var(--color-error-text)' }}>{`พบ Error จำนวน ${stringDefault(error_count, '-')} รายการ โปรดตรวจสอบ`}</Text>
                        </Col>
                        <Row style={{ gap: '12px', }}>
                            <Col style={{ padding: 0, }}>
                                {can_resubmit && (<Button type="primary" className="mx-2 px-8 font-bold" style={{ background: 'var(--color-quinary)' }} onClick={handleReSubmitButton}>ส่งข้อมูลอีกครั้ง</Button>)}
                            </Col>
                            <Col style={{ padding: 0, }}>
                                <Button
                                    type="primary"
                                    icon={
                                        <Image width={20} src={IconFileExcelWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                    }
                                    style={{ background: 'var(--color-error)', display: 'flex', }}
                                    onClick={() => handleDownload(error_file_url, error_file_name)}
                                >
                                    Export Error
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                    <Tabs
                        onChange={onChangeTab}
                        type="card"
                        className='w-full'
                        activeKey={isSelectTab}
                        size="small"
                        tabBarExtraContent={
                            <div className='grid grid-cols-3 gap-4 items-center'>
                                <Button
                                    type="primary"
                                    shape="circle"
                                    style={{ border: '0', padding: '6px !important', minWidth: '24px', minHeight: '24px', maxWidth: '24px', maxHeight: '24px', background: report.findIndex(tab => tab === isSelectTab) <= 0 ? 'var(--color-gray-primary)' : '' }}
                                    icon={
                                        <LeftOutlined
                                            style={{ display: 'flex', fontSize: '10px', color: 'var(--color-white)' }}
                                        />
                                    }
                                    disabled={report.findIndex(tab => tab === isSelectTab) <= 0}
                                    onClick={handlePrevTab}
                                />
                                <Button
                                    type="primary"
                                    shape="circle"
                                    style={{ border: '0', padding: '6px !important', minWidth: '24px', minHeight: '24px', maxWidth: '24px', maxHeight: '24px', background: report.findIndex(tab => tab === isSelectTab) >= report.length - 1 ? 'var(--color-gray-primary)' : '' }}
                                    icon={
                                        <RightOutlined
                                            style={{ display: 'flex', fontSize: '10px', color: 'var(--color-white)' }}
                                        />
                                    }
                                    disabled={report.findIndex(tab => tab === isSelectTab) >= report.length - 1}
                                    onClick={handleNextTab}
                                />
                                {/* <Tooltip
                                    key='tooltip-information-isc01-report-error'
                                    placement="leftTop"
                                    color="#FFFFFF"
                                    autoAdjustOverflow={true}
                                    overlayInnerStyle={{
                                        padding: '0.5rem 1rem',
                                        width: '385px',
                                        maxWidth: '385px',
                                    }}
                                    overlayStyle={{
                                        width: '385px',
                                        maxWidth: '385px',
                                    }}
                                    title={
                                        <Row className="w-full gap-2">
                                            <Row align='middle' className="gap-1" wrap={false}>
                                                <Row className="bg-green-400 p-[0.5rem]" />
                                                <Text style={{ fontSize: "12px" }}>หมายถึง Sheet ที่มีการบันทึกการขอ Bypassแล้ว</Text>
                                            </Row>
                                            <Col justify='center' className="gap-1 w-full">
                                                <Row className='gap-2 w-full'>
                                                    <Col flex="95px"><Text className="underline decoration-solid" style={{ fontSize: "12px" }}>สถานะ</Text></Col>
                                                    <Col><Text style={{ fontSize: "12px" }}></Text></Col>
                                                </Row>
                                                <Row className='gap-2 w-full'>
                                                    <Col flex="95px"><Text style={{ fontSize: "12px" }}>ไม่ผ่าน</Text></Col>
                                                    <Col><Text style={{ fontSize: "12px" }}>หมายถึง สถานะเริ่มต้นของรายการ Error</Text></Col>
                                                </Row>
                                                <Row className='gap-2 w-full'>
                                                    <Col flex="95px"><Text style={{ fontSize: "12px" }}>รออนุมัติ Bypass</Text></Col>
                                                    <Col><Text style={{ fontSize: "12px" }}>หมายถึง รายการนั้นรอการอนุมัติ Bypass จาก คปภ.</Text></Col>
                                                </Row>
                                                <Row className='gap-2 w-full'>
                                                    <Col flex="95px"><Text style={{ fontSize: "12px" }}>อนุมัติ Bypass</Text></Col>
                                                    <Col><Text style={{ fontSize: "12px" }}>หมายถึง รายการนั้นได้รับการอนุมัติ Bypass</Text></Col>
                                                </Row>
                                                <Row className='gap-2 w-full'>
                                                    <Col flex="95px"><Text style={{ fontSize: "12px" }}>ไม่อนุมัติ Bypass</Text></Col>
                                                    <Col><Text style={{ fontSize: "12px" }}>หมายถึง รายการนั้นไม่ได้รับการอนุมัติ Bypass</Text></Col>
                                                </Row>
                                            </Col>
                                            <Row align='middle' className="gap-1 w-full" wrap={false}>
                                                <Text style={{ fontSize: "12px" }}>1. หากสำนักงาน คปภ. อนุมัติ/ไม่อนุมัติ Bypass จะมีการแจ้งเตือนทางระบบและอีเมล</Text>
                                            </Row>
                                        </Row>
                                    }
                                >
                                    <Image
                                        width={18}
                                        src={IconInformation}
                                        preview={false}
                                        style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                                    />
                                </Tooltip> */}
                            </div>
                        }
                        items={errorData.data?.report_codes.map((data, i) => {
                            return {
                                label: (
                                    <div className={[
                                        'min-h-[22px]',
                                        (isSelectTab != data?.sheet_name && data?.bypass_saved) ? `bypass-saved-${Boolean(data?.bypass_saved)}` : ''
                                    ].join(" ")}>
                                        {data?.sheet_name}
                                    </div>
                                ),
                                id: `bypass-saved-${Boolean(data?.bypass_saved)}`,
                                key: `${data?.sheet_name}`,
                                children: (
                                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                                        <Row gutter={[8, 8]}>
                                            <Col style={{ width: "100%", }}>
                                                <TableReportISCInfoRefError
                                                    errorSelectByPass={errorSelectByPass}
                                                    dataList={error_items}
                                                    pagination={pagination}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                ),
                            };
                        })}
                    />
                </Row>
                <div className="flex items-center justify-center py-6">
                    <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white" onClick={handleBackButton}>ย้อนกลับ</Button>
                    {/* {
                        can_resubmit ?
                            (<Button type="primary" className="font-bold" style={{ background: 'var(--color-quinary)' }} onClick={handleReSubmitButton}>ส่งข้อมูลอีกครั้ง</Button>)
                            :
                            (<Button type="primary" className="font-bold" onClick={handleSaveButton}>บันทึก</Button>)
                    } */}
                </div>
            </Content>
        </Layout >
    );
};

export default ReportISCInfoRefError;
