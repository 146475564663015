import { Empty, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialStatePagination, setPage } from '../../../features/redux/slice/paginationSlice';
import { initialErrorSelectByPass, setErrorSelectByPass } from '../../../features/redux/slice/reportISCTabSlice';
import { stringDefault } from '../../../helper/base';
import { convertStatusInfoRefErr } from '../fn';

const { Column } = Table;

const TableReportISCInfoRefError = ({
    errorSelectByPass = [],
    dataList = [],
    pagination = {},
}) => {
    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
    } = pagination;

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const data = dataList;

    useEffect(() => {
        if (!!data) {
            const defaultSelectedKeys = data.filter(item => (item?.req_bypass === true && (item?.check_box_hide === false && item?.check_box_disable === false))).map(item => item?.error_id);
            setSelectedRowKeys(defaultSelectedKeys);
            dispatch(setErrorSelectByPass({
                errorSelectByPass: defaultSelectedKeys || initialErrorSelectByPass
            }));
        }
    }, [data]);

    const columns = [
        {
            title: 'ลำดับ',
            dataIndex: 'order',
            key: 'order',
            render: (text, record, index) => <Typography.Paragraph>{(page - 1) * page_size + index + 1}</Typography.Paragraph>,
            className: "/!min-w-[80px] /!max-w-[80px] whitespace-nowrap !py-4",
            align: "start",
        },
        {
            title: 'ชื่อรายงาน',
            dataIndex: 'report_code',
            key: 'report_code',
            render: (text) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>,
            className: "/!min-w-[200px] /!max-w-[200px] whitespace-nowrap",
            align: "start",
        },
        {
            title: 'Column',
            dataIndex: 'column_code',
            key: 'column_code_1',
            render: (text) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>,
            className: "/!min-w-[120px] /!max-w-[120px] whitespace-nowrap",
            align: "start",
        },
        {
            title: 'Row',
            dataIndex: 'row_code',
            key: 'row_code',
            render: (text) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>,
            className: "/!min-w-[120px] /!max-w-[120px] whitespace-nowrap",
            align: "start",
        },
        {
            title: 'Error Message',
            dataIndex: 'error_message',
            key: 'error_message',
            render: (text) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>,
            className: "/!min-w-[120px] /!max-w-[120px] whitespace-nowrap",
            align: "start",
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <Typography.Paragraph style={{ color: convertStatusInfoRefErr(text).color }} > {stringDefault(convertStatusInfoRefErr(text).text, '-')}</Typography.Paragraph>,
            className: "/!min-w-[100px] /!max-w-[100px] whitespace-nowrap",
            align: "start",
        },
        Table.SELECTION_COLUMN,
    ];

    const handleChange = (selectedRowKeys, selectedRows) => {
        console.log(`----  handleChange  selectedRows:`, selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        dispatch(setErrorSelectByPass({
            errorSelectByPass: [...selectedRows].map((item) => (item?.error_id)) || initialErrorSelectByPass
        }));
    };

    const clearSelection = () => {
        setSelectedRowKeys([]);
    };

    useEffect(() => {
        if ([...errorSelectByPass]?.length === 0) {
            clearSelection();
        }
    }, [errorSelectByPass]);

    return (
        <Table
            columns={columns}
           /*  rowSelection={{
                type: 'checkbox',
                selectedRowKeys,
                onChange: handleChange,
                getCheckboxProps: (record) => ({
                    disabled: record.check_box_disable === true, // Disable if check_box_disable is true
                    style: { visibility: record.check_box_hide ? 'hidden' : 'visible' }, // Hide if check_box_hide is true
                }),
                columnTitle: (checkboxNode) => (
                    <Row wrap={false} style={{ gap: '6px', paddingLeft: '1rem', paddingRight: '1rem' }}>
                        {checkboxNode}
                        <Typography.Text style={{ width: 'max-content' }}>การขอ Bypass</Typography.Text>
                    </Row>
                ),
            }} */
            dataSource={data}
            scroll={{ x: true }}
            rowKey={"error_id"}
            pagination={{
                onChange: (page, pageSize) => {
                    dispatch(setPage(page));
                },
                showSizeChanger: false,
                current: page,
                pageSize: page_size,
                total: total,
                showQuickJumper: true,
                showTotal: (t, range) =>
                    `แสดงข้อมูล  ${range[0]} -  ${range[1]} จาก  ${total} รายการ`,
            }}
            locale={{
                emptyText: <Empty style={{ padding: '25px' }} imageStyle={{ display: 'none' }} description='- ไม่พบข้อมูล -' />,
            }}
        />
    );
};

export default TableReportISCInfoRefError;
