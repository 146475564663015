import { CloseCircleFilled, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Modal, Row, Typography, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchReportCycleSubmissionImportExcel, fetchReportCycleSubmissionUploadExcel } from '../../../features/redux/apiSlice/apiReportISC02Slice';
import { setAlert } from '../../../features/redux/slice/alertMsgSlice';
import { setLoading } from '../../../features/redux/slice/loadingSlice';
import { setModalUploadReportISC02 } from '../../../features/redux/slice/modalSlice';
import { resetUploadFileISC02, setUploadFileField } from '../../../features/redux/slice/uploadFileSlice';
import { iconRender } from '../fn';
import LuckyExcel from "luckyexcel";
import { uniqueId } from 'lodash';
import { setSelectTabField } from '../../../features/redux/slice/reportISCTabSlice';
import { setUserField } from '../../../features/redux/slice/userSlice';

const ModalUploadReportISC02 = () => {
    const [form] = Form.useForm();
    const { isLoading } = useSelector(state => state.loading);
    const { modalUploadReportISC02 } = useSelector(state => state.modal);
    const { tableReportISC02InfoRef } = useSelector(state => state.table);
    const { uploadFileISC02, fileExcelISC02, fileExcelCheckDataNameISC02, fileExcelMapErrorISC02, fileExcelMapValueISC02, uploadFileLuckySheet, uploadFileISC02Draft } = useSelector(state => state.uploadFile);
    const { data: dataUploadFileISC, err: errUploadFileISC } = uploadFileISC02Draft;
    const { isSelectTab = '', stepTab = 0, isSelectTabDraft = "" } = useSelector(state => state.reportISCTab);
    const { file_id = '', sheet_names = [] } = dataUploadFileISC || { file_id: '', sheet_names: [] };
    console.log(`----  ModalUploadReportISC02  file_id:`, file_id);
    const { msg = '', icon = '' } = errUploadFileISC || { msg: '', icon: '' };
    const {
        meta = {},
        data = {},
    } = tableReportISC02InfoRef;
    const {
        is_edit_request = false
    } = data;
    const { submissionId = '', submissionDetailId = '' } = useParams();
    const dispatch = useDispatch();
    const {
        isOpen = false,
    } = modalUploadReportISC02;
    const [fileList, setFileList] = useState([]);
    // const [fileData, setFileData] = useState(null);
    // console.log(`----  ModalUploadReportISC02  fileData:`, fileData);
    // const [scriptsLoaded, setScriptsLoaded] = useState(false);
    // const [sheetId] = useState(uniqueId("datasheet_"));
    // const sheetRef = useRef(null);

    // useEffect(() => {
    //     const loadScripts = async () => {
    //         const jQueryScript = document.createElement('script');
    //         jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    //         jQueryScript.type = 'text/javascript';
    //         jQueryScript.onload = async () => {
    //             const mousewheelScript = document.createElement('script');
    //             mousewheelScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery-mousewheel/3.1.13/jquery.mousewheel.min.js';
    //             mousewheelScript.type = 'text/javascript';
    //             mousewheelScript.onload = async () => {
    //                 const stylesheets = [
    //                     'https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/css/pluginsCss.css',
    //                     'https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/plugins.css',
    //                     'https://cdn.jsdelivr.net/npm/luckysheet/dist/css/luckysheet.css',
    //                     'https://cdn.jsdelivr.net/npm/luckysheet/dist/assets/iconfont/iconfont.css',
    //                 ];
    //                 stylesheets.forEach(href => {
    //                     const link = document.createElement('link');
    //                     link.rel = 'stylesheet';
    //                     link.href = href;
    //                     document.head.appendChild(link);
    //                 });

    //                 const luckysheetModule = await import('luckysheet');
    //                 const luckysheet = luckysheetModule.default || luckysheetModule;
    //                 window.luckysheet = luckysheet;

    //                 setScriptsLoaded(true);
    //             };
    //             document.head.appendChild(mousewheelScript);
    //         };
    //         document.head.appendChild(jQueryScript);
    //     };

    //     loadScripts();
    // }, []);


    // useEffect(() => {
    // if (scriptsLoaded && sheetRef.current && fileData) {
    //         const luckysheet = window.luckysheet;
    //         if (luckysheet) {
    // sheetRef.current.setAttribute("id", sheetId);
    //             luckysheet.create({
    //                 container: sheetId,
    //                 data: fileData?.sheets,//|| dataSheetTest,
    //                 allowUpdate: false,
    //                 updateUrl: '',
    //                 autoFormat: false,
    //                 allowCopy: false,
    //                 showtoolbar: false,
    //                 showtoolbarConfig: {
    //                     undoRedo: false,
    //                     paintFormat: false,
    //                     currencyFormat: false,
    //                     percentageFormat: false,
    //                     numberDecrease: false,
    //                     numberIncrease: false,
    //                     moreFormats: false,
    //                     font: false,
    //                     fontSize: false,
    //                     bold: false,
    //                     italic: false,
    //                     strikethrough: false,
    //                     underline: false,
    //                     textColor: false,
    //                     fillColor: false,
    //                     border: false,
    //                     mergeCell: false,
    //                     horizontalAlignMode: false,
    //                     verticalAlignMode: false,
    //                     textWrapMode: false,
    //                     textRotateMode: false,
    //                     image: false,
    //                     link: false,
    //                     chart: false,
    //                     postil: false,
    //                     pivotTable: false,
    //                     function: false,
    //                     frozenMode: false,
    //                     sortAndFilter: false,
    //                     conditionalFormat: false,
    //                     dataVerification: false,
    //                     splitColumn: false,
    //                     screenshot: false,
    //                     findAndReplace: false,
    //                     protection: false,
    //                     print: false,
    //                 },
    //                 showinfobar: false,
    //                 showsheetbar: false,
    //                 showsheetbarConfig: {
    //                     add: false,
    //                     menu: false,
    //                     sheet: false
    //                 },
    //                 showstatisticBar: false,
    //                 showstatisticBarConfig: {
    //                     count: false,
    //                     view: false,
    //                     zoom: false
    //                 },
    //                 enableAddRow: false,
    //                 enableAddBackTop: false,
    //                 userInfo: {
    //                     userInfo: false,
    //                 },
    //                 showConfigWindowResize: false,
    //                 forceCalculation: false,
    //                 cellRightClickConfig: {
    //                     copy: false,
    //                     copyAs: false,
    //                     paste: false,
    //                     insertRow: false,
    //                     insertColumn: false,
    //                     deleteRow: false,
    //                     deleteColumn: false,
    //                     deleteCell: false,
    //                     hideRow: false,
    //                     hideColumn: false,
    //                     rowHeight: false,
    //                     columnWidth: false,
    //                     clear: false,
    //                     matrix: false,
    //                     sort: false,
    //                     filter: false,
    //                     chart: false,
    //                     image: false,
    //                     link: false,
    //                     data: false,
    //                     cellFormat: false
    //                 },
    //                 sheetRightClickConfig: {
    //                     delete: false,
    //                     copy: false,
    //                     rename: false,
    //                     color: false,
    //                     hide: false,
    //                     move: false,
    //                 },
    //                 sheetFormulaBar: false,
    //                 defaultFontSize: 16,
    //                 limitSheetNameLength: false,
    //                 cellEdit: false,
    //                 allowEdit: false,
    //                 enableAddRow2: false,
    //                 showHelpBox: false,
    //                 hook: {
    //                     rangeSelect: (range) => {
    //                         form.getFieldValue()
    //                         const rangeAxis = luckysheet.getRangeAxis(range);
    //                         console.log(`----  useEffect  rangeAxis:`, rangeAxis);
    //                         console.log(`----  useEffect  stepTab:`, stepTab);

    //                         form.setFieldsValue({
    //                             nameRangeUseFrom: stepTab === 1 ? rangeAxis.toString() : form.getFieldValue('nameRangeUseFrom'),
    //                             cellRangeUseFrom: stepTab === 2 ? rangeAxis.toString() : form.getFieldValue('cellRangeUseFrom'),
    //                             msdNameUseFrom: stepTab === 2 ? form.getFieldValue('msdNameUseFrom') : form.getFieldValue('msdNameUseFrom'),
    //                             newNameUseFrom: stepTab === 2 ? form.getFieldValue('newNameUseFrom') : form.getFieldValue('newNameUseFrom'),
    //                             valueRangeUseFrom: stepTab === 3 ? rangeAxis[0]?.toString() ? rangeAxis[0]?.toString() : form.getFieldValue('valueRangeUseFrom') : form.getFieldValue('valueRangeUseFrom'),
    //                             prevValueRangeUseFrom: stepTab === 3 ? rangeAxis[1]?.toString() ? rangeAxis[1]?.toString() : form.getFieldValue('prevValueRangeUseFrom') : form.getFieldValue('prevValueRangeUseFrom'),
    //                             currencyUnitUseFrom: stepTab === 3 ? form.getFieldValue('currencyUnitUseFrom') : form.getFieldValue('currencyUnitUseFrom'),
    //                         });
    //                         form.getFieldValue()
    //                     }
    //                 }
    //             });

    //             const handleResize = () => {
    //                 if (luckysheet && typeof luckysheet.resize === 'function') {
    //                     luckysheet.resize();
    //                 }
    //             };

    //             window.addEventListener('resize', handleResize);

    //             return () => {
    //                 window.removeEventListener('resize', handleResize);
    //             };
    //         }
    //     }
    // }, [scriptsLoaded, fileData, sheetRef, form, stepTab]);


    const handleCancel = () => {
        dispatch(setModalUploadReportISC02({ isOpen: false }));
        if (!dataUploadFileISC) {
            dispatch(resetUploadFileISC02());
            setFileList([]); // Clear the fileList when modal is closed
        }
    };

    const handleReset = () => {
        setFileList([]); // Clear the fileList when modal is closed
        dispatch(resetUploadFileISC02());
        dispatch(setSelectTabField({ field: 'stepTab', data: 0 }))
    };

    const handleSubmit = (values) => {
        console.log(`----  handleSubmit  values:`, values);
        dispatch(fetchReportCycleSubmissionImportExcel({
            "submission_id": Number(submissionId),
            "file_id": file_id,
            "sheet_name": isSelectTabDraft,
        }));
        // dispatch(fetchReportCycleSubmissionImportExcel({
        //     // "submission_id": 23,
        //     // "file_id": "0d4e183c-aa07-4892-a47b-9a5ca6c82b2d",
        //     // "sheet_name": "CoverSheet"

        //     // "submission_id": 23,
        //     // "file_id": "0d4e183c-aa07-4892-a47b-9a5ca6c82b2d",
        //     // "sheet_name": "Sheet1"

        //     "submission_id": 23,
        //     "file_id": "0d4e183c-aa07-4892-a47b-9a5ca6c82b2d",
        //     "sheet_name": "Sheet1"
        // }));
    };

    const customUpload = {
        name: 'file',
        fileList,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        beforeUpload: (file) => {
            if (fileList.length >= 1) {
                dispatch(setAlert({ type: 'error', msg: 'คุณสามารถอัปโหลดได้เพียงไฟล์เดียว' }));
                return Upload.LIST_IGNORE;
            }
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isExcel) {
                handleReset();
                dispatch(setAlert({ type: 'error', msg: 'รองรับไฟล์ .xlsx เท่านั้น' }));
                return Upload.LIST_IGNORE;
            }
            const isSizeAcceptable = file.size / 1024 / 1024 < 100;
            if (!isSizeAcceptable) {
                handleReset();
                dispatch(setAlert({ type: 'error', msg: 'ขนาดไฟล์เกิน 100MB!' }));
                return Upload.LIST_IGNORE;
            }
            return true;
        },
        onChange: (info) => {
            setFileList(info.fileList.slice(-1)); // Keep only the latest file
            if (info.file.status === 'done') {
                // dispatch(setAlert({ type: 'success', msg: `${info.file.name} อัปโหลดไฟล์สำเร็จ` }));
            } else if (info.file.status === 'error') {
                dispatch(setAlert({ type: 'error', msg: `${info.file.name} อัปโหลดไฟล์ล้มเหลว` }));
                handleReset();
            }
        },
        itemRender: (originNode, file, fileList, actions) => {
            return (
                <Row justify={'space-between'} wrap={false} style={{ marginLeft: '0 !important', marginRight: '0 !important', gap: '1rem', }}>
                    <Typography.Text style={{ color: 'var(--color-table-text)', fontSize: '16px' }}>{file.name}</Typography.Text>
                    <Button type="text"
                        onClick={() => {
                            actions.remove(file);
                            handleReset();
                        }}
                        icon={<CloseCircleFilled style={{ fontSize: 20, }} />}
                        style={{ color: '#D5D5D5', padding: 0, justifyContent: 'flex-start', alignItems: 'flex-start' }}
                    />
                </Row>
            );
        },
        customRequest: ({ file, onSuccess, onError, onProgress }) => {
            console.log(`----  ModalUploadReportISC02  customRequest file:`, file);
            try {
                const simulateUploadProgress = () => {
                    dispatch(setLoading())
                    let progress = 0;
                    const interval = setInterval(() => {
                        progress += 10;
                        if (progress <= 100) {
                            onProgress({ percent: progress });
                        } else {
                            clearInterval(interval);
                            onSuccess(
                                // dispatch(fetchReportCycleSubmissionUploadExcel({
                                //     submission_id: Number(submissionId),
                                //     upload_id: Number(submissionDetailId),
                                //     type: "ReportISC02",
                                //     file: file,
                                // })),
                                console.log(`----  interval  file:`, file),
                                // dispatch(setUploadFileField({ field: 'uploadFileLuckySheetDraft', data: file })),
                                LuckyExcel.transformExcelToLucky(file, (exportJson, luckysheetfile) => {
                                    if (!exportJson.sheets || exportJson.sheets.length === 0) {
                                        alert("Failed to read the content of the excel file, currently does not support xls files!");
                                        return;
                                    }

                                    // setFileData(exportJson);
                                    console.log(`----  LuckyExcel.transformExcelToLucky  exportJson:`, exportJson);

                                    dispatch(fetchReportCycleSubmissionUploadExcel({
                                        submission_id: Number(submissionId),
                                        upload_id: Number(submissionDetailId),
                                        type: "ReportISC02",
                                        file: file,
                                        file_stringify: JSON.stringify(exportJson),
                                    }))


                                    // dispatch(setUploadFileField({ field: 'uploadFileLuckySheet', data: exportJson }));

                                    const luckysheet = window.luckysheet;
                                    if (luckysheet && typeof luckysheet.destroy === 'function') {
                                        luckysheet.destroy();
                                    }
                                }),
                            );
                        }
                    }, 100);
                };
                setTimeout(() => {
                    simulateUploadProgress();
                }, 1000);
            } catch (error) {
                onError(
                    dispatch(setAlert({ type: 'error', msg: 'คุณสามารถอัปโหลดได้เพียงไฟล์เดียว' }))
                )
            }
        },
        progress: {
            strokeColor: {
                '0%': '#4899e3',
                '80%': '#4899e3',
                '100%': '#11458d',
            },
            strokeWidth: 5,
            format: (percent) => {
                return percent && `${parseFloat(percent.toFixed(2))}%`
            }
        },
        showUploadList: {
            showRemoveIcon: true,
            removeIcon: <DeleteOutlined onClick={(e) => console.log(e, 'custom removeIcon event')} />,
        },
    };


    useEffect(() => {
        if (msg) {
            setFileList([]); // Clear the fileList when modal is closed
        }

    }, [msg]);

    useEffect(() => {
        // if (isOpen) {
        //     dispatch(fetchReportCycleSubmissionUploadExcel({
        //         submission_id: Number(submissionId),
        //         upload_id: Number(submissionDetailId),
        //         type: "ReportISC02",
        //         file: null,
        //     }))
        // }
    }, [isOpen]);

    return (
        <Modal
            maskClosable={false}
            title="นำเข้าข้อมูล"
            centered
            open={isOpen}
            onOk={handleSubmit}
            onCancel={handleCancel}
            footer={[
                <div className="flex justify-center mt-10 mb-3" key="footer-buttons">
                    <Button className="px-8 me-3 font-bold" key="btn-back" onClick={handleCancel} style={{ background: 'var(--color-gray-primary-btn)', color: 'var(--color-white)' }}>
                        ย้อนกลับ
                    </Button>
                    {(!!file_id) && (
                        <Form form={form} onFinish={handleSubmit}>
                            <Button className="px-10 font-bold" key="btn-save" type="primary" onClick={() => form.submit()}>
                                บันทึก
                            </Button>
                        </Form>
                    )}
                </div>
            ]}
            width={1000}
            zIndex={2000}
        >
            <p>(รองรับไฟล์ .xlsx ขนาดไม่เกิน 100MB เท่านั้น)</p>
            <div>
                <Upload {...customUpload}>
                    <Col gap={6}>
                        {/* MSG ERR */}
                        {!!msg && (
                            <Row style={{ marginTop: '1rem', gap: '1rem', }}>
                                <Image
                                    width={20}
                                    src={iconRender(icon)}
                                    preview={false}
                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                />
                                <Typography.Text type="danger">{msg}</Typography.Text>
                            </Row>
                        )}
                        {/* MSG ERR */}

                        {!file_id && !isLoading && (
                            <Button
                                className="upload my-4"
                                type="primary"
                                style={{ paddingLeft: '30px', paddingRight: '30px', minWidth: '180px' }}
                                icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />
                                }>
                                อัปโหลดไฟล์
                            </Button>
                        )}
                    </Col>
                </Upload>
            </div>
            {/* <div ref={sheetRef} style={{ width: '100%', height: '600px' }}></div> */}
        </Modal>
    );
};

export default ModalUploadReportISC02;
