import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Image, Input, Row, Select, Tabs, Typography } from "antd";
import { uniqueId } from "lodash";
import LuckyExcel from "luckyexcel";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchReportCycleSubmissionImportExcel, fetchReportISCSubmissionCheckDataName, fetchReportISCSubmissionMapError } from "../../../features/redux/apiSlice/apiReportISC02Slice";
import { setModalUploadReportISC02 } from "../../../features/redux/slice/modalSlice";
import { setSelectTab } from "../../../features/redux/slice/reportISCTabSlice";
import { stringDefault } from "../../../helper/base";
import { IconFileExcelWhite } from "../../../helper/icon";

const { Option } = Select;

const SubmissionDetailReportUploadFileISC02 = () => {
    const dispatch = useDispatch();
    const sheetRef = useRef();
    const fileInputRef = useRef();
    const [step, setStep] = useState(0);
    const [fileData, setFileData] = useState(null);
    const [scriptsLoaded, setScriptsLoaded] = useState(false);
    const [rangeValue, setRangeValue] = useState('');
    const [nameColumnValue, setNameColumnValue] = useState('');
    const [currentDataColumnValue, setCurrentDataColumnValue] = useState('');
    const [previousDataColumnValue, setPreviousDataColumnValue] = useState('');
    const [form] = Form.useForm();
    const [inputValue, setInputValue] = useState('');
    const [isInputVisible, setIsInputVisible] = useState(false);
    const sheetId = uniqueId("datasheet_");
    const { isSelectTab = "" } = useSelector(state => state.reportISCTab);
    const { uploadFileISC02, fileExcelISC02 } = useSelector(state => state.uploadFile);
    const { data: dataUploadFileISC, err: errUploadFileISC } = uploadFileISC02;
    const { file_id = '', sheet_names = [], isFileExcelISC02Finish = false } = dataUploadFileISC || { file_id: '', sheet_names: [], isFileExcelISC02Finish: false };
    const { msg = '', icon = '' } = errUploadFileISC || { msg: '', icon: '' };
    let { infoId = '', submissionId = '', submissionDetailId = '' } = useParams();

    // msd_names
    const options = [
        { value: 'option1', label: 'A1' },
        { value: 'option2', label: 'A2' },
        { value: 'option3', label: 'A3' },
    ];

    useEffect(() => {
        const loadScripts = async () => {
            const jQueryScript = document.createElement('script');
            jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
            jQueryScript.type = 'text/javascript';
            jQueryScript.onload = async () => {
                const mousewheelScript = document.createElement('script');
                mousewheelScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery-mousewheel/3.1.13/jquery.mousewheel.min.js';
                mousewheelScript.type = 'text/javascript';
                mousewheelScript.onload = async () => {
                    const stylesheets = [
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/css/pluginsCss.css',
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/plugins.css',
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/css/luckysheet.css',
                        'https://cdn.jsdelivr.net/npm/luckysheet/dist/assets/iconfont/iconfont.css',
                    ];
                    stylesheets.forEach(href => {
                        const link = document.createElement('link');
                        link.rel = 'stylesheet';
                        link.href = href;
                        document.head.appendChild(link);
                    });

                    const luckysheetModule = await import('luckysheet');
                    const luckysheet = luckysheetModule.default || luckysheetModule;
                    window.luckysheet = luckysheet;

                    setScriptsLoaded(true);
                };
                document.head.appendChild(mousewheelScript);
            };
            document.head.appendChild(jQueryScript);
        };

        loadScripts();
    }, []);

    useEffect(() => {
        if (scriptsLoaded && sheetRef.current && fileData) {
            const luckysheet = window.luckysheet;
            if (luckysheet) {
                sheetRef.current.setAttribute("id", sheetId);
                luckysheet.create({
                    container: sheetId,
                    data: fileData?.sheets,
                    showinfobar: false,
                    cellEdit: false, // Disable cell editing
                    allowEdit: false, // Disable all editing
                    showtoolbar: false, // Hide the toolbar
                    showsheetbar: false, // Hide the sheet bar
                    showstatisticBar: false, // Hide the statistic bar
                    enableAddRow: false, // Disable row addition
                    enableAddBackTop: false, // Disable top button
                    enableAddRow2: false, // Disable row addition button
                    showHelpBox: false, // Disable the help box
                    hook: {
                        rangeSelect: (range) => {
                            const rangeAxis = luckysheet.getRangeAxis(range);
                            console.log(`----  useEffect  rangeAxis:`, rangeAxis);
                            console.log(`----  useEffect  step:`, step);
                            setRangeValue(rangeAxis.toString());
                            setNameColumnValue(rangeAxis.toString());
                            setCurrentDataColumnValue(rangeAxis.toString());
                            setPreviousDataColumnValue(rangeAxis.toString());
                            form.setFieldsValue({
                                // nameDataColumn: step === 1 ? rangeAxis.toString() : '',
                                // currentDataColumn: step === 2 ? rangeAxis.toString() : '',
                                // previousDataColumn: step === 2 ? rangeAxis.toString() : '',
                                nameDataColumn: rangeAxis.toString(),
                                currentDataColumn: rangeAxis.toString(),
                                previousDataColumn: rangeAxis.toString()
                            });
                            if (step === 0) {
                                setStep(1);
                            }
                        }
                    }
                });

                const handleResize = () => {
                    if (luckysheet && typeof luckysheet.resize === 'function') {
                        luckysheet.resize();
                    }
                };

                window.addEventListener('resize', handleResize);

                return () => {
                    window.removeEventListener('resize', handleResize);
                };
            }
        }
    }, [scriptsLoaded, fileData, sheetRef, form]);

    const handleFileChange = (event) => {
        const files = event.target.files;
        console.log(`----  handleFileChange  files:`, files);
        if (files.length > 0) {
            const file = files[0];
            const maxSize = 100 * 1024 * 1024;

            if (file.size > maxSize) {
                alert("File size exceeds 10MB. Please upload a smaller file.");
                event.target.value = "";
                return;
            }
            LuckyExcel.transformExcelToLucky(file, (exportJson, luckysheetfile) => {
                if (!exportJson.sheets || exportJson.sheets.length === 0) {
                    alert("Failed to read the content of the excel file, currently does not support xls files!");
                    return;
                }

                setFileData(exportJson);

                const luckysheet = window.luckysheet;
               
                if (luckysheet && typeof luckysheet.destroy === 'function') {
                    luckysheet.destroy();
                }
            });
        }
    };

    const onChangeTab = (key) => {
        setStep(1)
        dispatch(setSelectTab({ isSelectTab: key }));
        dispatch(fetchReportCycleSubmissionImportExcel({
            "submission_id": Number(submissionId),
            "file_id": file_id,
            "sheet_name": key,
        }));


        const currentIndex = sheet_names.findIndex(tab => tab === isSelectTab);
        const luckysheet = window.luckysheet;
        luckysheet.setSheet(currentIndex );
       
    };

    const handleNextTab = () => {
        const currentIndex = sheet_names.findIndex(tab => tab === isSelectTab);
        if (currentIndex < sheet_names.length - 1) {
            onChangeTab(sheet_names[currentIndex + 1]);
          
        }
    };

    const handlePrevTab = () => {
        const currentIndex = sheet_names.findIndex(tab => tab === isSelectTab);
        if (currentIndex > 0) {
            onChangeTab(sheet_names[currentIndex - 1]);
        }
    };

    const handleSelectChange = (value) => {
        if (value === "other") {
            setIsInputVisible(true);
        } else {
            setIsInputVisible(false);
            form.setFieldsValue({
                name: value,
                otherName: null,
            });
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        form.setFieldsValue({
            otherName: e.target.value,
            name: null,
        });
    };

    const handleOpenModalUploadReportISC02 = () => {
        setTimeout(() => {
            dispatch(setModalUploadReportISC02({ isOpen: true }));
        }, 1000);
    };

    const handleStep01 = () => {
        console.log(`----  handleStep01:`, handleStep01);
        dispatch(fetchReportISCSubmissionCheckDataName({
            "submission_id": Number(submissionId),
            "file_id": file_id,
            "sheet_name": isSelectTab,
            "name_range": nameColumnValue,
        }));
    };
    const handleStep02 = () => {
        console.log(`----  handleStep02:`, handleStep02);
        dispatch(fetchReportISCSubmissionMapError({
            "mapping_id": 73,
            "msd_name": "msdName",
            "new_name": "",
            "sheet_name": isSelectTab,
            "file_id": file_id,
        }));
    };
    const handleStep03 = () => {
        console.log(`----  handleStep03:`, handleStep03);
        // currentDataColumnValue
        // previousDataColumnValue

        // dispatch(fetchReportISCSubmissionCheckDataName({
        //     "submission_id": 23,
        //     "file_id": "d9b70385-5bb0-498d-a197-a76709292d2d",
        //     "sheet_name": "งบแสดง ฐานะการเงิน",
        //     "name_range": "A3:A10"
        // }));
    };

    // useEffect(() => {
    //     const processBinaryString = (binaryString) => {
    //         const bytes = new Uint8Array(binaryString.length);
    //         for (let i = 0; i < binaryString.length; i++) {
    //             bytes[i] = binaryString.charCodeAt(i);
    //         }
    //         return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     };

    //     console.log(`----  useEffect  fileExcelISC02//:`, fileExcelISC02);
    //     // if (fileExcelISC02?.filePart?.fileContent) {
    //     if (fileExcelISC02) {
    //         const arrayBuffer = processBinaryString(fileExcelISC02?.filePart?.fileContent);
    //         console.log(`----  useEffect  arrayBuffer:`, arrayBuffer);

    //         const file = new File([arrayBuffer], "uploaded_file.xlsx", { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         console.log(`----  useEffect  file:`, file);
    //         console.log(`----  useEffect  file:`, JSON.stringify(file));
    //         // file['uid'].remove()
    //         // delete file.uid2;

    //         LuckyExcel.transformExcelToLucky(file, (exportJson, luckysheetfile) => {
    //             if (!exportJson.sheets || exportJson.sheets.length === 0) {
    //                 alert("Failed to read the content of the excel file, currently does not support xls files!");
    //                 return;
    //             }

    //             setFileData(exportJson);

    //             const luckysheet = window.luckysheet;
    //             if (luckysheet && typeof luckysheet.destroy === 'function') {
    //                 luckysheet.destroy();
    //             }
    //         });
    //     }
    // }, [fileExcelISC02]);

    return (
        <Row style={{ gap: '6px', padding: "18px 32px" }}>
            <Col flex={1} style={{ gap: '6px', display: 'flex', flexDirection: 'column' }}>
                <Row justify={'space-between'} style={{ gap: '6px' }}>
                    <Col flex={1}>
                        <Tabs
                            activeKey={isSelectTab}
                            onChange={onChangeTab}
                            type="card"
                            className='w-full'
                            size="small"
                            tabBarExtraContent={
                                <Row align={'middle'} style={{ display: 'flex', gap: "8px" }}>
                                    {sheet_names.length > 0 && (
                                        <>
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                style={{ border: '0', padding: '6px !important', minWidth: '24px', minHeight: '24px', maxWidth: '24px', maxHeight: '24px', background: sheet_names.findIndex(tab => tab === isSelectTab) <= 0 ? 'var(--color-gray-primary)' : '' }}
                                                icon={
                                                    <LeftOutlined
                                                        style={{ display: 'flex', fontSize: '10px', color: 'var(--color-white)' }}
                                                    />
                                                }
                                                disabled={sheet_names.findIndex(tab => tab === isSelectTab) <= 0}
                                                onClick={handlePrevTab}
                                            />
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                style={{ border: '0', padding: '6px !important', minWidth: '24px', minHeight: '24px', maxWidth: '24px', maxHeight: '24px', background: sheet_names.findIndex(tab => tab === isSelectTab) >= sheet_names.length - 1 ? 'var(--color-gray-primary)' : '' }}
                                                icon={
                                                    <RightOutlined
                                                        style={{ display: 'flex', fontSize: '10px', color: 'var(--color-white)' }}
                                                    />
                                                }
                                                disabled={sheet_names.findIndex(tab => tab === isSelectTab) >= sheet_names.length - 1}
                                                onClick={handleNextTab}
                                            />
                                        </>
                                    )}
                                    <Button
                                        type="primary"
                                        className='importAction'
                                        icon={
                                            <Image width={20} src={IconFileExcelWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle' }} />
                                        }
                                        style={{ background: 'var(--color-excel)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem' }}
                                        onClick={handleOpenModalUploadReportISC02}
                                    >
                                        Import
                                    </Button>
                                    <Button
                                        hidden
                                        type="primary"
                                        onClick={() => window.location.reload()}
                                    >
                                        reload
                                    </Button>
                                </Row>
                            }
                            items={sheet_names.map((data) => ({
                                label: data,
                                key: data,
                                children: null,
                            }))}
                        />
                    </Col>
                </Row>
                <Row style={{ gap: '6px' }}>
                    <Col style={{ flex: 3 }}>
                        <div>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                            {!fileData && (
                                <Empty
                                    style={{
                                        padding: '35px 25px',
                                        minHeight: '40vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    imageStyle={{ display: 'none' }}
                                    description='-- กรุณา Import ไฟล์ --'
                                />
                            )}
                            <div
                                className="dataSheetClass"
                                ref={sheetRef}
                                style={{
                                    margin: '0px',
                                    padding: '0px',
                                    minHeight: !fileData ? '0' : '40vh',
                                    maxHeight: '100%',
                                    left: '0px',
                                    top: '0px',
                                    zIndex: 9999,
                                }}
                            />
                        </div>
                    </Col>
                    <Col style={{ flex: 1, display: step === 0 ? 'none' : 'flex' }}>
                        <Card
                            title={step === 1 ? `กำหนดชื่อข้อมูล` : step === 2 ? `ตั้งค่าเซลล์` : step === 3 ? `กำหนดค่าข้อมูล` : `ตั้งค่าเซลล์`}
                            rootClassName="h-full"
                            className="h-full"
                            style={{
                                flex: 1,
                                body: {
                                    padding: '1rem',
                                    height: '100% !important',
                                }
                            }}
                        >
                            <Form form={form}>
                                {step !== 0 && (
                                    <>
                                        <Col style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Typography.Paragraph>{`Sheet : ${stringDefault(Number(sheet_names.findIndex(e => e === isSelectTab) + 1) || "0", '-')}`}</Typography.Paragraph>
                                            </Col>
                                        </Col>
                                    </>
                                )}
                                {step !== 0 && step !== 1 && step !== 3 && (
                                    <>
                                        <Col style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Typography.Paragraph>{`Cell : ${stringDefault(null, '-')}`}</Typography.Paragraph>
                                            </Col>
                                        </Col>
                                    </>
                                )}
                                {step === 1 && (
                                    <>
                                        <Col span={5} style={{ minWidth: "100%", margin: 0, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Row gutter={[5, 5]}>
                                                {nameColumnValue ? (
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                        <Typography.Text className='text-[16px]'>คอลัมน์ชื่อข้อมูล</Typography.Text>
                                                        <Form.Item name="nameDataColumn" style={{ minWidth: "100%", margin: 0 }}>
                                                            <Input
                                                                placeholder=""
                                                                value={nameColumnValue}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', padding: '8px 0' }}>
                                                        <Typography.Text className='text-[16px] text-center text-[var(--color-text-gray-primary)]'>กรุณากดปุ่ม กำหนดชื่อข้อมูล<br />หรือ กำหนดค่าข้อมูล<br />กรณีต้องการแก้ไข</Typography.Text>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row gutter={[5, 5]} justify={'center'}>
                                                <Button
                                                    type="primary"
                                                    onClick={() => { setStep(2); handleStep01(); }}
                                                    className="font-bold"
                                                    block={false}
                                                >
                                                    ตรวจสอบ
                                                </Button>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                {step === 2 && (
                                    <>
                                        <Col span={5} style={{ minWidth: "100%", margin: 0, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Row gutter={[5, 5]}>
                                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                    <Typography.Text className='text-[16px]'>ชื่อข้อมูล</Typography.Text>
                                                    <Form.Item name="name" style={{ minWidth: "100%", margin: 0 }}>
                                                        <Select
                                                            placeholder="-- เลือกชื่อข้อมูล --"
                                                            onChange={handleSelectChange}
                                                        >
                                                            {options.map((option) => (
                                                                <Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                        <Typography.Text className='text-[16px]'>กรณีไม่มีชื่อข้อมูลในตัวเลือก</Typography.Text>
                                                        <Form.Item name="otherName" style={{ minWidth: "100%", margin: 0 }}>
                                                            <Input
                                                                placeholder="กรุณากรอกข้อมูล"
                                                                onChange={handleInputChange}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row gutter={[5, 5]} justify={'center'}>
                                                <Button
                                                    type="primary"
                                                    onClick={() => { setStep(3); handleStep02(); }}
                                                    className="font-bold"
                                                    block={false}
                                                >
                                                    บันทึก
                                                </Button>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                {step === 3 && (
                                    <>
                                        <Col span={5} style={{ minWidth: "100%", margin: 0, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Row gutter={[5, 5]}>
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                        <Typography.Text className='text-[16px]'>คอลัมน์ค่าข้อมูลงวดปัจจุบัน</Typography.Text>
                                                        <Form.Item name="currentDataColumn" style={{ minWidth: "100%", margin: 0 }}>
                                                            <Input
                                                                placeholder=""
                                                                value={currentDataColumnValue}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={5} style={{ minWidth: "100%", margin: 0 }}>
                                                <Row gutter={[5, 5]}>
                                                    <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                        <Typography.Text className='text-[16px]'>คอลัมน์ค่าข้อมูลงวดก่อนหน้า</Typography.Text>
                                                        <Form.Item name="previousDataColumn" style={{ minWidth: "100%", margin: 0 }}>
                                                            <Input
                                                                placeholder=""
                                                                value={previousDataColumnValue}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Typography.Text className='text-[16px]'>หน่วยเงิน</Typography.Text>
                                            <Form.Item name="nameMoney" style={{ minWidth: "100%", margin: 0 }}>
                                                <Select
                                                    placeholder="-- เลือกสกุลเงิน --"
                                                    onChange={handleSelectChange}
                                                >
                                                    <Option value="option1">บาท</Option>
                                                </Select>
                                            </Form.Item>
                                            <Row gutter={[5, 5]} justify={'center'}>
                                                <Button
                                                    type="primary"
                                                    onClick={() => { setStep(3); handleStep03(); }}
                                                    className="font-bold"
                                                    block={false}
                                                >
                                                    บันทึก
                                                </Button>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default SubmissionDetailReportUploadFileISC02;
