import { indexHost } from ".";

export const reportISCHost = {
    base: indexHost.base,

    /* -------------------------------- Sprint1 ------------------------------- */
    /* Web Report ISC */
    reportISCBase: `/report-isc-01`,
    reportISCInfoBase: `/report-isc-01/info/:infoId`,
    reportISCSubmissionBase: `/report-isc-01/info/:infoId/submission/:submissionId`,
    reportISCUploadBase: `/report-isc-01/info/:infoId/submission/:submissionId/upload/:uploadId`,
    reportISCInfoByIdBase: (id = '-') => `/report-isc-01/info/${id}`,
    reportISCSubmissionById: (submissionId = '-') => `/submission/${submissionId}`,
    reportISCUploadById: (uploadId = '-') => `/upload/${uploadId}`,
    reportISCSubmissionByIdBase: (infoId = '-', submissionId = '-') => `/report-isc-01/info/${infoId}/submission/${submissionId}`,
    reportISCUploadByIdBase: (infoId = '-', submissionId = '-', uploadId = '-') => `/report-isc-01/info/${infoId}/submission/${submissionId}/upload/${uploadId}`,
    /* Web Report ISC */

    /* Api Report ISC */
    reportISCGetList: `/report-cycle/isc01/get-list`,
    reportISCGetDetail: `/report-cycle/isc01/get-detail`,

    reportISCSubmissionGenerateRef: `/report-submission/create`,
    reportISCSubmissionGetDetail: `/report-submission/get-detail`,
    reportISCSubmissionUploadExcel: `/report-submission/upload`,
    reportISCSubmissionImportExcel: `/report-submission/confirm-import`,
    reportISCSubmissionCancelEditRequest: `/report-submission/cancel-edit-request`,
    reportISCSubmissionCancelUploadErrorDetail: `/report-submission/upload_error_detail`,
    reportISCSubmissionUploadDetailGetAllSheet: `report-submission/upload-detail/get-all-sheet`,
    reportISCSubmissionUploadDetailGetSheetDetail: `report-submission/upload-detail/get-sheet-detail`,
    reportISCSubmissionSaveByPass: `/report-submission/upload-detail/save-bypass`,
    reportISCSubmissionResubmit: `/report-submission/upload-detail/resubmit`,

    reportISCSubmissionUpdateStatusInterval: (submission_id) => `/report-submission/upload-detail/get-status?submission_id=${submission_id}`,
    reportISCSubmissionGetFile: (queryId) => `/report-submission/get-file?${queryId}`,
    reportISCSubmissionGetTemplateUrlFile: (queryId) => `/report-submission/get-file?${queryId}`, /*FIXME - change url GetTemplateUrlFile*/
    /* Api Report ISC */
    /* ------------------------------------------------------------------------- */
}