// src/components/TableReportISC02Info.js
import { Button, Col, Empty, Image, Row, Table, Tooltip, Typography } from 'antd';
import React from 'react';
import { IconCircleClose, IconFileExcel, IconFileExcelFill, IconFileInfoFill, IconSearch, IconViewMore, IconViewMoreOrange } from '../../../helper/icon';
import { convertDateFormat } from '../../../helper/date';
import { convertStatusInfo } from '../fn';
import { stringDefault } from '../../../helper/base';
import { initialStatePagination, setPage } from '../../../features/redux/slice/paginationSlice';
import { useDispatch } from 'react-redux';
const { Column } = Table;


const TableReportISC02Info = ({
    dataList = [],
    pagination = {},
    onClickClose = () => null,
    onClickInfo = () => null,
    onClickFileExcel = () => null,
    onClickSearch = () => null,
    onClickViewMore = () => null,
}) => {
    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
        total_pages = initialStatePagination.totalPages,
    } = pagination

    const data = dataList;

    return (
        <Table
            dataSource={dataList}
            scroll={{ x: true }}
            className="full-width-table"
            rowKey={"row_no"}
            pagination={{
                onChange: (page, pageSize) => {
                    dispatch(setPage(page));
                },
                showSizeChanger: false,
                current: page,
                pageSize: page_size,
                total: total,
                showQuickJumper: true,
                showTotal: (t, range) =>
                    `แสดงข้อมูล  ${range[0]} -  ${range[1]} จาก  ${total} รายการ`, // `แสดงข้อมูล 1 - 17 จาก 17 รายการ`
            }}
            locale={{
                emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- กรุณากดปุ่ม ส่งข้อมูล -' />,
            }}
        >
            <Column className="whitespace-nowrap" align="start" title="เลขที่อ้างอิงข้อมูล" dataIndex="reference_code" key="reference_code" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="Upload ครั้งที่" dataIndex="upload_num" key="upload_num" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="วันที่ส่ง" dataIndex="submission_date" key="submission_date" render={(text, record) => <Typography.Paragraph>{stringDefault(convertDateFormat(text, 'dateTime'), '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="ผู้ส่งล่าสุด" dataIndex="last_submitted_by" key="last_submitted_by" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="center" title="สถานะ" dataIndex="submission_status" key="submission_status" render={(text, record) => <Typography.Paragraph style={{ color: convertStatusInfo(text).color }}>{stringDefault(convertStatusInfo(text).text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="center" title="สถานะการอนุมัติ" dataIndex="approval_status" key="approval_status" render={(text, record) => <Typography.Paragraph style={{ color: convertStatusInfo(text).color }}>{stringDefault(convertStatusInfo(text).text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="วันที่อนุมัติ" dataIndex="approved_date" key="approved_date" render={(text, record) => <Typography.Paragraph>{stringDefault(convertDateFormat(text, 'dateTime'), '-')}</Typography.Paragraph>} />
            <Column
                className="whitespace-nowrap"
                align='center'
                title="เครื่องมือ"
                key="action"
                render={(text, record) => (
                    <Row gutter={[2, 2]} justify={'center'}>
                        {record.can_cancel_edit && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickClose(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        width={25}
                                        src={IconCircleClose}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                </Button>
                            </Col>
                        )}
                        {!!record.notes && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickInfo(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    {/* <Tooltip title={record.notes} key='tooltip-information' style={{ verticalAlign: 'sub', }} > */}
                                    <Image
                                        width={25}
                                        src={IconFileInfoFill}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                    {/* </Tooltip> */}
                                </Button>
                            </Col>
                        )}
                        {/* {record.approvalStatus === 'รออนุมัติแก้ไข' && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickFileExcel(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        width={25}
                                        src={IconFileExcelFill}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                </Button>
                            </Col>
                        )} */}
                        {(!!record.approval_status) && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickSearch(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        width={25}
                                        src={IconSearch}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                </Button>
                            </Col>
                        )}
                        {(!record.approval_status) && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickViewMore(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        width={25}
                                        src={record.is_view ? IconViewMore : IconViewMoreOrange}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                </Button>
                            </Col>
                        )}

                    </Row>
                )}
            />
        </Table>
    );
};

export default TableReportISC02Info;
