// src/components/LifeQuarter.js
import React, { useEffect, useState } from 'react';
import { Select, Col, Row, Button, Image, Tag } from 'antd';
import { IconViewMoreOrange } from "../../../helper/icon";
import { useDispatch } from 'react-redux';
import { setDetailNoteDataInput } from '../../../features/redux/slice/detailNoteSlice';

const Quarter = ({ onClickViewMore = () => null, sectionOne, sectionTwo, sectionThree, setUnit, unit, dataError }) => {
    const dispatch = useDispatch()
    const handleChange = (value) => {
        setUnit(value);
        dispatch(setDetailNoteDataInput(
            {
                unit: value,
            }
        ))
    };

    const formatNumber = (value) => {
        return `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }



    return <div>
        <Col style={{ paddingLeft: '32px', paddingRight: '32px', paddingBottom: '0px', paddingTop: '12px' }}>
            <div className='flex justify-between items-center'>
                <p className='text-[16px]'>
                    2. รายละเอียดข้อมูลหมายเหตุประกอบงบการเงิน
                    <span className='text-[16px] text-red-600 font-bold pl-2'>*</span>
                </p>
                <div className='flex items-center gap-5'>
                    <p>หน่วย :</p>
                    <Select
                        defaultValue={unit == '' ? 'บาท' : unit}
                        value={unit == '' ? 'บาท' : unit}
                        style={{ width: 200, borderColor: '#E5EBEB', color: unit ? 'red' : 'initial' }}
                        onChange={handleChange}
                        options={[
                            {
                                value: 'บาท',
                                label: <span className={`${dataError?.length > 0 ? dataError[0]?.finance_unit != 'บาท' ? unit == 'บาท' ? 'text-red-600' : '' : '' : ''}`}>บาท</span>
                            },
                            {
                                value: 'พันบาท',
                                label: <span className={`${dataError?.length > 0 ? dataError[0]?.finance_unit != 'พันบาท' ? unit == 'พันบาท' ? 'text-red-600' : '' : '' : ''}`}>พันบาท</span>
                            },
                            {
                                value: 'ล้านบาท',
                                label: <span className={`${dataError?.length > 0 ? dataError[0]?.finance_unit != 'ล้านบาท' ? unit == 'ล้านบาท' ? 'text-red-600' : '' : '' : ''}`}>ล้านบาท</span>
                            },
                        ]}
                    />
                </div>
            </div>
        </Col>
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '12px', paddingTop: '12px' }} >
            <div className='flex flex-col gap-3'>
                <div className='flex items-center text-[#464646] text-base font-bold'>
                    1. องค์ประกอบของงบแสดงฐานะการเงิน
                </div>
                <div className='pl-4 flex items-center text-[#464646] text-base font-bold'>
                    วันสิ้นรอบระยะเวลาระหว่างกาลปีปัจจุบัน (สิ้นงวดปัจจุบัน)
                </div>
            </div>
            {sectionOne?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 1 ? '0px !important' : '3px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 500 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: '0px !important ', border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle' }} />
                            }
                            onClick={() => onClickViewMore('วันสิ้นรอบระยะเวลาระหว่างกาลปีปัจจุบัน (สิ้นงวดปัจจุบัน)', 1)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            <div className='pl-4 flex items-center text-[#464646] text-base font-bold'>
                วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)
            </div>
            {sectionTwo?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 1 ? '0px !important' : '3px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 500 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)', 2)}
                        >
                        </Button>
                            : <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '32px', paddingTop: '12px' }} >
            <div className='flex items-center text-[#464646] text-base font-bold'>
                2. รายได้และผลการดำเนินงานการบริการประกันภัย
            </div>
            {sectionThree?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 1 ? '0px !important' : '3px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 500 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                // <FormOutlined />
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('2. รายได้และผลการดำเนินงานการบริการประกันภัย', 3)}
                        >
                        </Button>
                            : <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
    </div>
};


export default Quarter;