import { indexHost } from ".";

export const userHost = {
    base: indexHost.base,

    userBase: `user`,
    getData: (id) => `user/get-data/${id}`,
    updateStatus: `user/update-status`,
    deleteData: (id) => `user/${id}`,
    
    getDepartments: `user/departments`,
}