// src/components/SidebarBase.js
import { Image, Layout, Menu } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { initialStateActiveDefault, initialStateCollapsed, initialStateMenuSidebar, initialStateOpenDefault, setActiveKey, setCollapsedSidebarDefault, setOpenKey, setOptionSidebar } from '../features/redux/slice/sidebarSlice';
import { stringDefault } from '../helper/base';
import { Sidebar1, Sidebar2, Sidebar3, Sidebar4, Sidebar5, Sidebar6 } from '../helper/icon';
import { reportISCHost } from '../server/service/host/reportISCHost';
import { reportISC02Host } from '../server/service/host/reportISC02Host';
import { indexHost } from '../server/service/host';
import { reportISC03Host } from '../server/service/host/reportISC03Host';
import { announHost } from '../server/service/host/announHost';
import { logHost } from '../server/service/host/logHost';
import { masterDataHost } from '../server/service/host/masterDataHost';
import { userHost } from '../server/service/host/userHost';



const { Sider } = Layout;

/* 
    Dashboard

    นำส่งและติดตามผล
        รายงาน ช สำหรับบริษัทประกัน
        

    // Dashboard

    // การจัดส่งข้อมูล
    //     จัดการแบบรายงาน
    //     อนุมัติ Whitelist IP
    //     ติดตามการส่งข้อมูล
    //     คำขออนุมัติแก้ไข

    // รายงาน

    // ประวัติการยอมรับข้อตกลง

    // ความช่วยเหลือ
    //     FAQ
    //     คู่มือการใช้งาน

    // ระบบจัดการเนื้อหา
    //     จัดการป้ายประชาสัมพันธ์
    //     จัดการข่าวประชาสัมพันธ์
    //     จัดการข้อกำหนดและเงื่อนไข
    //     จัดการนโยบายความเป็นส่วนตัว
    //     จัดการ FAQ
    //     จัดการคู่มือการใช้งาน
    //     จัดการช่องทางการติดต่อ

    // ตั้งค่า
*/


const SidebarBase = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const path = `/${stringDefault(pathname.split('/')[1])}`

    const {
        menuSidebar = initialStateMenuSidebar,
        collapsed = initialStateCollapsed,
        activeKey = initialStateActiveDefault,
        openKeys = initialStateOpenDefault,
    } = useSelector(state => state.sidebar);


    const itemList = menuSidebar.length > 0 ? menuSidebar : [
        /* {
            key: '1',
            icon: Sidebar1,
            label: 'Dashboard',
            path: '/dashboard',
        }, */
        {
            key: 'sub1',
            icon: Sidebar2,
            label: 'นำส่งและติดตามผล',
            children: [
                {
                    key: '2',
                    label: 'แบบรายงานเกี่ยวกับฐานะการเงินและกิจการ',
                    path: reportISCHost.reportISCBase,
                },
                {
                    key: '3',
                    label: 'งบการเงิน',
                    path: reportISC02Host.reportISC02Base,
                },
                {
                    key: '4',
                    label: 'รายงานกระทบยอด',
                    path: reportISC03Host.reportISC03Base,
                },
                // {
                //     key: '5',
                //     label: 'รายงานเปลี่ยนแปลงในกำไรขาดทุนเบ็ดเสร็จ',
                //     path: reportISC02Host.reportISC02Base,
                // },
                 {
                     key: '10',
                     label: 'ดัชนีตารางอ้างอิงมาตรฐาน',
                     path: masterDataHost.msdBase,
                 },
            ],
        },
        /* {
            key: 'sub2',
            icon: Sidebar3,
            label: 'รายงาน',
            children: [
                {
                    key: '4',
                    label: 'รายงาน',
                    path: null,
                },
            ],
        },
        {
            key: 'sub3',
            icon: Sidebar4,
            label: 'ประวัติการยอมรับข้อตกลง',
            children: [
                {
                    key: '5',
                    label: 'ประวัติการยอมรับข้อตกลง',
                    path: null,
                },
            ],
        }, */
        {
            key: 'sub4',
            icon: Sidebar5,
            label: 'ความช่วยเหลือ',
            children: [
                /*  {
                     key: '6',
                     label: 'FAQ',
                     path: null,
                 }, */
                {
                    key: '7',
                    label: 'คู่มือการใช้งาน',
                    path: indexHost.userManualBase,
                },
                {
                    key: '8',
                    label: 'ประกาศ',
                    path: announHost.announBase,
                },
            ],
        },
        {
             key: 'sub5',
             icon: Sidebar6,
             label: 'ตั้งค่า',
            children: [
                {
                    key: 'sub5_sub1',
                    label: 'ผู้ใช้งานและสิทธิ์การใช้งาน',
                    children: [
                        {
                            key: '11',
                            label: 'จัดการผู้ใช้งาน',
                            path: userHost.userBase,
                        },
                        {
                            key: '9',
                            label: 'ประวัติการใช้งานระบบ',
                            path: logHost.logBase,
                        },
                    ],
                }, 
             ],
         }, 
    ];

    const items = itemList.map((item, index) => {
        return {
            key: item?.key,
            id: item?.key,
            icon: <Image width={20} src={item?.icon} preview={false} />,
            // icon: <Image src={item?.icon} preview={false} />,
            label: <Link to={item?.path}>{item?.label}</Link>,
            disabled: !item?.children && !item?.path,
            children: item?.children ? [...item?.children].map((item, index) => {
                return {
                    key: item?.key,
                    id: item?.key,
                    icon: item?.icon ? <Image width={20} src={item?.icon} preview={false} /> : null,
                    // icon: item?.icon ? <Image src={item?.icon} preview={false} /> : null,
                    label: item?.children ? <Link to={item?.path} style={{ textAlign: 'left', paddingLeft: '40px' }}>{item?.label}</Link> : <Link to={item?.path} style={{ textAlign: 'left'}}>{item?.label}</Link>,
                    children: item?.children ? [...item?.children].map((item, index) => {
                        return {
                            key: item?.key,
                            id: item?.key,
                            icon: item?.icon ? <Image width={20} src={item?.icon} preview={false} /> : null,
                            // icon: item?.icon ? <Image src={item?.icon} preview={false} /> : null,
                            label: <Link to={item?.path} style={{ textAlign: 'left', paddingLeft: '25px' }}>{item?.label}</Link>,
                            children: item?.children ? [...item?.children] : null,
                            disabled: !item?.path && !item?.children,
                        }
                    }) : null,
                    disabled: !item?.path && !item?.children,
                }
            }) : null
        }
    })
    const allKeys = itemList.reduce((keys, item) => {
        keys.push(item.key);
        if (item.children) {
            item.children.forEach(child => {
                keys.push(child.key);
                if (child.children) {
                    child.children.forEach(grandChild => keys.push(grandChild.key));
                }
            });
        }
        return keys;
    }, []);
    const setActiveSelectDefault = (isCollapsed = false) => {
        let keys = [];
        itemList.forEach(item => {
            if (item.path === path || (item.children && item.children.some(child => child.path === path))) {
                keys.unshift(item.key);
                if (item.children) {
                    item.children.forEach(child => {
                        if (child.path === path) {
                            keys.unshift(child.key);
                        }
                    });
                }
            }
        });
        dispatch(setOptionSidebar({
            openKeys: allKeys,
            activeKey: [...keys],
        }));
    };

    useEffect(() => {
        // dispatch(setCollapsedSidebarDefault());
        if (!collapsed) {
            setActiveSelectDefault()
        } else {
            setActiveSelectDefault(true)
        }
    }, [collapsed]);


    return (
        <Sider
            width={280}
            collapsedWidth={50}
            style={{ overflowY: 'scroll', height: 'calc(100vh - var(--header-height))', }}
            trigger={null}
            collapsible
            collapsed={collapsed}
            aria-expanded={!collapsed}
            inlineCollapsed={collapsed}
        >
            <Menu
                mode="inline"
                items={items}
                defaultSelectedKeys={activeKey}
                openKeys={openKeys}
                selectedKeys={activeKey}
                activeKey={activeKey}
                onOpenChange={(e) => {
                    dispatch(setOpenKey([...e]));
                }}
                onSelect={(e) => {
                    dispatch(setOptionSidebar({
                        openKeys: openKeys,
                        activeKey: [...e.keyPath],
                    }));
                    return null
                }}
                style={{ height: '100%', borderRight: 0, background: 'var(--color-white)', }}
            />
        </Sider>
    )

};

export default SidebarBase;
