import { saveAs } from 'file-saver';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { stringDefault } from '../../../helper/base';
import { reportISCHost } from '../../../server/service/host/reportISCHost';
import ReportISCService from '../../../server/service/reportISCService';
import { fetchReportCycleGetDetail, fetchReportCycleGetList, fetchReportCycleSubmissionCancelRequest, fetchReportCycleSubmissionDownloadReportFileById, fetchReportCycleSubmissionGenerate, fetchReportCycleSubmissionGetDetail, fetchReportCycleSubmissionGetUploadErrorDetail, fetchReportCycleSubmissionImportExcel, fetchReportCycleSubmissionUploadExcel, fetchReportISCSubmissionResubmit, fetchReportISCSubmissionSaveByPass, fetchReportISCSubmissionUpdateStatusInterval, fetchReportISCSubmissionUploadDetailGetAllSheet, fetchReportISCSubmissionUploadDetailGetAllSheetSelectTab } from '../apiSlice/apiReportISCSlice';
import { setAlert } from '../slice/alertMsgSlice';
import { setCountdown } from '../slice/countdownSlice';
import { setLoading, setLoadingTimeout } from '../slice/loadingSlice';
import { setModalUploadReportISC } from '../slice/modalSlice';
import { setPagination } from '../slice/paginationSlice';
import { setErrorData, setSelectTab } from '../slice/reportISCTabSlice';
import { selectTable, setTableReportISC, setTableReportISCInfo, setTableReportISCInfoRef, setTableReportISCInfoRefError } from '../slice/tableSlice';
import { setUploadFileISC } from '../slice/uploadFileSlice';
import { selectConfig } from '../slice/configSlice';

function* fetchReportCycleGetListSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportCycleGetList, action.payload);
        console.log(`----  function*fetchReportCycleGetListSaga  data:`, data);
        yield put(setPagination(data.data.pagination))
        yield put(setTableReportISC(data));
    } catch (error) {
        console.log(`----  function*fetchReportCycleGetListSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout({ time: 500 }));
    }
}

function* fetchReportCycleGetDetailSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportCycleGetDetail, action.payload);
        console.log(`----  function*fetchReportCycleGetDetailSaga  data:`, data);
        yield put(setTableReportISCInfo(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleGetDetailSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionCancelRequestSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportCycleSubmissionCancelRequest, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionCancelRequestSaga  data:`, data);
        yield put(setTableReportISCInfo(data));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionCancelRequestSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        window.location.reload();
    }
}


function* fetchReportCycleSubmissionGenerateSaga(action) {
    let dataFetch = null
    try {
        const data = yield call(ReportISCService.apiReportCycleSubmissionGenerate, action.payload);
        dataFetch = { ...data }
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  data:`, data);

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        yield delay(1500);
        /* Navigate */
        console.log(`----  function*fetchReportCycleSubmissionGenerateSaga  dataFetch:`, dataFetch);
        const referenceCode = dataFetch?.data?.submission_id;
        if (referenceCode != null) {
            const submissionUrl = reportISCHost.reportISCSubmissionById(stringDefault(referenceCode, '-'))
            const navigateUrl = window.location.href + submissionUrl;
            window.location.href = navigateUrl
        } else {
            yield put(setAlert({ type: 'warning', msg: 'กรุณาลองใหม่อีกครั้ง' }))
        }
        /* Navigate */
    }
}

function* fetchReportCycleSubmissionUploadExcelSaga(action) {
    try {
        yield put(setLoading());
        const data = yield call(ReportISCService.apiReportCycleSubmissionUploadExcel, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionUploadExcelSaga  data:`, data);
        yield put(setUploadFileISC({ data: data }));
        yield put(setAlert({ type: 'success', msg: 'เก็บข้อมูลไฟล์บนระบบสำเร็จ' }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionUploadExcelSaga  error:`, error);
        yield delay(2000)
        yield put(setAlert({ type: 'error', msg: 'เก็บข้อมูลไฟล์บนระบบล้มเหลว กรุณาอัปโหลดไฟล์อีกครั้ง' }));
        // ไม่สามารถอัปโหลดไฟล์ได้ เนื่องจากขนาดไฟล์ไม่ถูกต้อง กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        // ไม่สามารถอัปโหลดไฟล์ได้ เนื่องจากสกุลไฟล์ไม่ถูกต้อง กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        // ไม่สามารถอัปโหลดไฟล์ได้ กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง
        yield put(setUploadFileISC({ err: { msg: error?.err?.response?.data?.meta?.message || 'ไม่สามารถอัปโหลดไฟล์ได้ กรุณาอัปโหลดไฟล์ใหม่อีกครั้ง', icon: 'warning' } }));
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionImportExcelSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportCycleSubmissionImportExcel, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  data:`, data);
        yield put(setTableReportISCInfo(data));
        yield put(setModalUploadReportISC({ isOpen: false }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionImportExcelSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
        window.location.reload();
    }
}

function* fetchReportCycleSubmissionGetDetailSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportCycleSubmissionGetDetail, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionGetDetailSaga  data:`, data);
        yield put(setTableReportISCInfoRef(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
        yield put(setLoadingTimeout());

        // Call the second saga with the required payload
        yield* fetchReportISCSubmissionUpdateStatusIntervalSaga({
            payload: {
                submission_id: action.payload.submission_id
            }
        });

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGetDetailSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionGetUploadErrorDetailSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportCycleSubmissionGetUploadErrorDetail, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionGetUploadErrorDetailSaga  data:`, data);
        yield put(setTableReportISCInfoRefError(data));
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionGetUploadErrorDetailSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportISCSubmissionUploadDetailGetAllSheetSaga(action) {
    try {
        yield put(setLoading());
        const payload = {
            "submission_id": action?.payload?.submission_id,
            "upload_id": action?.payload?.upload_id,
        }
        const data = yield call(ReportISCService.apiReportISCSubmissionUploadDetailGetAllSheet, payload);
        yield put(setErrorData({ errorData: data }))
        const firstReportCode = data?.data?.report_codes[0];
        console.log(`----  function*fetchReportISCSubmissionUploadDetailGetAllSheetSaga  firstReportCode:`, firstReportCode);
        yield put(setSelectTab({ isSelectTab: firstReportCode?.sheet_name }))
        console.log(`----  function*fetchReportISCSubmissionUploadDetailGetAllSheetSaga  data:`, data);

        // Call the second saga with the required payload
        yield* fetchReportISCSubmissionUploadDetailGetAllSheetSelectTabSaga({
            payload: {
                upload_id: action?.payload?.upload_id,
                report_code: firstReportCode?.sheet_name || "",
                pagination: action?.payload?.pagination
            }
        });

    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionUploadDetailGetAllSheetSaga  error:`, error);
    }
}

function* fetchReportISCSubmissionUploadDetailGetAllSheetSelectTabSaga(action) {
    try {
        const payload = {
            "upload_id": action?.payload?.upload_id,
            "report_code": action?.payload?.report_code,
            "pagination": {
                "page": action?.payload?.pagination?.page,
                "page_size": action?.payload?.pagination?.page_size,
            }
        }
        const data = yield call(ReportISCService.apiReportISCSubmissionUploadDetailGetSheetDetail, payload);
        console.log(`----  function*fetchReportISCSubmissionUploadDetailGetAllSheetSelectTabSaga  data2:`, data);
        yield put(setTableReportISCInfoRefError(data));
        yield put(setPagination(data.data.pagination))
        yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionUploadDetailGetAllSheetSelectTabSaga  error2:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionDownloadReportFileByIdSaga(action) {
    console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  action:`, action);
    try {

        const response = yield call(ReportISCService.apiReportCycleSubmissionDownloadReportFileById, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  response:`, response);
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  blob:`, blob);
        const fileName = `file.xlsx`
        yield call(saveAs, blob, fileName);

    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionDownloadReportFileByIdSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionSaveByPassSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportISCSubmissionSaveByPass, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionSaveByPassSaga  data:`, data);
        // yield put(setTableReportISCInfoRefError(data));
        // yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionSaveByPassSaga  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}

function* fetchReportCycleSubmissionResubmitSaga(action) {
    try {
        const data = yield call(ReportISCService.apiReportISCSubmissionResubmit, action.payload);
        console.log(`----  function*fetchReportCycleSubmissionResubmit  data:`, data);
        // yield put(setTableReportISCInfoRefError(data));
        // yield put(setCountdown({ countdown: data.data.due_date, countdownTimestamp: data.meta.timestamp }));
    } catch (error) {
        console.log(`----  function*fetchReportCycleSubmissionResubmit  error:`, error);
    } finally {
        yield put(setLoadingTimeout());
    }
}


function* fetchReportISCSubmissionUpdateStatusIntervalSaga(action) {
    try {
        const tableData = yield select(selectTable);
        const configData = yield select(selectConfig);
        const { tableReportISCInfoRef } = tableData;
        const { interval_time } = configData;
        const dataResF = yield call(ReportISCService.apiReportISCSubmissionUpdateStatus, action.payload);
        const dataF = dataResF?.data
        if(dataF.verify_status !== "อนุมัติ" && dataF.verify_status !== "ไม่ผ่าน"){
            while (true) {
                const dataRes = yield call(ReportISCService.apiReportISCSubmissionUpdateStatus, action.payload);
                const data = dataRes?.data
    
                // Update specific fields in upload_items
                const updatedUploadItems = [...tableReportISCInfoRef?.data?.upload_items]?.map(item => {
                    if (item.upload_id === data.upload_id) {
                        return {
                            ...item,
                            report_count: data.report_count,
                            error_count: data.error_count,
                            verify_status: data.verify_status,
                        };
                    }
                    return item;
                });
    
                // Create the updated state
                const updatedTableReportISCInfoRef = {
                    ...tableReportISCInfoRef,
                    data: {
                        ...tableReportISCInfoRef.data,
                        upload_items: updatedUploadItems,
                    },
                };
    
                // Dispatch the updated state
                yield put(setTableReportISCInfoRef(updatedTableReportISCInfoRef));
                if(data.verify_status === "อนุมัติ" || data.verify_status === "ไม่ผ่าน"){
                    window.location.reload();
                    break;
                }
                yield delay(interval_time);
            }
        }
            } catch (error) {
        console.log(`----  function*fetchReportISCSubmissionUpdateStatusIntervalSaga  error:`, error);
    } finally {

        // yield put(setLoading(false));
    }
}

function* watchFetchReportCycleGetList() {
    yield takeLatest(fetchReportCycleGetList.type, fetchReportCycleGetListSaga);
    yield takeLatest(fetchReportCycleGetDetail.type, fetchReportCycleGetDetailSaga);
    yield takeLatest(fetchReportCycleSubmissionCancelRequest.type, fetchReportCycleSubmissionCancelRequestSaga);
    yield takeLatest(fetchReportCycleSubmissionGenerate.type, fetchReportCycleSubmissionGenerateSaga);
    yield takeLatest(fetchReportCycleSubmissionUploadExcel.type, fetchReportCycleSubmissionUploadExcelSaga);
    yield takeLatest(fetchReportCycleSubmissionImportExcel.type, fetchReportCycleSubmissionImportExcelSaga);
    yield takeLatest(fetchReportCycleSubmissionGetDetail.type, fetchReportCycleSubmissionGetDetailSaga);
    yield takeLatest(fetchReportCycleSubmissionGetUploadErrorDetail.type, fetchReportCycleSubmissionGetUploadErrorDetailSaga);
    yield takeLatest(fetchReportCycleSubmissionDownloadReportFileById.type, fetchReportCycleSubmissionDownloadReportFileByIdSaga);
    yield takeLatest(fetchReportISCSubmissionUploadDetailGetAllSheet.type, fetchReportISCSubmissionUploadDetailGetAllSheetSaga);
    yield takeLatest(fetchReportISCSubmissionUploadDetailGetAllSheetSelectTab.type, fetchReportISCSubmissionUploadDetailGetAllSheetSelectTabSaga);
    yield takeLatest(fetchReportISCSubmissionSaveByPass.type, fetchReportCycleSubmissionSaveByPassSaga);
    yield takeLatest(fetchReportISCSubmissionResubmit.type, fetchReportCycleSubmissionResubmitSaga);
    yield takeLatest(fetchReportISCSubmissionUpdateStatusInterval.type, fetchReportISCSubmissionUpdateStatusIntervalSaga);


}

export default function* apiReportISCSagas() {
    yield all([
        watchFetchReportCycleGetList(),
    ]);
}
