import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportCycleSubmissionGetDetailReport } from '../../redux/apiSlice/apiReportISC02Slice';
import { useParams } from 'react-router-dom';

export const useFetchReportISC02InfoRefDetail = () => {
    const dispatch = useDispatch();
    const { pagination } = useSelector(state => state.pagination);
    let { infoId = '', submissionId = '', submissionDetailId = '' } = useParams();
    const {
        tableReportISC02InfoRefDetail,
    } = useSelector(state => state.table);
    const {
        meta = {},
        data = {},
    } = tableReportISC02InfoRefDetail
    const {
        upload_id = null,
        can_save = false,
        cycle_name = '',
        cycle_id = null,
        due_date = '',
        submission_id = null,
        reference_code = '',
        report_items = [],
    } = data
    const {
        page = null,
        pageSize = null,
        total = null,
    } = pagination;

    console.log(`submissionDetailId de`, submissionDetailId)
    const fetchDataTable = () => {
        dispatch(fetchReportCycleSubmissionGetDetailReport(
            {
                "submission_id": submissionId,
                "upload_id": submissionDetailId,
                "pagination": {
                    "page": page,
                    "page_size": pageSize,
                }
            }
        ));
    }

    useEffect(() => {
        fetchDataTable()
    }, [
        // keyword,
        // toDate,
        // fromDate, ,
        // status,
        // page,
        // pageSize,
        // total,
    ]);

    return {
    };
};