import { createSlice } from '@reduxjs/toolkit';

export const initialIsLoading = false
export const initialIsLoadingTimeout = false
export const initialLoadingTimeout = 1500

export const initialState = {
    isLoading: initialIsLoading,
    isLoadingTimeout: initialIsLoadingTimeout,
    loadingTimeout: initialLoadingTimeout,
};

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoadingTime(state, action) {
            const { payload } = action || { payload: { time: initialLoadingTimeout } }
            const { time = initialLoadingTimeout } = payload || { time: initialLoadingTimeout }
            state.loadingTimeout = time;
        },
        setLoading(state, action) {
            state.isLoading = true;
            state.isLoadingTimeout = false;
        },
        setLoadingTimeout(state, action) {
            const { payload } = action || { payload: { time: initialLoadingTimeout } }
            const { time = initialLoadingTimeout } = payload || { time: initialLoadingTimeout }
            state.isLoading = true;
            state.isLoadingTimeout = true;
            state.loadingTimeout = time;
        },
        resetLoading(state, action) {
            state.isLoading = initialIsLoading;
            state.isLoadingTimeout = initialIsLoadingTimeout;
            state.loadingTimeout = initialLoadingTimeout;
        },
    },
});

export const {
    setLoadingTime,
    setLoading,
    setLoadingTimeout,
    resetLoading,
} = loadingSlice.actions;

export const selectLoading = (state) => state.loading;

export default loadingSlice.reducer;
