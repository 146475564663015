import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import SearchCard from '../components/dashboard/CardSearchDashboard';
import TableDashboard from '../components/dashboard/TableDashboard';

const Dashboard = () => {

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <SearchCard />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>
                            <Col style={{ width: "100%", padding: 0, }}>
                                <TableDashboard />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content >
        </Layout >
    );
};

export default Dashboard;
