import controller from "../../api/controller";
import { reportISC02Host } from "../host/reportISC02Host";

const apiReportCycleGetList = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  https://icert-api.devmas.net/api/report-cycle/isc02/get-list
            {
                "company_id": 3,        
                "filter": {
                    "cycle_year": [],
                    "upload_status": [],
                    "approval_status": [],
                },
                "paging": {
                    "page": 1,
                    "page_size": 20,
                }
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCGetList, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleGetDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/ReportCycle/ISC01/get_detail
            // {
            //     "companyId": 3,  
            //     "cycleId": 12,
            //     "filter": {
            //         "keyword": "",
            //         "submissionStatus": [],
            //         "approvalStatus": [],
            //         "fromDate": "",
            //         "toDate": "",
            //     },
            //     "paging": {
            //         "page": 1,
            //         "pageSize": 20,
            //         "total": 0        
            //     }
            // }
            {
                "companyId": 1,
                "cycleId": 1,
                "filter": {
                    "keyword": "",
                    "toDate": null,
                    "fromDate": null,
                    "submissionStatus": [],
                    "approvalStatus": []
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 20,
                    "total": 0
                }
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCGetDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionCancelRequest = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/cancel_request
            {
                "data": {
                    "companyId": 3,
                    "cycleId": 12,
                    "submissionId": 1,
                    "cancelReason": "",
                }
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionCancelEditRequest, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGenerate = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/create
            // {
            //     "companyId": 3,
            //     "cycleId": 12,
            //     "isRequestEdit": false
            //     "editReason": ""
            // }
            {
                "companyId": 1,
                "cycleId": 1,
                "isEditRequest": false
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGenerateRef, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGetSheetData = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc02/get-sheet-data
            {
                "submission_id": 23,
                "file_id": "cee6fd98-bc5f-4cb6-b7df-01ccab43b143",
                "sheet_name": "งบกำไรขาดทุดเบ็ดเสร็จ"
            }
        */
        // const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetSheetData, data);
        const res = await controller.requestPost(
            reportISC02Host.reportISCSubmissionGetSheetData,
            data,
            {
                // baseURL: urlDownload,
                headers: {
                    // "Content-Type": "application/javascript",
                    "Content-Type": "application/json",
                    // 'Content-Type': 'multipart/form-data'
                    // 'Accept': 'multipart/mixed'
                    // "Content-Type": "multipart",
                    // "Content-Type": "multipart/mixed",
                    // "Content-Type": "multipart/form-data",
                    // "Content-Type": 'multipart/mixed; boundary="e6b8c487-b0d5-4515-98ac-b28fa9954134"',
                },
            }
        );
        return res;
        // console.log(`----  apiReportCycleSubmissionGetSheetData  JSON.stringify(res.data):`, JSON.stringify(res.data));
        // console.log(`----  apiReportCycleSubmissionGetSheetData JSON.stringify(data, null, 2):`, JSON.stringify(res.data, null, 2));
        // return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGetSheetFile = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc02/get-sheet-file
            {
                "submission_id": 23,
                "file_id": "cee6fd98-bc5f-4cb6-b7df-01ccab43b143",
                "sheet_name": "งบกำไรขาดทุดเบ็ดเสร็จ"
            }
        */
        // const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetSheetData, data);
        const res = await controller.requestPost(
            reportISC02Host.reportISCSubmissionGetSheetFile,
            data,
            {
                // baseURL: urlDownload,
                headers: {
                    // "Content-Type": "application/javascript",
                    "Content-Type": "application/json",
                    // 'Content-Type': 'multipart/form-data'
                    // 'Accept': 'multipart/mixed'
                    // "Content-Type": "multipart",
                    // "Content-Type": "multipart/mixed",
                    // "Content-Type": "multipart/form-data",
                    // "Content-Type": 'multipart/mixed; boundary="e6b8c487-b0d5-4515-98ac-b28fa9954134"',
                },
            }
        );
        return res;
        // console.log(`----  apiReportCycleSubmissionGetSheetData  JSON.stringify(res.data):`, JSON.stringify(res.data));
        // console.log(`----  apiReportCycleSubmissionGetSheetData JSON.stringify(data, null, 2):`, JSON.stringify(res.data, null, 2));
        // return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionImportExcel = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/report-submission/confirm-import
            {
                "companyId": 3,
                "cycleId": 12,
                "submissionId": 1,
                "fileName": "report_test.xlsx",
                "fileData": binaryData, 
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionImportExcel, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGetDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/get_detail
            {
                "submissionId": 1,
                "companyId": 3,
                "cycleId": 12,
                "filter": {
                    "keyword": "",
                    "verify_status": [],
                    "fromDate": "",
                    "toDate": "",
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 20,
                    "total": 0
                }
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionGetDetailReport = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  https://icert-api.devmas.net/api/report-submission-isc02/get-all-report
            {
                "upload_id": 3,
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetDetailReport, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionGetUploadErrorDetail = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/upload_error_detail
            {
                "companyId": 3,
                "cycleId": 12,
                "submissionId": 1,
                "submissionNum": 2,
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionCancelUploadErrorDetail, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionDownloadReportFileById = async (urlDownload = '') => {
    try {

        // let idOnly = queryId.includes('report-submission') ? queryId.split('=').slice(1).join('=') : queryId
        // console.log(`----  apiReportCycleSubmissionDownloadReportFileById  idOnly:`, idOnly);
        // const res = await controller.requestGet(reportISC02Host.reportISCSubmissionGetFile(queryId), null, { 'Content-Type': 'application/octet-stream' });
        const res = await controller.requestGet(null,
            {
                baseURL: urlDownload,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            }
        );
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionGetAuditDropdown = async (data = {}) => {
    try {
        const res = await controller.requestGet(reportISC02Host.reportISCSubmissionGetAuditDropdown, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportCycleSubmissionCheckAccountant = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/upload_error_detail
            {
                "license_number": "0001"
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionCheckAccountant, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionGetSubmissionHeader = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  http://icert:3000/api/reportSubmission/get-submission-header
            {
                "submission_id": "33"
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetSubmissionHeader, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionSaveReportAuditor = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  {<!-- -->{host}}/api/report-submission-isc02/save-report-Auditor
            {
                "submission_id": 23,
                "upload_id": 3,
                "report_file_id": "b4c4176f-1745-431b`-9a4c-6ac99ce4fe7"
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionSaveReportAuditor, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionSaveReportNote = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  {<!-- -->{host}}/api/report-submission-isc02/save-report-note
            {
                "submission_id": 23,
                "upload_id": 3,
                "report_file_id": "b4c4176f-1745-431b-9a4c-6ac99ce4fe7"
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionSaveReportNote, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionSaveReportNoteYear = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  {<!-- -->{host}}/api/report-submission-isc02/save-report-note
            {
                "submission_id": 86,
                "upload_id": 96,
                "file_record_id": 993,
                "currency_unit": "บาท",
                "compensation_data_type": "ปีอบัติเหตุ",
                "input_data": [
                    {"msd_detail_id" : 71, "value": 20000 },
                    {"msd_detail_id" : 72, "value": 20000 },
                    {"msd_detail_id" : 73, "value": 20000 }
                ]
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionSaveReportNoteYear, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionCreateUpload = async (data = {}) => {
    try {
        /* NOTE - 
            POST:  https://icert-api.devmas.net/api/report-submission-isc02/create-upload
                {
                    "submission_id": 3,
                }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionCreateUpload, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportCycleSubmissionUploadReport = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc02/upload-report
            {
                "submissionId": 1,
                "type": "ReportISC02", // "ReportISC02","ReportNote","AuditorReport"
                "file": file
            }
        */
        const res = await controller.requestPost(
            reportISC02Host.reportISCSubmissionUploadReport,
            data,
            {
                // baseURL: urlDownload,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionCheckDataName = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc02/check-data-name`
            {
                "submission_id": 23,
                "file_id": "d9b70385-5bb0-498d-a197-a76709292d2d",
                "sheet_name": "งบแสดง ฐานะการเงิน",
                "name_range": "A3:A10"
            }

        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionCheckDataName, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionMapError = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc02/map-error`
            {
                "mapping_id": 73,
                "msd_name": "msdName",
                "msd_id": 15, //ดึงจาก msd_names .id
                "new_name": "",
                "sheet_name": "งบการเงิน",
                "file_id": "85af9b4d-902a-4ac9-905f-2ff832c056c1"
                "company_id": 1
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionMapError, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionMapValue = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc02/map-value`
            {
                "submission_id": 23,
                "file_id": "0eec5a15-5cc0-4fc3-9bfd-8d3afc8a0a3e",
                "sheet_name": "งบแสดงฐานะการเงิน_Q",
                "value_range": "D9:D25",
                "prev_value_range": "E9:E25",
                "currency_unit": "บาท"
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionMapValue, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionSaveReport = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  https://icert-api.devmas.net/api/report-submission-isc02/save-report`
            {
                "submission_id": 23,
                "upload_id": 3,
                "report_file_id": "33d5a9d8-630d-4833-9582-899c291c2b76"
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionSaveReport, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionSaveUpload = async (data = {}) => {
    try {
        /* NOTE - 
        POST:  {<!-- -->{host}}/api/report-submission-isc02/save-upload
            {
                "upload_id": 3,
                "submission_id": 23
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionSaveUpload, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionEditReportAuditor = async (data = {}) => {
    try {
        /* NOTE - 
        {<!-- -->{host}}/api/report-submission-isc02/edit-report-auditor
            {
                "submission_id": 23,
                "upload_id": 22,
                "file_record_id": 53
            }
        */
        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionEditReportAuditor, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionSaveDetailNote = async (data = {}) => {
    try {
        /* NOTE - 
        POST: {<!-- -->{host}}/api/report-submission-isc02/report-note/save-section-detail
                {
                    "submission_id": 69,
                    "upload_id": 88,
                    "section_id": 1,
                    "file_record_id": 1026, //ถ้าไม่มีใส่ null
                    "rows": [
                        {
                        "data_name": "แถว1",
                        "details": [
                            {
                            "msd_detail_id": 6,
                            "value": 10
                            },
                            {
                            "msd_detail_id": 7,
                            "value": 20
                         },            
                        ]
                    }
                ]
            }
        */

        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionSaveDetailNote, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const apiReportISCSubmissionGetNoteView = async (data = {}) => {
    try {
        /* NOTE - 
        POST: {<!-- -->{host}}/api/report-submission-isc02/report-note/edit
                {
                    "submission_id": 69,
                    "upload_id": 88,
                    "file_record_id": 970 // เอามาจากหน้ารายการ  ไม่มีส่ง null
                }
        */

        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetDetailNoteView, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};
const apiReportISCSubmissionGetNoteSection = async (data = {}) => {
    try {
        /* NOTE - 
        POST: {<!-- -->{host}}/api/report-submission-isc02/report-note/edit-section
                {
                    "submission_id": 69,
                    "upload_id": 88,
                    "file_record_id": 1026, // ถ้าไม่มีใส่ null
                    "section_id": 1
                }
        */

        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetDetailNoteSection, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};


const apiReportISCSubmissionGetExcelJson = async (data = {}) => {
    try {
        /* NOTE - 
        POST: {<!-- -->{host}}/api/report-submission-isc02/get-excel-json
                {
                    "submission_id": 23,
                    "file_record_id": 53
                }
        */

        const res = await controller.requestPost(reportISC02Host.reportISCSubmissionGetExcelJson, data);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
};

const reportISC02Service = {
    apiReportCycleGetList,
    apiReportCycleGetDetail,
    apiReportCycleSubmissionCancelRequest,
    apiReportCycleSubmissionGenerate,
    apiReportCycleSubmissionImportExcel,
    apiReportCycleSubmissionGetDetail,
    apiReportCycleSubmissionGetDetailReport,
    apiReportCycleSubmissionGetUploadErrorDetail,
    apiReportCycleSubmissionDownloadReportFileById,
    apiReportCycleSubmissionGetSheetData,
    apiReportCycleSubmissionGetSheetFile,
    apiReportISCSubmissionCheckDataName,
    apiReportISCSubmissionMapError,
    apiReportISCSubmissionMapValue,
    apiReportISCSubmissionSaveReport,
    apiReportISCSubmissionEditReportAuditor,
    apiReportISCSubmissionSaveDetailNote,
    apiReportISCSubmissionGetNoteView,
    apiReportISCSubmissionGetNoteSection,
    apiReportISCSubmissionGetExcelJson,

    // form
    apiReportISCSubmissionSaveUpload,
    apiReportCycleSubmissionGetAuditDropdown,
    apiReportCycleSubmissionCheckAccountant,
    apiReportCycleSubmissionGetSubmissionHeader,
    apiReportCycleSubmissionUploadReport,
    apiReportCycleSubmissionSaveReportAuditor,
    apiReportCycleSubmissionCreateUpload,
    apiReportCycleSubmissionSaveReportNote,
    apiReportCycleSubmissionSaveReportNoteYear,
};

export default reportISC02Service;
