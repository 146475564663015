import { Button, Col, Collapse, Image, Row, Typography } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconUser } from '../helper/icon';
import { clearLocalStorageKey } from '../helper/storage';


const { Text } = Typography;
const { Panel } = Collapse;

const CardUser = () => {
    const userInfo = useSelector((state) => state.user.userInfo);
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const handleMouseEnter = () => {
        setTimeout(() => {
            setActive(true)
        }, 200);
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            setActive(false)
        }, 200);
    };

    const serviceLogout = async () => {
        try {
            let errors = {};
            // ส่งค่า username และ password ไปยัง API เพื่อตรวจสอบการ login
            const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/Auth/logout', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify()
            });

            // ตรวจสอบว่าการล็อกอินสำเร็จหรือไม่
            if (response.ok) {
                const data = await response.json();
                console.log('Logout successful:', data);
            } else {
                // หากไม่สำเร็จ แสดงข้อความแจ้งเตือน
                console.error('Logout failed:', response.statusText);
                errors.isError = true;
                errors.password = 'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง';
                //errors.password = response.statusText;
            }
            return errors;
        } catch (error) {
            console.log(`error:`, error);
        }
    }

    const signOutUser = async () => {

        const userInfo = localStorage.getItem('userInfo');
        /* console.log(`userInfoLocal :`,userInfo);
        let userInfo = {};
        if (userInfoLocal) {
            userInfo = JSON.parse(userInfoLocal);
        } */

        await serviceLogout();

        /*NOTE -  clearLocalStorage
            persist:iCert
            token
        */
        clearLocalStorageKey('persist:iCert')
        clearLocalStorageKey('token')
        clearLocalStorageKey('userInfo');
        clearLocalStorageKey('pageStack');

    }

    return (
        <Row
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                minWidth: 'var(--width-card-user)',
                height: 'var(--header-height)',
                zIndex: 999,
            }}
        >

            <Collapse
                className='collapse-custom-card-user'
                expandIcon={() => null}
                accordion
                activeKey={active ? 'card-user-profile' : 'card-user-profile-in-active'}
                style={{
                    position: 'absolute',
                    border: 0,
                    top: 'calc(100% - (50% + (56px/2)))',
                    left: 0,
                    right: 0,
                    background: 'transparent',
                }}
            >
                <Panel
                    key="card-user-profile"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    header={
                        <Row gutter={[4]} justify={'space-between'} align={'middle'} wrap={false}>
                            <Col>
                                <Image
                                    width={30}
                                    src={IconUser}
                                    preview={false}
                                    wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                />
                            </Col>
                            <Col flex={1} style={{ maxWidth: 'var(--width-card-user-label)' }}>
                                <Text strong ellipsis={true} style={{ fontSize: "20px", lineHeight: '24px' }}>{userInfo.first_name} {userInfo.last_name}</Text>
                                <Text ellipsis={true} style={{ fontSize: "12px", lineHeight: '18px' }}>เจ้าหน้าที่บริษัทประกันภัย</Text>
                            </Col>
                        </Row>
                    }
                >
                    <Button
                        type="text"
                        style={{
                            width: '100%',
                            textAlign: 'start',
                            borderBottom: '1px solid var(--color-disable)',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                        onClick={() => {

                        }}
                    >
                        แก้ไขข้อมูลส่วนตัว
                    </Button>
                    <Button
                        type="text"
                        style={{
                            width: '100%',
                            textAlign: 'start',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                        onClick={() => {

                        }}
                    >
                        Credentials
                    </Button>
                    <Button
                        type="text"
                        style={{
                            width: '100%',
                            textAlign: 'start',
                            borderBottom: '1px solid var(--color-disable)',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                        onClick={() => {

                        }}
                    >
                        API Keys
                    </Button>
                    <Button
                        type="text"
                        style={{
                            width: '100%',
                            textAlign: 'start',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                        onClick={() => {

                            signOutUser();
                            setTimeout(() => {
                                navigate('/login');
                            }, 1000);

                        }}
                    >
                        Log out
                    </Button>

                </Panel>
            </Collapse>
        </Row >
    );
};

export default CardUser;
