// src/components/Footer.js
import React from 'react';
import ICert from '../image/icert.png';
import PlaceIcon from "@mui/icons-material/Place";
import CallIcon from '@mui/icons-material/Call';
const Footer = () => {
    return (
        <>
            <footer className="hidden md:block bg-white">
                <div className="w-[auto] h-[3px] bg-sky-950 rounded-[1px]" />
                <div className="mx-auto w-full max-w-screen-xl px-4 pt-4 pb-2">
                    <div className="md:flex md:justify-center">
                        <div className="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3">
                            <div>
                                <ul className="text-dark font-medium hidden md:block">
                                    <li className=".mb-4">
                                        <img
                                            alt=""
                                            className="w-[272px] h-[85px] left-[503px] top-[34px] "
                                            src={ICert}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p className="font-kanit text-[16px] text-gray-500 ">
                                    <div class="flex">
                                        <div class="flex-none">
                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.74062 15.6405C8.34375 13.629 12 8.7539 12 6.01557C12 2.69447 9.3125 0 6 0C2.6875 0 0 2.69447 0 6.01557C0 8.7539 3.65625 13.629 5.25938 15.6405C5.64375 16.1198 6.35625 16.1198 6.74062 15.6405ZM6 4.01038C6.53043 4.01038 7.03914 4.22164 7.41421 4.59768C7.78929 4.97373 8 5.48376 8 6.01557C8 6.54738 7.78929 7.0574 7.41421 7.43345C7.03914 7.8095 6.53043 8.02076 6 8.02076C5.46957 8.02076 4.96086 7.8095 4.58579 7.43345C4.21071 7.0574 4 6.54738 4 6.01557C4 5.48376 4.21071 4.97373 4.58579 4.59768C4.96086 4.22164 5.46957 4.01038 6 4.01038Z" fill="#012D5E" />
                                            </svg>
                                        </div>
                                        <div class="grow ms-[13px]">
                                            <span className='-m-0'>สำนักงานคณะกรรมการกำกับและส่งเสริม</span>
                                        </div>
                                    </div>
                                    <span className='ms-[25px]'>
                                        การประกอบธุรกิจประกันภัย (คปภ.)<br />
                                    </span>
                                    <span className='ms-[25px] text-[#3C4142]'>
                                        22/79 ถนนรัชดาภิเษก แขวงจันทรเกษม
                                        <br />
                                        <span className='ms-[25px]'>
                                            เขตจตุจักร กรุงเทพมหานคร 10900
                                        </span>
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p className="font-kanit text-[16px] text-gray-500">
                                    <div class="flex">
                                        <div class="flex-none">
                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.23438 6.84615C8.23438 7.04448 8.39375 7.20031 8.58854 7.20031C8.99229 7.20031 9.31813 7.52969 9.31813 7.9299C9.31813 8.12469 9.4775 8.28406 9.67229 8.28406C9.86708 8.28406 10.0265 8.12469 10.0265 7.9299C10.0265 7.13656 9.38187 6.49198 8.58854 6.49198C8.39375 6.49198 8.23438 6.65135 8.23438 6.84615ZM14.0002 8.28406C14.1985 8.28406 14.3544 8.12469 14.3544 7.9299C14.3544 4.74948 11.769 2.16406 8.58854 2.16406C8.39375 2.16406 8.23438 2.32344 8.23438 2.51823C8.23438 2.71302 8.39375 2.8724 8.58854 2.8724C11.3794 2.8724 13.646 5.1426 13.646 7.9299C13.646 8.12469 13.8054 8.28406 14.0002 8.28406Z" fill="#012D5E" />
                                                <path d="M11.4816 7.92979C11.4816 8.12458 11.641 8.28396 11.8358 8.28396C12.0306 8.28396 12.19 8.12458 12.19 7.92979C12.19 5.94292 10.575 4.32792 8.58809 4.32792C8.3933 4.32792 8.23392 4.48729 8.23392 4.68208C8.23392 4.88042 8.3933 5.03625 8.58809 5.03625C10.1854 5.03625 11.4816 6.33604 11.4816 7.92979ZM8.58809 0C8.3933 0 8.23392 0.159375 8.23392 0.354167C8.23392 0.548958 8.3933 0.708333 8.58809 0.708333C12.5725 0.708333 15.8131 3.94896 15.8131 7.92979C15.8131 8.12458 15.9689 8.28396 16.1673 8.28396C16.362 8.28396 16.5214 8.12458 16.5214 7.92979C16.5214 3.55583 12.962 0 8.58809 0ZM12.0483 11.6627L11.5843 11.9142C10.8016 12.3427 9.80996 12.1975 9.17601 11.5635L5.43601 7.82354C4.80205 7.18958 4.65684 6.19792 5.08538 5.41167L5.33684 4.95125C5.62017 4.42708 5.72288 3.83917 5.63788 3.25125C5.55759 2.66452 5.28624 2.12058 4.8658 1.70354C4.34871 1.18646 3.66163 0.903125 2.92851 0.903125C2.19538 0.903125 1.50476 1.18646 0.987673 1.70354L0.807048 1.88771C-0.149202 2.84396 -0.258993 4.49083 0.495382 6.53083C1.20726 8.47875 2.64163 10.5896 4.5258 12.4738C7.39809 15.346 10.5962 17 12.8912 17C13.7979 17 14.5664 16.7415 15.1118 16.196L15.296 16.0119C15.551 15.7571 15.7533 15.4546 15.8913 15.1215C16.0292 14.7885 16.1002 14.4315 16.1 14.071C16.095 13.3451 15.8065 12.6499 15.296 12.1337C14.4425 11.2802 13.1073 11.089 12.0483 11.6627Z" fill="#012D5E" />
                                            </svg>
                                        </div>
                                        <div class="grow ms-[8px]">
                                            <span>โทรศัพท์ <span className='ms-[58px] text-[#3C4142]'>0-2515-3999</span></span><br />
                                        </div>
                                    </div>
                                    <span className='ms-[25px]'>
                                        สายด่วน คปภ. <span className='ms-[22px] text-[#3C4142]'>1186</span><br />
                                    </span>
                                    <span className='ms-[25px]'>
                                        อีเมล&nbsp;&nbsp;&nbsp;<span className='ms-[73px] text-[#3C4142]'>icert.support@oic.or.th</span><br />
                                    </span>
                                    <span className='ms-[25px]'>
                                        เวลาทำการ&nbsp;&nbsp;&nbsp;<span className='ms-[33px] text-[#3C4142]'>วันจันทร์-วันศุกร์ เวลา 8.30-16.30 น.</span><br />
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="mt-2 text-[12px] text-center text-gray-400">สงวนลิขสิทธิ์ พ.ศ.2566 สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.) © 2023 Office of Insurance Commission Department. All rights Reserved</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;
