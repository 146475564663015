/* -------------- LocalStorage -------------- */
// ฟังก์ชั่นเพื่อเก็บข้อมูลใน localStorage
const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

// ฟังก์ชั่นเพื่อดึงข้อมูลจาก localStorage
const getLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
};

// ฟังก์ชั่นเพื่อเก็บข้อมูลใน localStorage พร้อมกำหนดเวลาหมดอายุ
const setLocalStorageExpired = (key, value, expirationInMinutes) => {
    const expirationTime = new Date().getTime() + expirationInMinutes * 60000; // หน่วยเป็น millisecond
    localStorage.setItem(key, JSON.stringify({ value, expirationTime }));
};

// ฟังก์ชั่นเพื่อดึงข้อมูลจาก localStorage โดยพิจารณาเวลาหมดอายุ
const getLocalStorageExpired = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date().getTime();

    if (now > item.expirationTime) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
};

// ฟังก์ชันสำหรับเคลียร์ข้อมูลใน Local Storage
const clearLocalStorage = () => {
    localStorage.clear();
};

const clearLocalStorageKey = (key) => {
    localStorage.removeItem(key);
};

/* -------------- LocalStorage -------------- */




/* -------------- Cookie -------------- */

// ฟังก์ชั่นเพื่อเก็บข้อมูลใน cookie
const setCookie = (key, value, expirationDays) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    document.cookie = `${key}=${JSON.stringify(value)}; expires=${expirationDate.toUTCString()}; path=/`;
};

// ฟังก์ชั่นเพื่อดึงข้อมูลจาก cookie
const getCookie = (key) => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith(`${key}=`));
    return cookie ? JSON.parse(cookie.split('=')[1]) : null;
};

// ฟังก์ชั่นเพื่อเก็บข้อมูลใน cookie พร้อมกำหนดเวลาหมดอายุ
const setCookieExpired = (key, value, expirationDays) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    document.cookie = `${key}=${JSON.stringify(value)}; expires=${expirationDate.toUTCString()}; path=/`;
};

// ฟังก์ชั่นเพื่อดึงข้อมูลจาก cookie โดยพิจารณาเวลาหมดอายุ
const getCookieExpired = (key) => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith(`${key}=`));
    if (!cookie) return null;

    const cookieValue = JSON.parse(cookie.split('=')[1]);
    return cookieValue;
};

const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
};

/* -------------- Cookie -------------- */



export {
    setLocalStorage,
    getLocalStorage,
    setLocalStorageExpired,
    getLocalStorageExpired,

    clearLocalStorage,
    clearLocalStorageKey,

    getCookie,
    setCookie,
    getCookieExpired,
    setCookieExpired,

    clearCookies,
}
