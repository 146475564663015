import { Breadcrumb as BreadcrumbAnt } from 'antd';
import React from 'react';

const Breadcrumb = ({ items = [] }) => {

    const breadcrumbList = [...items].map((e, index) => {
        return ({
            title: e.link ?
                <a
                    key={`breadcrumb-${e.title}`}
                    href={e.link}
                    style={{
                        color: items?.length === 1 ? 'var(--color-black) !important' : '',
                    }}
                >
                    {e.title}
                </a>
                :
                <div key={`breadcrumb-${e.title}`}>
                    {e.title}
                </div>
        })
    })

    return (
        <BreadcrumbAnt items={breadcrumbList} />
    );
};

export default Breadcrumb;
