// src/components/ReportISCInfoRef.js
import { Button, Col, Flex, Image, Layout, Row, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import ModalUploadReportISC from '../../components/reportISC/modal/ModalUploadReportISC';
import CardSearchReportISCInfoRef from '../../components/reportISC/reportISCInfoRef/CardSearchReportISCInfoRef';
import TableReportISCInfoRef from '../../components/reportISC/reportISCInfoRef/TableReportISCInfoRef';
import { setModalUploadReportISC, setModalUploadReportISCViewDetail } from '../../features/redux/slice/modalSlice';
import { setTableReportISCInfoRef } from '../../features/redux/slice/tableSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { IconFileExcelWhite } from '../../helper/icon';
import { mockTableReportISC01InfoRef } from '../../mock/mockTableReportISC01InfoRef';
import { downloadFile, downloadXLSFileTemplateISC } from '../../helper/downloadFile';
import { useFetchReportISCInfoRef } from '../../features/hooks/reportISC/useFetchReportISCInfoRef';
import { initialStatePagination } from '../../features/redux/slice/paginationSlice';
import { reportISCHost } from '../../server/service/host/reportISCHost';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import ModalUploadReportISCViewDetail from '../../components/reportISC/modal/ModalUploadReportISCViewDetail';

const ReportISCInfoRef = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISCInfoRef();

    const {
        companyInfo,
    } = useSelector(state => state.user);
    const {
        tableReportISCInfoRef,
    } = useSelector(state => state.table);
    const {
        company_id = null,
    } = companyInfo
    const {
        meta = {},
        data = {},
    } = tableReportISCInfoRef
    const {
        submission_id = '',
        reference_code = null,
        // company_id = null,
        cycle_name = '',
        cycle_id = null,
        can_import = false,
        start_date = '',
        current_date = '',
        due_date = '',
        template_url = '',
        template_name = '',
        upload_items = [],
        verify_status_list = [],
        pagination = initialStatePagination,
    } = data

    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC = cycle_name || infoId
    const refReportISC = reference_code || submissionId

    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-01` },
        { title: `แบบรายงานเกี่ยวกับฐานะการเงินและกิจการ ${stringDefault(nameReportISC, '-')}`, link: reportISCHost.reportISCInfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC, '-')}`, link: `` }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
    ]

    const onNavigate = (record) => {
        console.log(`----  onClickViewMore  record:`, record);
        dispatch(setLoadingTimeout());
        let url = `/report-isc-01/info/${stringDefault(record.cycle_id || infoId, '-')}/submission/${stringDefault(record.submission_id || submissionId, '-')}/upload/${stringDefault(record.upload_id, '-')}`
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    };

    const handleDownload = (record, url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url // 'https://icert-api.devmas.net/api/report-submission/get-file?report_file_id=383252b4-d996-4046-9393-53b869845af0&submission_id=247'
        // let idOnly = url.includes('report-submission') ? url.split('=').slice(1).join('=') : url
        // const id = `a807aa07-919e-450c-ae1f-e4532ded1736`;
        // const urlLoad = `report_file_id=${id}&submission_id=247`;
        if (URL) {
            // dispatch(fetchReportCycleSubmissionDownloadReportFileById(URL)); //not work
            downloadFile(URL, fileName) //work
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    const onClickInfo = (record) => {
        console.log(`----  onClickInfo  record:`, record);
        dispatch(setModalUploadReportISCViewDetail({ isOpen: true, value: record }));
    }
    const onClickFileExcel = (record) => {
        console.log(`----  onClickFileExcel  record:`, record);
        const { report_url = '', report_name = '' } = record
        handleDownload(record, report_url, report_name)
    }
    const onClickFileExcelErr = (record) => {
        console.log(`----  onClickFileExcelErr  record:`, record);
        const { error_excel_url = '', error_excel_name = '' } = record
        handleDownload(record, error_excel_url, error_excel_name)
    }
    const onClickSearch = (record) => {
        console.log(`----  onClickSearch  record:`, record);
        onNavigate(record)
    }



    const handleOpenModalUploadReportISC = () => {
        setTimeout(() => {
            dispatch(setModalUploadReportISC({ isOpen: true, }));
        }, 1000);
    };


    useEffect(() => {
        // dispatch(setTableReportISCInfoRef(mockTableReportISC01InfoRef));
        return () => {
        }
    }, []);


    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <ModalUploadReportISC />
            <ModalUploadReportISCViewDetail />
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISCInfoRef nameReportISC={nameReportISC} refReportISC={refReportISC} />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>

                            <Col style={{ width: "100%", margin: 0 }}>
                                <Row justify="space-between" align="bottom">
                                    <Typography.Text strong>{`กำหนดส่ง ${dateline}`}</Typography.Text>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle" style={{ width: "100%", margin: 0 }}>
                                            <Col style={{ paddingLeft: 0 }}>
                                                <Button
                                                    type="primary"
                                                    icon={
                                                        <Image width={20} src={IconFileExcelWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                                    }
                                                    style={{ background: 'var(--color-excel)', display: 'flex', }}
                                                    onClick={() => {
                                                        handleDownload(null, template_url, template_name)
                                                    }}
                                                >
                                                    <Flex>
                                                        Export Template
                                                    </Flex>
                                                </Button>
                                            </Col>
                                            <Button
                                                disabled={!can_import}
                                                type="primary"
                                                className='importAction'
                                                icon={
                                                    <Image width={20} src={IconFileExcelWhite} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                                }
                                                style={{ background: can_import ? 'var(--color-excel)' : 'var(--color-gray-primary)', display: 'flex', paddingLeft: '2rem', paddingRight: '2rem' }}
                                                onClick={handleOpenModalUploadReportISC}
                                            >
                                                Import
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col style={{ width: "100%", }}>
                                <TableReportISCInfoRef
                                    dataList={upload_items}
                                    pagination={pagination}
                                    onClickInfo={onClickInfo}
                                    onClickFileExcel={onClickFileExcel}
                                    onClickFileExcelErr={onClickFileExcelErr}
                                    onClickSearch={onClickSearch}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISCInfoRef;