// src/components/NotFoundBase.js
import { NotificationOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import React from 'react';

const NotFoundBase = () => {
    return (
        <Content style={{ height: 'inherit', alignContent: 'center', }}>
            <Row justify={'center'} align={'middle'}>
                <NotificationOutlined style={{ fontSize: '20vh', color: 'var(--color-primary)' }} />
                <Title style={{ textAlign: 'center' }}> 404 - Not Found</Title>
            </Row>
            <Row justify={'center'} align={'middle'}>
                <Title level={2} style={{ textAlign: 'center' }}>The page you are looking for does not exist.</Title>
            </Row>
        </Content>
    );
};

export default NotFoundBase;
