import { createSlice } from '@reduxjs/toolkit';

export const initialStateCountdown = '' //duedate
export const initialStateCountdownTimestamp = '' //new date

export const initialState = {
    countdown: initialStateCountdown, //duedate
    countdownTimestamp: initialStateCountdownTimestamp, //new date
};

export const countdownSlice = createSlice({
    name: 'countdown',
    initialState,
    reducers: {
        setCountdownField: (state, action) => { // dispatch(setCountdownField({ field: 'countdown', data: new Data() }));
            const { field, data } = action.payload;
            state[field] = data;
        },
        setCountdown: (state, action) => { // dispatch(setCountdown({ countdown:countdown, countdownTimestamp:countdownTimestamp }));
            const { countdown = '', countdownTimestamp = '' } = action.payload;
            state.countdown = countdown;
            state.countdownTimestamp = countdownTimestamp;
        },
    },
});

export const {
    setCountdownField,
    setCountdown,
} = countdownSlice.actions;

export const selectCountdown = (state) => state.countdown;

export default countdownSlice.reducer;
