import { createSlice } from '@reduxjs/toolkit';

export const initialStateMenuSidebar = [];
export const initialStateCollapsed = false;
export const initialStateActiveDefault = ['1'];
export const initialStateOpenDefault = ['1'];

export const initialState = {
    menuSidebar: initialStateMenuSidebar,
    collapsed: initialStateCollapsed,
    activeKey: initialStateActiveDefault,
    openKeys: initialStateOpenDefault,
};


const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setMenuSidebar: (state, action) => { // dispatch(setMenuSidebar([]));
            const { menuSidebar = initialStateMenuSidebar } = action.payload
            state.menuSidebar = menuSidebar;
        },
        setActiveKey: (state, action) => { // dispatch(setActiveKey([]));
            const { payload = initialStateActiveDefault } = action
            state.activeKey = payload;
        },
        setOpenKey: (state, action) => { // dispatch(setActiveKey([]));
            const { payload = initialStateOpenDefault } = action
            state.openKeys = payload;
        },
        setOptionSidebar: (state, action) => { // dispatch(setActiveKey([]));
            /* 
            const option = {
                openKeys: ['1'],
                activeKey: ['1'],
            };
            dispatch(setOptionSidebar(option));
            */
            const { openKeys = initialStateOpenDefault, activeKey = initialStateActiveDefault } = action.payload
            state.openKeys = openKeys;
            state.activeKey = activeKey;
        },
        setCollapsedSidebar: (state, action) => { // dispatch(setCollapsedSidebar(false));
            state.collapsed = !state.collapsed;
        },
        setCollapsedSidebarDefault: (state, action) => { // dispatch(setCollapsedSidebarDefault(false));
            state.collapsed = initialStateCollapsed;
        },
    },
});

export const {
    setMenuSidebar,
    setActiveKey,
    setOpenKey,
    setOptionSidebar,
    setCollapsedSidebarDefault,
    setCollapsedSidebar,
} = sidebarSlice.actions;

export const selectSidebar = (state) => state.sidebar;

export default sidebarSlice.reducer;
