import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportCycleSubmissionGetDetail } from '../../redux/apiSlice/apiReportISCSlice';
import { useParams } from 'react-router-dom';

export const useFetchReportISCInfoRef = () => {
    const dispatch = useDispatch();
    const { searchReportISCInfoRef } = useSelector(state => state.search);
    const { companyInfo } = useSelector(state => state.user);
    const { pagination } = useSelector(state => state.pagination);

    let { infoId = '', submissionId = '' } = useParams();

    const {
        keyword = '',
        toDate = null,
        fromDate = null,
        verifyStatus = [],
    } = searchReportISCInfoRef;
    const {
        company_id = null,
        thai_name = '',
        short_name = '',
        full_name_eng = '',
        // status = '',
        domain_name = '',
    } = companyInfo;

    const {
        tableReportISCInfoRef,
    } = useSelector(state => state.table);

    const {
        meta = {},
        data = {},
    } = tableReportISCInfoRef

    const {
        submission_id = '',
        reference_code = null,
        // company_id = null,
        cycle_name = '',
        cycle_id = null,
        is_edit_request = false,
        start_date = '',
        current_date = '',
        due_date = '',
        template_url = '',
        upload_items = [],
        verify_status_list = [],
    } = data
    const {
        page = null,
        pageSize = [],
        total = [],
    } = pagination;

    const fetchDataTable = () => {
        dispatch(fetchReportCycleSubmissionGetDetail(
            {
                "company_id": company_id,
                "cycle_id": infoId,
                "submission_id": submissionId,
                "filter": {
                    "keyword": keyword,
                    "to_date": toDate,
                    "from_date": fromDate,
                    "verify_status": verifyStatus,
                },
                "pagination": {
                    "page": page,
                    "page_size": pageSize,
                    "total": total,
                }
            }
        ));
    }

    useEffect(() => {
        fetchDataTable()
    }, [
        keyword,
        toDate,
        fromDate,
        verifyStatus,
        page,
        // pageSize,
        // total,
    ]);

    return {
    };
};