import { createSlice } from '@reduxjs/toolkit';

export const initialAuditDropdown = []
export const initialCheckInformation = {}
export const initialFormData = {}
export const initialEditReportAuditor = {}
export const initialCheckAccountant = {
    license_number: '',
};
export const initialisFormValid = {
    isFormValid: true,
};
export const initialFormDetailAuditorISC02 = {
    name_thai: '',
    license_number: '',
    full_name: '',
    licensed_by_gaap: '',
    comment_date: '',
    auditor_comment: '',
    notes: '',
    // file_record_id: '',
}

export const initialState = {
    auditDropdown: initialAuditDropdown,
    checkInformation: initialCheckInformation,
    formData: initialFormData,
    editReportAuditor: initialEditReportAuditor,
    formDetailAuditorISC02: initialFormDetailAuditorISC02,
    isFormValidISC03: initialisFormValid,
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setEditReportAuditor(state, action) {
            const { payload = initialEditReportAuditor } = action;
            state.editReportAuditor = {
                ...payload,
            };
        },
        setAuditDropdown(state, action) {
            const { payload = initialAuditDropdown } = action;
            // const { data = initialAuditDropdown} = payload;
            state.auditDropdown = {
                ...payload,
            };
        },
        setCheckInformation: (state, action) => {
            state.checkInformation = action.payload;
        },
        setIsFormValidISC03(state, action) {
            state.isFormValidISC03 = action.payload;
        },
        resetCheckInformation: (state, action) => {
            state.checkInformation = initialCheckInformation;
        },
        setFormData: (state, action) => {
            state.formData = action.payload;
        },
        resetFormData: (state, action) => {
            state.formData = initialFormData;
        },
        setFormDetailAuditorISC02: (state, action) => {
            state.formDetailAuditorISC02 = {
                ...state.formDetailAuditorISC02,
                ...action.payload
            };
        },
        resetFormDetailAuditorISC02: (state, action) => {
            state.formDetailAuditorISC02 = initialFormDetailAuditorISC02;
        },
    },
});

export const {
    setEditReportAuditor,
    setAuditDropdown,
    setCheckAccountant,
    setCheckInformation,
    setFormData,
    setFormDetailAuditorISC02,
    setIsFormValidISC03,
    resetFormData,
    resetFormDetailAuditorISC02,
    resetCheckAccountant,
    resetCheckInformation,
} = formSlice.actions;

export const selectForm = (state) => state.form;

export default formSlice.reducer;