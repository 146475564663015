import controller from '../../api/controller';
import { masterDataHost } from "../host/masterDataHost";

const getCompanies = async (filter = { keyword: null, companyTypeId: null }, itemPerPage = 20, pageNo = 1) => {
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/msd-companies
         * {
         *     "keyword": string?,
         *     "companyTypeId": int?,
         * }
         */

        const params = new URLSearchParams({ itemPerPage, pageNo });
        if (filter && filter.keyword) {
            params.set('keyword', filter.keyword)
        }
        if (filter && filter.companyTypeId) {
            params.set('companyTypeId', filter.companyTypeId)
        }

        const res = await controller.requestGet(`${masterDataHost.msdCompanyBase}?${params.toString()}`);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const exportCompanies = async (filter = { keyword: null, companyTypeId: null }) => {
    // 
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/msd-companies/export
         * {
         *     "keyword": string?,
         *     "companyTypeId": int?,
         * }
         */

        const params = new URLSearchParams();
        if (filter && filter.keyword) {
            params.set('keyword', filter.keyword)
        }
        if (filter && filter.companyTypeId) {
            params.set('companyTypeId', filter.companyTypeId)
        }

        const res = await controller.requestGet(`${masterDataHost.msdCompanyExportBase}?${params.toString()}`, {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/octet-stream",
            },
        });

        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const masterDataService = { getCompanies, exportCompanies };

export default masterDataService;