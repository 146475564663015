import { indexHost } from ".";

export const reportISC02Host = {
    base: indexHost.base,

    /* -------------------------------- Sprint2 ------------------------------- */
    /* Web Report-02 ISC */
    reportISC02Base: `/report-isc-02`,
    reportISC02InfoBase: `/report-isc-02/info/:infoId`,
    reportISC02SubmissionBase: `/report-isc-02/info/:infoId/submission/:submissionId`,
    reportISC02SubmissionDetailBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId`,
    // reportISC02SubmissionDetailReportBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-report`,
    // reportISC02SubmissionDetailNoteBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-note`,
    // reportISC02SubmissionDetailAuditorBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-auditor`,
    // reportISC02SubmissionDetailNoteBaseView: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-note/view-report`,
    reportISC02SubmissionDetailReportBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-report/:fileRecordId`,
    reportISC02SubmissionDetailNoteBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-note/:fileRecordId`,
    reportISC02SubmissionDetailAuditorBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-auditor/:fileRecordId`,
    reportISC02SubmissionDetailNoteBaseView: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-note/:fileRecordId/view-report`,
    // reportISC02SubmissionDetailReportBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-report/:submissionReportId`,
    // reportISC02SubmissionDetailNoteBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-note/:submissionNoteId`,
    // reportISC02SubmissionDetailAuditorBase: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-auditor/:submissionAuditorId`,
    // reportISC02SubmissionDetailNoteBaseView: `/report-isc-02/info/:infoId/submission/:submissionId/submission-detail/:submissionDetailId/submission-note/view-report/:submissionAuditorId`,

    reportISC02InfoByIdBase: (infoId) => `/report-isc-02/info/${infoId}`,
    reportISC02SubmissionById: (submissionId = '-') => `/submission/${submissionId}`,
    reportISC02SubmissionByIdBase: (infoId, submissionId) => `/report-isc-02/info/${infoId}/submission/${submissionId}`,
    reportISC02SubmissionDetailByIdBase: (infoId, submissionId, submissionDetailId,) => `/report-isc-02/info/${infoId}/submission/${submissionId}/submission-detail/${submissionDetailId}`,
    reportISC02SubmissionDetailReportByTypeBase: (infoId, submissionId, submissionDetailId, type,) => `/report-isc-02/info/${infoId}/submission/${submissionId}/submission-detail/${submissionDetailId}/${type}`,
    reportISC02SubmissionDetailReportByIdBase: (infoId, submissionId, submissionDetailId, submissionReportId,) => `/report-isc-02/info/${infoId}/submission/${submissionId}/submission-detail/${submissionDetailId}/submission-report/${submissionReportId}`,
    reportISC02SubmissionDetailNoteByIdBase: (infoId, submissionId, submissionDetailId, submissionNoteId,) => `/report-isc-02/info/${infoId}/submission/${submissionId}/submission-detail/${submissionDetailId}/submission-note/${submissionNoteId}`,
    reportISC02SubmissionDetailAuditorByIdBase: (infoId, submissionId, submissionDetailId, submissionAuditorId) => `/report-isc-02/info/${infoId}/submission/${submissionId}/submission-detail/${submissionDetailId}/submission-auditor/${submissionAuditorId}`,
    reportISC02SubmissionDetailNoteViewByIdBase: (infoId, submissionId, submissionDetailId, submissionAuditorId) => `/report-isc-02/info/${infoId}/submission/${submissionId}/submission-detail/${submissionDetailId}/submission-auditor/${submissionAuditorId}`,
    /* Web Report-02 ISC */

    /* Api Report-02 ISC */
    reportISCGetList: `/report-cycle/isc02/get-list`,
    reportISCGetDetail: `/report-cycle/isc02/get-detail`,

    reportISCSubmissionGenerateRef: `/report-submission-isc02/create`,
    reportISCSubmissionGetDetail: `/report-submission-isc02/get-detail`,
    reportISCSubmissionGetDetailReport: `/report-submission-isc02/get-all-report`,
    reportISCSubmissionUploadExcel: `/report-submission-isc02/upload`,
    reportISCSubmissionImportExcel: `/report-submission-isc02/confirm-import`,
    reportISCSubmissionCancelEditRequest: `/report-submission-isc02/cancel-edit-request`,
    reportISCSubmissionCancelUploadErrorDetail: `/report-submission-isc02/upload_error_detail`,
    reportISCSubmissionGetDetailNoteView: `/report-submission-isc02/report-note/edit`,
    reportISCSubmissionGetDetailNoteSection: `/report-submission-isc02/report-note/edit-section`,
    reportISCSubmissionSaveDetailNote: `/report-submission-isc02/report-note/save-section-detail`,
    reportISCSubmissionGetExcelJson: `/report-submission-isc02/get-excel-json`,

    // form api
    reportISCSubmissionGetAuditDropdown: `/report-submission-isc02/get-audit-dropdown`,
    reportISCSubmissionGetSubmissionHeader: `/report-submission-isc02/get-submission-header`,
    reportISCSubmissionCheckAccountant: `/report-submission-isc02/check-accountant`,
    reportISCSubmissionUploadReport: `/report-submission-isc02/upload-report`,
    reportISCSubmissionSaveReportAuditor: `/report-submission-isc02/save-report-auditor`,
    reportISCSubmissionSaveReportNote: `/report-submission-isc02/save-report-note`,
    reportISCSubmissionSaveReportNoteYear: `/report-submission-isc02/save-report-note-year`,
    reportISCSubmissionCreateUpload: `/report-submission-isc02/create-upload`,
    reportISCSubmissionSaveUpload: `/report-submission-isc02/save-upload`,

    reportISCSubmissionGetSheetData: `/report-submission-isc02/get-sheet-data`,
    reportISCSubmissionGetSheetFile: `/report-submission-isc02/get-sheet-file`,
    reportISCSubmissionCheckDataName: `/report-submission-isc02/check-data-name`,
    reportISCSubmissionMapError: `/report-submission-isc02/map-error`,
    reportISCSubmissionMapValue: `/report-submission-isc02/map-value`,
    reportISCSubmissionSaveReport: `/report-submission-isc02/save-report`,
    reportISCSubmissionEditReportAuditor: `/report-submission-isc02/edit-report-auditor`,
    /* Api Report-02 ISC */
    /* ------------------------------------------------------------------------- */


    /* -------------------------------- Sprint3 ------------------------------- */
    /* Web Report-03 ISC */
    /* Web Report-03 ISC */

    /* Api Report-03 ISC */
    /* Api Report-03 ISC */
    /* ------------------------------------------------------------------------- */
}