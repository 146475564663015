import { ConfigProvider } from 'antd';
import th from 'antd/es/date-picker/locale/th_TH';
import thTH from 'antd/locale/th_TH';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';

import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import BaseAll from './components/BaseAll';
import Loading from './components/Loading';
import MainLayout from './components/MainLayout';
import PrivateRoute from './components/PrivateRoute.js';
import ProtectedRoute from './components/ProtectedRoute';
import './fonts.css';
import { clearLocalStorageKey } from './helper/storage.js';
import './index.css';
import ChangeLog from './pages/ChangeLog.js';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import Register from './pages/Register';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ReportISC from './pages/reportISC/ReportISC';
import ReportISCInfo from './pages/reportISC/ReportISCInfo';
import ReportISCInfoRef from './pages/reportISC/ReportISCInfoRef';
import ReportISCInfoRefError from './pages/reportISC/ReportISCInfoRefError';
import ReportISC02 from './pages/reportISC02/ReportISC02';
import ReportISC02Info from './pages/reportISC02/ReportISC02Info';
import ReportISC02InfoRef from './pages/reportISC02/ReportISC02InfoRef';
import ReportISC02InfoRefDetail from './pages/reportISC02/ReportISC02InfoRefDetail';
import ReportISC02InfoRefDetailAuditor from './pages/reportISC02/ReportISC02InfoRefDetailAuditor';
import ReportISC02InfoRefDetailNote from './pages/reportISC02/ReportISC02InfoRefDetailNote';
import ReportISC02InfoRefDetailNoteView from './pages/reportISC02/ReportISC02InfoRefDetailNoteView.js';
import ReportISC02InfoRefDetailReportExcel from './pages/reportISC02/ReportISC02InfoRefDetailReportExcel';
import ReportISC03 from './pages/reportISC03/ReportISC03.js';
import ReportISC03Info from './pages/reportISC03/ReportISC03Info.js';
import ReportISC03InfoRef from './pages/reportISC03/ReportISC03InfoRef.js';
import UserManual from './pages/UserManual/UserManual';
import MsdListPage from './pages/masterData/MsdListPage.js';
import MsdCompanyListPage from './pages/masterData/MsdCompanyListPage.js';
import LogPage from './pages/log/LogPage.js';
import UserIndexPage from './pages/user/UserIndexPage.js';
import UserEditPage from './pages/user/UserEditPage.js';
import AnnounPage from './pages/announ/AnnounPage.js';
import { indexHost } from './server/service/host/index.js';
import { reportISC02Host } from './server/service/host/reportISC02Host';
import { reportISC03Host } from './server/service/host/reportISC03Host.js';
import { reportISCHost } from './server/service/host/reportISCHost';


// Component level locale for Thai Buddhist Era
const buddhistLocale = {
  ...th,
  lang: {
    ...th.lang,
    fieldDateFormat: 'BBBB-MM-DD',
    fieldDateTimeFormat: 'BBBB-MM-DD HH:mm:ss',
    yearFormat: 'BBBB',
    cellYearFormat: 'BBBB',
  },
};

// ConfigProvider level locale for Thai Buddhist Era
const globalBuddhistLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    lang: buddhistLocale.lang,
  },
};

dayjs.extend(buddhistEra);
dayjs.locale('th');


function App() {
  const serviceLogout = async () => {
    try {
      let errors = {};
      // ส่งค่า username และ password ไปยัง API เพื่อตรวจสอบการ login
      const response = await fetch(process.env.REACT_APP_BASE_API_URL + '/Auth/logout', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify()
      });

      // ตรวจสอบว่าการล็อกอินสำเร็จหรือไม่
      if (response.ok) {
        const data = await response.json();
        console.log('Logout successful:', data);
      } else {
        // หากไม่สำเร็จ แสดงข้อความแจ้งเตือน
        console.error('Logout failed:', response.statusText);
        errors.isError = true;
        errors.password = 'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง';
        //errors.password = response.statusText;
      }
      return errors;
    } catch (error) {
      console.log(`error:`, error);
    }
  }
  useEffect(() => {
    const isFirst = sessionStorage.getItem('isFirst');
    console.log('isFirst: ', isFirst);

    if (isFirst === null) {
      sessionStorage.setItem('isFirst', 'true');
    } else {
      sessionStorage.setItem('isFirst', 'false');
    }
    const isFirstCheck = sessionStorage.getItem('isFirst');
    console.log('isFirstCheck: ', isFirstCheck);
    const pageStack = localStorage.getItem('pageStack');

    console.log('pageStack: ', pageStack);
    if (isFirstCheck === 'true') {
      if (pageStack === null || parseInt(pageStack) <= 0) {
        console.log('case: pageStack === null || parseInt(pageStack) <= 0');
        serviceLogout();
        clearLocalStorageKey('persist:iCert');
        clearLocalStorageKey('token');
        clearLocalStorageKey('userInfo');
        clearLocalStorageKey('pageStack');
        window.location.reload();

      } else if (parseInt(pageStack) < 0) {
        console.log('parseInt(pageStack)<0');
        localStorage.setItem('pageStack', '1');
        const pageStackTobe = localStorage.getItem('pageStack');
        console.log('pageStackTobe: ', pageStackTobe);
      } else {
        console.log('else');
        localStorage.setItem('pageStack', (parseInt(pageStack) + 1).toString());
        const pageStackTobe = localStorage.getItem('pageStack');
        console.log('pageStackTobe: ', pageStackTobe);
      }
    } else {
      if (pageStack === null || parseInt(pageStack) === 0) {
        console.log('case: pageStack === null || parseInt(pageStack) === 0');
        localStorage.setItem('pageStack', '1');
        const pageStackTobe = localStorage.getItem('pageStack');
        console.log('pageStackTobe: ', pageStackTobe);
      } else if (parseInt(pageStack) < 0) {
        console.log('parseInt(pageStack)<0');
        localStorage.setItem('pageStack', '1');
        const pageStackTobe = localStorage.getItem('pageStack');
        console.log('pageStackTobe: ', pageStackTobe);
      } else {
        console.log('else');
        localStorage.setItem('pageStack', (parseInt(pageStack) + 1).toString());
        const pageStackTobe = localStorage.getItem('pageStack');
        console.log('pageStackTobe: ', pageStackTobe);
      }
    }

    const handleBeforeUnload = (event) => {
      const currentPageStack = localStorage.getItem('pageStack');
      console.log('currentPageStack', currentPageStack);
      if (currentPageStack !== null) {
        localStorage.setItem('pageStack', (parseInt(currentPageStack) - 1).toString());
      }
      console.log('currentPageStack', localStorage.getItem('pageStack'));
    };

    // Set a flag in sessionStorage on load
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <ConfigProvider
      locale={globalBuddhistLocale}
      // locale={thTH}
      theme={{
        token: {
          colorPrimary: "#11458d",
          colorPrimaryBg: "#F5F5F5",
          colorBgTextHover: "transparent",
          borderRadius: 3,
          controlHeight: 40,
          colorTextPlaceholder: 'var(--color-placeholder)',
          tableScrollBg: "var(--color-scrollbar-track)",
          tableScrollThumbBg: "var(--color-scrollbar-thumb)",
          tableScrollThumbBgHover: "var(--color-scrollbar-thumb-hover)",
        },
        components: {
          Layout: {
            bodyBg: "var(--color-layout-bg)",
            siderBg: "var(--color-white)",
            headerBg: "var(--color-white)",
          },
          Upload: {
            fontSize: 16,
          },
          Table: {
            headerBg: "var(--color-secondary)",
            footerBg: "var(--color-white)",
            rowSelectedBg: "transparent",
            headerSplitColor: "transparent",
            // rowHoverBg: "var(--color-table-hover-gray)",
            // rowSelectedHoverBg: "var(--color-table-hover-gray)",
            rowHoverBg: "initial !important",
            rowSelectedHoverBg: "initial !important",
            cellPaddingInline: 6,
            cellPaddingBlock: 6,
            headerBorderRadius: 2,
            tableScrollBg: "var(--color-scrollbar-track)",
            tableScrollThumbBg: "var(--color-scrollbar-thumb)",
            tableScrollThumbBgHover: "var(--color-scrollbar-thumb-hover)",
          },
          Button: {
            textHoverBg: "transparent",
            paddingInline: 24,
          },
          Breadcrumb: {
            itemColor: "var(--color-gray-primary)",
            lastItemColor: "var(--color-gray-primary)",
            linkColor: "var(--color-gray-primary)",
            linkHoverColor: "var(--color-gray-primary)",
            separatorColor: "var(--color-gray-primary)",
            separatorMargin: 4,
            iconFontSize: 16,
          },
          Select: {
            multipleItemHeight: 25,
          },
          Menu: {
            itemColor: "var(--color-table-text)",
            itemSelectedColor: "var(--color-table-text)",
            iconSize: 20,
            collapsedIconSize: 20,
          },
        },
      }}
    >
      <Loading>
        <BaseAll />
        {/* <AlertMsg /> */}
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <MainLayout>
                  <PrivateRoute>
                    <Routes>
                      <Route path="*" element={<NotFound />} />
                      <Route path="/dashboard" element={<Dashboard />} />

                      {/* ISC01 */}
                      <Route path={reportISCHost.reportISCBase} element={<ReportISC />} />
                      <Route path={reportISCHost.reportISCInfoBase} element={<ReportISCInfo />} />
                      <Route path={reportISCHost.reportISCSubmissionBase} element={<ReportISCInfoRef />} />
                      <Route path={reportISCHost.reportISCUploadBase} element={<ReportISCInfoRefError />} />
                      {/* ISC01 */}

                      {/* ISC02 */}
                      <Route path={reportISC02Host.reportISC02Base} element={<ReportISC02 />} />
                      <Route path={reportISC02Host.reportISC02InfoBase} element={<ReportISC02Info />} />
                      <Route path={reportISC02Host.reportISC02SubmissionBase} element={<ReportISC02InfoRef />} />
                      <Route path={reportISC02Host.reportISC02SubmissionDetailBase} element={<ReportISC02InfoRefDetail />} />
                      <Route path={reportISC02Host.reportISC02SubmissionDetailReportBase} element={<ReportISC02InfoRefDetailReportExcel />} />
                      <Route path={reportISC02Host.reportISC02SubmissionDetailNoteBase} element={<ReportISC02InfoRefDetailNote />} />
                      <Route path={reportISC02Host.reportISC02SubmissionDetailAuditorBase} element={<ReportISC02InfoRefDetailAuditor />} />
                      <Route path={reportISC02Host.reportISC02SubmissionDetailNoteBaseView} element={<ReportISC02InfoRefDetailNoteView />} />
                      {/* ISC02 */}


                      {/* ISC03 */}
                      <Route path={reportISC03Host.reportISC03Base} element={<ReportISC03 />} />
                      <Route path={reportISC03Host.reportISC03InfoBase} element={<ReportISC03Info />} />
                      <Route path={reportISC03Host.reportISC03SubmissionBase} element={<ReportISC03InfoRef />} />


                      {/* ISC03 */}
                      <Route path={indexHost.userManualBase} element={<UserManual />} />

                      {/* MSD */}
                      <Route path="/msd/list" element={<MsdListPage />} />
                      <Route path="/msd/companies" element={<MsdCompanyListPage />} />

                      {/* Log */}
                      <Route path="/log" element={<LogPage />} />
                              
                      {/*User*/}
                      <Route path="/user" element={<UserIndexPage />} />
                      <Route path="/user/edit/:userId" element={<UserEditPage />} />

                      {/*Announ*/}
                      <Route path="/announ" element={<AnnounPage/>} />

                              
                    </Routes>
                  </PrivateRoute>

                </MainLayout>
              }
            />

            <Route
              path={indexHost.defaultBase}
              element={
                <ProtectedRoute>
                  <Login />
                </ProtectedRoute>
              }
            />

            <Route
              path={indexHost.loginBase}
              element={
                <ProtectedRoute>
                  <Login />
                </ProtectedRoute>
              }
            />

            <Route
              path={indexHost.forgotPasswordBase}
              element={<ForgotPassword />}
            />

            <Route
              path={indexHost.registerBase}
              element={<Register />}
            />

            <Route
              path={indexHost.homeBase}
              element={<Home />}
            />

            <Route
              path={indexHost.changeLogBase}
              element={<ChangeLog />}
            />


            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Loading>
    </ConfigProvider>
  );
}

export default App;
