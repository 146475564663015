import { Col, Input, Layout, Row, Form, Typography, Card, Button, Table, Empty, Select, DatePicker } from 'antd';
import { ClearOutlined, FileSearchOutlined, PrinterOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import logService from '../../server/service/log';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

const { Column } = Table;
const { Option } = Select;

const LogPage = () => {
    const defaultPageSize = 10;
    const [keyword, setKeyword] = useState('');
    const [menu, setMenu] = useState(null);
    const [role, setRole] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [filter, setFilter] = useState({ keyword: null, menu: null, role: null, fromDate: null, toDate: null });
    const [paging, setPaging] = useState({ item_per_page: defaultPageSize, page_no: 1 });
    const [totalItem, setTotalItem] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [isExporting, setIsExporting] = useState(false);
    const [logTypes, setLogTypes] = useState([]);

    useEffect(() => {
        (async () => {
            await logService.log(logService.LogType.Log, "ดูรายการ");

            var res = await logService.getLogTypes();
            var temp = [{ value: null, label: 'ทั้งหมด' }];
            for (var i = 0; i < res.length; i++) {
                temp.push({ value: res[i], label: res[i] })
            }
            setLogTypes(temp)
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                var res = await logService.getLogs(filter, paging.item_per_page, paging.page_no);
                console.log({ res });
                setLogs(res.data);
                setTotalItem(res.paging.total_item);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [filter, paging]);

    const onSearchHandle = () => {
        console.log({ keyword: keyword, menu: menu, role: role, fromDate: fromDate, toDate: toDate });
        setFilter({ keyword: keyword, menu: menu, role: role, fromDate: fromDate, toDate: toDate });
    };

    const onClearHandle = () => {
        setKeyword('');
        setMenu('');
        setRole('');
        setFromDate(null);
        setToDate(null);
        setFilter({ keyword: null, menu: null, role: null, fromDate: null, toDate: null });
    };

    const onShowSizeChange = (current, size) => {
        setPaging({ page_no: 1, item_per_page: size });
    };

    const onChangePage = (page, pageSize) => {
        setPaging({ page_no: page, item_per_page: pageSize });
    };

    const onExportFileHandle = async () => {
        try {
            setIsExporting(true);
            var res = await logService.exportLogs(filter);

            const blob = new Blob([res], { type: 'application/octet-stream' });

            const fileName = `OIC_ICeRT_Loglist_${new dayjs().format("YYMMDDHHmmss")}.xlsx`

            saveAs(blob, fileName);

        } catch (error) {
            console.error(error);
        } finally {
            setIsExporting(false);
        }
    }

    const breadcrumbList = [
        { title: `ประวัติการใช้งานระบบ`, link: `` },
        { title: `รายการประวัติการใช้งาน`, link: `` },
    ]

    const formatDateText = (date) => dayjs(date).format('DD/MM/BBBB HH:mm')



    const exportList = [
        {
            key: '1',
            label: '1st item',
        },
        {
            key: '2',
            label: '2nd item',
        }
    ];

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <Card title="รายการประวัติการใช้งาน" classNames={{ header: 'ant-card-head-search-report-isc', }}
                            styles={{
                                title: {
                                    fontSize: 20,
                                    color: '#000000',
                                }
                            }}>
                            <Form>
                                <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                                    <Col span={24}>
                                        <Row gutter={[5, 5]}>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>ค้นหา</Typography.Text>
                                                <Input placeholder="-- ระบุคำที่ต้องการค้นหา --" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                            </Col>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>เมนู</Typography.Text>
                                                <Select onChange={(val) => setMenu(val)} value={menu} options={logTypes} className='select-scroll' placeholder="-- เลือกเมนู --" style={{ width: "100%" }} listItemHeight={10} listHeight={250}></Select>
                                            </Col>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>วันที่เริ่มต้น</Typography.Text>
                                                <DatePicker onChange={(val) => setFromDate(val)} value={fromDate} />
                                            </Col>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>วันที่สิ้นสุด</Typography.Text>
                                                <DatePicker onChange={(val) => setToDate(val)} value={toDate} />
                                            </Col>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>สิทธิ์การใช้งาน</Typography.Text>
                                                <Select onChange={(val) => setRole(val)} value={role} className='select-scroll' placeholder="-- เลือกสิทธิ์การใช้งาน --" style={{ width: "100%" }} listItemHeight={10} listHeight={250}>
                                                    <Option value={''}>ทั้งหมด</Option>
                                                    <Option value={'admin'}>admin</Option>
                                                    <Option value={'user'}>user</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle">
                                            <Col>
                                                <Button type="primary" onClick={onSearchHandle} loading={isLoading} style={{ paddingLeft: '30px', paddingRight: '30px', }} icon={<FileSearchOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ค้นหา</Button>
                                            </Col>
                                            <Col>
                                                <Button type="primary" onClick={onClearHandle} loading={isLoading} style={{ background: 'var(--color-gray-primary)', }} icon={<ClearOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ล้างการค้นหา</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col flex="1 1 250px" />
                                </Row>
                            </Form >
                        </Card >
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row className='mb-5' justify="end">
                            <Col>
                                <Button type="primary" onClick={onExportFileHandle} loading={isExporting} style={{ background: '#2E7D32' }} icon={<PrinterOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>Export</Button>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col style={{ width: "100%", padding: 0, }}>
                                <Table
                                    dataSource={logs}
                                    loading={isLoading}
                                    scroll={{ x: true }}
                                    rowKey={(record) => record.log_id}
                                    className="full-width-table"
                                    style={{ background: 'var(--color-white)' }}
                                    pagination={{
                                        defaultPageSize: defaultPageSize,
                                        pageSize: paging.item_per_page,
                                        total: totalItem,
                                        disabled: isLoading,
                                        showTotal: (total, range) => `แสดงข้อมูล ${range[0]} - ${range[1]} จาก ${total} รายการ `,
                                        onShowSizeChange: onShowSizeChange,
                                        onChange: onChangePage
                                    }}
                                    locale={{
                                        emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- ไม่พบข้อมูล -' />,
                                    }}
                                >
                                    <Column title="วันที่และเวลา" key="time_stamp" render={(_, record) => formatDateText(record.time_stamp)} />
                                    <Column title="ชื่อผู้ใช้งาน" dataIndex="username" key="username" align='center' />
                                    <Column title="ชื่อ-สกุล" dataIndex="full_name" key="full_name" />
                                    <Column title="หน่วยงาน/บริษัท" dataIndex="company_name" key="company_name" />
                                    <Column title="สิทธิ์การใช้งาน" dataIndex="role_name" key="role_name" align='center' />
                                    <Column title="เมนู" dataIndex="result" key="result" align='center' />
                                    <Column title="รายละเอียด" dataIndex="description" key="description" />
                                    <Column title="เบราว์เซอร์" dataIndex="action" key="action" align='center' />
                                    <Column title="IP" dataIndex="ipaddress" key="ipaddress" align='center' />
                                </Table>
                            </Col>
                        </Row>
                    </Col >
                </Row >
            </Content >
        </Layout >
    );
};

export default LogPage;

