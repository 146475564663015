// src/components/TableReportISC02InfoRef.js
import { Button, Col, Empty, Image, Row, Table, Tooltip, Typography } from 'antd';
import React from 'react';
import { IconCircleClose, IconFileExcel, IconFileExcelFill, IconFileInfoFill, IconSearch, IconViewMore } from '../../../helper/icon';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateFormat } from '../../../helper/date';
import { convertStatusInfoRef } from '../fn';
import { stringDefault } from '../../../helper/base';
import { initialStatePagination, setPage } from '../../../features/redux/slice/paginationSlice';
const { Column } = Table;


const TableReportISC02InfoRef = ({
    dataList = [],
    pagination = {},
    onClickInfo = () => null,
    onClickFileExcel = () => null,
    onClickFileExcelErr = () => null,
    onClickSearch = () => null,
}) => {
    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
        total_pages = initialStatePagination.totalPages,
    } = pagination || initialStatePagination
    console.log(`pagination`, pagination)
    const data = dataList;

    const tooltipInformation = ''

    return (
        <Table
            dataSource={data}
            scroll={{ x: true }}
            className="full-width-table"
            rowKey={"row_no"}
            pagination={{
                onChange: (page, pageSize) => {
                    dispatch(setPage(page));
                },
                showSizeChanger: false,
                current: page,
                pageSize: page_size,
                total: total,
                showQuickJumper: true,
                showTotal: (t, range) =>
                    `แสดงข้อมูล  ${range[0]} -  ${range[1]} จาก  ${total} รายการ`, // `แสดงข้อมูล 1 - 17 จาก 17 รายการ`
            }}
            locale={{
                emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- กรุณากดปุ่ม Import -' />,
            }}
        >
            <Column className="whitespace-nowrap" align="start" title="Upload ครั้งที่" dataIndex="upload_count" key="upload_count" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="วันที่ส่ง" dataIndex="created_at" key="created_at" render={(text, record) => <Typography.Paragraph>{stringDefault(convertDateFormat(text, 'dateTime'), '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="ผู้ส่งล่าสุด" dataIndex="last_submitted_by" key="last_submitted_by" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="whitespace-nowrap" align="start" title="จำนวนรายงาน" dataIndex="report_count" key="report_count" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            {/* <Column className="whitespace-nowrap" align="start" title="จำนวน Error" dataIndex="error_count" key="error_count" render={(text, record) => <Typography.Paragraph>{stringDefault(text == 0 ? '0' : text, '-')}</Typography.Paragraph>} /> */}
            <Column className="whitespace-nowrap" align="start" title="สถานะ" dataIndex="status" key="status" render={(text, record) => <Typography.Paragraph style={{ color: convertStatusInfoRef(text).color }}>{stringDefault(convertStatusInfoRef(text).text, '-')}</Typography.Paragraph>} />

            <Column className="whitespace-nowrap"
                align='center'
                title="เครื่องมือ"
                key="action"
                render={(text, record) => (
                    <Row gutter={[2, 2]} justify={'center'}>
                        {!!record.notes && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickInfo(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    {/* <Tooltip title={record.notes} key='tooltip-information' style={{ verticalAlign: 'sub', }} > */}
                                    <Image
                                        width={25}
                                        src={IconFileInfoFill}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                    {/* </Tooltip> */}
                                </Button>
                            </Col>
                        )}
                        {!!record.report_url && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickFileExcel(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        width={25}
                                        src={IconFileExcel}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                </Button>
                            </Col>
                        )}
                        {!!record.error_excel_url && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickFileExcelErr(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        width={25}
                                        src={IconFileExcelFill}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                </Button>
                            </Col>
                        )}
                        {/* {(record.approvalStatus !== 'กำลังตรวจสอบ (1/2)' && record.approvalStatus !== 'กำลังตรวจสอบ (2/2)') && ( */}
                        {(record.error_count != 0) && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickSearch(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        width={25}
                                        src={IconSearch}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                </Button>
                            </Col>
                        )}
                    </Row>
                )}
            />
        </Table >
    );
};

export default TableReportISC02InfoRef;
