import { Button, Card, Col, DatePicker, Input, Row, Select, Typography } from 'antd';
import { useState } from 'react';
import { stringDefault } from '../../../helper/base';
import { ClearOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import BoxDueDate from '../../BoxDueDate';

const { Option } = Select;

const CardSearchReportISC03InfoRef = ({
    nameReportISC03 = '',
    refReportISC03 = '',
    uploadReportISC03 = '',
}) => {

    return (
        <Card
            extra={<BoxDueDate isShow={true} />}
            // title={`ส่งข้อมูลเลขที่อ้างอิงข้อมูล  ICeRT-XX-66-Q-999812F00345 `}
            title={`เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC03, '-')}`}
            styles={{
                body: {
                    padding: 0,
                },
                title: {
                    fontSize: 20,
                    color: '#000000',
                }
            }}
            classNames={{ header: 'ant-card-head-search-report-isc-error', title: 'min-w-[130px]', }}
        >
            {/* เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0034 : Upload ครั้งที่ 2   */}

        </Card >
    );
};

export default CardSearchReportISC03InfoRef;
