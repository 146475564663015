import React, { useEffect, useState, useRef } from 'react';
import { Badge, Button, Row, Typography } from 'antd';
import axios from 'axios';
import { IconBell } from '../helper/icon';
import '../notification.css'; // Make sure to create and import this CSS file

const { Paragraph } = Typography;

// Define your base URL here
const BASE_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:3000';

const Notification = ({ onClickBell = () => null }) => {
    const [count, setCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [viewAll, setViewAll] = useState(false);
    const [page, setPage] = useState(1);
    const notificationRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchNotificationCount = () => {
        const token = localStorage.getItem('token');
            axios.get(BASE_URL + '/notification/get-count', {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data.meta.status_code === 200) {
                        setCount(response.data.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching notification count:', error);
                });
        };

        fetchNotificationCount(); // Initial fetch

        // Set up polling every 30 seconds (adjust as needed)
        const intervalId = setInterval(fetchNotificationCount, 30000);

        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            clearInterval(intervalId); // Clear interval on unmount
        };
    }, []);

    const fetchNotifications = (pageNumber = 1) => {
        const token = localStorage.getItem('token');

        axios.get(`${BASE_URL}/notification/get-all?page=${pageNumber}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.meta.status_code === 200) {
                    setNotifications(prevNotifications => 
                        pageNumber === 1
                            ? response.data.data.notification_companies_list
                            : [...prevNotifications, ...response.data.data.notification_companies_list]
                    );
                    if (pageNumber > 1) {
                        setPage(pageNumber);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching notification details:', error);
            });
    };

    const handleBellClick = () => {
        setIsVisible(!isVisible);
        if (!isVisible) {
            fetchNotifications();
        }
        onClickBell();
    };

    const handleLinkClick = (notificationId, linkPath, event) => {
        event.preventDefault(); // Prevent default link behavior
    
        const token = localStorage.getItem('token');
    
        // Get the current host
        const currentHost = window.location.origin;
    
        // Construct the full URL
        const fullUrl = `${currentHost}/${linkPath}`;
    
        axios.post(`${process.env.REACT_APP_BASE_API_URL}/notification/link-click`, 
            null, // No body for this POST request
            {
                params: {
                    notification_Id: notificationId
                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
        .then(response => {
            if (response.data.meta.status_code === 200) {
                // Redirect to the full URL if the request is successful
                window.location.href = fullUrl; // Use `window.location.href` to open in the same tab
            }
        })
        .catch(error => {
            console.error('Error updating notification status:', error);
            // Optionally, handle errors here
        });
    };    

    const handleViewAllClick = () => {
        setViewAll(true);
        fetchNotifications(); // Fetch initial notifications for view all
    };

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollHeight - scrollTop === clientHeight) {
            fetchNotifications(page + 1);
        }
    };

    return (
        <div className={`notification-container ${viewAll ? 'view-all' : ''}`}>
            <Row>
                <Button
                    type="text"
                    onClick={handleBellClick}
                    style={{
                        alignSelf: 'center',
                        padding: 10,
                    }}
                >
                    <Badge 
                        count={count > 99 ? '99+' : count} 
                        offset={[0, 10]} 
                        overflowCount={99} 
                        style={{
                            backgroundColor: '#f5222d',
                            color: '#fff',
                            borderRadius: '50%',
                            height: '20px',
                            width: '20px',
                            lineHeight: '20px',
                            textAlign: 'center',
                            padding: '0 6px',
                            fontSize: '10px'
                        }}
                    >
                        <img
                            width={32}
                            src={IconBell}
                            alt="Notification Bell"
                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                        />
                    </Badge>
                </Button>
            </Row>
            {isVisible && (
                <div
                    className={`notification-list ${viewAll ? 'view-all' : ''}`}
                    ref={notificationRef}
                    onScroll={viewAll ? handleScroll : undefined}
                >
                    {viewAll ? (
                        <>
                            <h2>การแจ้งเตือน</h2>
                            <div className="notification-section new">
                                <h3>ใหม่</h3>
                                {notifications.length > 0 && (
                                    <div className={`notification-item ${notifications[0].is_link_clicked ? 'used' : ''}`}>
                                        <a 
                                            href={`${BASE_URL}${notifications[0].link_path}`} 
                                            rel="noopener noreferrer"
                                            onClick={(event) => handleLinkClick(notifications[0].notification_id, notifications[0].link_path, event)}
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: notifications[0].detail }} />
                                            <div className="notification-time">
                                                {new Date(notifications[0].create_at).toLocaleString('th-TH', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit'
                                                })}
                                            </div>
                                            {!notifications[0].is_link_clicked && (
                                                <div className="blue-circle" />
                                            )}
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className="notification-section old">
                                <h3>ก่อนหน้า</h3>
                                {notifications.slice(1).map((notification) => (
                                    <div key={notification.notification_id} className={`notification-item ${notification.is_link_clicked ? 'used' : ''}`}>
                                        <a 
                                            href={`${BASE_URL}${notification.link_path}`} 
                                            rel="noopener noreferrer"
                                            onClick={(event) => handleLinkClick(notification.notification_id, notification.link_path, event)}
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: notification.detail }} />
                                            <div className="notification-time">
                                                {new Date(notification.create_at).toLocaleString('th-TH', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit'
                                                })}
                                            </div>
                                            {!notification.is_link_clicked && (
                                                <div className="blue-circle" />
                                            )}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            {notifications.map((notification) => (
                                <div key={notification.notification_id} className={`notification-item ${notification.is_link_clicked ? 'used' : ''}`}>
                                    <a 
                                        href={`${BASE_URL}${notification.link_path}`} 
                                        rel="noopener noreferrer"
                                        onClick={(event) => handleLinkClick(notification.notification_id, notification.link_path, event)}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: notification.detail }} />
                                        <div className="notification-time">
                                            {new Date(notification.create_at).toLocaleString('th-TH', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })}
                                        </div>
                                        {!notification.is_link_clicked && (
                                            <div className="blue-circle" />
                                        )}
                                    </a>
                                </div>
                            ))}
                            <Button type="link" className="view-all-button" onClick={handleViewAllClick}>
                                ดูทั้งหมด
                            </Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Notification;
