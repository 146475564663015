// src/components/ReportISC02InfoRefDetail.js
import { Button, Card, Col, Flex, InputNumber, Layout, Row, Input, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchReportISC02InfoRefDetail from '../../components/reportISC02/reportISC02InfoRefDetail/CardSearchReportISC02InfoRefDetail';
import { useFetchReportISC02InfoRefDetailHeader } from '../../features/hooks/reportISC02/useFetchReportISC02InfoRefDetailHeader';
import { fetchReportCycleSubmissionSaveReportNote, fetchReportISCSubmissionGetDetailNote, fetchReportISCSubmissionGetNoteSection, fetchReportISCSubmissionSaveDetailNote } from '../../features/redux/apiSlice/apiReportISC02Slice';
import { resetFormData } from '../../features/redux/slice/formSlice';
import { resetUploadFileISC02FileName, resetUploadReport } from '../../features/redux/slice/uploadFileSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import ModalUploadReportISC02ViewDetailNote from '../../components/reportISC02/modal/ModalUploadReportISC02ViewDetailNote';
import { setModalReportISC02BackDetailNote, setModalReportISC02SaveDetailNote, setModalUploadReportISC02ViewDetailNote } from '../../features/redux/slice/modalSlice';
import FinancialPosition from '../../components/reportISC02/reportISC02DetailNote/table/FinancialPosition';
import InsuranceServices from '../../components/reportISC02/reportISC02DetailNote/table/InsuranceServices';
import InsuranceContract from '../../components/reportISC02/reportISC02DetailNote/table/InsuranceContract ';
import ReinsurancsContracts from '../../components/reportISC02/reportISC02DetailNote/table/ReinsurancsContracts';
import DevelopmentCompensation from '../../components/reportISC02/reportISC02DetailNote/table/DevelopmentCompensation';
import Classification from '../../components/reportISC02/reportISC02DetailNote/table/Classification';
import InvestmentIncome from '../../components/reportISC02/reportISC02DetailNote/table/InvestmentIncome';
import RealizedMoney from '../../components/reportISC02/reportISC02DetailNote/table/RealizedMoney';
import RealizedMoneyTotal from '../../components/reportISC02/reportISC02DetailNote/table/RealizedMoneyTotal';
import ContractIncome from '../../components/reportISC02/reportISC02DetailNote/table/ContractIncome';
import { selectDetailNote } from '../../features/redux/slice/detailNoteSlice';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import FinancialPositionGi from '../../components/reportISC02/reportISC02DetailNote/table/FinancialPositionGi';
import InvestmentIncomeGi from '../../components/reportISC02/reportISC02DetailNote/table/InvestmentIncomeGi';
import RealizedMoneyGi from '../../components/reportISC02/reportISC02DetailNote/table/RealizedMoneyGi';
import RealizedMoneyTotalGi from '../../components/reportISC02/reportISC02DetailNote/table/RealizedMoneyTotalGi';
import ModalReportISC02SaveDetailNote from '../../components/reportISC02/modal/ModalReportISC02SaveDetailNote';
import ModalReportISC02BackDetailNote from '../../components/reportISC02/modal/ModalReportISC02BackDetailNote';

const ReportISC02InfoRefDetailNoteView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { } = useFetchReportISC02InfoRefDetailHeader();
    const location = useLocation();
    const message = location.state.message;
    const secID = location.state.sectionId;

    const [titlePage, setTitlePage] = useState('');
    const [content, setContent] = useState('');

    const [dataRows, setDataRows] = useState([]);
    const [sectionId, setSectionId] = useState(null);

    const [dataSection, setDataSection] = useState([]);

    const { TextArea } = Input;
    const detailNote = useSelector(state => state.detailNote)
    const [submittable, setSubmittable] = useState(false);
    const [checkTextInput, setCheckTextInput] = useState(false);

    useEffect(() => {
        dispatch(fetchReportISCSubmissionGetNoteSection({
            "submission_id": submissionId,
            "upload_id": submissionDetailId,
            "file_record_id": fileRecordId == 'null' ? null : fileRecordId, // ถ้าไม่มีใส่ null
            "section_id": secID
        }));
        // setIsRefesh(!isRefesh)
    }, [])

    useEffect(() => {
        let title = message;
        const data = detailNote?.sectionNote?.data?.rows || [];
        setDataSection(data);
        if (title == 'วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)') {
            setTitlePage('1. องค์ประกอบของงบแสดงฐานะการเงิน วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)');
            setContent(title);
        } else if (title == 'วันสิ้นรอบระยะเวลาระหว่างกาลปีปัจจุบัน (สิ้นงวดปัจจุบัน)') {
            setTitlePage('1. องค์ประกอบของงบแสดงฐานะการเงิน วันสิ้นรอบระยะเวลาระหว่างกาลปีปัจจุบัน (สิ้นงวดปัจจุบัน)');
            setContent(title);
        } else if (title == 'สัญญาประกันภัยที่ออก') {
            setTitlePage('3. จำนวนเงินที่ได้พิจารณาตามวิธีปฏิบัติในช่วงเปลี่ยนผ่าน สัญญาประกันภัยที่ออก');
            setContent(title);
        } else if (title == 'สัญญาประกันภัยต่อที่ถือไว้') {
            setTitlePage('3. จำนวนเงินที่ได้พิจารณาตามวิธีปฏิบัติในช่วงเปลี่ยนผ่าน สัญญาประกันภัยต่อที่ถือไว้');
            setContent(title);
        } else if (title == 'สินทรัพย์ทางการเงิน') {
            setTitlePage('5. การจัดประเภทสินทรัพย์ทางการเงินและหนี้สินทางการเงิน สินทรัพย์ทางการเงิน');
            setContent(title);
        } else if (title == 'หนี้สินทางการเงิน') {
            setTitlePage('5. การจัดประเภทสินทรัพย์ทางการเงินและหนี้สินทางการเงิน หนี้สินทางการเงิน');
            setContent(title);
        } else if (title == 'ผลการดำเนินงานการบริการประกันภัย' || title == 'จำนวนเงินที่รับรู้ในงบกำไรขาดทุน' || title == 'รายได้จากการลงทุนและค่าใช้จ่ายทางการเงินจากการประกันภัย สัญญาประกันภัยที่ออก' || title == 'การลงทุนสุทธิ' || title == 'จำนวนเงินที่รับรู้ในงบกำไรขาดทุนเบ็ดเสร็จอื่น') {
            setTitlePage(`6. รายได้จากการลงทุนและค่าใช้จ่ายทางการเงินจากการประกันภัย ${title == 'จำนวนเงินที่รับรู้ในงบกำไรขาดทุน' || title == 'จำนวนเงินที่รับรู้ในงบกำไรขาดทุนเบ็ดเสร็จอื่น' ? title : ''}`);
            setContent(title);
        } else {
            setTitlePage(title);
            setContent(title);
        }
        // console.log('dataRows :',dataRows)
    }, [detailNote?.sectionNote?.meta?.timestamp, dataRows]);

    const {
        isc02Header,
    } = useSelector(state => state.isc);
    const {
        meta = {},
        data = {},
    } = isc02Header
    const {
        row_no = null,
        total_rows = null,
        submission_id = null,
        cycle_id = null,
        cycle_name = '',
        due_date = '',
        reference_code = '',
        upload_num = null,
        first_submission_date = null,
        last_submission_date = null,
        last_submitted_by = '',
        submission_status = '',
        approval_status = '',
        approved_date = null,
        company_id = null,
        notes = '',
        is_edit_request = false,
        can_cancel_edit = false,
        is_view = false
    } = data

    let { infoId = '', submissionId = '', submissionDetailId = '', fileRecordId = '' } = useParams();

    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC02 = cycle_name || infoId
    const refReportISC02 = reference_code || submissionId
    const uploadReportISC02 = submissionDetailId
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-02` },
        { title: `รายงานงบการเงิน ${stringDefault(nameReportISC02, '-')}`, link: reportISC02Host.reportISC02InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC02, '-')}`, link: reportISC02Host.reportISC02SubmissionByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-')) }, //เลขที่อ้างอิงข้อมูล ICeRT-LI-66-Q-999812F0035
        { title: `ส่งข้อมูล`, link: reportISC02Host.reportISC02SubmissionDetailByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-')) },
        { title: `หมายเหตุประกอบงบการเงิน` },
    ]

    const handleSaveButton = () => {

        setTimeout(() => {
            dispatch(setModalReportISC02BackDetailNote({ isOpen: false, }));
        }, 100);
        if (submittable == false) {
            setTimeout(() => {
                dispatch(setModalReportISC02SaveDetailNote({ isOpen: true, }));
            }, 100);
            setCheckTextInput(true);
        } else {
            setCheckTextInput(false);
            let rows = dataRows;
            const data = rows?.items.map(item => ({
                ...item,
                details: item.details.map(detail => ({
                    ...detail,
                    value: parseFloat(detail.value.replace(/,/g, ''))
                }))
            }));
            const payload = {
                "submission_id": Number(submissionId),
                "upload_id": Number(submissionDetailId),
                "section_id": sectionId,
                "file_record_id": fileRecordId == 'null' ? null : Number(fileRecordId),
                'rows': data
            }

            dispatch(fetchReportISCSubmissionSaveDetailNote(payload));
            dispatch(setLoadingTimeout());
        }
    };

    const NavigateBackPage = () => {
        let url = reportISC02Host.reportISC02SubmissionDetailNoteByIdBase(stringDefault(infoId, '/-'), stringDefault(submissionId, '/-'), stringDefault(submissionDetailId, '/-'), stringDefault(fileRecordId, '/null'))
        setTimeout(() => {
            dispatch(setModalReportISC02BackDetailNote({ isOpen: false }));
            navigate(url);
        }, 1000);
        // setTimeout(() => {
        //     navigate(url);
        // }, 1000);
    }

    const handleBackButton = () => {
        setTimeout(() => {
            dispatch(setModalReportISC02BackDetailNote({ isOpen: true }));
        }, 100);
    }

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <ModalReportISC02BackDetailNote handleSave={() => handleSaveButton()} NavigateBackPage={() => NavigateBackPage()} />
            <ModalReportISC02SaveDetailNote />
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 20]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02InfoRefDetail
                            nameReportISC02={nameReportISC02}
                            refReportISC02={refReportISC02}
                            uploadReportISC02={uploadReportISC02}
                        />
                    </Col>

                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: 0, }}>
                        <Card
                            title={`${titlePage}`}
                            styles={{
                                body: {
                                    padding: 0,
                                },
                                title: {
                                    fontSize: 20,
                                    color: '#000000',
                                }
                            }}
                        >
                        </Card>
                        <Col style={{ paddingLeft: '5px', paddingTop: '32px', paddingBottom: '32px' }}>
                            {
                                content == 'วันสิ้นรอบระยะเวลาระหว่างกาลปีปัจจุบัน (สิ้นงวดปัจจุบัน)' || content == 'วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)'
                                    ? data?.insurance_type == 'LI'
                                        ? <FinancialPosition content={content} setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                        : <FinancialPositionGi content={content} setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                    : null
                            }
                            {
                                content == '2. รายได้และผลการดำเนินงานการบริการประกันภัย' ?
                                    <InsuranceServices setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                    : null
                            }
                            {
                                content == 'สัญญาประกันภัยที่ออก' ?
                                    <InsuranceContract setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} /> : null
                            }
                            {
                                content == 'สัญญาประกันภัยต่อที่ถือไว้' ?
                                    <ReinsurancsContracts setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} /> : null
                            }
                            {
                                content == '4. พัฒนาการค่าสินไหมทดแทน' ?
                                    <DevelopmentCompensation setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} /> : null
                            }
                            {
                                content == 'สินทรัพย์ทางการเงิน' || content == 'หนี้สินทางการเงิน' ?
                                    <Classification setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} content={content} checkTextInput={checkTextInput} /> : null
                            }
                            {
                                content == 'การลงทุนสุทธิ'
                                    ? data?.insurance_type == 'LI'
                                        ? <InvestmentIncome setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                        : <InvestmentIncomeGi setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                    : null
                            }
                            {
                                content == 'รายได้จากการลงทุนและค่าใช้จ่ายทางการเงินจากการประกันภัย สัญญาประกันภัยที่ออก' ?
                                    <ContractIncome setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} /> : null
                            }
                            {
                                content == 'จำนวนเงินที่รับรู้ในงบกำไรขาดทุน'
                                    ? data?.insurance_type == 'LI'
                                        ? <RealizedMoney setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                        : <RealizedMoneyGi setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                    : null
                            }
                            {
                                content == 'จำนวนเงินที่รับรู้ในงบกำไรขาดทุนเบ็ดเสร็จอื่น'
                                    ? data?.insurance_type == 'LI'
                                        ? <RealizedMoneyTotal setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                        : <RealizedMoneyTotalGi setDataRows={setDataRows} setSectionId={setSectionId} dataSection={dataSection} setSubmittable={setSubmittable} checkTextInput={checkTextInput} />
                                    : null
                            }
                        </Col>
                    </Col>
                </Row>
                <Row items='middle' justify={'center'} className="py-6">
                    <Button type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white" onClick={handleBackButton}>ย้อนกลับ</Button>
                    <Button type="primary" className="font-bold" onClick={handleSaveButton} disabled={!submittable} >บันทึก</Button>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISC02InfoRefDetailNoteView;