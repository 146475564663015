import { createSlice } from '@reduxjs/toolkit';

/* isc01 */
export const initialStateSearch = {
    cycleYear: [],
    submissionStatus: [],
    approvalStatus: [],
};

export const initialStateSearchReportISCInfo = {
    keyword: '',
    cycleId: '',
    submissionStatus: [],
    approvalStatus: [],
    fromDate: null,
    toDate: null,
};

export const initialStateSearchReportISCInfoRef = {
    keyword: '',
    cycleId: '',
    verifyStatus: [],
    fromDate: null,
    toDate: null,
};

/* isc01 */

/* isc02 */
export const initialStateSearch02 = {
    cycleYear: [],
    submissionStatus: [],
    approvalStatus: [],
};

export const initialStateSearchReportISC02Info = {
    keyword: '',
    cycleId: '',
    submissionStatus: [],
    approvalStatus: [],
    fromDate: null,
    toDate: null,
};

export const initialStateSearchReportISC02InfoRef = {
    keyword: '',
    cycleId: '',
    verifyStatus: [],
    fromDate: null,
    toDate: null,
};
/* isc02 */

/* isc03 */
export const initialStateSearch03 = {
    cycleYear: [],
    submissionStatus: [],
    approvalStatus: [],
};

export const initialStateSearchReportISC03Info = {
    keyword: '',
    cycleId: '',
    submissionStatus: [],
    approvalStatus: [],
    fromDate: null,
    toDate: null,
};

export const initialStateSearchReportISC03InfoRef = {
    keyword: '',
    cycleId: '',
    verifyStatus: [],
    fromDate: null,
    toDate: null,
};

export const initialState = {
    /* isc01 */
    searchReportISC: initialStateSearch,
    searchReportISCInfo: initialStateSearchReportISCInfo,
    searchReportISCInfoRef: initialStateSearchReportISCInfoRef,
    /* isc01 */

    /* isc02 */
    searchReportISC02: initialStateSearch02,
    searchReportISC02Info: initialStateSearchReportISC02Info,
    searchReportISC02InfoRef: initialStateSearchReportISC02InfoRef,
    /* isc02 */

    /* isc03 */
    searchReportISC03: initialStateSearch03,
    searchReportISC03Info: initialStateSearchReportISC03Info,
    searchReportISC03InfoRef: initialStateSearchReportISC03InfoRef,
    /* isc03 */
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        /* isc01 */
        setSearchReportISC: (state, action) => {
            /* 
            dispatch(setSearchReportISC(searchData));
            */
            const {
                cycleYear = state.searchReportISC.cycleYear || initialStateSearch.cycleYear,
                submissionStatus = state.searchReportISC.submissionStatus || initialStateSearch.submissionStatus,
                approvalStatus = state.searchReportISC.approvalStatus || initialStateSearch.approvalStatus,
            } = action.payload
            state.searchReportISC.cycleYear = cycleYear;
            state.searchReportISC.submissionStatus = submissionStatus;
            state.searchReportISC.approvalStatus = approvalStatus;
        },
        setSearchReportISCInfo: (state, action) => {
            /* 
            dispatch(setSearchReportISCInfo(searchInfoData));
            */
            const {
                keyword = state.searchReportISCInfo.keyword || initialStateSearchReportISCInfo.keyword,
                cycleId = state.searchReportISCInfo.cycleId || initialStateSearchReportISCInfo.cycleId,
                submissionStatus = state.searchReportISCInfo.submissionStatus || initialStateSearchReportISCInfo.submissionStatus,
                approvalStatus = state.searchReportISCInfo.approvalStatus || initialStateSearchReportISCInfo.approvalStatus,
                fromDate = state.searchReportISCInfo.fromDate || initialStateSearchReportISCInfo.fromDate,
                toDate = state.searchReportISCInfo.toDate || initialStateSearchReportISCInfo.toDate,
            } = action.payload
            state.searchReportISCInfo.keyword = keyword;
            state.searchReportISCInfo.cycleId = cycleId;
            state.searchReportISCInfo.submissionStatus = submissionStatus;
            state.searchReportISCInfo.approvalStatus = approvalStatus;
            state.searchReportISCInfo.fromDate = fromDate;
            state.searchReportISCInfo.toDate = toDate;
        },
        setSearchReportISCInfoRef: (state, action) => {
            /* 
            dispatch(setSearchReportISCInfoRef(searchInfoRefData));
            */
            const {
                keyword = state.searchReportISCInfoRef || initialStateSearchReportISCInfo.keyword,
                cycleId = state.searchReportISCInfoRef || initialStateSearchReportISCInfo.cycleId,
                verifyStatus = state.searchReportISCInfoRef || initialStateSearchReportISCInfo.verifyStatus,
                fromDate = state.searchReportISCInfoRef || initialStateSearchReportISCInfo.fromDate,
                toDate = state.searchReportISCInfoRef || initialStateSearchReportISCInfo.toDate,
            } = action.payload
            state.searchReportISCInfoRef.keyword = keyword;
            state.searchReportISCInfoRef.cycleId = cycleId;
            state.searchReportISCInfoRef.verifyStatus = verifyStatus;
            state.searchReportISCInfoRef.fromDate = fromDate;
            state.searchReportISCInfoRef.toDate = toDate;
        },
        resetSearchStateInfo: (state) => {
            state.searchReportISCInfo = initialStateSearchReportISCInfo;
        },
        resetSearchStateInfoRef: (state) => {
            state.searchReportISCInfoRef = initialStateSearchReportISCInfoRef;
        },
        /* isc01 */

        /* isc02 */
        setSearchReportISC02: (state, action) => {
            /* 
            dispatch(setSearchReportISC02(searchData));
            */
            const {
                cycleYear = state.searchReportISC02.cycleYear || initialStateSearch.cycleYear,
                submissionStatus = state.searchReportISC02.submissionStatus || initialStateSearch.submissionStatus,
                approvalStatus = state.searchReportISC02.approvalStatus || initialStateSearch.approvalStatus,
            } = action.payload
            state.searchReportISC02.cycleYear = cycleYear;
            state.searchReportISC02.submissionStatus = submissionStatus;
            state.searchReportISC02.approvalStatus = approvalStatus;
        },
        setSearchReportISC02Info: (state, action) => {
            /* 
            dispatch(setSearchReportISC02Info(searchInfoData));
            */
            const {
                keyword = state.searchReportISC02Info.keyword || initialStateSearchReportISC02Info.keyword,
                cycleId = state.searchReportISC02Info.cycleId || initialStateSearchReportISC02Info.cycleId,
                submissionStatus = state.searchReportISC02Info.submissionStatus || initialStateSearchReportISC02Info.submissionStatus,
                approvalStatus = state.searchReportISC02Info.approvalStatus || initialStateSearchReportISC02Info.approvalStatus,
                fromDate = state.searchReportISC02Info.fromDate || initialStateSearchReportISC02Info.fromDate,
                toDate = state.searchReportISC02Info.toDate || initialStateSearchReportISC02Info.toDate,
            } = action.payload
            state.searchReportISC02Info.keyword = keyword;
            state.searchReportISC02Info.cycleId = cycleId;
            state.searchReportISC02Info.submissionStatus = submissionStatus;
            state.searchReportISC02Info.approvalStatus = approvalStatus;
            state.searchReportISC02Info.fromDate = fromDate;
            state.searchReportISC02Info.toDate = toDate;
        },
        setSearchReportISC02InfoRef: (state, action) => {
            /* 
            dispatch(setSearchReportISC02InfoRef(searchInfoRefData));
            */
            const {
                keyword = state.searchReportISC02InfoRef || initialStateSearchReportISC02Info.keyword,
                cycleId = state.searchReportISC02InfoRef || initialStateSearchReportISC02Info.cycleId,
                verifyStatus = state.searchReportISC02InfoRef || initialStateSearchReportISC02Info.verifyStatus,
                fromDate = state.searchReportISC02InfoRef || initialStateSearchReportISC02Info.fromDate,
                toDate = state.searchReportISC02InfoRef || initialStateSearchReportISC02Info.toDate,
            } = action.payload
            state.searchReportISC02InfoRef.keyword = keyword;
            state.searchReportISC02InfoRef.cycleId = cycleId;
            state.searchReportISC02InfoRef.verifyStatus = verifyStatus;
            state.searchReportISC02InfoRef.fromDate = fromDate;
            state.searchReportISC02InfoRef.toDate = toDate;
        },

        resetSearchState02Info: (state) => {
            state.searchReportISC02Info = initialStateSearchReportISC02Info;
        },
        resetSearchState02InfoRef: (state) => {
            state.searchReportISC02InfoRef = initialStateSearchReportISC02InfoRef;
        },
        /* isc02 */

        /* isc03 */
        setSearchReportISC03: (state, action) => {
            /* 
            dispatch(setSearchReportISC03(searchData));
            */
            const {
                cycleYear = state.searchReportISC03.cycleYear || initialStateSearch.cycleYear,
                submissionStatus = state.searchReportISC03.submissionStatus || initialStateSearch.submissionStatus,
                approvalStatus = state.searchReportISC03.approvalStatus || initialStateSearch.approvalStatus,
            } = action.payload
            state.searchReportISC03.cycleYear = cycleYear;
            state.searchReportISC03.submissionStatus = submissionStatus;
            state.searchReportISC03.approvalStatus = approvalStatus;
        },
        setSearchReportISC03Info: (state, action) => {
            /* 
            dispatch(setSearchReportISC03Info(searchInfoData));
            */
            const {
                keyword = state.searchReportISC03Info.keyword || initialStateSearchReportISC03Info.keyword,
                cycleId = state.searchReportISC03Info.cycleId || initialStateSearchReportISC03Info.cycleId,
                submissionStatus = state.searchReportISC03Info.submissionStatus || initialStateSearchReportISC03Info.submissionStatus,
                approvalStatus = state.searchReportISC03Info.approvalStatus || initialStateSearchReportISC03Info.approvalStatus,
                fromDate = state.searchReportISC03Info.fromDate || initialStateSearchReportISC03Info.fromDate,
                toDate = state.searchReportISC03Info.toDate || initialStateSearchReportISC03Info.toDate,
            } = action.payload
            state.searchReportISC03Info.keyword = keyword;
            state.searchReportISC03Info.cycleId = cycleId;
            state.searchReportISC03Info.submissionStatus = submissionStatus;
            state.searchReportISC03Info.approvalStatus = approvalStatus;
            state.searchReportISC03Info.fromDate = fromDate;
            state.searchReportISC03Info.toDate = toDate;
        },
        setSearchReportISC03InfoRef: (state, action) => {
            /* 
            dispatch(setSearchReportISC03InfoRef(searchInfoRefData));
            */
            const {
                keyword = state.searchReportISC03InfoRef || initialStateSearchReportISC03Info.keyword,
                cycleId = state.searchReportISC03InfoRef || initialStateSearchReportISC03Info.cycleId,
                verifyStatus = state.searchReportISC03InfoRef || initialStateSearchReportISC03Info.verifyStatus,
                fromDate = state.searchReportISC03InfoRef || initialStateSearchReportISC03Info.fromDate,
                toDate = state.searchReportISC03InfoRef || initialStateSearchReportISC03Info.toDate,
            } = action.payload
            state.searchReportISC03InfoRef.keyword = keyword;
            state.searchReportISC03InfoRef.cycleId = cycleId;
            state.searchReportISC03InfoRef.verifyStatus = verifyStatus;
            state.searchReportISC03InfoRef.fromDate = fromDate;
            state.searchReportISC03InfoRef.toDate = toDate;
        },

        resetSearchState03Info: (state) => {
            state.searchReportISC03Info = initialStateSearchReportISC03Info;
        },
        resetSearchState03InfoRef: (state) => {
            state.searchReportISC03InfoRef = initialStateSearchReportISC03InfoRef;
        },
        /* isc03 */
    },
});

export const {
    /* isc01 */
    setSearchReportISC,
    setSearchReportISCInfo,
    setSearchReportISCInfoRef,

    // reset
    resetSearchStateInfo,
    resetSearchStateInfoRef,
    /* isc01 */

    /* isc02 */
    setSearchReportISC02,
    setSearchReportISC02Info,
    setSearchReportISC02InfoRef,

    // reset
    resetSearchState02Info,
    resetSearchState02InfoRef,
    /* isc02 */

    /* isc03 */
    setSearchReportISC03,
    setSearchReportISC03Info,
    setSearchReportISC03InfoRef,

    // reset
    resetSearchState03Info,
    resetSearchState03InfoRef,
    /* isc02 */

} = searchSlice.actions;

export const selectSearch = (state) => state.search;

export default searchSlice.reducer;
