//2. รายได้และผลการดำเนินงานการบริการประกันภัย
import React, { useEffect, useState, useRef } from 'react'
import { InputNumber, Input, Button, Col, Row, Flex, Form } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

function InsuranceServices({ setDataRows, setSectionId, dataSection, setSubmittable, checkTextInput }) {
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const { setFieldsValue, getFieldValue } = form
  const inputRef = useRef(null);
  const [result, setResult] = useState({
    27: 0,
    28: 0,
    29: 0,
    30: 0
  });

  useEffect(() => {
    if (dataSection.length == 0 || dataSection == null) {
      setFieldsValue({
        items: [
          {
            item_name: '',
            details: [
              { msd_detail_id: 27, value: '0.00' },
              { msd_detail_id: 28, value: '0.00' },
              { msd_detail_id: 29, value: '0.00' },
              { msd_detail_id: 30, value: '0.00' },
            ]
          },
        ]
      })
    } else {
      setFieldsValue({
        items: dataSection
      })
    }
    setSectionId(3)
    resulTotal()
  }, [dataSection]);

  const validateFields = (fields) => {
    for (const item of fields.items) {
      if (item.item_name?.trim() === '') {
        return setSubmittable(false);
      }
      for (const detail of item.details) {
        if (detail.value === '' || detail.value === null) {
          return setSubmittable(false);
        }
      }
    }
    return setSubmittable(true);
  }

  const formatNumber = (value) => {
    return `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const resulTotal = () => {
    const values = form.getFieldsValue();
    const formatNumberString = (num) => {
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    };
    const convertString = values?.items.map(item => ({
      ...item,
      details: item.details.map(detail => ({
        ...detail,
        value: formatNumberString(detail.value)
      }))
    }));
    form.setFieldsValue({
      items: convertString
    });
    const details = convertString.flatMap(item => item.details);

    const sumByDetailId = details.reduce((acc, curr) => {

      if (!acc[curr.msd_detail_id]) {
        acc[curr.msd_detail_id] = 0;
      }
      acc[curr.msd_detail_id] += parseFloat(curr.value.replace(/,/g, '')) || 0;
      return acc;
    }, {});
    setDataRows(form.getFieldsValue('items'));
    setResult(sumByDetailId);
    validateFields(form.getFieldsValue('items'));
  };

  const addField = () => {
    const values = form.getFieldsValue();
    const newItem = {
      item_name: '',
      details: [
        { msd_detail_id: 27, value: '0.00' },
        { msd_detail_id: 28, value: '0.00' },
        { msd_detail_id: 29, value: '0.00' },
        { msd_detail_id: 30, value: '0.00' },
      ]
    };
    form.setFieldsValue({
      items: [...(values.items || []), newItem]
    });
    setDataRows(form.getFieldsValue('items'));
    validateFields(form.getFieldsValue('items'));
  }

  const removeField = (index) => {
    const formData = form.getFieldValue();
    const updatedItems = (formData.items || []).filter((_, i) => i !== index);
    form.setFieldsValue({
      items: updatedItems
    });
    resulTotal();
    setDataRows(form.getFieldsValue('items'));
  }

  const handleChange = (e) => {
    let { value } = e.target;
    value = value.replace(/[^0-9\.,-]/g, '');
    const negativeSign = value.startsWith('-') ? '-' : '';
    const [integerPart, decimalPart] = value.replace('-', '').split('.');
    const notManusintegerPart = integerPart.replace(/[^0-9\.,]/g, '');
    const resetComma = notManusintegerPart.replace(/,/g, '');
    const formattedInteger = resetComma.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    e.target.value = decimalPart !== undefined ? `${negativeSign}${formattedInteger}.${decimalPart.replace(/-/g, '')}` : `${negativeSign}${formattedInteger}`;
  };

  useEffect(() => {
    function incrementInputId(inputId) {
      const result = inputId.match(/(\d+)$/);
      if (result) {
        const num = parseInt(result[0], 10) + 1;
        return inputId.replace(/(\d+)$/, num.toString());
      }
      return inputId;
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const inputs = Array.from(document.querySelectorAll('.input-field'));
        const newInputId = incrementInputId(document.activeElement.id);

        const nextInput = document.getElementById(newInputId);
        if (nextInput) {
          nextInput.focus();
        } else {

        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Col style={{ width: '100%' }}>
      <Flex horizontal >
        <div className='bg-[#184E84] pt-1 mr-3 rounded-t-2xl w-full'>
          <Row style={
            {
              backgroundColor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              borderWidth: '2px',
              borderColor: '#E5EBEB',
              width: '100%',
            }
          }>
            <Flex horizontal justify='space-between' align='center' style={{
              width: '100%',
              borderRadius: 6,
              padding: 10,
            }}>
              <Col style={{ textAlign: 'center', width: '20%', fontWeight: 600 }}>
                ประเภท
              </Col>
              <Col style={{ textAlign: 'center', width: '20%', fontWeight: 600 }}>
                รายได้จากการประกันภัย
              </Col>
              <Col style={{ textAlign: 'center', width: '20%', fontWeight: 600 }}>
                ค่าใช้จ่ายในการบริการประกันภัย
              </Col >
              <Col style={{ textAlign: 'center', width: '20%', fontWeight: 600 }}>
                ค่าใช้จ่ายสุทธิจากสัญญา<br />ประกันภัยต่อที่ถือไว้ทั้งหมด
              </Col>
              <Col style={{ textAlign: 'center', width: '20%', fontWeight: 600 }}>
                ผลการดำเนินงานการบริการประกันภัย
              </Col>
            </Flex>
          </Row>
        </div>
        <Row style={{ width: '50px' }}></Row>
      </Flex>
      <Flex horizontal>
        <Flex horizontal align='center' style={{
          width: '100%',
          padding: 10,
          gap: '8px'
        }}>
          <Col style={{ width: '20%', textAlign: 'end' }}>รวม</Col>
          <Col style={{
            textAlign: 'right', width: '20%', whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '0.5rem',
            display: 'block',
            backgroundColor: '#E5EBEB'
          }}>
            <div className='w-full text-[#464646] text-right'>{formatNumber(result[27])}</div>
          </Col>
          <Col style={{
            textAlign: 'right', width: '20%', whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '0.5rem',
            backgroundColor: '#E5EBEB',
            display: 'block'
          }}>
            <div className='w-full text-[#464646] text-right'>{formatNumber(result[28])}</div>
          </Col >
          <Col style={{
            textAlign: 'right', width: '20%', whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '0.5rem',
            display: 'block',
            backgroundColor: '#E5EBEB'
          }}>
            <div className='w-full text-[#464646] text-right'>{formatNumber(result[29])}</div>
          </Col>
          <Col style={{
            textAlign: 'right', width: '20%', whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            padding: '0.5rem',
            backgroundColor: '#E5EBEB'
          }}>
            <div className='w-full text-[#464646] text-right'>{formatNumber(result[30])}</div>
          </Col>
        </Flex>
        <Row style={{ width: '50px' }}></Row>
      </Flex>
      <Form
        form={form}
      >
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Flex key={key}>
                  <Flex
                    style={{
                      width: '100%',
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 0,
                      gap: '5px'
                    }}>

                    <Form.Item
                      {...restField}
                      name={[name, 'item_name']}
                      style={{ width: '20%', textAlign: 'center' }}
                      validateStatus={checkTextInput == true ? form.getFieldValue('items')[name].item_name == '' ? 'error' : '' : ''}
                    >
                      <TextArea
                        className="input-field"
                        id={`input-${key}-0`}
                        autoSize={{ minRows: 1 }}
                        style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                        placeholder='-- กรุณากรอกข้อมูล --'
                        onBlur={resulTotal}
                      />
                    </Form.Item>
                    <Form.List name={[name, 'details']}>
                      {(detailFields, { add: addDetail, remove: removeDetail }) => (
                        <>
                          {detailFields.map(({ key: detailKey, name: detailName, fieldKey: detailFieldKey, ...detailRestField }, index) => (
                            <Row
                              style={{ textAlign: 'center', width: '20%' }}
                              id='input-insurance-services'
                            >
                              <Form.Item
                                {...detailRestField}
                                name={[detailName, 'value']}
                                style={{ width: '100%' }}
                              >
                                <Input
                                  className="input-field"
                                  id={`input-${key}-${detailKey + 1}`}
                                  controls={false}
                                  formatter={value => {
                                    return `${parseFloat(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                  }}
                                  onBlur={e => {
                                    const newDetails = form.getFieldValue(['items', name, 'details']);
                                    newDetails[detailName].value = formatNumber(Number(e.target.value.replace(/,/g, '')));
                                    form.setFieldsValue({
                                      items: [
                                        ...form.getFieldValue('items').slice(0, name),
                                        {
                                          ...form.getFieldValue(['items', name]),
                                          details: newDetails,
                                        },
                                        ...form.getFieldValue('items').slice(name + 1),
                                      ],
                                    });
                                    resulTotal();
                                  }}
                                  ref={inputRef}
                                  onFocusCapture={e => {
                                    setTimeout(() => {
                                      inputRef.current.select();
                                    }, 0);
                                    const value = form.getFieldValue('items')[name].details[detailName].value;
                                    const newDetails = form.getFieldValue(['items', name, 'details']);
                                    if (value === '0.00' || value === 0 || value === '') {
                                      newDetails[detailName].value = '';
                                    } else {
                                      newDetails[detailName].value = value;
                                    }
                                    form.setFieldsValue({
                                      items: [
                                        ...form.getFieldValue('items').slice(0, name),
                                        {
                                          ...form.getFieldValue(['items', name]),
                                          details: newDetails,
                                        },
                                        ...form.getFieldValue('items').slice(name + 1),
                                      ],
                                    });
                                  }}
                                  onChangeCapture={handleChange}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                      const nextIndex = index + 1;
                                      if (index < nextIndex) {
                                        const nextDetailFieldId = `input-${nextIndex}`;
                                        document.getElementById(nextDetailFieldId)?.focus();
                                      }
                                    }
                                  }}
                                  style={{
                                    width: '100%', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                />
                              </Form.Item>
                            </Row>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </Flex>
                  <Row style={{
                    width: '50px', whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                    {fields.length > 1 ?
                      <Button
                        onClick={() => removeField(name)}
                        style={{ width: '40px', background: '#FF6658', fontSize: '30px', color: '#FFFFFF', paddingBottom: 20 }}
                        icon={<MinusOutlined />}
                      /> : null
                    }
                  </Row>
                </Flex>
              ))}
              <Form.Item>
                <Button
                  onClick={addField}
                  style={{
                    marginTop: '40px',
                    borderStyle: 'dashed', // Set border style to dashed
                    borderColor: 'black', // Set border color
                    borderWidth: '1px',
                    width: '96%',// Set border width
                  }}>
                  เพิ่มรายการ
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>

    </Col >
  )
}

export default InsuranceServices