// src/components/TableReportISCInfoRef.js
import { Button, Col, Empty, Image, Row, Table, Tooltip, Typography } from 'antd';
import React from 'react';
import { IconCircleClose, IconFileExcel, IconFileExcelFill, IconFileInfoFill, IconSearch, IconViewMore } from '../../../helper/icon';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateFormat } from '../../../helper/date';
import { convertStatusInfoRef } from '../fn';
import { stringDefault } from '../../../helper/base';
import { initialStatePagination, setPage } from '../../../features/redux/slice/paginationSlice';
const { Column } = Table;


const TableReportISCInfoRef = ({
    dataList = [],
    pagination = {},
    onClickInfo = () => null,
    onClickFileExcel = () => null,
    onClickFileExcelErr = () => null,
    onClickSearch = () => null,
}) => {
    const dispatch = useDispatch();
    const {
        page = initialStatePagination.page,
        page_size = initialStatePagination.pageSize,
        total = initialStatePagination.total,
        total_pages = initialStatePagination.totalPages,
    } = pagination

    const data = dataList;

    const tooltipInformation = ''

    const TooltipCustom = ({ children, title }) => {
        return (
            <Tooltip
                key={`tooltip-information-isc01-report-ref-${title}`}
                overlayClassName="tooltip-information-isc01-report-ref"
                placement="topRight"
                color="#FFFFFF"
                // autoAdjustOverflow={true}
                arrow={false}
                title={
                    <Row className="w-full">
                        <Typography.Text style={{ fontSize: "14px" }}>{title}</Typography.Text>
                    </Row>
                }
            >
                {children}
            </Tooltip>
        )
    }

    return (
        <Table
            dataSource={data}
            scroll={{ x: true }}
            className="full-width-table"
            rowKey={"row_no"}
            pagination={{
                onChange: (page, pageSize) => {
                    dispatch(setPage(page));
                },
                showSizeChanger: false,
                current: page,
                pageSize: page_size,
                total: total,
                showQuickJumper: true,
                showTotal: (t, range) =>
                    `แสดงข้อมูล  ${range[0]} -  ${range[1]} จาก  ${total} รายการ`, // `แสดงข้อมูล 1 - 17 จาก 17 รายการ`
            }}
            locale={{
                emptyText: <Empty style={{ padding: '25px', }} imageStyle={{ display: 'none', }} description='- กรุณากดปุ่ม Import -' />,
            }}
        >
            <Column className="/!min-w-[120px] /!max-w-[120px] whitespace-nowrap" align="start" title="Upload ครั้งที่" dataIndex="upload_count" key="upload_count" render={(text, record) => text} />
            <Column className="/!min-w-[100px] /!max-w-[100px] whitespace-nowrap" align="start" title="วันที่ส่ง" dataIndex="created_at" key="created_at" render={(text, record) => <Typography.Paragraph>{convertDateFormat(text, 'dateTime') || '-'}</Typography.Paragraph>} />
            <Column className="/!min-w-[95px] /!max-w-[95px] whitespace-nowrap" align="start" title="ผู้ส่งล่าสุด" dataIndex="submitter" key="submitter" render={(text, record) => <Typography.Paragraph>{stringDefault(text, '-')}</Typography.Paragraph>} />
            <Column className="/!min-w-[95px] /!max-w-[95px] whitespace-nowrap" align="start" title="จำนวนรายงาน" dataIndex="report_count" key="report_count" render={(text, record) => text} />
            <Column className="/!min-w-[95px] /!max-w-[95px] whitespace-nowrap" align="start" title="จำนวน Error" dataIndex="error_count" key="error_count" render={(text, record) => text} />
            <Column className="/!min-w-[95px] /!max-w-[95px] whitespace-nowrap" align="start" title="สถานะ" dataIndex="verify_status" key="verify_status" render={(text, record) => <Typography.Paragraph style={{ color: convertStatusInfoRef(text).color }}>{stringDefault(convertStatusInfoRef(text).text, '-')}</Typography.Paragraph>} />

            <Column className="/!min-w-[95px] /!max-w-[95px] whitespace-nowrap"
                align='center'
                title="เครื่องมือ"
                key="action"
                render={(text, record) => (
                    <Row gutter={[2, 2]} justify={'center'} wrap={false}>
                        {!!record.notes && (
                            <Col>
                                <Button
                                    type="text"
                                    onClick={() => onClickInfo(record)}
                                    style={{
                                        alignSelf: 'center',
                                        padding: 0,
                                    }}
                                >
                                    {/* <Tooltip title={record.notes} key='tooltip-information' style={{ verticalAlign: 'sub', }} > */}
                                    <Image
                                        width={25}
                                        src={IconFileInfoFill}
                                        preview={false}
                                        wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                    />
                                    {/* </Tooltip> */}
                                </Button>
                            </Col>
                        )}
                        {!!record.report_url && (
                            <Col>
                                <TooltipCustom title={'Input Report'}>
                                    <Button
                                        type="text"
                                        onClick={() => onClickFileExcel(record)}
                                        style={{
                                            alignSelf: 'center',
                                            padding: 0,
                                        }}
                                    >
                                        <Image
                                            width={25}
                                            src={IconFileExcel}
                                            preview={false}
                                            wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        />
                                    </Button>
                                </TooltipCustom>
                            </Col>
                        )}
                        {!!record.error_excel_url && (
                            <Col>
                                <TooltipCustom title={'Export Error'}>
                                    <Button
                                        type="text"
                                        onClick={() => onClickFileExcelErr(record)}
                                        style={{
                                            alignSelf: 'center',
                                            padding: 0,
                                        }}
                                    >
                                        <Image
                                            width={25}
                                            src={IconFileExcelFill}
                                            preview={false}
                                            wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        />
                                    </Button>
                                </TooltipCustom>
                            </Col>
                        )}
                        {/* {(record.approvalStatus !== 'กำลังตรวจสอบ (1/2)' && record.approvalStatus !== 'กำลังตรวจสอบ (2/2)') && ( */}
                        {(record.error_count != 0) && (
                            <Col>
                                <TooltipCustom title={'View Error'}>
                                    <Button
                                        type="text"
                                        onClick={() => onClickSearch(record)}
                                        style={{
                                            alignSelf: 'center',
                                            padding: 0,
                                        }}
                                    >
                                        <Image
                                            width={25}
                                            src={IconSearch}
                                            preview={false}
                                            wrapperStyle={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                            style={{ alignSelf: 'center', verticalAlign: 'sub' }}
                                        />
                                    </Button>
                                </TooltipCustom>
                            </Col>
                        )}
                    </Row>
                )}
            />
        </Table >
    );
};

export default TableReportISCInfoRef;
