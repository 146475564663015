import { ClearOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
// import locale from 'antd/es/date-picker/locale/th_TH';
// import 'moment/locale/th-th';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchReportCycleSubmissionGetDetail } from '../../../features/redux/apiSlice/apiReportISCSlice';
import { initialStatePagination, resetPagination } from '../../../features/redux/slice/paginationSlice';
import { initialStateSearchReportISCInfoRef, resetSearchStateInfoRef, setSearchReportISCInfoRef } from '../../../features/redux/slice/searchSlice';
import { customFormat, stringDefault } from '../../../helper/base';
import BoxDueDate from '../../BoxDueDate';
const { Option } = Select;


const CardSearchReportISCInfoRef = ({ nameReportISC = '', refReportISC = '' }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { pagination } = useSelector(state => state.pagination);
    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const {
        searchReportISCInfoRef,
        tableReportISCInfoRef,
        company_id,
    } = useSelector(state => ({
        companyInfo: state.user.companyInfo,
        searchReportISCInfoRef: state.search.searchReportISCInfoRef,
        tableReportISCInfoRef: state.table.tableReportISCInfoRef,
    }));

    const {
        keyword = '',
        cycleId = '',
        verifyStatus = [],
        fromDate = '',
        toDate = '',
    } = searchReportISCInfoRef

    const {
        meta = {},
        data = {},
    } = tableReportISCInfoRef

    const {
        // company_id = null,
        submission_id = '',
        reference_code = null,
        cycle_name = '',
        cycle_id = null,
        is_edit_request = false,
        start_date = '',
        current_date = '',
        due_date = '',
        template_url = '',
        upload_items = [],
        verify_status_list = [],
    } = data

    const {
        page = null,
        pageSize = [],
        total = [],
    } = pagination;

    const onFinish = (values) => {
        const payload = {
            keyword: values.keyword || '',
            cycleId: values.cycleId || null,
            verifyStatus: values.verifyStatus ? [...values.verifyStatus] : [],
            fromDate: values.fromDate || '',
            toDate: values.toDate || '',
        };

        dispatch(resetPagination())
        dispatch(setSearchReportISCInfoRef(payload)); // Update local search state
        dispatch(fetchReportCycleSubmissionGetDetail(
            {
                "company_id": company_id,
                "cycle_id": infoId,
                "submission_id": submissionId,
                "filter": {
                    "keyword": values.keyword,
                    "to_date": values.toDate,
                    "from_date": values.fromDate,
                    "verify_status": values.verifyStatus,
                },
                "pagination": {
                    "page": initialStatePagination.page,
                    "page_size": initialStatePagination.pageSize,
                }
            }
        ));
    };

    const onReset = () => {
        dispatch(resetPagination())
        dispatch(resetSearchStateInfoRef());  // ทำการรีเซ็ต state ใน Redux
        form.resetFields();           // รีเซ็ต fields ของ form
        dispatch(fetchReportCycleSubmissionGetDetail(
            {
                "company_id": company_id,
                "cycle_id": infoId,
                "submission_id": submissionId,
                "filter": {
                    "keyword": initialStateSearchReportISCInfoRef.keyword,
                    "to_date": initialStateSearchReportISCInfoRef.toDate,
                    "from_date": initialStateSearchReportISCInfoRef.fromDate,
                    "verify_status": initialStateSearchReportISCInfoRef.verifyStatus,
                },
                "pagination": {
                    "page": initialStatePagination.page,
                    "page_size": initialStatePagination.pageSize,
                }
            }
        ));
    };

    return (
        <Card
            extra={<BoxDueDate isShow={true} />}
            title={`เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC, '-')}`}
            classNames={{ header: 'ant-card-head-search-report-isc', title: 'min-w-[130px]', }}
            styles={{
                title: {
                    fontSize: 20,
                    color: '#000000',
                }
            }}
        >
            <Form form={form} onFinish={onFinish}>
                <Row gutter={[16, 16]} style={{ width: "100%", margin: 0 }}>
                    <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                        <Col flex="1 1 250px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>ค้นหา เลขที่อ้างอิงข้อมูล, ชื่อผู้ส่งล่าสุด</Typography.Text>
                                    <Form.Item name="keyword" style={{ width: "100%", margin: 0 }}>
                                        <Input
                                            placeholder="-- ระบุคำที่ต้องการค้นหา --"
                                            value={keyword}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>สถานะ</Typography.Text>
                                    <Form.Item name="verifyStatus" style={{ width: "100%", margin: 0 }}>
                                        <Select
                                            mode="multiple"
                                            placeholder="-- เลือกสถานะ --"
                                            value={verifyStatus}
                                            style={{ width: '100%' }}
                                            className='select-scroll'
                                        >
                                            {verify_status_list.map(option => (
                                                <Option key={`status-list-${option}`} value={option}>{option}</Option>
                                            ))}
                                        </Select></Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>วันที่ส่งล่าสุด</Typography.Text>
                                    <Form.Item name="fromDate" style={{ width: "100%", margin: 0 }}>
                                        <DatePicker
                                            format={customFormat}
                                            placeholder="DD/MM/YYYY"
                                            style={{ width: '100%' }}
                                        /></Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px">
                            <Row gutter={[5, 5]}>
                                <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text className='text-[16px]' strong>ถึงวันที่</Typography.Text>
                                    <Form.Item name="toDate" style={{ width: "100%", margin: 0 }}>
                                        <DatePicker
                                            format={customFormat}
                                            placeholder="DD/MM/YYYY"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 200px"></Col>
                    </Row>

                    <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                        <Col>
                            <Row gutter={[16, 16]} justify="start" align="middle">
                                <Col>
                                    <Button type="primary" htmlType="submit" style={{ paddingLeft: '30px', paddingRight: '30px', minWidth: '180px' }} icon={<FileSearchOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ค้นหา</Button>
                                </Col>
                                <Col>
                                    <Button type="primary" onClick={onReset} style={{ background: 'var(--color-gray-primary)', minWidth: '180px' }} icon={<ClearOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />} > ล้างการค้นหา</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Form>
        </Card>
    );
};

export default CardSearchReportISCInfoRef;
