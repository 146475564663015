//4. พัฒนาการค่าสินไหมทดแทน
import React, { useEffect, useState, useRef } from 'react'
import { Input, Button, Col, Row, Flex, Form } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

function DevelopmentCompensation({ setDataRows, setSectionId, dataSection, setSubmittable, checkTextInput }) {
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const { setFieldsValue, getFieldValue } = form
  const [result, setResult] = useState({
    40: 0,
    41: 0,
  });
  const inputRef = useRef(null);

  const formatNumber = (value) => {
    return `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const onBlurName = (index, field, value) => {
    let formData = form.getFieldValue();
    setDataRows(form.getFieldsValue('items'));
    validateFields(formData);
  };

  const handleChange = (e) => {
    let { value } = e.target;
    value = value.replace(/[^0-9\.,-]/g, '');
    const negativeSign = value.startsWith('-') ? '-' : '';
    const [integerPart, decimalPart] = value.replace('-', '').split('.');
    const notManusintegerPart = integerPart.replace(/[^0-9\.,]/g, '');
    const resetComma = notManusintegerPart.replace(/,/g, '');
    const formattedInteger = resetComma.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    e.target.value = decimalPart !== undefined ? `${negativeSign}${formattedInteger}.${decimalPart.replace(/-/g, '')}` : `${negativeSign}${formattedInteger}`;
  };

  const validateFields = (fields) => {
    for (const item of fields.items) {
      if (item.item_name?.trim() === '') {
        return setSubmittable(false);
      }
      for (const detail of item.details) {
        if (detail.value === '' || detail.value === null) {
          return setSubmittable(false);
        }
      }
    }
    return setSubmittable(true);
  }

  const onBlurValue = (index, field, value) => {
    const values = form.getFieldsValue();
    const formatNumberString = (num) => {
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    };

    const convertString = values?.items.map(item => ({
      ...item,
      details: item.details.map(detail => ({
        ...detail,
        value: formatNumberString(detail.value)
      }))
    }));
    form.setFieldsValue({
      items: convertString
    });
    const details = convertString.flatMap(item => item.details);

    const sumByDetailId = details.reduce((acc, curr) => {

      if (!acc[curr.msd_detail_id]) {
        acc[curr.msd_detail_id] = 0;
      }
      acc[curr.msd_detail_id] += parseFloat(curr.value.replace(/,/g, '')) || 0;
      return acc;
    }, {});
    setDataRows(form.getFieldsValue('items'));
    setResult(sumByDetailId);
    validateFields(form.getFieldsValue('items'));
  };

  useEffect(() => {
    setSectionId(6);
    if (dataSection.length == 0) {
      setFieldsValue({
        items: [
          {
            item_name: '',
            details: [
              { msd_detail_id: 40, value: '0.00' },
              { msd_detail_id: 41, value: '0.00' },
            ]
          },
        ]
      })
    } else {
      setFieldsValue({
        items: dataSection
      })
    }
    onBlurValue();
  }, [dataSection]);

  useEffect(() => {
    function incrementInputId(inputId) {
      const result = inputId.match(/(\d+)$/);
      if (result) {
        const num = parseInt(result[0], 10) + 1;
        return inputId.replace(/(\d+)$/, num.toString());
      }
      return inputId;
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const inputs = Array.from(document.querySelectorAll('.input-field'));
        const newInputId = incrementInputId(document.activeElement.id);

        const nextInput = document.getElementById(newInputId);

        if (nextInput) {
          nextInput.focus();
        } else {

        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const addField = () => {
    const formData = form.getFieldValue();
    const newData = {
      item_name: '',
      details: [
        { msd_detail_id: 40, value: '0.00' },
        { msd_detail_id: 41, value: '0.00' },
      ]
    };
    form.setFieldsValue({
      items: [...(formData.items || []), newData]
    });

    setDataRows(form.getFieldsValue('items'));

    validateFields(form.getFieldValue());
  }

  const removeField = (index) => {
    const formData = form.getFieldValue();
    const updatedItems = (formData.items || []).filter((_, i) => i !== index);
    form.setFieldsValue({
      items: updatedItems
    });
    onBlurValue();
    setDataRows(form.getFieldsValue('items'));
  }



  return (
    <Col style={{ width: '100%' }}>
      <Row style={{ width: '100%', fontWeight: 700 }}>
        <Col style={{ width: '96%' }} span={30}>
          <div className='bg-[#184E84] pt-1 rounded-t-2xl'>
            <Row justify="start" align="top" style={
              {
                backgroundColor: 'white',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderWidth: '2px',
                borderColor: '#E5EBEB',
                paddingTop: '10px',
                paddingBottom: '5px',
              }
            }>
              <Col span={6} >
                <Row align="middle" justify='center' >
                  ปี
                </Row>
              </Col>
              <Col span={9} >
                <Flex justify='center'>
                  หนิ้สินสำหรับค่าสินไหมทดแทนที่เกิดขึ้นแล้ว (ก่อนการประกันภัยต่อ)
                </Flex>
              </Col>
              <Col span={9} >
                <Flex justify='center'>
                  หนิ้สินสำหรับค่าสินไหมทดแทนที่เกิดขึ้นแล้ว (สุทธิ)
                </Flex>
              </Col>
            </Row>
          </div>
        </Col>
        <Col style={{ width: '35px', paddingLeft: 10 }}>
        </Col>
      </Row>
      <Row style={{ width: '100%', marginTop: 10 }}>
        <Col style={{ width: '96%' }} span={30}>
          <Row justify="start" align="middle">
            <Col span={6} >
              <Flex justify='end' align='center'>
                รวม
              </Flex>
            </Col>
            <Col span={9} >
              <Row style={{
                paddingLeft: '5px',
                paddingRight: '0px',
                gap: '15px',
              }}>
                <Flex style={{
                  width: '100%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  textAlign: 'right'
                }} justify='end' align='center'>
                  {formatNumber(result[40])}
                </Flex>
              </Row>
            </Col>
            <Col span={9} >
              <Row style={{
                paddingLeft: '5px',
                paddingRight: '0px',
                gap: '15px',
              }}>
                <Flex style={{
                  width: '100%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  textAlign: 'right'
                }} justify='end' align='center'>
                  {formatNumber(result[41])}
                </Flex>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col style={{ width: '35px', paddingLeft: 10 }}>
        </Col>
      </Row>

      <Form form={form} style={{ marginTop: 20 }}>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, fieldIndex) => (
                <Row style={{ width: '100%' }} key={field.key}>
                  <Col style={{ width: '96%' }} span={30} id='input-insurance-services'>
                    <Row justify="start" align="start">
                      <Col span={6} >
                        <Form.Item
                          {...field}
                          name={[field.name, 'item_name']}
                          fieldKey={[field.fieldKey, 'item_name']}
                          validateStatus={checkTextInput == true ? form.getFieldValue('items')[field.name].item_name == '' ? 'error' : '' : ''}
                          style={{ width: '100%' }}
                        >
                          <TextArea
                            className="input-field"
                            id={`input-${field.key}-0`}
                            autoSize={{ minRows: 1 }}
                            style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                            placeholder="-- กรุณากรอกข้อมูล --"
                            onBlur={onBlurName}
                          />
                        </Form.Item>
                      </Col>
                      <Form.List name={[field.name, 'details']}>
                        {(detailFields) => (
                          <>
                            {detailFields.map((detailField, detailIndex) => (
                              <Col span={9} key={detailField.key} id='input-insurance-services'>
                                <Row style={{
                                  paddingLeft: '5px',
                                  paddingRight: '0px',
                                  gap: '15px',
                                }}>
                                  <Form.Item
                                    {...detailField.detailRestField}
                                    name={[detailField.name, 'value']}
                                    style={{ width: '100%' }}
                                  >
                                    <Input
                                      className="input-field"
                                      id={`input-${field.key}-${detailField.key + 1}`}
                                      controls={false}
                                      ref={inputRef}
                                      formatter={value => {
                                        return `${parseFloat(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                      }}
                                      onBlur={e => {
                                        const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                        newDetails[detailField.name].value = formatNumber(Number(e.target.value.replace(/,/g, '')));
                                        form.setFieldsValue({
                                          items: [
                                            ...form.getFieldValue('items').slice(0, field.name),
                                            {
                                              ...form.getFieldValue(['items', field.name]),
                                              details: newDetails,
                                            },
                                            ...form.getFieldValue('items').slice(field.name + 1),
                                          ],
                                        });
                                        onBlurValue();
                                      }}
                                      onFocusCapture={
                                        e => {
                                          setTimeout(() => {
                                            inputRef.current.select();
                                          }, 0);
                                          const value = form.getFieldValue('items')[field.name].details[detailField.name].value;
                                          const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                          if (value === '0.00' || value === 0 || value === '') {
                                            newDetails[detailField?.name].value = '';
                                          } else {
                                            newDetails[detailField?.name].value = `${value}`;
                                          }
                                          form.setFieldsValue({
                                            items: [
                                              ...form.getFieldValue('items').slice(0, field.name),
                                              {
                                                ...form.getFieldValue(['items', field.name]),
                                                details: newDetails,
                                              },
                                              ...form.getFieldValue('items').slice(field.name + 1),
                                            ],
                                          });
                                        }}
                                      onChangeCapture={handleChange}
                                      style={{
                                        width: '100%', whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    />
                                  </Form.Item>
                                </Row>
                              </Col>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Col>
                  <Col style={{ width: '35px', paddingLeft: 10 }}>
                    {fields.length > 1 ?
                      <Form.Item>
                        <Button
                          onClick={() => {
                            removeField(field.name)
                          }}
                          icon={<MinusOutlined />}
                          style={{ background: '#FF6658', color: 'white' }}
                        />
                      </Form.Item> : null
                    }
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button form={form}
                  onClick={addField}
                  style={{
                    marginTop: '20px',
                    borderStyle: 'dashed', // Set border style to dashed
                    borderColor: 'black', // Set border color
                    borderWidth: '1px',
                    width: '96%',// Set border width
                  }}>
                  เพิ่มรายการ
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form >

    </Col >
  )
}

export default DevelopmentCompensation