import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

// Configure persist options
const persistConfig = {
    key: 'iCert',
    storage,
    whitelist: ['sidebar', 'user','detailNote'],
    // blacklist: [
    //     'countdown',
    //     'modal',
    //     'pagination',
    //     'search',
    //     'sidebar',
    //     'table',
    // ],
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create store with combined reducers and saga middleware
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        // getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
        getDefaultMiddleware({
            thunk: false, // Disable thunk as we are using saga
            serializableCheck: {
                // Ignore these action types during serializability check
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
            },
        }).concat(sagaMiddleware),
});

// Run root saga
sagaMiddleware.run(rootSaga);

// Create persistor for persisting data
export const persistor = persistStore(store);
