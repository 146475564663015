// src/components/ReportISCO02DetailNote/LifeYear.js
import React, { useEffect, useState, useRef } from 'react'
import { Select, Col, Row, Button, Image, Input, InputNumber, Typography, Form } from 'antd';
import { IconViewMoreOrange } from "../../../helper/icon";
import { useDispatch } from 'react-redux';
import { setDetailNoteDataInput } from '../../../features/redux/slice/detailNoteSlice';


function Year2({
    onClickViewMore = () => null, setSubmit,
    setUnit, unit, setDataType, dataType, setInputData, dataError, dataInput, setCheckInputValue,
    sectionOne, sectionTwo, sectionThree, sectionFour, sectionFive, sectionSix, sectionSeven,
    sectionEigth, sectionNine, sectionTen, sectionEleven, sectionTwelve, checkTextInput
}) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { setFieldsValue, getFieldValue } = form
    const inputRef = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const [inputNewData, setInputNewData] = useState([])

    const handleChangeValue = (value, msd_detail_id) => {
        const newDataInput = dataInput.map(item => {
            if (item.msd_detail_id === msd_detail_id) {
                return { ...item, value: value };
            }
            return item;
        });
        const checkValuesNotNull = form.getFieldValue().items.every(item => item.value !== null);
        dispatch(setDetailNoteDataInput(
            {
                unit: unit,
                data_input: newDataInput,
                data_type: dataType,
            }
        ))
        setCheckInputValue(checkValuesNotNull);
        setInputData(newDataInput);
        setInputNewData(newDataInput);
    };

    const formatNumberToString = (number) => {
        return number.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const handleChange = (e) => {
        let { value } = e.target;
        value = value.replace(/[^0-9\.,-]/g, '');
        const negativeSign = value.startsWith('-') ? '-' : '';
        const [integerPart, decimalPart] = value.replace('-', '').split('.');
        const notManusintegerPart = integerPart.replace(/[^0-9\.,]/g, '');
        const resetComma = notManusintegerPart.replace(/,/g, '');
        const formattedInteger = resetComma.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        e.target.value = decimalPart !== undefined ? `${negativeSign}${formattedInteger}.${decimalPart.replace(/-/g, '')}` : `${negativeSign}${formattedInteger}`;

    };

    useEffect(() => {
        const data = dataInput?.map(item => ({
            ...item,
            value: item.value !== null ? `${item.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0.00'
        }));
        setFieldsValue({
            items: data
        })
        setInputNewData(data);
    }, [dataInput, unit, dataType]);

    const formatNumber = (value) => {
        return `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const selectedCurrencyUnit = (value) => {
        setUnit(value);
        dispatch(setDetailNoteDataInput(
            {
                unit: value,
                data_input: inputNewData,
                data_type: dataType,
            }
        ))
    };

    const selectedCompensationDataType = (value) => {
        setDataType(value);
        dispatch(setDetailNoteDataInput(
            {
                unit: unit,
                data_input: inputNewData,
                data_type: value,
            }
        ))
    };

    useEffect(() => {   
        function incrementInputId(inputId) {
            const result = inputId.match(/(\d+)$/);
            if (result) {
                const num = parseInt(result[0], 10) + 1;
                return inputId.replace(/(\d+)$/, num.toString());
            }
            return inputId;
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const inputs = Array.from(document.querySelectorAll('.input-field'));
                const newInputId = incrementInputId(document.activeElement.id);

                const nextInput = document.getElementById(newInputId);

                if (nextInput) {
                    nextInput.focus();
                } else {

                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return <div>
        <Col style={{ paddingLeft: '32px', paddingRight: '32px', paddingTop: '12px' }}>
            <div className='flex justify-between items-center'>
                <p className='text-[16px]'>
                    2. รายละเอียดข้อมูลหมายเหตุประกอบงบการเงิน
                    <span className='text-[16px] text-red-600 font-bold pl-2'>*</span>
                </p>
                <div className='flex items-center gap-5'>
                    <p>หน่วย :</p>
                    <Select
                        defaultValue={unit == '' ? 'บาท' : unit}
                        value={unit == '' ? 'บาท' : unit}
                        style={{ width: 200, borderColor: '#E5EBEB', color: 'black' }}
                        onChange={selectedCurrencyUnit}
                        options={[
                            {
                                value: 'บาท',
                                label: <span className={`${dataError?.length > 0 ? dataError[0]?.finance_unit != 'บาท' ? unit == 'บาท' ? 'text-red-600' : '' : '' : ''}`}>บาท</span>

                            },
                            {
                                value: 'พันบาท',
                                label: <span className={`${dataError?.length > 0 ? dataError[0]?.finance_unit != 'พันบาท' ? unit == 'พันบาท' ? 'text-red-600' : '' : '' : ''}`}>พันบาท</span>
                            },
                            {
                                value: 'ล้านบาท',
                                label: <span className={`${dataError?.length > 0 ? dataError[0]?.finance_unit != 'ล้านบาท' ? unit == 'ล้านบาท' ? 'text-red-600' : '' : '' : ''}`}>ล้านบาท</span>
                            },
                        ]}
                    />
                </div>
            </div>
        </Col>
        {/* 1. องค์ประกอบของงบแสดงฐานะการเงิน */}
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '32px', paddingTop: '12px' }} >
            <div className='flex flex-col gap-3'>
                <div className='flex items-center text-[#464646] text-base font-bold'>
                    1. องค์ประกอบของงบแสดงฐานะการเงิน
                </div>
                <div className='pl-4 flex items-center text-[#464646] text-base font-bold'>
                    วันสิ้นรอบระยะเวลาระหว่างกาลปีปัจจุบัน (สิ้นงวดปัจจุบัน)
                </div>
            </div>
            <Form form={form}>
                <Row style={{ paddingTop: '20px', alignContent: 'center', height: '43px' }} id='input-insurance-services'>
                    <Row style={{ width: 700, paddingLeft: '1rem', color: '#464646' }}>
                        สินทรัพย์หมุนเวียน
                        <Typography style={{ color: 'red' }}> *</Typography>
                    </Row>
                    <Form.Item
                        rules={[{ required: true, message: '' }]}
                    >
                        <Input
                            id='input-0-0'
                            controls={false}
                            style={{
                                width: '14rem', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onChangeCapture={handleChange}
                            value={dataInput[0]?.value}
                            ref={inputRef[0]}
                            onChange={(e) => handleChangeValue(e.target.value, 74)}
                            onBlur={(e) => {
                                const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                                handleChangeValue(formattedValue, 74);
                            }}
                            onFocusCapture={e => {
                                setTimeout(() => {
                                    inputRef[0].current.select();
                                }, 0);
                                const rawValue = dataInput[0]?.value;
                                if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                    handleChangeValue('', 74);
                                } else {

                                    handleChangeValue(rawValue, 74);
                                }
                            }}
                        />
                    </Form.Item>

                </Row>
                <Row style={{ paddingTop: '0px', height: '43px' }} id='input-insurance-services'>
                    <Col style={{ width: 700 }}>
                        <div className='pl-4 flex items-center text-[#464646]'>
                            สินทรัพย์ไม่หมุนเวียน <span className='text-red-700'>*</span>
                        </div>
                    </Col>
                    <Form.Item
                        rules={[{ required: true, message: '' }]}
                    >
                        <Input
                            id='input-0-1'
                            controls={false}
                            style={{
                                width: '14rem', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            ref={inputRef[1]}
                            onChangeCapture={handleChange}
                            value={dataInput[1]?.value}
                            onChange={(e) => handleChangeValue(e.target.value, 75)}
                            onBlur={(e) => {
                                const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                                handleChangeValue(formattedValue, 75);
                            }}
                            onFocusCapture={e => {
                                setTimeout(() => {
                                    inputRef[1].current.select();
                                }, 0);
                                const rawValue = dataInput[1]?.value;
                                if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                    handleChangeValue('', 75);
                                } else {
                                    handleChangeValue(rawValue, 75);
                                }
                            }}
                        />
                    </Form.Item>
                </Row>
                <Row style={{ paddingTop: '0px', height: '43px' }} id='input-insurance-services'>
                    <Col style={{ width: 700 }}>
                        <div className='pl-4 flex items-center text-[#464646]'>
                            หนี้สินหมุนเวียน <span className='text-red-700'>*</span>
                        </div>
                    </Col>
                    <Form.Item
                        rules={[{ required: true, message: '' }]}
                    >
                        <Input
                            id='input-0-2'
                            controls={false}
                            style={{
                                width: '14rem', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onChangeCapture={handleChange}
                            ref={inputRef[2]}
                            value={dataInput[2]?.value}
                            onChange={(e) => handleChangeValue(e.target.value, 76)}
                            onBlur={(e) => {
                                const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                                handleChangeValue(formattedValue, 76);
                            }}
                            onFocusCapture={e => {
                                setTimeout(() => {
                                    inputRef[2].current.select();
                                }, 0);
                                const rawValue = dataInput[2]?.value;
                                if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                    handleChangeValue('', 76);
                                } else {
                                    handleChangeValue(rawValue, 76);
                                }
                            }}
                        />
                    </Form.Item>
                </Row>
                <Row style={{ paddingTop: '0px', height: '43px' }} id='input-insurance-services'>
                    <Col style={{ width: 700 }}>
                        <div className='pl-4 flex items-center text-[#464646]'>
                            หนี้สินไม่หมุนเวียน <span className='text-red-700'>*</span>
                        </div>
                    </Col>
                    <Form.Item
                        rules={[{ required: true, message: '' }]}
                    >
                        <Input
                            id='input-0-3'
                            controls={false}
                            style={{
                                width: '14rem', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onChangeCapture={handleChange}
                            value={dataInput[3]?.value}
                            ref={inputRef[3]}
                            onChange={(e) => handleChangeValue(e.target.value, 77)}
                            onBlur={(e) => {
                                const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                                handleChangeValue(formattedValue, 77);
                            }}
                            onFocusCapture={e => {
                                setTimeout(() => {
                                    inputRef[3].current.select();
                                }, 0);
                                const rawValue = dataInput[3]?.value;
                                if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                    handleChangeValue('', 77);
                                } else {
                                    handleChangeValue(rawValue, 77);
                                }
                            }}
                        />
                    </Form.Item>
                </Row>
            </Form>
            {sectionOne?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '10px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end' style={{
                            color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('วันสิ้นรอบระยะเวลาระหว่างกาลปีปัจจุบัน (สิ้นงวดปัจจุบัน)', 1)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            <div className='pl-4 flex items-center text-[#464646] text-base font-bold'>
                วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)
            </div>
            <Row style={{ paddingTop: '20px', alignContent: 'center', height: '43px' }} id='input-insurance-services'>
                <Col style={{ width: 700 }}>
                    <div className='pl-4 flex items-center text-[#464646]'>
                        สินทรัพย์หมุนเวียน <span className='text-red-700'>*</span>
                    </div>
                </Col>
                <Form.Item
                    rules={[{ required: true, message: '' }]}
                >
                    <Input
                        id='input-1-0'
                        controls={false}
                        style={{
                            width: '14rem', whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        onChangeCapture={handleChange}
                        value={dataInput[4]?.value}
                        ref={inputRef[4]}
                        onChange={(e) => handleChangeValue(e.target.value, 78)}
                        onBlur={(e) => {
                            const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                            handleChangeValue(formattedValue, 78);
                        }}
                        onFocusCapture={e => {
                            setTimeout(() => {
                                inputRef[4].current.select();
                            }, 0);
                            const rawValue = dataInput[4]?.value;
                            if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                handleChangeValue('', 78);
                            } else {
                                handleChangeValue(rawValue, 78);
                            }
                        }}
                    />
                </Form.Item>
            </Row>
            <Row style={{ paddingTop: '0px', height: '43px' }} id='input-insurance-services'>
                <Col style={{ width: 700 }}>
                    <div className='pl-4 flex items-center text-[#464646]'>
                        สินทรัพย์ไม่หมุนเวียน <span className='text-red-700'>*</span>
                    </div>
                </Col>
                <Form.Item
                    rules={[{ required: true, message: '' }]}
                >
                    <Input
                        id='input-1-1'
                        controls={false}
                        style={{
                            width: '14rem', whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        onChangeCapture={handleChange}
                        ref={inputRef[5]}
                        value={dataInput[5]?.value}
                        onChange={(e) => handleChangeValue(e.target.value, 79)}
                        onBlur={(e) => {
                            const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                            handleChangeValue(formattedValue, 79);
                        }}
                        onFocusCapture={e => {
                            setTimeout(() => {
                                inputRef[5].current.select();
                            }, 0);
                            const rawValue = dataInput[5]?.value;
                            if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                handleChangeValue('', 79);
                            } else {
                                handleChangeValue(rawValue, 79);
                            }
                        }}
                    />
                </Form.Item>
            </Row>
            <Row style={{ paddingTop: '0px', height: '43px' }} id='input-insurance-services'>
                <Col style={{ width: 700 }}>
                    <div className='pl-4 flex items-center text-[#464646]'>
                        หนี้สินหมุนเวียน <span className='text-red-700'>*</span>
                    </div>
                </Col>
                <Form.Item
                    rules={[{ required: true, message: '' }]}
                >
                    <Input
                        id='input-1-2'
                        controls={false}
                        style={{
                            width: '14rem', whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        onChangeCapture={handleChange}
                        ref={inputRef[6]}
                        value={dataInput[6]?.value}
                        onChange={(e) => handleChangeValue(e.target.value, 80)}
                        onBlur={(e) => {
                            const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                            handleChangeValue(formattedValue, 80);
                        }}
                        onFocusCapture={e => {
                            setTimeout(() => {
                                inputRef[6].current.select();
                            }, 0);
                            const rawValue = dataInput[6]?.value;
                            if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                handleChangeValue('', 80);
                            } else {
                                handleChangeValue(rawValue, 80);
                            }
                        }}
                    />
                </Form.Item>
            </Row>
            <Row style={{ paddingTop: '0px', height: '43px' }} id='input-insurance-services'>
                <Col style={{ width: 700 }}>
                    <div className='pl-4 flex items-center text-[#464646]'>
                        หนี้สินไม่หมุนเวียน <span className='text-red-700'>*</span>
                    </div>
                </Col>
                <Form.Item
                    rules={[{ required: true, message: '' }]}
                >
                    <Input
                        id='input-1-3'
                        controls={false}
                        style={{
                            width: '14rem', whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        onChangeCapture={handleChange}
                        ref={inputRef[7]}
                        value={dataInput[7]?.value}
                        onChange={(e) => handleChangeValue(e.target.value, 81)}
                        onBlur={(e) => {
                            const formattedValue = formatNumberToString(Number(e.target.value.replace(/,/g, '')));
                            handleChangeValue(formattedValue, 81);
                        }}
                        onFocusCapture={e => {
                            setTimeout(() => {
                                inputRef[7].current.select();
                            }, 0);
                            const rawValue = dataInput[7]?.value;
                            if (rawValue === '0.00' || rawValue === 0 || rawValue === '' || rawValue === null) {
                                handleChangeValue('', 81);
                            } else {
                                handleChangeValue(rawValue, 81);
                            }
                        }}
                    />
                </Form.Item>
            </Row>
            {sectionTwo?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '10px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)', 2)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
        {/* 2. รายได้และผลการดำเนินงานการบริการประกันภัย */}
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '32px' }} >
            <div className='flex items-center text-[#464646] text-base font-bold'>
                2. รายได้และผลการดำเนินงานการบริการประกันภัย
            </div>
            {sectionThree?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '10px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('2. รายได้และผลการดำเนินงานการบริการประกันภัย', 3)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
        {/* 3. จำนวนเงินที่ได้พิจารณาตามวิธีปฏิบัติในช่วงเปลี่ยนผ่าน */}
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '32px' }} >
            <div className='flex items-center text-[#464646] text-base font-bold'>
                3. จำนวนเงินที่ได้พิจารณาตามวิธีปฏิบัติในช่วงเปลี่ยนผ่าน
            </div>
            <div className='flex items-center text-[#464646] text-base pl-4'>
                (ไม่รวมสัญญาประกันภัยที่วัดมูลค่าภายใต้วิธีการปันส่วนเบี้ยประกันภัย)
            </div>
            <Row style={{ paddingTop: '12px', paddingLeft: '1rem', fontWeight: 700 }}>
                สัญญาประกันภัยที่ออก
            </Row>
            <Row style={{ paddingTop: '5px', paddingLeft: '1rem', textDecoration: 'underline' }}>
                รายได้จากการประกันภัย
            </Row>
            {sectionFour?.slice(0, 3).map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '5px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('สัญญาประกันภัยที่ออก', 4)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            <Row style={{ paddingTop: '5px', paddingBottom: '15px', paddingLeft: '1rem', textDecoration: 'underline' }}>
                กำไรจากการให้บริการตามสัญญา
            </Row>
            {sectionFour?.slice(3, 6).map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '10px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            <Row style={{ paddingTop: '20px', paddingLeft: '1rem', fontWeight: 700 }}>
                สัญญาประกันภัยต่อที่ถือไว้
            </Row>
            <Row style={{ paddingTop: '12px', paddingLeft: '1rem', textDecoration: 'underline' }}>
                กำไรจากการให้บริการตามสัญญา
            </Row>
            {sectionFive?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '5px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('สัญญาประกันภัยต่อที่ถือไว้', 5)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
        {/* 4. พัฒนาการค่าสินไหมทดแทน */}
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '32px' }} >
            <div className='flex items-center text-[#464646] text-base font-bold'>
                4. พัฒนาการค่าสินไหมทดแทน
            </div>
            <Row style={{ paddingTop: '12px' }}>
                <Col style={{ width: 700 }}>
                    <div className='pl-4 flex items-center text-[#464646]'>
                        ลักษณะข้อมูล  <span className='text-red-700'>*</span>
                    </div>
                </Col>
                <Form.Item
                    validateStatus={checkTextInput == true ? dataType == null|| dataType == '' ? 'error' : '' : ''}
                >
                    <Select
                        defaultValue={dataType == null ? null : dataType}
                        value={dataType == '' ? null : dataType}
                        style={{ width: '14rem', color: 'black' }}
                        onChange={selectedCompensationDataType}
                        placeholder='--เลือกลักษณะข้อมูล--'
                        options={[
                            { value: 'ปีรับประกันภัย', label: 'ปีรับประกันภัย' },
                            { value: 'ปีอุบัติเหตุ', label: 'ปีอุบัติเหตุ' },
                        ]}
                    />
                </Form.Item>
            </Row>
            {sectionSix?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '4px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('4. พัฒนาการค่าสินไหมทดแทน', 6)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
        {/* 5. การจัดประเภทสินทรัพย์ทางการเงินและหนี้สินทางการเงิน */}
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '32px' }} >
            <div className='flex items-center text-[#464646] text-base font-bold'>
                5. การจัดประเภทสินทรัพย์ทางการเงินและหนี้สินทางการเงิน
            </div>
            {sectionSeven?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: '10px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('สินทรัพย์ทางการเงิน', 7)}
                        />
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            {sectionEigth?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: '0px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('หนี้สินทางการเงิน', 8)}
                        />
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
        {/* 6. รายได้จากการลงทุนและค่าใช้จ่ายทางการเงินจากการประกันภัย */}
        <Col style={{ paddingLeft: '80px', paddingRight: '32px', paddingBottom: '32px' }} >
            <div className='flex items-center text-[#464646] text-base font-bold'>
                6. รายได้จากการลงทุนและค่าใช้จ่ายทางการเงินจากการประกันภัย
            </div>
            {sectionNine?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '5px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('การลงทุนสุทธิ', 9)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            {sectionTen?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '32px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('รายได้จากการลงทุนและค่าใช้จ่ายทางการเงินจากการประกันภัย สัญญาประกันภัยที่ออก', 10)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            {sectionEleven?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: index === 0 ? '32px' : index === 1 ? '0px' : '4px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('จำนวนเงินที่รับรู้ในงบกำไรขาดทุน', 11)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
            {sectionTwelve?.map((e, index) => {
                const error = dataError.find(err => err.note_msd_id === e.msd_detail_id);
                return (
                    <Row style={{ paddingBottom: '0px', paddingTop: '0px' }} key={e?.msd_detail_id}>
                        <Col style={{ width: 700 }}>
                            <div className='pl-4 flex items-center text-[#464646]'>
                                {e?.name_th}
                            </div>
                        </Col>
                        <div className='bg-[#E5EBEB] w-56 px-4 py-2 h-9 text-end'
                            style={{
                                color: error ? 'red' : '#464646', whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {e?.value == null ? '0.00' : formatNumber(Number(e?.value))}
                        </div>
                        {index === 0 ? <Button
                            style={{ marginLeft: '1.5rem', padding: 0, border: 'none' }}
                            icon={
                                <Image width={30} src={IconViewMoreOrange} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                            }
                            onClick={() => onClickViewMore('จำนวนเงินที่รับรู้ในงบกำไรขาดทุนเบ็ดเสร็จอื่น', 12)}
                        /> :
                            <Row style={{ marginLeft: '2rem', padding: 0, border: 'none', width: 30 }} />
                        }
                        {error && (
                            <Row style={{ marginLeft: '1rem', color: 'red' }}>
                                {error.error_message}
                            </Row>
                        )}
                    </Row>
                )
            })}
        </Col>
    </div>
};

export default Year2