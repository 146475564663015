import React, { useState } from 'react';
import { Carousel } from 'antd';
const ShowCarousel = ({ images }) => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);

  };
  const carouselStyle = {
    width: '100%',
    height: '637px',
    color: '#fff',
    lineHeight: '637px',
    textAlign: 'center',
    background: '#364d79',
  };
  const contentStyle = {
    width: '100%',
    height: '100%',
    color: '#fff',
    background: '#364d79',
    objectFit: 'cover',
    objectPosition: 'center',
  };
  const customDot = (dot, { index, isActive }) => {
    return (
      <span
        className={`my-dot ${isActive ? 'active' : ''}`}
        key={`customDot-${index}`}
      />
    );
  };
  return (
    <Carousel afterChange={onChange} dots={{ render: customDot }}>
      {images && (images.map(image => (
        <div
          key={`carousel-image-${image?.title}`}
          style={carouselStyle}
        >
          <div
            style={carouselStyle}
          >
            <img src={image.thumbnailUrl} alt={image.title} style={contentStyle} />
          </div>
        </div>
      )))}
    </Carousel>
    //  <div>
    //  {images.map(item => (
    //    <div key={item.id}>
    //      {/* Customize the content inside each carousel item */}
    //      <h3>{item.title}</h3>
    //      <p>{item.description}</p>
    //      <img src={item.thumbnailUrl} alt={item.title} />
    //    </div>
    //  ))}
    //    </div>
  );
};

export default ShowCarousel;