import axios from 'axios';
import { headers } from '../server/api/axios';

const downloadXLSFileTemplateISC = async () => {
    const serverUrl = window.location.origin;
    const filePath = '/files/template-isc01.xlsx';
    const fileUrl = serverUrl + filePath;
    const headers = { 'Content-Type': 'application/octet-stream' };
    const config = { method: 'GET', url: fileUrl, responseType: 'arraybuffer', headers };

    try {
        const outputFilename = `template-isc01.xlsx`;
        const response = await axios(config);
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        throw Error(error);
    }
};

const downloadFile = async (urlDownload = '', fileName = '', fileExtension = '') => {
    try {
        const response = await axios({
            url: urlDownload,
            method: 'GET',
            responseType: 'blob',
            headers: { ...headers },
            timeout: 60000,
        });

        // Use the provided filename or a default one if not found
        let filename = `${fileName}${fileExtension}`

        const contentDisposition = response.headers['content-disposition'] // || "attachment; filename=d132cfbd-dc5c-424d-8882-94cac4f6ce4f.xlsx; filename*=UTF-8''d132cfbd-dc5c-424d-8882-94cac4f6ce4f.xlsx";
        // console.log(response.headers.get('content-disposition'));
        let file = new Blob([response.data]);

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches && matches[1]) {
                filename = matches[1].replace(/['"]/g, ''); // Remove quotes if any
            }
        }
        console.log(`----  downloadFile  filename:`, filename);

        // Create a download link
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
        // Handle error if needed
    }
};


export {
    downloadFile, downloadXLSFileTemplateISC
};
