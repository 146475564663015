import controller from '../../api/controller';
import { logHost } from "../host/logHost";

class LogType {
    static User = "จัดการผู้ใช้งาน";
    static MasterData = "ดัชนีตารางอ้างอิงมาตรฐาน";
    static MasterDataCompany = "รหัสบริษัทประกันภัย";
    static Log = "ประวัติการใช้งานระบบ";
}

const getLogs = async (filter = { keyword: null, companyTypeId: null }, itemPerPage = 20, pageNo = 1) => {
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/log
         * {
         *     "keyword": string?,
         *     "menu": string?,
         *     "role": string?,
         *     "fromDate": datetime?,
         *     "toDate": datetime?,
         * }
         */

        const params = new URLSearchParams({ itemPerPage, pageNo });
        if (filter && filter.keyword) {
            params.set('keyword', filter.keyword)
        }
        if (filter && filter.menu) {
            params.set('menu', filter.menu)
        }
        if (filter && filter.role) {
            params.set('role', filter.role)
        }
        if (filter && filter.fromDate) {
            params.set('fromDate', filter.fromDate)
        }
        if (filter && filter.toDate) {
            params.set('toDate', filter.toDate)
        }
        const res = await controller.requestGet(`${logHost.logBase}?${params.toString()}`);
        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}
const exportLogs = async (filter = { keyword: null, companyTypeId: null }) => {
    // 
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/log/export
         * {
         *     "keyword": string?,
         *     "menu": string?,
         *     "role": string?,
         *     "fromDate": datetime?,
         *     "toDate": datetime?,
         * }
         */

        const params = new URLSearchParams();
        if (filter && filter.keyword) {
            params.set('keyword', filter.keyword)
        }
        if (filter && filter.menu) {
            params.set('menu', filter.menu)
        }
        if (filter && filter.role) {
            params.set('role', filter.role)
        }
        if (filter && filter.fromDate) {
            params.set('fromDate', filter.fromDate)
        }
        if (filter && filter.toDate) {
            params.set('toDate', filter.toDate)
        }

        const res = await controller.requestGet(`${logHost.logExportBase}?${params.toString()}`, {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/octet-stream",
            },
        });

        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const log = async (logType, desc = null) => {
    try {
        /*
         * NOTE - 
         * POST: {<!-- -->{host}}/api/log
         * {
         *     "result": string,
         *     "description": string?,
         * }
         */

        const res = await controller.requestPost(logHost.logBase, { result: logType, description: desc });

        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const getLogTypes = async () => {
    // 
    try {
        /*
         * NOTE - 
         * GET: {<!-- -->{host}}/api/log/log-types
         */

        const res = await controller.requestGet(logHost.logTypeBase);

        return res?.data;
    } catch (err) {
        throw {
            err: err,
            code: err?.code,
            status: err?.response?.status,
        };
    }
}

const logService = { getLogs, exportLogs, LogType, log, getLogTypes };

export default logService;