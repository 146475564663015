// src/pages/NotFound.js
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import NotFoundBase from '../components/NotFoundBase';

const NotFound = () => {
    return (
        <Layout style={{ height: 'inherit' }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <NotFoundBase />
            </Content>
        </Layout>
    );
};

export default NotFound;
