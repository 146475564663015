// src/components/CardSearchDashboard.js
import { ClearOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, Row, Select, Typography } from 'antd';
import React from 'react';
import { customFormat } from '../../helper/base';

const { Option } = Select;

const CardSearchDashboard = () => {
    const handleSearch = () => {
        console.log(`----  handleSearch`);
    };

    const handleClear = () => {
        console.log(`----  handleClear`);
    };

    return (
        <Card title="ประวัติการยอมรับข้อตกลง">
            <div>
                <Row gutter={[16, 16]} style={{ width: "100%", margin: 0 }}>
                    <Row gutter={[16, 16]} justify="space-between" align="bottom" style={{ width: "100%", margin: 0 }}>
                        <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Typography.Text strong>ชื่อหัวข้อ</Typography.Text>
                            <Select className='select-scroll' placeholder="เลือกหัวข้อ" style={{ width: "100%" }} listItemHeight={10} listHeight={250} mode="multiple">
                                <Option value="topic1">หัวข้อ 1</Option>
                                <Option value="topic2">หัวข้อ 2</Option>
                                <Option value="topic3">หัวข้อ 3</Option>
                                <Option value="topic4">หัวข้อ 4</Option>
                                <Option value="topic5">หัวข้อ 5</Option>
                                <Option value="topic6">หัวข้อ 6</Option>
                                <Option value="topic7">หัวข้อ 7</Option>
                                {/* Add more options as needed */}
                            </Select>
                        </Col>
                        <Col flex="1 1 150px">
                            <Row gutter={[5, 5]}>
                                <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text strong>เวอร์ชั่น</Typography.Text>
                                    <Select className='select-scroll' placeholder="เลือกเวอร์ชั่น" style={{ width: "100%" }} listItemHeight={10} listHeight={250} mode="multiple">
                                        <Option value="version1">เวอร์ชั่น 1</Option>
                                        <Option value="version2">เวอร์ชั่น 2</Option>
                                        {/* Add more options as needed */}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 150px">
                            <Row gutter={[5, 5]}>
                                <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text strong>บริษัทประกัน</Typography.Text>
                                    <Select className='select-scroll' placeholder="เลือกบริษัทประกัน" style={{ width: "100%" }} listItemHeight={10} listHeight={250} mode="multiple">
                                        <Option value="company1">บริษัทประกัน 1</Option>
                                        <Option value="company2">บริษัทประกัน 2</Option>
                                        {/* Add more options as needed */}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 150px">
                            <Row gutter={[5, 5]}>
                                <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text strong>ชื่อ</Typography.Text>
                                    <Input placeholder="ชื่อ-สกุล" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} justify="space-between" align="bottom" style={{ width: "100%", margin: 0 }}>
                        <Col flex="1 1 150px">
                            <Row gutter={[5, 5]}>
                                <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text strong>วันที่เริ่มต้น</Typography.Text>
                                    <DatePicker
                                        format={customFormat} placeholder="เลือกวันที่เริ่มต้น" style={{ width: '100%' }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 150px">
                            <Row gutter={[5, 5]}>
                                <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text strong>วันที่สิ้นสุด</Typography.Text>
                                    <DatePicker
                                        format={customFormat} placeholder="เลือกวันที่สิ้นสุด" style={{ width: '100%' }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="1 1 150px">
                            <Row gutter={[5, 5]}>
                                <Col flex="1 1 150px" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography.Text strong>สถานะ</Typography.Text>
                                    <Select className='select-scroll' placeholder="เลือกสถานะ" style={{ width: '100%' }} listItemHeight={10} listHeight={250} mode="multiple">
                                        <Option value="status1">สถานะ 1</Option>
                                        <Option value="status2">สถานะ 2</Option>
                                        {/* Add more options as needed */}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row gutter={[16, 16]} justify="start" align="middle">
                                <Col>
                                    <Button type="primary" onClick={handleSearch} style={{ paddingLeft: '30px', paddingRight: '30px', }} icon={<FileSearchOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ค้นหา</Button>
                                </Col>
                                <Col>
                                    <Button type="primary" onClick={handleClear} style={{ background: 'var(--color-gray-primary)', }} icon={<ClearOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ล้างการค้นหา</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </div>
        </Card>
    );
};

export default CardSearchDashboard;
