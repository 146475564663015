//1.องค์ประกอบของงบแสดงฐานะการเงิน (วินาศ)
import React, { useEffect, useState, useRef } from 'react'
import { InputNumber, Input, Button, Col, Row, Flex, Form, Image } from 'antd';
import { MinusCircleOutlined, MinusOutlined } from '@ant-design/icons';

function FinancialPositionGi({ content, setDataRows, setSectionId, dataSection, setSubmittable, checkTextInput }) {
    const { TextArea } = Input;
    const [form] = Form.useForm()
    const { setFieldsValue, getFieldValue } = form
    const [result, setResult] = useState({
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
    });
    const [section, setSection] = useState();
    const inputRef = useRef(null);

    const formatNumber = (value) => {
        return `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const onBlurName = (index, field, value) => {
        let formData = form.getFieldValue();
        setDataRows(form.getFieldsValue('items'));
        validateFields(formData);
    };

    const handleChange = (e) => {
        let { value } = e.target;
        value = value.replace(/[^0-9\.,-]/g, '');
        const negativeSign = value.startsWith('-') ? '-' : '';
        const [integerPart, decimalPart] = value.replace('-', '').split('.');
        const notManusintegerPart = integerPart.replace(/[^0-9\.,]/g, '');
        const resetComma = notManusintegerPart.replace(/,/g, '');
        const formattedInteger = resetComma.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        e.target.value = decimalPart !== undefined ? `${negativeSign}${formattedInteger}.${decimalPart.replace(/-/g, '')}` : `${negativeSign}${formattedInteger}`;
    };

    const validateFields = (fields) => {
        for (const item of fields.items) {
            if (item.item_name === '') {
                return setSubmittable(false);
            }
            for (const detail of item.details) {
                if (detail.value === '' || detail.value === null) {
                    return setSubmittable(false);
                }
            }
        }
        return setSubmittable(true);
    }

    const onBlurValue = (index, field, value) => {
        const values = form.getFieldsValue();
        const formatNumberString = (num) => {
            return num.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        };

        const convertString = values?.items.map(item => ({
            ...item,
            details: item.details.map(detail => ({
                ...detail,
                value: formatNumberString(detail.value)
            }))
        }));
        form.setFieldsValue({
            items: convertString
        });
        const details = convertString.flatMap(item => item.details);

        const sumByDetailId = details.reduce((acc, curr) => {

            if (!acc[curr.msd_detail_id]) {
                acc[curr.msd_detail_id] = 0;
            }
            acc[curr.msd_detail_id] += parseFloat(curr.value.replace(/,/g, '')) || 0;
            return acc;
        }, {});
        setDataRows(form.getFieldsValue('items'));
        setResult(sumByDetailId);
        validateFields(form.getFieldsValue('items'));
    };

    const desiredOrderSec1 = [6, 7, 8, 9, 10, 11, 12, 13];
    const desiredOrderSec2 = [19, 20, 21, 22, 23, 24, 25, 26];

    const sortDetails = (details, order) => {
        return order.map(id => {
            return details.find(item => item.msd_detail_id === id);
        });
    };

    const sortedSetdataSec1 = dataSection.map(item => {
        return {
            ...item,
            details: sortDetails(item.details, desiredOrderSec1)
        };
    })
    const sortedSetdataSec2 = dataSection.map(item => {
        return {
            ...item,
            details: sortDetails(item.details, desiredOrderSec2)
        };
    })

    useEffect(() => {
        if (content == 'วันสิ้นปีบัญชีล่าสุด (สิ้นปีก่อน)') {
            if (dataSection?.length == 0) {
                setFieldsValue({
                    items: [
                        {
                            data_name: '',
                            details: [
                                { msd_detail_id: 19, value: '0.00' },
                                { msd_detail_id: 20, value: '0.00' },
                                { msd_detail_id: 21, value: '0.00' },
                                { msd_detail_id: 22, value: '0.00' },
                                { msd_detail_id: 23, value: '0.00' },
                                { msd_detail_id: 24, value: '0.00' },
                                { msd_detail_id: 25, value: '0.00' },
                                { msd_detail_id: 26, value: '0.00' },
                            ]
                        },
                    ]
                })
            } else {
                setFieldsValue({
                    items: sortedSetdataSec2
                })
            }
            setSectionId(2);
            setSection(2);
            onBlurValue();
        } else {
            setSectionId(1);
            setSection(1);
            if (dataSection.length == 0) {
                setFieldsValue({
                    items: [
                        {
                            item_name: '',
                            details: [
                                { msd_detail_id: 6, value: '0.00' },
                                { msd_detail_id: 7, value: '0.00' },
                                { msd_detail_id: 8, value: '0.00' },
                                { msd_detail_id: 9, value: '0.00' },
                                { msd_detail_id: 10, value: '0.00' },
                                { msd_detail_id: 11, value: '0.00' },
                                { msd_detail_id: 12, value: '0.00' },
                                { msd_detail_id: 13, value: '0.00' },
                            ]
                        },
                    ]
                })
            } else {
                setFieldsValue({
                    items: sortedSetdataSec1
                })
            }
            onBlurValue();
        }
    }, [dataSection, section]);


    const addField = () => {
        const formData = form.getFieldValue();
        const newItemSec1 = {
            item_name: '',
            details: [
                { msd_detail_id: 6, value: '0.00' },
                { msd_detail_id: 7, value: '0.00' },
                { msd_detail_id: 8, value: '0.00' },
                { msd_detail_id: 9, value: '0.00' },
                { msd_detail_id: 10, value: '0.00' },
                { msd_detail_id: 11, value: '0.00' },
                { msd_detail_id: 12, value: '0.00' },
                { msd_detail_id: 13, value: '0.00' },
            ]
        };
        const newItemSec2 = {
            item_name: '',
            details: [
                { msd_detail_id: 19, value: '0.00' },
                { msd_detail_id: 20, value: '0.00' },
                { msd_detail_id: 21, value: '0.00' },
                { msd_detail_id: 22, value: '0.00' },
                { msd_detail_id: 23, value: '0.00' },
                { msd_detail_id: 24, value: '0.00' },
                { msd_detail_id: 25, value: '0.00' },
                { msd_detail_id: 26, value: '0.00' },
            ]
        }
        if (section == 1) {
            form.setFieldsValue({
                items: [...(formData.items || []), newItemSec1]
            });
            setDataRows(form.getFieldsValue('items'));
        } else {
            form.setFieldsValue({
                items: [...(formData.items || []), newItemSec2]
            });
            setDataRows(form.getFieldsValue('items'));
        }
        // const formData = form.getFieldValue();
        setDataRows(form.getFieldsValue('items'));

        validateFields(form.getFieldValue());
    }

    const removeField = (index) => {
        const formData = form.getFieldValue();
        if (section === 1) {
            // Filter out the item with the given index for section 1
            const updatedItems = (formData.items || []).filter((_, i) => i !== index);
            form.setFieldsValue({
                items: updatedItems
            });
        } else {
            // Filter out the item with the given index for section 2
            const updatedItems = (formData.items || []).filter((_, i) => i !== index);
            form.setFieldsValue({
                items: updatedItems
            });
        }
        onBlurValue();
        setDataRows(form.getFieldsValue('items'));
    }

    useEffect(() => {
        function incrementInputId(inputId) {
            const result = inputId.match(/(\d+)$/);
            if (result) {
                const num = parseInt(result[0], 10) + 1;
                return inputId.replace(/(\d+)$/, num.toString());
            }
            return inputId;
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const inputs = Array.from(document.querySelectorAll('.input-field'));
                const newInputId = incrementInputId(document.activeElement.id);

                const nextInput = document.getElementById(newInputId);

                if (nextInput) {
                    nextInput.focus();
                } else {

                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Col style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
                <Col style={{ width: '96%' }} span={30}>
                    <div className='bg-[#184E84] pt-1 rounded-t-2xl'>
                        <Row justify="start" align="top" style={
                            {
                                backgroundColor: 'white',
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                                borderWidth: '2px',
                                borderColor: '#E5EBEB',
                                paddingTop: '10px',
                                paddingBottom: '5px',
                            }
                        }>
                            <Col span={2} >
                                <Row align="middle" justify='center' style={{ height: '12rem', fontWeight: 700 }} >
                                    ประเภท
                                </Row>
                            </Col>
                            <Col span={6} >
                                <Flex justify='center' style={{ borderBottom: '2px solid #E5EBEB', fontWeight: 700 }}>
                                    สินทรัพย์จากสัญญาประกันภัย
                                </Flex>
                                <Flex horizontal justify='space-between' align='start'>
                                    <Row style={{ width: '50%', padding: '10px 8px 0 10px', textAlign: 'center', fontWeight: 700 }}>
                                        สินทรัพย์จากสัญญาประกันภัยที่ไม่รวมรายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัย
                                    </Row>
                                    <Row style={{ width: '50%', padding: '10px 10px 0 10px', textAlign: 'center', fontWeight: 700 }}>
                                        รายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัย
                                    </Row>
                                </Flex>
                            </Col>
                            <Col span={5} >
                                <Flex justify='center' style={{ borderBottom: '2px solid #E5EBEB', fontWeight: 700 }}>
                                    หนี้สินจากสัญญาประกันภัย
                                </Flex>
                                <Flex horizontal>
                                    <Row style={{ width: '50%', padding: '10px 8px 0 10px', textAlign: 'center', fontWeight: 700 }}>
                                        หนี้สินจากสัญญาประกันภัยที่ไม่รวมรายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัย
                                    </Row>
                                    <Row style={{ width: '50%', padding: '10px 10px 0 10px', textAlign: 'center', fontWeight: 700 }}>
                                        รายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัย
                                    </Row>
                                </Flex>
                            </Col>
                            <Col span={6} >
                                <Flex justify='center' style={{ borderBottom: '2px solid #E5EBEB', fontWeight: 700, whiteSpace: 'nowrap' }}>
                                    สินทรัพย์จากสัญญาประกันภัยต่อ
                                </Flex>
                                <Flex horizontal justify='space-between' align='start'>
                                    <Row style={{ width: '50%', padding: '10px 8px 0 0px', textAlign: 'center', fontWeight: 700 }}>
                                        สินทรัพย์จากสัญญาประกันภัยต่อที่ไม่รวมรายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัยต่อที่ถือไว้
                                    </Row>
                                    <Row style={{ width: '50%', padding: '10px 10px 0 10px', textAlign: 'center', fontWeight: 700 }}>
                                        รายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัยต่อที่ถือไว้
                                    </Row>
                                </Flex>
                            </Col>

                            <Col span={5} >
                                <Flex justify='center' style={{ borderBottom: '2px solid #E5EBEB', fontWeight: 700 }}>
                                    หนี้สินจากสัญญาประกันภัยต่อ
                                </Flex>
                                <Flex horizontal justify='space-between' align='start'>
                                    <Row style={{ width: '50%', padding: '10px 8px 0 10px', textAlign: 'center', fontWeight: 700 }}>
                                        หนี้สินจากสัญญาประกันภัยต่อที่ไม่รวมรายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัยต่อที่ถือไว้
                                    </Row>
                                    <Row style={{ width: '50%', padding: '10px 10px 0 10px', textAlign: 'center', fontWeight: 700 }}>
                                        รายการที่เกิดขึ้นก่อนรับรู้กลุ่มของสัญญาประกันภัยต่อที่ถือไว้
                                    </Row>
                                </Flex>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col style={{ width: '35px', paddingLeft: 10 }}>
                </Col>
            </Row>
            <Row style={{ width: '100%', marginTop: 10 }}>
                <Col style={{ width: '96%' }} span={30}>
                    <Row justify="start" align="middle">
                        <Col span={2} style={{
                            paddingLeft: '0px',
                            paddingRight: '5px',
                            gap: '5px',
                        }}>
                            <Flex justify='end' align='center'>
                                รวม
                            </Flex>
                        </Col>
                        <Col span={6} >
                            <Row style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                gap: '5px',
                            }}>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[6])}` : ` ${formatNumber(result[19])}`}
                                </Flex>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[7])}` : `${formatNumber(result[20])}`}

                                </Flex>
                            </Row>
                        </Col>
                        <Col span={5} >
                            <Row style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                gap: '5px',
                            }}>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[8])}` : ` ${formatNumber(result[21])}`}
                                </Flex>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[9])}` : `${formatNumber(result[22])}`}

                                </Flex>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                gap: '5px',
                            }}>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[10])}` : ` ${formatNumber(result[23])}`}
                                </Flex>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[11])}` : `${formatNumber(result[24])}`}

                                </Flex>
                            </Row>
                        </Col>
                        <Col span={5}>
                            <Row style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                gap: '5px',
                            }}>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[12])}` : ` ${formatNumber(result[25])}`}
                                </Flex>
                                <Flex style={{
                                    width: '48%', background: '#E5EBEB', padding: '0.5rem', whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'right'
                                }} justify='end' align='center'>
                                    {section == 1 ? `${formatNumber(result[13])}` : `${formatNumber(result[26])}`}

                                </Flex>
                            </Row>
                        </Col>
                    </Row>

                </Col>
                <Col style={{ width: '35px', paddingLeft: 10 }}>
                </Col>
            </Row>

            <Form form={form} style={{ marginTop: 20 }}>
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row style={{ width: '100%' }} key={field.key}>
                                    <Col style={{ width: '96%' }} span={30}>
                                        <Row justify="start" align="start">
                                            <Col span={2} style={{
                                                paddingLeft: '0px',
                                                paddingRight: '5px',
                                                gap: '5px',
                                            }}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'item_name']}
                                                    fieldKey={[field.fieldKey, 'item_name']}
                                                    style={{ width: '100%' }}
                                                    validateStatus={checkTextInput == true ? form.getFieldValue('items')[field.name].item_name == '' ? 'error' : '' : ''}
                                                >
                                                    <TextArea
                                                        className="input-field"
                                                        id={`input-${field.key}-0`}
                                                        autoSize={{ minRows: 1 }}
                                                        style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                                                        placeholder="-- กรุณากรอกข้อมูล --"
                                                        onBlur={onBlurName}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Form.List name={[field.name, 'details']}>
                                                {(detailFields) => (
                                                    <>
                                                        {detailFields.map((detailField, detailIndex) => (
                                                            <>
                                                                {detailField.key == 0 || detailField.key == 2 || detailField.key == 4 || detailField.key == 6 ?
                                                                    <Col span={detailField.key == 0 || detailField.key == 4 ? 6 : 5} id='input-insurance-services'>
                                                                        <Row style={{
                                                                            paddingLeft: '0px',
                                                                            paddingRight: '0px',
                                                                            gap: '5px',
                                                                        }}>
                                                                            <Form.Item
                                                                                {...detailField.detailRestField}
                                                                                name={[detailField.name, 'value']}
                                                                                style={{ width: '48%' }}
                                                                            >
                                                                                <Input
                                                                                    className="input-field"
                                                                                    id={`input-${field.key}-${detailField.key + 1}`}
                                                                                    controls={false}
                                                                                    formatter={value => {
                                                                                        return `${parseFloat(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                                                    }}
                                                                                    onBlur={e => {
                                                                                        const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                                                                        newDetails[detailField.name].value = formatNumber(Number(e.target.value.replace(/,/g, '')));
                                                                                        form.setFieldsValue({
                                                                                            items: [
                                                                                                ...form.getFieldValue('items').slice(0, field.name),
                                                                                                {
                                                                                                    ...form.getFieldValue(['items', field.name]),
                                                                                                    details: newDetails,
                                                                                                },
                                                                                                ...form.getFieldValue('items').slice(field.name + 1),
                                                                                            ],
                                                                                        });
                                                                                        onBlurValue();
                                                                                    }}
                                                                                    ref={inputRef}
                                                                                    onFocusCapture={e => {
                                                                                        setTimeout(() => {
                                                                                            inputRef.current.select();
                                                                                        }, 0);
                                                                                        const value = form.getFieldValue('items')[field.name].details[detailField.name].value;
                                                                                        const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                                                                        if (value === '0.00' || value === 0 || value === '') {
                                                                                            newDetails[detailField?.name].value = '';
                                                                                        } else {
                                                                                            newDetails[detailField?.name].value = value;
                                                                                        }
                                                                                        form.setFieldsValue({
                                                                                            items: [
                                                                                                ...form.getFieldValue('items').slice(0, field.name),
                                                                                                {
                                                                                                    ...form.getFieldValue(['items', field.name]),
                                                                                                    details: newDetails,
                                                                                                },
                                                                                                ...form.getFieldValue('items').slice(field.name + 1),
                                                                                            ],
                                                                                        });
                                                                                    }}
                                                                                    onChangeCapture={handleChange}
                                                                                    style={{
                                                                                        width: '100%', whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                {...detailField.detailRestField}
                                                                                name={[detailField.name + 1, 'value']}
                                                                                style={{ width: '48%' }}
                                                                            >
                                                                                <Input
                                                                                    id={`input-${field.key}-${detailField.key + 2}`}
                                                                                    controls={false}
                                                                                    formatter={value => {
                                                                                        return `${parseFloat(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                                                    }}
                                                                                    onBlur={e => {
                                                                                        const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                                                                        newDetails[detailField.name + 1].value = formatNumber(Number(e.target.value.replace(/,/g, '')));
                                                                                        form.setFieldsValue({
                                                                                            items: [
                                                                                                ...form.getFieldValue('items').slice(0, field.name),
                                                                                                {
                                                                                                    ...form.getFieldValue(['items', field.name]),
                                                                                                    details: newDetails,
                                                                                                },
                                                                                                ...form.getFieldValue('items').slice(field.name + 1),
                                                                                            ],
                                                                                        });
                                                                                        onBlurValue();
                                                                                    }}
                                                                                    ref={inputRef}
                                                                                    onFocusCapture={e => {
                                                                                        setTimeout(() => {
                                                                                            inputRef.current.select();
                                                                                        }, 0);
                                                                                        const value = form.getFieldValue('items')[field.name].details[detailField.name + 1].value;
                                                                                        const newDetails = form.getFieldValue(['items', field.name, 'details']);
                                                                                        if (value === '0.00' || value === 0 || value === '') {
                                                                                            newDetails[detailField?.name + 1].value = '';
                                                                                        } else {
                                                                                            newDetails[detailField?.name + 1].value = value;
                                                                                        }
                                                                                        form.setFieldsValue({
                                                                                            items: [
                                                                                                ...form.getFieldValue('items').slice(0, field.name),
                                                                                                {
                                                                                                    ...form.getFieldValue(['items', field.name]),
                                                                                                    details: newDetails,
                                                                                                },
                                                                                                ...form.getFieldValue('items').slice(field.name + 1),
                                                                                            ],
                                                                                        });
                                                                                    }}
                                                                                    onChangeCapture={handleChange}
                                                                                    style={{
                                                                                        width: '100%', whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Row>
                                                                    </Col >
                                                                    : null
                                                                }
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            </Form.List>
                                        </Row>
                                    </Col>
                                    <Col style={{ width: '35px', paddingLeft: 10 }}>
                                        {fields.length > 1 ?
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        removeField(field.name)
                                                    }}
                                                    icon={<MinusOutlined />}
                                                    style={{ background: '#FF6658', color: 'white' }}
                                                />
                                            </Form.Item> : null
                                        }
                                    </Col>
                                </Row>
                            ))}

                            <Form.Item>
                                <Button form={form}
                                    onClick={addField}
                                    style={{
                                        marginTop: '40px',
                                        borderStyle: 'dashed', // Set border style to dashed
                                        borderColor: 'black', // Set border color
                                        borderWidth: '1px',
                                        width: '96%',// Set border width
                                    }}>
                                    เพิ่มรายการ
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form >
        </Col >
    )
}

export default FinancialPositionGi