// src/components/ReportISC02.js
import { Col, Image, Layout, Row, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import CardSearchReportISC02 from '../../components/reportISC02/CardSearchReportISC02';
import TableReportISC02 from '../../components/reportISC02/TableReportISC02';
import { useFetchReportISC02 } from '../../features/hooks/reportISC02/useFetchReportISC02';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { initialStatePagination } from '../../features/redux/slice/paginationSlice';
import { stringDefault } from '../../helper/base';
import { downloadFile } from '../../helper/downloadFile';
import { IconInformation } from '../../helper/icon';
import { reportISC02Host } from '../../server/service/host/reportISC02Host';

const ReportISC02 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC02();

    const {
        tableReportISC02,
    } = useSelector(state => state.table);
    const {
        meta = {},
        data = {},
    } = tableReportISC02
    const {
        company_id = null,
        submission_status_list = [],
        approval_status_list = [],
        report_items = [],
        // pagination = {},
        pagination = initialStatePagination,
    } = data
    console.log(`----  ReportISC02  tableReportISC02:`, tableReportISC02);


    let { infoId = '', submissionId = '', uploadId = '' } = useParams();

    const tooltipInformation = 'รายการที่มีพื้นหลังสีเขียว คือ งวดปัจจุบัน'

    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `` },
    ]


    const handleDownload = (record, url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url // 'https://icert-api.devmas.net/api/report-submission/get-file?report_file_id=d132cfbd-dc5c-424d-8882-94cac4f6ce4f&submission_id=247'
        // let idOnly = url.includes('report-submission') ? url.split('=').slice(1).join('=') : url
        // const id = `a807aa07-919e-450c-ae1f-e4532ded1736`;
        // const urlLoad = `report_file_id=${id}&submission_id=247`;
        if (URL) {
            // dispatch(fetchReportCycleSubmissionDownloadReportFileById(URL)); //not work
            downloadFile(URL, fileName) //work
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    const onClickFileExcel = (text, record) => {
        const { template_url = '', template_name = '' } = record

        handleDownload(record, template_url, template_name)
    }
    const onClickFilePDF = (text, record) => {
        const { index_doc_url = '', index_doc_name = '' } = record

        handleDownload(record, index_doc_url, index_doc_name)
    }
    const onClickExport = (text, record) => {
        const { report_url = '', report_name = '' } = record

        handleDownload(record, report_url, report_name)
    }
    const onClickViewMore = (text, record) => {
        let url = reportISC02Host.reportISC02InfoByIdBase(stringDefault(record?.cycle_id, '/-'))
        console.log(`----  ReportISC02  url:`, url);
        dispatch(setLoadingTimeout());
        setTimeout(() => {
            navigate(url);
        }, 1000);
    }



    useEffect(() => {
        // dispatch(setTableReportISC02(mockTableReportISC0201));
        return () => {
        }
    }, []);

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            {/*TODO - Delete when finish */}
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC02 />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>

                            <Col style={{ width: "100%", margin: 0 }}>
                                <Row justify="end" align="bottom">
                                    <Col>
                                        <Tooltip title={tooltipInformation} key='tooltip-information-isc02-report' style={{ verticalAlign: 'sub', }} >
                                            <Image width={18} src={IconInformation} preview={false} style={{ cursor: 'pointer', verticalAlign: 'middle', }} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Col>

                            <Col style={{ width: "100%", }}>
                                <TableReportISC02
                                    dataList={report_items}
                                    pagination={pagination}
                                    onClickFileExcel={onClickFileExcel}
                                    onClickFilePDF={onClickFilePDF}
                                    onClickExport={onClickExport}
                                    onClickViewMore={onClickViewMore}
                                />
                            </Col>

                        </Row>
                    </Col >
                </Row >
            </Content >
        </Layout >
    );
};

export default ReportISC02;

