import { combineReducers } from 'redux';
import countdownReducer from './slice/countdownSlice';
import modalReducer from './slice/modalSlice';
import paginationReducer from './slice/paginationSlice';
import searchReducer from './slice/searchSlice';
import sidebarReducer from './slice/sidebarSlice';
import tableReducer from './slice/tableSlice';
import userReducer from './slice/userSlice';
import loadingReducer from './slice/loadingSlice';
import uploadFileReducer from './slice/uploadFileSlice';
import alertMsgReducer from './slice/alertMsgSlice';
import reportISCTabReducer from './slice/reportISCTabSlice';
import configReducer from './slice/configSlice';
import formReducer from './slice/formSlice';
import iscReducer from './slice/iscSlice';
import detailNoteReducer from './slice/detailNoteSlice';

/* API */
import apiBaseReducer from './apiSlice/apiBaseSlice';
import apiReportISCReducer from './apiSlice/apiReportISCSlice';
import apiReportISC02Reducer from './apiSlice/apiReportISC02Slice';
/* API */


const rootReducer = combineReducers({
    countdown: countdownReducer,
    modal: modalReducer,
    pagination: paginationReducer,
    search: searchReducer,
    sidebar: sidebarReducer,
    table: tableReducer,
    user: userReducer,
    loading: loadingReducer,
    uploadFile: uploadFileReducer,
    alertMsg: alertMsgReducer,
    reportISCTab: reportISCTabReducer,
    config: configReducer,
    form: formReducer,
    isc: iscReducer,
    detailNote: detailNoteReducer,
    /* API */
    apiBase: apiBaseReducer,

    apiReportISC: apiReportISCReducer,
    apiReportISC02: apiReportISC02Reducer,
    /* API */
});

export default rootReducer;