import { Spin } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoading } from '../features/redux/slice/loadingSlice';

const Loading = ({ children }) => {
    const dispatch = useDispatch();
    const { isLoading, isLoadingTimeout, loadingTimeout } = useSelector(state => state.loading);
    // const containerRef = useRef(null);
    // const resizeObserverRef = useRef(null);

    // const debounce = (func, wait) => {
    //     let timeout;
    //     return (...args) => {
    //         clearTimeout(timeout);
    //         timeout = setTimeout(() => func(...args), wait);
    //     };
    // };

    // useEffect(() => {

    //     const handleResize = debounce((entries) => {
    //         for (let entry of entries) {
    //             console.log('Resized element:', entry?.target);
    //         }
    //     }, 1);

    //     resizeObserverRef.current = new ResizeObserver(handleResize);

    //     if (containerRef?.current) {
    //         resizeObserverRef?.current.observe(containerRef?.current);
    //     }

    //     return () => {
    //         if (resizeObserverRef?.current) {
    //             resizeObserverRef?.current.disconnect();
    //         }
    //     };
    // }, [resizeObserverRef]);

    useEffect(() => {
        if (isLoadingTimeout) {
            setTimeout(() => {
                try {
                    dispatch(resetLoading());
                } catch (error) {
                    console.error('Error resetting loading state:', error);
                }
            }, loadingTimeout);
        }
    }, [isLoadingTimeout, dispatch, loadingTimeout]);

    useEffect(() => {
        function hideError(e) {
            try {
                if (e?.message === 'ResizeObserver loop completed with undelivered notifications.') {
                    const resizeObserverErrDiv = document.getElementById(
                        'webpack-dev-server-client-overlay-div'
                    );
                    const resizeObserverErr = document.getElementById(
                        'webpack-dev-server-client-overlay'
                    );
                    if (resizeObserverErr) {
                        resizeObserverErr.setAttribute('style', 'display: none');
                    }
                    if (resizeObserverErrDiv) {
                        resizeObserverErrDiv.setAttribute('style', 'display: none');
                    }
                }
            } catch (error) {
            }
        }

        window.addEventListener('error', hideError)
        return () => {
            window.addEventListener('error', hideError)
        }
    }, [])

    return (
        <Spin spinning={isLoading} tip="Loading" size="large" style={{ minHeight: '100vh', zIndex: 9999 }}>
            <div
                // ref={containerRef}
                style={{
                    resize: 'none',
                    overflow: 'hidden',
                    maxWidth: '100%',
                    maxHeight: '100%',
                }}
            >
                {children}
            </div>
        </Spin>
    );
};

export default Loading;
