import { useDispatch } from 'react-redux';
import { fetchReportCycleSubmissionGenerate } from '../../redux/apiSlice/apiReportISCSlice';

export const useFetchReportCycleSubmissionGenerate = () => {
    const dispatch = useDispatch();
    // const { searchReportISC } = useSelector(state => state.search);
    // const { pagination } = useSelector(state => state.pagination);

    // const {
    //     keyword = '',
    //     toDate = null,
    //     fromDate = null,
    //     submissionStatus = [],
    //     approvalStatus = [],
    // } = searchReportISC;

    // const {
    //     page = null,
    //     pageSize = [],
    //     total = [],
    // } = pagination;

    const fetchDataReportCycleSubmissionGenerate = ({ company_id = null, cycle_id = null, is_edit_request = false }) => {
        dispatch(fetchReportCycleSubmissionGenerate(
            {
                "company_id": company_id,
                "cycle_id": cycle_id,
                "is_edit_request": is_edit_request
            }
        ));
    }

    // useEffect(() => {
    //     fetchDataTable()
    // }, [
    //     keyword,
    //     toDate,
    //     fromDate, ,
    //     submissionStatus,
    //     approvalStatus,
    //     page,
    //     pageSize,
    //     total,
    // ]);

    return {
        fetchDataReportCycleSubmissionGenerate: fetchDataReportCycleSubmissionGenerate,
    };
};