// src/components/ReportISC03InfoRef.js
import { Button, Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
// import { fetchReportCycleSubmissionCreateUpload } from '../../features/redux/apiSlice/apiReportISC03Slice';
import { setAlert } from '../../features/redux/slice/alertMsgSlice';
import { setLoadingTimeout } from '../../features/redux/slice/loadingSlice';
import { initialStatePagination } from '../../features/redux/slice/paginationSlice';
import { stringDefault } from '../../helper/base';
import { convertDateFormat } from '../../helper/date';
import { downloadFile } from '../../helper/downloadFile';
// import { reportISC03Host } from '../../server/service/host/reportISC03Host';
import CardSearchReportISC03InfoRef from '../../components/reportISC03/reportISC03InfoRef/CardSearchReportISC03InfoRef';
import TableReportISC03InfoRef from '../../components/reportISC03/reportISC03InfoRef/TableReportISC03InfoRef';
import { reportISC03Host } from '../../server/service/host/reportISC03Host';
import { useFetchReportISC03InfoRef } from '../../features/hooks/reportISC03/useFetchReportISC03InfoRef';
import { fetchReportISCSubmissionSaveData } from '../../features/redux/apiSlice/apiReportISC03Slice';
import { resetIsc03Save } from '../../features/redux/slice/iscSlice';
import ModalReportISC03 from '../../components/reportISC03/modal/ModalReportISC03';
import { setModalReportISC03, setModalReportISC03Save } from '../../features/redux/slice/modalSlice';
import ModalReportISC03Save from '../../components/reportISC03/modal/ModalReportISC03Save';

const ReportISC03InfoRef = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { } = useFetchReportISC03InfoRef();

    const {
        companyInfo,
    } = useSelector(state => state.user);

    const {
        tableReportISC03InfoRef,
    } = useSelector(state => state.table);
    const {
        company_id = null,
    } = companyInfo
    const {
        meta = {},
        data = {},
    } = tableReportISC03InfoRef
    // console.log(tableReportISC03InfoRef);
    const {
        // "submission_id": 0,
        // "items_data": []
        submission_id = '',
        reference_code = null,
        // company_id = null,
        cycle_name = '',
        cycle_id = null,
        can_import = false,
        start_date = '',
        current_date = '',
        due_date = '',
        items_data = [],
        pagination = initialStatePagination,
    } = data
    let { infoId = '', submissionId = '', submissionDetailId = '' } = useParams();
    const dateline = convertDateFormat(due_date) || '-'
    const nameReportISC03 = cycle_name || infoId
    const refReportISC03 = reference_code || submissionId
    const uploadReportISC03 = submissionDetailId
    const [checkDataInput, setCheckDataInput] = useState(false);

    console.log(`reference_code`, reference_code)
    const breadcrumbList = [
        { title: `นำส่งและติดตามผล`, link: `/report-isc-03` },
        { title: `รายงานกระทบยอด ${stringDefault(nameReportISC03, '-')}`, link: reportISC03Host.reportISC03InfoByIdBase(stringDefault(infoId, '/-')) },
        // { title: `รายงานกระทบยอด ไตรมาสที่ 4  2566 ${stringDefault(nameReportISC03, '-')}`, link: reportISC03Host.reportISC03InfoByIdBase(stringDefault(infoId, '/-')) },
        { title: `เลขที่อ้างอิงข้อมูล ${stringDefault(refReportISC03, '-')}`, link: `` },
    ]

    const handleDownload = (record, url, fileName = 'file.xlsx') => {
        dispatch(setLoadingTimeout());
        const URL = url
        if (URL) {
            downloadFile(URL, fileName) //work
        } else {
            setTimeout(() => {
                dispatch(setAlert({ type: 'warning', msg: 'ไม่พบข้อมูลไฟล์' }));
            }, 1000);
        }
    };

    const onClickFileExcel = (record) => {
        console.log(`----  onClickFileExcel  record:`, record);
        const { report_url = '', report_name = '' } = record
        handleDownload(record, report_url, report_name)
    }
    const onClickFileExcelErr = (record) => {
        console.log(`----  onClickFileExcelErr  record:`, record);
        const { error_excel_url = '', error_excel_name = '' } = record
        handleDownload(record, error_excel_url, error_excel_name)
    }
    const onClickSearch = (record) => {
        console.log(`----  onClickSearch  record:`, record);
        dispatch(setLoadingTimeout());
        let url = `/report-isc-03/info/${stringDefault(record.cycle_id || infoId, '-')}/submission/${stringDefault(record.submission_id, '-')}/submission-detail/${stringDefault(record.upload_id, '-')}`
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    }

    const isc03SaveItems = useSelector(state => state.isc.isc03Save.items);
    const { isFormValidISC03 } = useSelector(state => state.form);

    console.log('detail_id', isc03SaveItems)
    // const handleSave = () => {
    //     if (!isFormValidISC03.isFormValid) {
    //         dispatch(setAlert({ type: 'warning', msg: 'กรุณากรอกข้อมูลให้ครบ' }));
    //     } else {
    //         // ดำเนินการบันทึกข้อมูลตามปกติ
    //     }
    // };
    const handleSaveButton = (record) => {
        console.log('isFormValidISC03.isFormValid :', isFormValidISC03.isFormValid)
        if (isFormValidISC03.isFormValid == false) {
            setCheckDataInput(true);
            dispatch(setModalReportISC03Save({ isOpen: true }));
            dispatch(setModalReportISC03({ isOpen: false }));
        } else {
            setCheckDataInput(false);
            console.log('บันทึกข้อมูลเรียบร้อย');
            console.log(`----  handleSaveButton  record:`, record);
            let url = reportISC03Host.reportISC03InfoByIdBase(stringDefault(infoId, '/-'))
            const dataToSubmit = isc03SaveItems.map(item => ({
                "detail_id": Number(item.detail_id),
                "reason": item.reason
            }));
            const payload = {
                "submission_id": Number(submissionId),
                "data": dataToSubmit
            }
            console.log(`----  handleSaveButton  payload:`, payload);
            dispatch(fetchReportISCSubmissionSaveData(payload));
            setTimeout(() => {
                navigate(url);
            }, 1000);
            return {
            }
        }
    };

    // const handleSaveButton = (record) => {
    //     console.log(`----  handleSaveButton  record:`, record);
    //     let url = reportISC03Host.reportISC03InfoByIdBase(stringDefault(infoId, '/-'))
    //     const dataToSubmit = isc03SaveItems.map(item => ({
    //         "detail_id": Number(item.detail_id),
    //         "reason": item.reason
    //     }));
    //     const payload = {
    //         "submission_id": Number(submissionId),
    //         "data": dataToSubmit
    //     }
    //     console.log(`----  handleSaveButton  payload:`, payload);
    //     dispatch(fetchReportISCSubmissionSaveData(payload));
    //     setTimeout(() => {
    //         navigate(url);
    //     }, 1000);
    //     return {
    //     }
    // }
    const handleBackButton = () => {
        console.log('Back button clicked')
        dispatch(resetIsc03Save());
        let url = reportISC03Host.reportISC03InfoByIdBase(stringDefault(infoId, '/-'))
        setTimeout(() => {
            navigate(url);
        }, 1000);
        return {
        }
    }

    const handleOpen = () => {
        dispatch(setModalReportISC03({ isOpen: true, }));
    };
    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <ModalReportISC03
                handleSaveButton={handleSaveButton}
                handleBackButton={handleBackButton}
            />
            <ModalReportISC03Save/>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <CardSearchReportISC03InfoRef nameReportISC03={nameReportISC03} refReportISC03={refReportISC03} />
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', paddingBottom: '62px', }}>
                        <Row gutter={[8, 8]}>
                            <Col style={{ width: "100%", paddingBottom: '32px' }}>
                                <TableReportISC03InfoRef
                                    dataList={items_data}
                                    pagination={pagination}
                                    onClickFileExcel={onClickFileExcel}
                                    onClickFileExcelErr={onClickFileExcelErr}
                                    onClickSearch={onClickSearch}
                                    checkDataInput={checkDataInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row items='middle' justify={'center'} className="py-6">
                    <Button onClick={handleOpen} type="primary" style={{ background: 'var(--color-gray-primary)' }} className="mx-2 px-6 font-bold text-white">ย้อนกลับ</Button>
                    <Button
                        // disabled={!can_save}
                        disabled={!isFormValidISC03.isFormValid}
                        type="primary"
                        className='font-bold'
                        style={{ display: 'flex', paddingLeft: '2rem', paddingRight: '2rem' }}
                        onClick={handleSaveButton}
                    >
                        บันทึก
                    </Button>
                </Row>
            </Content>
        </Layout >
    );
};

export default ReportISC03InfoRef;
