import { createSlice } from '@reduxjs/toolkit';

const initialStateTime = 60000
const initialState = {
    interval_time: initialStateTime,
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig(state, action) {
            const { payload } = action;
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            });
        },
    },
});

export const { setConfig } = configSlice.actions;

export const selectConfig = (state) => state.config;

export default configSlice.reducer;