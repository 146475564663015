import { createSlice } from '@reduxjs/toolkit';
import { initialStatePagination } from './paginationSlice';

export const initialStateTableReport = {}

export const initialState = {
    /* isc01 */
    tableReportISC: initialStateTableReport,
    tableReportISCInfo: initialStateTableReport,
    tableReportISCInfoRef: initialStateTableReport,
    tableReportISCInfoRefError: initialStateTableReport,
    /* isc01 */

    /* isc02 */
    tableReportISC02: initialStateTableReport,
    tableReportISC02Info: initialStateTableReport,
    tableReportISC02InfoRef: initialStateTableReport,
    tableReportISC02InfoRefDetail: initialStateTableReport,
    /* isc02 */

    /* isc03 */
    tableReportISC03: initialStateTableReport,
    tableReportISC03Info: initialStateTableReport,
    tableReportISC03InfoRef: initialStateTableReport,
    tableReportISC03InfoRefDetail: initialStateTableReport,
    /* isc03 */
};

export const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setTableData: (state, action) => {
            const { field, data } = action.payload;
            state[field] = data;
        },

        /* isc01 */
        setTableReportISC: (state, action) => { //dispatch(setTableReportISC(data));
            const { payload = initialStateTableReport } = action;
            state.tableReportISC = payload;
        },
        setTableReportISCInfo: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISCInfo = payload;
        },
        setTableReportISCInfoRef: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISCInfoRef = payload;
        },
        setTableReportISCInfoRefError: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISCInfoRefError = payload;
        },
        /* isc01 */

        /* isc02 */
        setTableReportISC02: (state, action) => { //dispatch(setTableReportISC02(data));
            const { payload = initialStateTableReport } = action;
            state.tableReportISC02 = payload;
        },
        setTableReportISC02Info: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISC02Info = payload;
        },
        setTableReportISC02InfoRef: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISC02InfoRef = payload;
        },
        // setTableReportISC02InfoRefDetail: (state, action) => {
        //     const { payload = initialStateTableReport } = action;
        //     state.tableReportISC02InfoRefDetail = payload;
        // },
        setTableReportISC02InfoRefDetail: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISC02InfoRefDetail = {
                ...payload,
                data: {
                    ...payload.data,
                    pagination: payload?.data?.pagination || initialStatePagination,
                },
            };
        },
        /* isc02 */

        /* isc03 */
        setTableReportISC03: (state, action) => { //dispatch(setTableReportISC03(data));
            const { payload = initialStateTableReport } = action;
            state.tableReportISC03 = payload;
        },
        setTableReportISC03Info: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISC03Info = payload;
        },
        setTableReportISC03InfoRef: (state, action) => {
            const { payload = initialStateTableReport } = action;
            state.tableReportISC03InfoRef = payload;
        },
        resetTableReportISC03InfoRef: (state, action) => {
            state.tableReportISC03InfoRef = initialStateTableReport;
        },
    },
});

export const {
    /* isc01 */
    setTableReportISC,
    setTableReportISCInfo,
    setTableReportISCInfoRef,
    setTableReportISCInfoRefError,
    /* isc01 */

    /* isc02 */
    setTableReportISC02,
    setTableReportISC02Info,
    setTableReportISC02InfoRef,
    setTableReportISC02InfoRefDetail,
    /* isc02 */

    /* isc03 */
    setTableReportISC03,
    setTableReportISC03Info,
    setTableReportISC03InfoRef,
    resetTableReportISC03InfoRef,
    /* isc03 */
} = tableSlice.actions;

export const selectTable = (state) => state.table;

export default tableSlice.reducer;
