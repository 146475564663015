import { useDispatch } from "react-redux";
import { fetchConfigAll } from "../../redux/apiSlice/apiBaseSlice";
import { useEffect } from "react";

const useFetchConfigAll = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchConfigAll())
    }, []);

    return {

    };
};


export default useFetchConfigAll;