import { createSlice } from '@reduxjs/toolkit';

export const initialStateModal = {
    value: {},
    isOpen: false,
    err: {
        msg: '',
        icon: '',
    }
}

export const initialState = {
    /* isc01 */
    modalReportISC: initialStateModal,
    modalReportISCViewDetail: initialStateModal,
    modalUploadReportISC: initialStateModal,
    modalUploadReportISCViewDetail: initialStateModal,
    /* isc01 */

    /* isc02 */
    modalReportISC02: initialStateModal,
    modalReportISC02ViewDetail: initialStateModal,
    modalReportISC02BackDetailNote: initialStateModal,
    modalReportISC02SaveDetailNote: initialStateModal,
    modalUploadReportISC02: initialStateModal,
    modalUploadReportISC02ViewDetail: initialStateModal,
    modalUploadReportISC02ViewDetailNote: initialStateModal,
    modalUploadReportISC02ViewDetailAuditor: initialStateModal,
    modalReportISC02BackDetailAuditor: initialStateModal,
    /* isc02 */

    /* isc03 */
    modalReportISC03: initialStateModal,
    modalReportISC03Save: initialStateModal,
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModalReportISC: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISC(modalData));
            */
            const {
                value = state.modalReportISC.value || initialStateModal.value,
                isOpen = state.modalReportISC.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC.value = value;
            state.modalReportISC.isOpen = isOpen;
            state.modalReportISC.err = err;
        },
        setModalReportISCViewDetail: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISCViewDetail(modalData));
            */
            const {
                value = state.modalReportISCViewDetail.value || initialStateModal.value,
                isOpen = state.modalReportISCViewDetail.isOpen || initialStateModal.isOpen,
                err = state.modalReportISCViewDetail.err || initialStateModal.err,
            } = action.payload
            state.modalReportISCViewDetail.value = value;
            state.modalReportISCViewDetail.isOpen = isOpen;
            state.modalReportISCViewDetail.err = err;
        },
        setModalReportISC02BackDetailNote: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISCViewDetail(modalData));
            */
            const {
                value = state.modalReportISC02BackDetailNote.value || initialStateModal.value,
                isOpen = state.modalReportISC02BackDetailNote.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC02BackDetailNote.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC02BackDetailNote.value = value;
            state.modalReportISC02BackDetailNote.isOpen = isOpen;
            state.modalReportISC02BackDetailNote.err = err;
        },
        setModalReportISC02SaveDetailNote: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISCViewDetail(modalData));
            */
            const {
                value = state.modalReportISC02SaveDetailNote.value || initialStateModal.value,
                isOpen = state.modalReportISC02SaveDetailNote.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC02SaveDetailNote.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC02SaveDetailNote.value = value;
            state.modalReportISC02SaveDetailNote.isOpen = isOpen;
            state.modalReportISC02SaveDetailNote.err = err;
        },
        setModalUploadReportISC: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalUploadReportISC(modalData));
            */
            const {
                value = state.modalReportISC.value || initialStateModal.value,
                isOpen = state.modalReportISC.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC.err || initialStateModal.err,
            } = action.payload
            state.modalUploadReportISC.value = value;
            state.modalUploadReportISC.isOpen = isOpen;
            state.modalUploadReportISC.err = err;
        },
        setModalUploadReportISCViewDetail: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalUploadReportISCViewDetail(modalData));
            */
            const {
                value = state.modalReportISC.value || initialStateModal.value,
                isOpen = state.modalReportISC.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC.err || initialStateModal.err,
            } = action.payload
            state.modalUploadReportISCViewDetail.value = value;
            state.modalUploadReportISCViewDetail.isOpen = isOpen;
            state.modalUploadReportISCViewDetail.err = err;
        },
        /* isc01 */

        /* isc02 */
        setModalReportISC02: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISC02(modalData));
            */
            const {
                value = state.modalReportISC02.value || initialStateModal.value,
                isOpen = state.modalReportISC02.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC02.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC02.value = value;
            state.modalReportISC02.isOpen = isOpen;
            state.modalReportISC02.err = err;
        },
        setModalReportISC02ViewDetail: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISC02ViewDetail(modalData));
            */
            const {
                value = state.modalReportISC02ViewDetail.value || initialStateModal.value,
                isOpen = state.modalReportISC02ViewDetail.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC02ViewDetail.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC02ViewDetail.value = value;
            state.modalReportISC02ViewDetail.isOpen = isOpen;
            state.modalReportISC02ViewDetail.err = err;
        },
        setModalUploadReportISC02ViewDetailNote: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(modalUploadReportISC02ViewDetailNote(modalData));
            */
            const {
                value = state.modalUploadReportISC02ViewDetailNote.value || initialStateModal.value,
                isOpen = state.modalUploadReportISC02ViewDetailNote.isOpen || initialStateModal.isOpen,
                err = state.modalUploadReportISC02ViewDetailNote.err || initialStateModal.err,
            } = action.payload
            state.modalUploadReportISC02ViewDetailNote.value = value;
            state.modalUploadReportISC02ViewDetailNote.isOpen = isOpen;
            state.modalUploadReportISC02ViewDetailNote.err = err;
        },
        setModalUploadReportISC02ViewDetailAuditor: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(modalUploadReportISC02ViewDetailNote(modalData));
            */
            const {
                value = state.modalUploadReportISC02ViewDetailAuditor.value || initialStateModal.value,
                isOpen = state.modalUploadReportISC02ViewDetailAuditor.isOpen || initialStateModal.isOpen,
                err = state.modalUploadReportISC02ViewDetailAuditor.err || initialStateModal.err,
            } = action.payload
            state.modalUploadReportISC02ViewDetailAuditor.value = value;
            state.modalUploadReportISC02ViewDetailAuditor.isOpen = isOpen;
            state.modalUploadReportISC02ViewDetailAuditor.err = err;
        },
        setModalUploadReportISC02: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalUploadReportISC02(modalData));
            */
            const {
                value = state.modalReportISC02.value || initialStateModal.value,
                isOpen = state.modalReportISC02.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC02.err || initialStateModal.err,
            } = action.payload
            state.modalUploadReportISC02.value = value;
            state.modalUploadReportISC02.isOpen = isOpen;
            state.modalUploadReportISC02.err = err;
        },
        setModalUploadReportISC02ViewDetail: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalUploadReportISC02ViewDetail(modalData));
            */
            const {
                value = state.modalReportISC02.value || initialStateModal.value,
                isOpen = state.modalReportISC02.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC02.err || initialStateModal.err,
            } = action.payload
            state.modalUploadReportISC02ViewDetail.value = value;
            state.modalUploadReportISC02ViewDetail.isOpen = isOpen;
            state.modalUploadReportISC02ViewDetail.err = err;
        },
        setModalReportISC02BackDetailAuditor: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISC02BackDetailAuditor(modalData));
            */
            const {
                value = state.modalReportISC02BackDetailAuditor.value || initialStateModal.value,
                isOpen = state.modalReportISC02BackDetailAuditor.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC02BackDetailAuditor.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC02BackDetailAuditor.value = value;
            state.modalReportISC02BackDetailAuditor.isOpen = isOpen;
            state.modalReportISC02BackDetailAuditor.err = err;
        },
        /* isc02 */

        /* isc03 */
        setModalReportISC03: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISC03(modalData));
            */
            const {
                value = state.modalReportISC03.value || initialStateModal.value,
                isOpen = state.modalReportISC03.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC03.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC03.value = value;
            state.modalReportISC03.isOpen = isOpen;
            state.modalReportISC03.err = err;
        },
        setModalReportISC03Save: (state, action) => {
            /* 
            const modalData = {
                isOpen: true,
                err: {
                    msg: 'Some error message'
                }
            };
            dispatch(setModalReportISC03(modalData));
            */
            const {
                value = state.modalReportISC03Save.value || initialStateModal.value,
                isOpen = state.modalReportISC03Save.isOpen || initialStateModal.isOpen,
                err = state.modalReportISC03Save.err || initialStateModal.err,
            } = action.payload
            state.modalReportISC03Save.value = value;
            state.modalReportISC03Save.isOpen = isOpen;
            state.modalReportISC03Save.err = err;
        },
    },
});

export const {
    /* isc01 */
    setModalReportISC,
    setModalReportISCViewDetail,
    setModalUploadReportISC,
    setModalUploadReportISCViewDetail,
    /* isc01 */

    /* isc02 */
    setModalReportISC02,
    setModalReportISC02ViewDetail,
    setModalReportISC02BackDetailNote,
    setModalReportISC02SaveDetailNote,
    setModalUploadReportISC02ViewDetailNote,
    setModalUploadReportISC02ViewDetailAuditor,
    setModalUploadReportISC02,
    setModalUploadReportISC02ViewDetail,
    setModalReportISC02BackDetailAuditor,
    /* isc02 */

    /* isc03 */
    setModalReportISC03,
    setModalReportISC03Save,
} = modalSlice.actions;

export const selectModal = (state) => state.modal;

export default modalSlice.reducer;

